import { Pipe, PipeTransform } from '@angular/core';
import { EntryGroup } from "../../../interfaces/cart";

@Pipe({
  name: 'countEntryGroupsQuantity'
})
export class CountEntryGroupsQuantityPipe implements PipeTransform {

  transform(entryGroups: EntryGroup[]): number {
    return entryGroups?.length ? entryGroups.reduce((acc: number, entryGroup: any) => {
      let increment: number;
      if (entryGroup.entries?.length) {
        increment = entryGroup.entries[0]?.quantity;
      } else {
        increment = entryGroup?.entryGroups?.length ? entryGroup.entryGroups[0]?.entries[0]?.quantity : 0;
      }
      acc += increment;
      return acc;
    }, 0) : 0;
  }

}
