import { Injectable, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { CxEvent, EventService } from "@spartacus/core";
import { LAUNCH_CALLER, LaunchDialogService } from "@spartacus/storefront";
import { CartAddEntryFailEvent } from "@spartacus/cart/base/root";
import { take } from "rxjs/operators";
import { subscriptionCodes } from "src/app/spartacus/configs";

export class CartUiEventAddToCart extends CxEvent {
    static override type = "CartUiEventAddToCart";
    productCode: string;
    quantity: number;
    numberOfEntriesBeforeAdd: number;
    pickupStoreName?: string;
    gaListName?: string;
}

@Injectable({
    providedIn: 'root',
})
export class CustomAddedToCartDialogEventListener implements OnDestroy {
    protected subscription = new Subscription();

    constructor(
        protected eventService: EventService,
        protected launchDialogService: LaunchDialogService
    ) {
        this.onAddToCart();
    }

    protected onAddToCart() {
        this.subscription.add(
            this.eventService.get(CartUiEventAddToCart).subscribe((event) => {
                this.openModal(event);
            })
        );

        this.subscription.add(
            this.eventService.get(CartAddEntryFailEvent).subscribe((event) => {
                this.closeModal(event);
            })
        );
    }

    protected openModal(event: CartUiEventAddToCart): void {
        const addToCartData = {
            productCode: event.productCode,
            quantity: event.quantity,
            numberOfEntriesBeforeAdd: event.numberOfEntriesBeforeAdd,
            gaListName: event.gaListName,
        };

        const dialog = this.launchDialogService.openDialog(
            LAUNCH_CALLER.ADDED_TO_CART,
            undefined,
            undefined,
            addToCartData
        );

        if (dialog) {
            dialog.pipe(take(1)).subscribe();
        }
    }

    protected closeModal(reason?: any): void {
        this.launchDialogService.closeDialog(reason);
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }
}
