import { ChangeDetectionStrategy, Component, ElementRef, HostListener } from '@angular/core';
import { EventService, GlobalMessageService, GlobalMessageType, RoutingService } from '@spartacus/core';
import { FavoritesService } from 'src/app/services/favorites.service';
import { Observable, Subscription, of, throwError } from 'rxjs';
import { FocusConfig, ICON_TYPE, LaunchDialogService } from "@spartacus/storefront";
import { catchError, finalize, switchMap, take, tap } from 'rxjs/operators';
import { ActiveCartFacade, Cart, MultiCartFacade } from '@spartacus/cart/base/root';
import { FavotitesDialogActionModes, FavoritesListItem } from 'src/app/interfaces/favorites.model';
import { isObjectEmpty } from 'src/app/shared/helpers/is-empty';

@Component({
  selector: 'favorites-confirm-action-dialog',
  templateUrl: './favorites-confirm-action-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavoritesConfirmActionDialogComponent {
  private subscription = new Subscription();
  iconTypes = ICON_TYPE;
  userId: string;
  mode: FavotitesDialogActionModes;
  list: FavoritesListItem;
  loading: boolean = false;

  focusConfig: FocusConfig = {
    trap: true,
    block: true,
    autofocus: 'button',
    focusOnEscape: true,
  };

  @HostListener('click', ['$event'])
  handleClick(event: UIEvent): void {
    // Close on click outside the dialog window
    if ((event.target as any).tagName === this.el.nativeElement.tagName) {
      this.close('Cross click');
    }
  }

  constructor(
    protected launchDialogService: LaunchDialogService,
    protected el: ElementRef,
    protected eventService: EventService,
    protected routingService: RoutingService,
    protected favoritesService: FavoritesService,
    protected activeCartFacade: ActiveCartFacade,
    protected globalMessageService: GlobalMessageService,
    private multiCartFacade: MultiCartFacade,
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.launchDialogService.data$.subscribe(
        (data: any) => {
          this.userId = data.userId;
          this.mode = data.mode as FavotitesDialogActionModes;
          this.list = data.list;
        }
      )
    );
  }

  private deleteFavoriteList(listId: string): Observable<{}> {
    return this.favoritesService.removeFavoritesList(this.userId, listId);
  };

  private removeAllItemsFromList(listId: string): Observable<FavoritesListItem> {
    return this.favoritesService.cleanFavoritesList(this.userId, listId);
  };

  private addAllItemsToCart(listId: string): Observable<any> {
    return this.getActiveCart().pipe(
      take(1),
      switchMap((cart) => this.favoritesService.addAllItemsToCart(this.userId, cart.code, listId))
    );
  }

  private getActiveCart(): Observable<Cart> {
    return this.activeCartFacade.getActive().pipe(
      take(1),
      switchMap(activeCart => {
        return isObjectEmpty(activeCart) ? this.multiCartFacade.createCart({
          userId: this.userId,
          extraData: {
            active: true,
          }
        }) : of(activeCart);
      })
    )
  }

  public confirmAction(listId: string): Subscription {
    this.loading = true;
    let request: Observable<any>;

    switch (this.mode) {
      case FavotitesDialogActionModes.RemoveList:
        request = this.deleteFavoriteList(listId);
        break;
      case FavotitesDialogActionModes.DeleteItems:
        request = this.removeAllItemsFromList(listId);
        break;
      case FavotitesDialogActionModes.AddAll:
        request = this.addAllItemsToCart(listId);
        break;
    }

    return request.pipe(
      take(1),
      catchError((error) => {
        let errorMessage = (error.error?.errors?.length && error.error.errors[0]?.message) || error?.error?.message || 'Something Went Wrong';
        this.globalMessageService.add(
          errorMessage,
          GlobalMessageType.MSG_TYPE_ERROR,
        );
        this.close('Close a dialog');
        return throwError(error);
      }),
      tap(() => this.close(this.mode || 'Close a dialog')),
      finalize(()=> this.loading = false)
    ).subscribe();
  }

  public close(reason: string): void {
    this.launchDialogService.closeDialog(reason);
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.close('Close a dialog');
  }
}
