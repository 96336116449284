import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ICON_TYPE } from "@spartacus/storefront";

@Component({
  selector: 'cx-offline-badge',
  templateUrl: './offline-badge.component.html',
  styleUrls: ['./offline-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OfflineBadgeComponent {
  @Input() internalCalculation: boolean = false;

  icon = ICON_TYPE.INFO;
  offlineMessage: string;
  tooltipMessage: string = 'Pricing is not currently available. Orders will be placed with MSRP pricing.';
}
