import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from "@angular/platform-browser";
import { SafeType } from "./safe-type.enum";

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer) {
  }

  transform(value: string, safeType?: SafeType): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    switch (safeType) {
      case SafeType.HTML:
        return this.sanitizer.bypassSecurityTrustHtml(value);
      case SafeType.Styles:
        return this.sanitizer.bypassSecurityTrustStyle(value);
      case SafeType.Script:
        return this.sanitizer.bypassSecurityTrustScript(value);
      case SafeType.URL:
        return this.sanitizer.bypassSecurityTrustUrl(value);
      case SafeType.ResourceUrl:
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      default:
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }
  }
}
