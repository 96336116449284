import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CartItemComponentOptions, CartItemContext, CartOutlets, PromotionLocation } from '@spartacus/cart/base/root';
import { ICON_TYPE } from '@spartacus/storefront';
import { CartItemContextSource } from '@spartacus/cart/base/components';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'cx-cart-item',
  templateUrl: './cart-item.component.html',
  providers: [
    CartItemContextSource,
    {provide: CartItemContext, useExisting: CartItemContextSource},
  ],
})
export class CartItemComponent implements OnChanges {
  @Input() compact = false;
  imageContainer: {
    product: any,
    thumbnail: any
  };

  _item: any;
  get item(): any {
    return this._item;
  }
  @Input() set item(value: any) {
    if ((value.product?.images?.PRIMARY || value.product?.images?.PRIMARY === undefined)
      && !Array.isArray(value.product?.images)) {
      this.imageContainer = value.product?.image?.PRIMARY;
    } else {
      const product = value.product.images?.find((i: any) => i.format === 'product');
      const thumbnail = value.product.images?.find((i: any) => i.format === 'thumbnail');
      this.imageContainer = {
        product,
        thumbnail
      };
    }
    this._item = value;
  }

  @Input() readonly = false;
  @Input() quantityControl: UntypedFormControl;
  @Input() promotionLocation: PromotionLocation = PromotionLocation.ActiveCart;

  // TODO: evaluate whether this is generic enough
  @Input() options: CartItemComponentOptions = {
    isSaveForLater: false,
    optionalBtn: null,
    displayAddToCart: false,
  };

  iconTypes = ICON_TYPE;
  readonly CartOutlets = CartOutlets;

  constructor(protected cartItemContextSource: CartItemContextSource) {
  }

  ngOnChanges(changes?: SimpleChanges) {
    if (changes?.compact) {
      this.cartItemContextSource.compact$.next(this.compact);
    }
    if (changes?.readonly) {
      this.cartItemContextSource.readonly$.next(this.readonly);
    }
    if (changes?.item) {
      this.cartItemContextSource.item$.next(this.item);
    }
    if (this.quantityControl && changes?.quantityControl) {
      this.cartItemContextSource.quantityControl$.next(this.quantityControl);
    }
    if (changes?.promotionLocation) {
      this.cartItemContextSource.location$.next(this.promotionLocation);
    }
    if (changes?.options) {
      this.cartItemContextSource.options$.next(this.options);
    }
  }

  isProductOutOfStock(product: any): boolean {
    // TODO Move stocklevelstatuses across the app to an enum
    return (
      product &&
      product.stock &&
      product.stock.stockLevelStatus === 'outOfStock'
    );
  }

  removeItem() {
    this.quantityControl.setValue(0);
    this.quantityControl.markAsDirty();
  }
}
