import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BasePageMetaResolver, PageMetaResolver, PageTitleResolver, TranslationService } from "@spartacus/core";

@Injectable({
  providedIn: 'root'
})
export class GeneracShippingAccountsResolver extends PageMetaResolver implements PageTitleResolver {
  constructor(
    protected translation: TranslationService,
    protected basePageMetaResolver: BasePageMetaResolver,
  ) {
    super();
    this.pageUid = 'shipping-accounts' as any;
  }

  resolveTitle(): Observable<string> {
    return this.translation.translate('pageMetaResolver.shippingAccounts.title')
  }
}
