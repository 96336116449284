import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener } from '@angular/core';
import { EventService, I18nModule, RoutingService } from '@spartacus/core';
import { ProductExtended } from 'src/app/interfaces/product';
import { FavoritesService } from 'src/app/services/favorites.service';
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';
import { FocusConfig, FormErrorsModule, ICON_TYPE, IconModule, KeyboardFocusModule, LaunchDialogService } from "@spartacus/storefront";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { catchError, filter, finalize, switchMap, take, tap } from 'rxjs/operators';
import { FavoritesListItem, FavoritesListResponse } from 'src/app/interfaces/favorites.model';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';

@Component({
  selector: 'add-to-favorites-dialog',
  templateUrl: './add-to-favorites-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    I18nModule,
    IconModule,
    FormErrorsModule,
    ReactiveFormsModule,
    FormsModule,
    KeyboardFocusModule,
  ]
})
export class AddToFavoritesDialogComponent {
  private subscription = new Subscription();
  iconTypes = ICON_TYPE;
  layoutOption: string | undefined;
  userId: string;
  productCode: string;
  product: ProductExtended;
  customListName: string;
  loading$ = new BehaviorSubject(false);
  favoritesLists: FavoritesListItem[];
  isInit: boolean = true;
  listsStateObj: { [key: string]: number } = {};
  gaListName: string;
  gaProductCategories: { name: string; }[];

  focusConfig: FocusConfig = {
    trap: true,
    block: true,
    autofocus: 'button',
    focusOnEscape: true,
  };

  @HostListener('click', ['$event'])
  handleClick(event: UIEvent): void {
    // Close on click outside the dialog window
    if ((event.target as any).tagName === this.el.nativeElement.tagName) {
      this.close('Cross click');
    }
  }

  constructor(
    protected launchDialogService: LaunchDialogService,
    protected el: ElementRef,
    protected eventService: EventService,
    protected routingService: RoutingService,
    protected favoritesService: FavoritesService,
    protected googleAnalyticsService: GoogleAnalyticsService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.launchDialogService.data$.pipe(
        take(1),
        tap((data: any) => {
          this.userId = data?.userId;
          this.productCode = data?.productCode;
          this.gaListName = data?.gaListName;
          this.gaProductCategories = data?.gaProductCategories;
        }),
        switchMap(() => this.getFavoritesLists(this.userId, this.productCode)),
      ).subscribe()
    );
  }

  private getFavoritesLists(userId: string, productCode: string): Observable<FavoritesListResponse> {
    return this.favoritesService.getFavoritesLists(userId, productCode)
      .pipe(
        tap((res) => {
          this.favoritesLists = res.lists;
          this.cdr.markForCheck();
        }),
        filter(res => this.isInit || (res.lists?.length > 0 && Object.keys(this.listsStateObj).length - 1 !== res.lists?.length)),
        tap(res => this.setListsStates(res.lists))
      )
  }

  private setListsStates(lists: FavoritesListItem[]) {
    if(lists) {
      lists.forEach(list => {
        if(this.listsStateObj[list.id] == undefined) {
          this.listsStateObj[list.id] = this.isInit ? 0 : 1;
        }
      })
    }
    this.isInit = false;
  }

  private changeListState(state: boolean, listId: string) {
    this.listsStateObj[listId] = state ? this.listsStateObj[listId] + 1 : this.listsStateObj[listId] - 1;
  }

  private sendAddToWishlistGAEvent(product: ProductExtended): void {
    const productWithCategories = { ...product, categories: this.gaProductCategories };
    const gaItem = this.googleAnalyticsService.mapProductToGaItem(productWithCategories, 0, 1, this.gaListName);
    this.googleAnalyticsService.sendGaEvent('add_to_wishlist', {
      currency: product.price?.currencyIso,
      value: product.price?.value,
      items: [{ ...gaItem, item_list_id: this.gaListName?.toLowerCase().trim().replace(/ /g, '_') }]
    });
  }

  public addToList(listId: string, listName: string): Observable<ProductExtended> {
    return this.favoritesService.addToFavorites(this.userId, this.productCode, listId, listName)
  }

  public removeFromList(listId: string): Observable<ProductExtended> {
    return this.favoritesService.deleteFromFavorites(this.userId, this.productCode, listId);
  }

  public selectList($target: any, list: FavoritesListItem): Subscription {
    const listSelected = (<HTMLInputElement>$target).checked;
    const request = listSelected ? this.addToList(list?.id, list?.name) : this.removeFromList(list.id);
    this.loading$.next(true);
    return request.pipe(
      tap((product: ProductExtended) => {
        this.customListName = '';
        this.product = product;
        this.changeListState(listSelected, list.id);
        if(listSelected) {
          this.sendAddToWishlistGAEvent(this.product);
        }
        this.cdr.markForCheck();
      }),
      switchMap(() => this.getFavoritesLists(this.userId, this.productCode)),
      catchError(() => of(this.loading$.next(false))),
      finalize(() => this.loading$.next(false))
    ).subscribe();
  }

  public apply(): void {
    this.close('Close Add To Favorites Dialog');
  }

  public close(reason: string): void {
    this.launchDialogService.closeDialog(this.product || reason);
  }

  public getLoadingStatus(): boolean {
    return this.loading$.value;
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.close('close dialog');
  }
}
