/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from "@angular/core";
import {
  BASE_SITE_CONTEXT_ID,
  BaseSiteService, getContextParameterDefault,
  OccConfig,
  OccEndpointsService,
} from "@spartacus/core";

@Injectable({
  providedIn: 'root',
})
// @ts-ignore
export class CustomOccEndpointsService extends OccEndpointsService {

  constructor(
    config: OccConfig,
    baseSiteService: BaseSiteService
  ) {
    super(config, baseSiteService);
  }

  private override get activeBaseSite(): string {
    return this['_activeBaseSite'] || getContextParameterDefault(this['config'], BASE_SITE_CONTEXT_ID) || localStorage.getItem('baseSite');
  }

}
