<ng-container *ngIf="order$ | async as order">
  <ng-container *ngIf="!(isOrderLoading$ | async)">
    <generac-order-overview
      *ngIf="!isSplitWfOrderConfirmationPage; else expandableOrderOverviewTmpl"
      [order]="order">
    </generac-order-overview>
    <ng-template #expandableOrderOverviewTmpl>
      <mat-accordion>
        <mat-expansion-panel [expanded]="false">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Order Information
                </mat-panel-title>
                <!-- <mat-panel-description>

                </mat-panel-description> -->
            </mat-expansion-panel-header>
            <generac-order-overview [order]="order"></generac-order-overview>
        </mat-expansion-panel>
    </mat-accordion>
    </ng-template>
  </ng-container>
</ng-container>