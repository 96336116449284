/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { BaseSiteService, SiteContextConfig, SiteContextSelectors, StateWithSiteContext } from "@spartacus/core";
import { filter, tap } from "rxjs/operators";

@Injectable()
export class CustomBaseSiteService extends BaseSiteService {
  constructor(
    protected override store: Store<StateWithSiteContext>,
    protected override config: SiteContextConfig
  ) {
    super(store, config);
  }

  /**
   * Represents the current baseSite uid.
   */
  override getActive(): Observable<string> {
    return this.store.pipe(
      select(SiteContextSelectors.getActiveBaseSite),
      filter((active) => Boolean(active)),
      tap(activeBaseSite => localStorage.setItem('baseSite', activeBaseSite))
    );
  }
}
