import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseSiteService, OccEndpointsService } from "@spartacus/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { filter, share, switchMap, take, tap } from "rxjs/operators";
import { AvailableSites, CssThemes, sitesByTheme } from "../enums/themes.enum";
import { GeneracBaseSite } from "../interfaces/base-site";

@Injectable({
  providedIn: "root"
})
export class BaseStoreService {
  phoneNumber$: Observable<string>;
  phoneNumberRes$$ = new BehaviorSubject(null);
  tooltipMessage$: Observable<string>;
  tooltipMessageRes$$ = new BehaviorSubject(null);
  currentSite$: Observable<AvailableSites>;
  private currentSite$$ = new BehaviorSubject<AvailableSites>(null);

  constructor(
    private httpClient: HttpClient,
    private occEndpoints: OccEndpointsService,
    protected baseSiteService: BaseSiteService,
  ) {
    this.currentSite$ = this.currentSite$$.asObservable().pipe(filter(site => !!site));
  }

  getPhoneNumber(): Observable<any> {
    const url = `${this.occEndpoints.getBaseUrl()}/b2bcontact/info/${localStorage.getItem('companyNumber')}`;
    if (this.phoneNumberRes$$.value && !localStorage.getItem('B2BChanged')) {
      return of(this.phoneNumberRes$$.value);
    } else {
      if (!this.phoneNumber$ || localStorage.getItem('B2BChanged')) {
        localStorage.removeItem('B2BChanged');
        this.phoneNumber$ = this.httpClient.get<any>(url)
          .pipe(
            share(),
            tap((res: any) => {
              if (res) this.phoneNumberRes$$.next(res);
            })
          );
      }
      return this.phoneNumber$;
    }
  }

  getTooltipMessage(): Observable<any> {
    const url = `${this.occEndpoints.getBaseUrl()}/b2bcontact/tooltip/${localStorage.getItem('companyNumber')}`;
    if (this.tooltipMessageRes$$.value && !localStorage.getItem('B2BChanged')) {
      return of(this.tooltipMessageRes$$.value);
    } else {
      if (!this.tooltipMessage$ || localStorage.getItem('B2BChanged')) {
        this.tooltipMessage$ = this.httpClient.get<any>(url)
          .pipe(
            share(),
            tap((res: any) => {
              if (res) this.tooltipMessageRes$$.next(res);
              localStorage.removeItem('B2BChanged');
            })
          );
      }
      return this.tooltipMessage$;
    }
  }

  setCurrentSite() {
    if(!this.currentSite$$.getValue()) {
      this.baseSiteService.getActive()
        .pipe(
          take(1),
          tap((baseSiteName) => this.setSiteName(baseSiteName)),
          switchMap(baseSiteName => this.baseSiteService.get(baseSiteName)),
          tap((activeBaseSite: GeneracBaseSite) => localStorage.setItem('isServicePartsEnabled', activeBaseSite?.enableServiceParts))
        ).subscribe();
    }
  }

  private setSiteName(baseSiteName: string) {
    const theme = baseSiteName.replace(/Site/g, '') as CssThemes;
    this.currentSite$$.next(sitesByTheme[theme]);
  }
}