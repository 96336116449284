<cx-carousel
  *ngIf="items$ | async as items"
  role="region"
  [attr.aria-label]="
    'productCarousel.carouselLabel' | cxTranslate : { title: title$ | async }
  "
  [items]="items"
  [title]="title$ | async"
  [template]="carouselItem"
  itemWidth="285px"
>
</cx-carousel>

<ng-template
  #carouselItem
  let-item="item"
>
  <cx-product-carousel-item [item]="item" [gaListName]="gaListName"></cx-product-carousel-item>
</ng-template>
