import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { StateWithProduct } from '@spartacus/core';
import { CustomProductActions } from '../spartacus/features/product/store/actions';

@Injectable({
  providedIn: 'root',
})
export class CustomProductStateService {
  constructor(private store: Store<StateWithProduct>) {}

  clearProductScope(): void {
    this.store.dispatch(new CustomProductActions.CustomClearProductScope());
  }
}
