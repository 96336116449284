import { Image } from "@spartacus/core";
import { CartModificationList } from "@spartacus/cart/base/root/models/cart.model";

export enum ImageFormat {
  Zoom = 'zoom',
  Product = 'product',
  Thumbnail = 'thumbnail',
  CartIcon = 'cartIcon',
  SuperZoom = 'superZoom'
}

export interface BundleItemPrice {
  code?: string;
  currency: string;
  unit: string;
  value: string;
}

export interface BundlePrice {
  products?: BundleItemPrice[]
  totalPrice?: string;
  errorMessages?: string[];
}

export type BundlePriceResponse = BundlePrice[];

export class BundleResponse {
  bundleTemplates: Bundle[];
  pagination?: BundlePagination;
}

export class BundlePagination {
  count: number;
  page: number;
  totalCount: number;
  totalPages: number;
}

export interface BundleItem {
  code: string;
  images: Image[];
  name: string;
  price?: BundleItemPrice;
}

export interface Bundle {
  id: string;
  items: BundleItem[];
  maxItemsAllowed: number;
  name: string;
  rootBundleTemplateName: string;
  type: string;
  version: string;
}

export interface ExtendedBundle extends Bundle {
  totalPrice: string;
  priority: number;
  product: BundleItem;
  errorMessages?: string[];
}

export interface BundleError {
  message: string;
  type: string;
}

export interface AddToBundleResponse extends CartModificationList {
  error?: {
    message: string[];
    type?: string;
  }
}