import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, HostListener } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { FocusConfig, FormErrorsModule, ICON_TYPE, IconModule, KeyboardFocusModule, LaunchDialogService } from "@spartacus/storefront";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'warning-dialog',
  templateUrl: './warning-dialog.component.html',
  styleUrls: ['./warning-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    I18nModule,
    IconModule,
    FormErrorsModule,
    ReactiveFormsModule,
    FormsModule,
    KeyboardFocusModule,
  ]
})
export class WarningDialogComponent {
  private subscription = new Subscription();
  iconTypes = ICON_TYPE;
  layoutOption: string | undefined;

  message: string;

  focusConfig: FocusConfig = {
    trap: true,
    block: true,
    autofocus: 'button',
    focusOnEscape: true,
  };

  @HostListener('click', ['$event'])
  handleClick(event: UIEvent): void {
    // Close on click outside the dialog window
    if ((event.target as any).tagName === this.el.nativeElement.tagName) {
      this.close('Cross click');
    }
  }

  constructor(
    protected launchDialogService: LaunchDialogService,
    protected el: ElementRef,
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.launchDialogService.data$.subscribe(
        (data: any) => {
          this.message = data.message;
        }
      )
    );
  }

  public close(reason: string): void {
    this.launchDialogService.closeDialog(reason);
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.close('close dialog');
  }
}
