<div
        *ngIf="_messages?.length"
        [class]="type + '-message'"
        class="custom-message"
        [ngClass]="{'cornered': isCornered}"
>
    <span class="alert-icon">
        <cx-icon class="cx-icon fas fa-exclamation-circle"></cx-icon>
    </span>
    <div class="custom-message-list">
        <p *ngFor="let message of _messages;trackBy: trackById">{{message}}</p>
    </div>
    <button type="button" class="close" (click)="clearMessages()">
        <cx-icon class="cx-icon fas fa-times">
    </cx-icon>
    </button>
</div>