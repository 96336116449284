import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { I18nModule } from '@spartacus/core';
import { AtMessageModule, FacetListComponent, FormErrorsModule, IconModule, KeyboardFocusModule } from '@spartacus/storefront';
import { GeneracFacetComponent } from './generac-facet/facet.component';

@Component({
  selector: 'gc-facet-list',
  standalone: true,
  templateUrl: './facet-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    I18nModule,
    IconModule,
    FormErrorsModule,
    ReactiveFormsModule,
    FormsModule,
    KeyboardFocusModule,
    AtMessageModule,
    RouterModule,
    GeneracFacetComponent,
  ]
})
export class GeneracFacetListComponent extends FacetListComponent {

}
