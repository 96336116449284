import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

@Directive({
  selector: '[cxSizeChanged]'
})
export class SizeChangedDirective implements OnInit, OnDestroy {

  @Output() cxSizeChanged = new EventEmitter<Element>();
  private resizeObserver: ResizeObserver;
  constructor(private elementRef: ElementRef) {
  }

  ngOnInit(): void {
    this.resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        this.cxSizeChanged.emit(entry.target);
      }
    });
    this.resizeObserver.observe(this.elementRef.nativeElement);
  }

  ngOnDestroy(): void {
    this.resizeObserver.unobserve(this.elementRef.nativeElement);
  }

}
