import { ChangeDetectionStrategy, ChangeDetectorRef, Component, } from '@angular/core';
import { PaginationModel, ProductScope, ProductService } from "@spartacus/core";
import { ExtendedBundle } from "../../interfaces/bundle.model";
import { Subscription } from "rxjs";
import { BundleService } from "../../services/bundle.service";
import { CustomerService } from "../../services/customer.service";
import { distinctUntilChanged, filter, first, switchMap, take, tap } from "rxjs/operators";
import { LoadingEnum } from "../../enums/loading.enum";
import { ProductExtended } from "../../interfaces/product";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'cx-bundle-list',
  templateUrl: './product-bundle-list.component.html',
  styleUrls: ['./product-bundle-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductBundleListComponent {
  pagination: PaginationModel;
  bundles: ExtendedBundle[] = [];
  loading: LoadingEnum = LoadingEnum.Idle;
  LoadingEnum = LoadingEnum;
  product: ProductExtended;
  private unit: string;
  private subscription = new Subscription();

  constructor(
    private bundleService: BundleService,
    private cdr: ChangeDetectorRef,
    private customerService: CustomerService,
    private productService: ProductService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.product = {
      code: this.route.snapshot.url[1].path
    };
    this.getBundles();
    this.subscribeToLoadingState();
    this.subscribeToProduct();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  trackByBundle(_: number, bundle: ExtendedBundle) {
    return bundle.id;
  }

  addBundleToTheCart(bundle: ExtendedBundle) {
    this.bundleService.addToCart(bundle.id, bundle.product.code)
      .pipe(filter(response => !!response?.error?.message?.length))
      .subscribe(response => {
        bundle.errorMessages = response?.error?.message;
      });
  }

  onPaginate(paginate: number): void {
    this.loading = LoadingEnum.Loading;
    this.bundleService.getBundlesList(this.unit, this.product.code, paginate).pipe(
      first()
    ).subscribe(([pagination, bundles]) => {
      this.pagination = pagination;
      this.bundles = bundles;
      this.cdr.detectChanges();
    });
  }

  private getBundles() {
    this.subscription.add(
      this.customerService.getCurrentB2bUnit().pipe(
        filter(unit => !!unit),
        distinctUntilChanged(),
        tap(unit => this.unit = unit),
        switchMap((unit) => this.bundleService.getBundlesList(unit, this.product.code)),
      ).subscribe(([pagination, bundles]) => {
        this.pagination = pagination;
        this.bundles = bundles;
        this.cdr.markForCheck();
      }),
    )
  }

  private subscribeToProduct() {
    this.subscription.add(
      this.productService.get(this.product.code, [
        ProductScope.DETAILS,
        ProductScope.PRICE,
      ]).pipe(
        filter((product) => !!product),
        take(1)
      ).subscribe(product => this.product = product)
    )
  }

  private subscribeToLoadingState() {
    this.subscription.add(
      this.bundleService.loading$
        .subscribe(loading => {
          this.loading = loading;
          this.cdr.markForCheck();
        })
    );
  }

}
