<ng-container *ngIf="order$ | async as order">
  <div class="cx-page-header">
    <span class="cx-page-title">
      {{ 'checkoutOrderConfirmation.confirmationOfOrder' | cxTranslate }}
      {{ order.replenishmentOrderCode ?? order.code }}
    </span>
  </div>

  <div class="cx-order-confirmation-message">
    <h2 *ngIf="!order.replenishmentSchedule?.interval">
      {{ (isSplitWfOrderConfirmationPage ? 'checkoutOrderConfirmation.thankYouWf' : 'checkoutOrderConfirmation.thankYou') | cxTranslate }}
    </h2>
    <h2 *ngIf="order.replenishmentSchedule?.interval">Thank you for your replenishment order!</h2>
      <p *ngIf="!order.internalCalculation && !order.prospectOrder">
          {{ (isSplitWfOrderConfirmationPage ? 'checkoutOrderConfirmation.invoiceHasBeenSentByEmailWf' : 'checkoutOrderConfirmation.invoiceHasBeenSentByEmail') | cxTranslate }}
      </p>
      <p *ngIf="!order.replenishmentSchedule?.interval && !order.internalCalculation && order.prospectOrder"    >
          {{ 'checkoutOrderConfirmation.callToRepresentativeDAOrder' | cxTranslate }}
      </p>
      <p *ngIf="!order.replenishmentSchedule?.interval && order.internalCalculation && (phoneNumber$ | async) as phoneNumber"
         class="internal-calculation">
          Your order has been created. Upon system restoration, your sales rep will contact you to confirm the order and order history will update with your correct net pricing.
      </p>
  </div>

  <div *ngIf="isSplitWfOrderConfirmationPage" class="continue-with-order">
    <cx-icon class="regular-icon active continue-with-order-icon" [type]="icon"></cx-icon>
    <h2 class="continue-with-order-title">{{ 'checkoutOrderConfirmation.continueWithOrderTitle' | cxTranslate }}</h2>
    <p class="continue-with-order-text">{{ 'checkoutOrderConfirmation.continueWithOrderText' | cxTranslate }}</p>
    <button class="btn btn-primary continue-with-order-btn">{{ 'checkoutOrderConfirmation.continueWithOrder' | cxTranslate }}</button>
  </div>

  <div *ngIf="isGuestCustomer">
    <cx-guest-register-form
      [guid]="orderGuid"
      [email]="order.paymentInfo.billingAddress.email"
    ></cx-guest-register-form>
  </div>

  <cx-add-to-home-screen-banner></cx-add-to-home-screen-banner>
</ng-container>
