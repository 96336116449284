import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, FeaturesConfigModule, I18nModule, provideDefaultConfig, UrlModule } from '@spartacus/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll'; 
import { AtMessageModule, IconModule, ItemCounterModule, ListNavigationModule, MediaModule, OutletModule, PageComponentModule, SpinnerModule, StarRatingModule, ViewConfig, defaultViewConfig } from '@spartacus/storefront';
import { ProductListComponent } from './container/product-list.component';
import { ProductScrollComponent } from './container/product-scroll/product-scroll.component';
import { ProductListItemComponent } from './product-list-item/product-list-item.component';
import { ProductViewComponent } from './product-view/product-view.component';
import { CustomProductAddToCartModule } from '../../cart/components/custom-product-add-to-cart/custom-product-add-to-cart.module';
import { CustomProductAddToCartComponent } from '../../cart/components/custom-product-add-to-cart/custom-product-add-to-cart.component';
import { ProductGridItemModule } from './product-grid-item/product-grid-item.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { InfoMessageModule } from 'src/app/shared/components/info-message/info-message.module';
import { AddToFavoritesComponent } from '../../favorites/add-to-favorites/add-to-favorites.component';
import { InfoPriceWrapperComponent } from "../components/info-price-wrapper/info-price-wrapper.component";
import { GeneracDocumentListComponent } from '../document-list/document-list.component';
import { GeneracDocumentListItemComponent } from '../document-list/document-list-item/document-list-item.component';
import { GeneracSpFlammableComponent } from 'src/app/shared/components/generac-sp-flammable/generac-sp-flammable.component';
import { GeneracSpLtlComponent } from 'src/app/shared/components/generac-sp-ltl/generac-sp-ltl.component';
import { SafeModule } from 'src/app/shared/pipes/safe/safe.module';

@NgModule({
  imports: [
    AtMessageModule,
    CommonModule,
    FeaturesConfigModule,
    I18nModule,
    IconModule,
    InfiniteScrollModule,
    ItemCounterModule,
    SharedModule,
    ListNavigationModule,
    MediaModule,
    ProductGridItemModule,
    OutletModule,
    PageComponentModule,
    CustomProductAddToCartModule,
    RouterModule,
    SpinnerModule,
    InfoMessageModule,
    StarRatingModule,
    UrlModule,
    SafeModule,
    AddToFavoritesComponent,
    InfoPriceWrapperComponent,
    GeneracSpLtlComponent,
    GeneracSpFlammableComponent,
  ],
  providers: [
    provideDefaultConfig(<ViewConfig>defaultViewConfig),
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        CMSProductListComponent: {
          component: ProductListComponent,
          data: {
            composition: {
              inner: ['ProductAddToCartComponent'],
            },
          },
        },
        ProductGridComponent: {
          component: ProductListComponent,
          data: {
            composition: {
              inner: ['ProductAddToCartComponent'],
            },
          },
        },
        SearchResultsListComponent: {
          component: ProductListComponent,
          data: {
            composition: {
              inner: ['ProductAddToCartComponent'],
            },
          },
        },
        ProductAddToCartComponent: {
          component: CustomProductAddToCartComponent,
          data: {
            otherProperties: {
              shouldUseCallForPricingTooltip: true
            }
          }
        },
      },
    }),
  ],
  declarations: [
    ProductListComponent,
    ProductListItemComponent,
    ProductViewComponent,
    ProductScrollComponent,
    GeneracDocumentListComponent,
    GeneracDocumentListItemComponent,
  ],
  exports: [
    ProductListComponent,
    ProductListItemComponent,
    ProductViewComponent,
    ProductScrollComponent,
    GeneracDocumentListComponent,
    GeneracDocumentListItemComponent,
  ],
})
export class ProductListModule { }
