import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NoReturnWarningComponent } from './no-return-warning.component';
import { IconModule } from "@spartacus/storefront";
import { SafeModule } from "../../../../../shared/pipes/safe/safe.module";

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    SafeModule
  ],
  declarations: [NoReturnWarningComponent],
  exports: [NoReturnWarningComponent],
})
export class NoReturnWarningModule {}
