import { HttpClient } from '@angular/common/http';
import { Injectable, InjectionToken } from '@angular/core';
import { Cart, CART_NORMALIZER } from '@spartacus/cart/base/root';
import { Converter, ConverterService, Occ, OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { SavedCartAdapter } from '../../core/connectors';
import {
  GeneracNotification
} from "../../../../../interceptors/notifications-response/notifications-response.interceptor";
import { CartExtended } from 'src/app/interfaces/cart';
@Injectable()
export class OccSavedCartAdapter implements SavedCartAdapter {
  EXT_CART_NORMALIZER: InjectionToken<Converter<any, CartExtended>>

  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService
  ) {}

  load(userId: string, cartId: string, cartOwner: string): Observable<CartExtended> {
    return this.http
      .get<{savedCartData: CartExtended, generacNotifications: GeneracNotification[] }>(this.getSavedCartEndpoint(userId, cartId, cartOwner))
      .pipe(
        map(response => {
          return {
            ...response?.savedCartData,
            isEmptyAfterBackendCleanup: !!response?.generacNotifications?.length && (response?.savedCartData.entryGroups?.length === 0)
          }
        }),
        this.converter.pipeable(this.EXT_CART_NORMALIZER)
      );
  }

  loadList(userId: string): Observable<Cart[]> {
    return this.http
      .get<Occ.CartList>(this.getSavedCartListEndpoint(userId))
      .pipe(
        pluck('carts'),
        map((carts) => carts ?? []),
        this.converter.pipeableMany(CART_NORMALIZER)
      );
  }

  restoreSavedCart(userId: string, cartId: string, cartOwner?: string): Observable<CartExtended> {
    return this.http
      .patch<{savedCartData: CartExtended, generacNotifications: GeneracNotification[] }>(this.getRestoreSavedCartEndpoint(userId, cartId, cartOwner), cartId)
      .pipe(
        map(response => {
          return {
            ...response?.savedCartData,
            isEmptyAfterBackendCleanup: !!response?.generacNotifications?.length && (response?.savedCartData.entryGroups?.length === 0)
          }
        }),
        this.converter.pipeable(this.EXT_CART_NORMALIZER)
      );
  }

  cloneSavedCart(
    userId: string,
    cartId: string,
    saveCartName: string,
    cartOwner: string
  ): Observable<Cart> {
    return this.http
      .post<Occ.Cart>(
        this.getCloneSavedCartEndpoint(userId, cartId, saveCartName, cartOwner),
        cartId
      )
      .pipe(pluck('savedCartData'), this.converter.pipeable(CART_NORMALIZER));
  }

  protected getSavedCartEndpoint(userId: string, cartId: string, cartOwner: string): string {
    return this.occEndpoints.buildUrl('savedCart', {
      urlParams: { userId, cartId, cartOwner },
    });
  }

  protected getSavedCartListEndpoint(userId: string): string {
    return this.occEndpoints.buildUrl('savedCarts', { urlParams: { userId } });
  }

  protected getRestoreSavedCartEndpoint(
    userId: string,
    cartId: string,
    cartOwner: string
  ): string {
    return this.occEndpoints.buildUrl('restoreSavedCart', {
      urlParams: { userId, cartId, cartOwner },
    });
  }

  protected getCloneSavedCartEndpoint(
    userId: string,
    cartId: string,
    saveCartName: string,
    cartOwner: string,
  ): string {
    return this.occEndpoints.buildUrl('cloneSavedCart', {
      urlParams: { userId, cartId, saveCartName, cartOwner },
    });
  }
}
