import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountEntryGroupsQuantityPipe } from './count-entry-groups-quantity.pipe';

@NgModule({
  declarations: [
    CountEntryGroupsQuantityPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CountEntryGroupsQuantityPipe
  ],
  providers: [
    CountEntryGroupsQuantityPipe
  ]
})
export class CountEntryGroupsQuantityModule { }
