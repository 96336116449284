import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslationService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { InfoMessageModule } from '../info-message/info-message.module';

@Component({
  selector: 'generac-sp-ltl',
  templateUrl: './generac-sp-ltl.component.html',
  styleUrls: ['./generac-sp-ltl.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    InfoMessageModule,
  ]
})
export class GeneracSpLtlComponent {
  @Input() ltl: boolean;
  @Input() position: string = 'position-right';

  ltlMessage: string = '';
  tooltipLtlMessage$: Observable<string>;

  constructor(
    protected translation: TranslationService,
  ) {
    this.tooltipLtlMessage$ = this.translation.translate('productList.tooltipLtlMessage');
  }
}
