import { Component, OnInit } from '@angular/core';
import { NavigationEvent } from "@spartacus/storefront";
import { filter, switchMap, take, tap } from "rxjs/operators";
import {
  EventService,
  OccEndpointsService,
  UserIdService,
  WindowRef
} from "@spartacus/core";
import { GoogleAnalyticsService } from "./services/google-analytics.service";
import { BaseStoreService } from "./services/base-store.service";
import { AuthService } from '@auth0/auth0-angular';
import { combineLatest, EMPTY, Observable, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ActiveCartFacade, MultiCartFacade } from '@spartacus/cart/base/root';
import { CustomerService } from './services/customer.service';
import { UserAccountFacade } from '@spartacus/user/account/root';
import packageJson from '../../package.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'spartacusstore';
  subscriptions = new Subscription();

  constructor(
    private eventService: EventService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private baseStoreService: BaseStoreService,
    private http: HttpClient,
    public auth: AuthService,
    private router: Router,
    private occEndpoints: OccEndpointsService,
    private multiCartFacade: MultiCartFacade,
    protected customerService: CustomerService,
    private userAccount: UserAccountFacade,
    private activeCartFacade: ActiveCartFacade,
    private userIdService: UserIdService,
    private windowRef: WindowRef
  ) {
  }

  ngOnInit(): void {
    this.googleAnalyticsService.init();
    this.baseStoreService.setCurrentSite();
    this.subscribeToSearchEvent();
    this.checkReOrdering();
    this.reOrderByOrderNbrState();
    this.logTheApplicationVersion();
  }

  private reOrderByOrderNbrState() {
    this.subscriptions.add(
      this.auth.appState$
        .pipe(
          take(1),
          switchMap(appState => {
            if (appState.orderNbr) {
              return this.reOrder(appState.orderNbr);
            }
            return EMPTY;
          }),
        )
        .subscribe()
    );
  }

  private checkReOrdering(): void {
    const search = new URLSearchParams(this.windowRef.location.search);
    const queryParams: { [key: string]: string } = Object.fromEntries(search as any);

    this.subscriptions.add(
      this.userAccount.get().pipe(
        filter((user) => !!user),
        take(1),
        switchMap(_ => {
          if (queryParams.companyNumber) {
            return this.customerService.setB2BUnit(queryParams.companyNumber, 'current').pipe(
              take(1),
              filter(() => !!queryParams.orderNbr),
              switchMap(() => this.reOrder(queryParams.orderNbr))
            );
          }
          return EMPTY;
        })
      ).subscribe()
    );
  }

  private subscribeToSearchEvent(): void {
    this.eventService
      .get(NavigationEvent)
      .pipe(
        filter(
          (navigationEvent: NavigationEvent) =>
            !!navigationEvent?.semanticRoute?.includes('search')
        )
      ).subscribe((e) => {
        this.googleAnalyticsService.sendGaEvent('search', {
          search_term: e?.params?.query
        });
    });
  }

  private reOrder(orderNbr: string): Observable<any> {
    return combineLatest([
      this.userIdService.takeUserId().pipe(filter(v => !!v)),
      this.activeCartFacade.getActiveCartId().pipe(filter(v => !!v)),
    ]).pipe(
      take(1),
      tap(([userId, cartId]) => {
        if (cartId) {
          this.multiCartFacade.deleteCart(cartId, userId);
        }
      }),
      switchMap(() => this.http.post(
        `${this.occEndpoints.getBaseUrl()}/orgUsers/current/cartFromOrder?orderCode=${orderNbr}`,
        {}
      )),
      tap(() => this.router.navigate(['/cart']))
    )
  }

  private logTheApplicationVersion() {
    console.log('Current application version => ', packageJson?.version);
  }

}
