/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalMessageService, TranslationService } from '@spartacus/core';
import { OrderConfirmationThankYouMessageComponent } from '@spartacus/order/components';
import { Order, OrderFacade } from '@spartacus/order/root';
import { Observable, Subscription } from 'rxjs';
import { filter, first, skip } from 'rxjs/operators';
import { OrderExtended } from 'src/app/interfaces/order';
import { BaseStoreService } from 'src/app/services/base-store.service';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'cx-order-confirmation-thank-you-message',
  templateUrl: './custom-order-confirmation-thank-you-message.component.html',
  styleUrls: ['./custom-order-confirmation-thank-you-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomOrderConfirmationThankYouMessageComponent extends OrderConfirmationThankYouMessageComponent
  implements OnInit, AfterViewInit, OnDestroy {
  override order$: Observable<OrderExtended> = super.order$ as Observable<OrderExtended>;
  phoneNumber$ = this.baseStoreService.getPhoneNumber();
  subscription = new Subscription();

  constructor(
    public override orderFacade: OrderFacade,
    public override globalMessageService: GlobalMessageService,
    public override translationService: TranslationService,
    private customerService: CustomerService,
    private router: Router,
    private baseStoreService: BaseStoreService,
  ) {
    super(orderFacade, globalMessageService, translationService);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.subscribeToUnitChange();
  }

  override ngOnDestroy(): void {
    this.subscription.unsubscribe();
    super.ngOnDestroy();
  }

  private subscribeToUnitChange() {
    this.subscription.add(
      this.customerService.getB2bUnitChanged()
        .pipe(
          skip(1),
          filter(x => !!x)
        ).subscribe(x => this.router.navigate(['/']))
    )
  }
}
