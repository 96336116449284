import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { ICON_TYPE } from "@spartacus/storefront";
import { UserAccountFacade } from "@spartacus/user/account/root";
import { Subscription } from "rxjs";
import { filter, switchMap, take, tap } from "rxjs/operators";
import { CustomIcon } from "src/app/enums/custom-icon.enum";
import { ExtAddress } from "src/app/interfaces/checkout";
import { CheckoutService } from "src/app/services/checkout.service";
import { CustomLaunchDialogService } from "src/app/services/custom-launch-dialog.service";

@Component({
	selector: 'saved-address-item',
	templateUrl: './saved-address-item.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SavedAddressItemComponent implements OnDestroy {
	@Input() savedAddress: ExtAddress;
  @Input() isUserRestricted: boolean;
	@Output() savedAddressDeleted = new EventEmitter();
  iconTypes= {...ICON_TYPE, ...CustomIcon};

	private subscription = new Subscription();

	constructor(
		protected checkoutService: CheckoutService,
		protected launchDialogService: CustomLaunchDialogService,
		protected cd: ChangeDetectorRef,
		private userAccount: UserAccountFacade,
	) { }

	public openEditSavedAddressModal(savedAddress: ExtAddress): void {
    if(this.isUserRestricted) return;

    this.launchDialogService.openDialogAndSubscribe(
      'EDIT_SAVED_ADDRESS',
      undefined,
      { savedAddress }
    );

    this.subscription.add(
      this.launchDialogService.dialogClose
        .pipe(
					filter((data) => !!data?.companyName),
         	take(1),
          switchMap(() =>  this.userAccount.get()),
          switchMap((user) => this.checkoutService.getDropShipAddressById(user.uid, this.savedAddress?.id)),
					tap((savedAddress: ExtAddress) => this.savedAddress = savedAddress),
					tap(() => this.cd.markForCheck())
        )
        .subscribe()
    );
  }

	public openDeleteSavedAddressModal(id: string) {
    if(this.isUserRestricted) return;

		const dialog = this.launchDialogService.openDialog(
      'DELETE_SAVED_ADDRESS',
      undefined,
      undefined,
      { id }
    );

    if (dialog) {
      dialog.pipe(take(1)).subscribe();
    }

    this.subscription.add(
      this.launchDialogService.dialogClose
        .pipe(
					filter((id) => id == this.savedAddress?.id),
         	take(1),
					tap((id) => this.savedAddressDeleted.emit(id)),
        )
        .subscribe()
    );
	}

	ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}