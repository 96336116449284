import {
  ComponentRef,
  ElementRef,
  inject,
  Inject,
  Injectable,
  isDevMode,
  ViewContainerRef,
} from '@angular/core';
import { LoggerService, resolveApplicable } from '@spartacus/core';
import { LAUNCH_CALLER, LaunchOptions, LaunchRenderStrategy, LayoutConfig } from '@spartacus/storefront';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CustomLaunchDialogService {
  private dialogs: { caller: LAUNCH_CALLER | string, component: Observable<ComponentRef<any> | undefined>, close$: Subject<any>, data$: BehaviorSubject<any> }[] = [];
  private _dialogClose = new BehaviorSubject<any | undefined>(undefined);
  private _dataSubject = new BehaviorSubject<any>(undefined);
  protected logger = inject(LoggerService);

  get dialogClose(): Observable<any | undefined> {
    return this.dialogs?.length > 0 ? this.dialogs[this.dialogs.length - 1]?.close$?.asObservable() : this._dialogClose.asObservable();
  }

  get data$(): Observable<any | undefined> {
    return this.dialogs?.length > 0 ? this.dialogs[this.dialogs.length - 1]?.data$?.asObservable() : this._dataSubject.asObservable();
  }

  constructor(
    @Inject(LaunchRenderStrategy)
    protected renderStrategies: LaunchRenderStrategy[],
    protected layoutConfig: LayoutConfig
  ) {
    this.renderStrategies = this.renderStrategies || [];
  }

  openDialog(
    caller: LAUNCH_CALLER | string,
    openElement?: ElementRef,
    vcr?: ViewContainerRef,
    data?: any
  ): Observable<any> | undefined {
    const close$ = new Subject<any>();
    const data$ = new BehaviorSubject<any>(data);
    const component = this.launch(caller, vcr, data$);

    if (component) {
      this.dialogs.push({ caller, component, close$, data$ });

      return combineLatest([component, close$]).pipe(
        filter(([, close]) => close !== undefined),
        tap(([comp]) => {
          openElement?.nativeElement.focus();
          this.clear(caller);
          comp?.destroy();
          this.dialogs = this.dialogs.filter(dialog => dialog.close$ !== close$);
        }),
        map(([comp]) => comp)
      );
    }

    return undefined;
  }

  launch(
    caller: LAUNCH_CALLER | string,
    vcr?: ViewContainerRef,
    data$?: BehaviorSubject<any>
  ): void | Observable<ComponentRef<any> | undefined> {
    const config = this.findConfiguration(caller);
    if (config) {
      const renderer = this.getStrategy(config);

      if (renderer) {
        if (data$ && this.dialogs?.length > 0) {
          data$.next(data$.value);
        } else {
          this._dataSubject.next(data$?.value);
        }
        return renderer.render(config, caller, vcr);
      }
    } else if (isDevMode()) {
      this.logger.warn('No configuration provided for caller ' + caller);
    }
  }

  openDialogAndSubscribe(
    caller: LAUNCH_CALLER | string,
    openElement?: ElementRef,
    data?: any
  ): void {
    this.openDialog(caller, openElement, undefined, data)
      ?.pipe(take(1))
      .subscribe();
  }

  clear(caller: LAUNCH_CALLER | string): void {
    const config = this.findConfiguration(caller);
    if (config) {
      const renderer = this.getStrategy(config);

      if (renderer) {
        renderer.remove(caller, config);
        if (this.dialogs?.length < 1) this.closeDialog('');
      }
    }
  }

  closeDialog(reason: any, caller?: LAUNCH_CALLER | string) {
    const dialog = this.dialogs.find(d => d.caller === (caller || this.dialogs[this.dialogs?.length - 1].caller));
    dialog ? dialog.close$.next(reason) : this._dialogClose.next(reason);
  }

  // getData(caller: LAUNCH_CALLER | string): Observable<any> | undefined {
  //   const dialog = this.dialogs.find(d => d.caller === (caller || this.dialogs[this.dialogs?.length - 1].caller));
  //   return dialog ? dialog.data$.asObservable() : undefined;
  // }

  protected findConfiguration(
    caller: LAUNCH_CALLER | string
  ): LaunchOptions | undefined {
    if (this.layoutConfig?.launch) {
      return this.layoutConfig.launch[caller];
    }
    return undefined;
  }

  protected getStrategy(
    config: LaunchOptions
  ): LaunchRenderStrategy | undefined {
    return resolveApplicable(this.renderStrategies, [config]);
  }
}