import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { LaunchDialogService, ProductListItemContextSource } from '@spartacus/storefront';
import {
  ProductExtended,
  ProductScaleQuantityToPrice
} from 'src/app/interfaces/product';
import { ProductService } from 'src/app/services/product.service';
import { filter, take, tap } from 'rxjs/operators';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { Image, TranslationService, WindowRef } from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { ProductListItemContext } from "../model";
import { UnavailabilityDetailType } from "../../../../../enums/unavailability-detail-type.enum";
import { UserExtended } from "../../../../../interfaces/login";
import { ContactFactoryInfo } from "../../../../../interfaces/contact-info";
import { TooltipPosition } from "../../../../../enums/tooltip-position.enum";

@Component({
  selector: 'cx-product-list-item',
  templateUrl: './product-list-item.component.html',
  styleUrls: ['./product-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ProductListItemContextSource,
    {
      provide: ProductListItemContext,
      useExisting: ProductListItemContextSource,
    },
  ],
})
export class ProductListItemComponent implements OnChanges {

  @Input() product: ProductExtended;
  @Input() gaProductCategories: { name: string }[];
  @Input() gaListName: string;

  @Input() set phoneNumberOrEmail(value: string) {
    this.contactForPricingHref = value?.includes('@') ? `mailto: ${value}` : `tel: ${value}`;
    this._phoneNumberOrEmail = value;
  }

  get phoneNumberOrEmail(): string {
    return this._phoneNumberOrEmail;
  }

  @Input() tooltipMessage: string;

  @HostListener('window:resize')
  onResize() {
    this.isMobileView = this.winRef?.nativeWindow.innerWidth < 768;
  }

  callForPricingMessage = '';
  contactForPricingHref: string;
  isMobileView: boolean;
  isServicePartsEnabled = false;
  _phoneNumberOrEmail: string;
  spGearImg: Image = {
    url: 'assets/icons/sp_gear_thumb.svg'
  };
  UnavailabilityDetailType = UnavailabilityDetailType;
  TooltipPosition = TooltipPosition;
  private user: UserExtended;
  private subscription = new Subscription();

  constructor(
    protected productListItemContextSource: ProductListItemContextSource,
    protected googleAnalyticsService: GoogleAnalyticsService,
    protected winRef: WindowRef,
    protected translation: TranslationService,
    private productService: ProductService,
    private userAccount: UserAccountFacade,
    private launchDialogService: LaunchDialogService
  ) {
    this.initListConfig();
    this.gerUser();
  }

  ngOnChanges(changes?: SimpleChanges): void {
    if (changes?.product) {
      this.productListItemContextSource.product$.next(this.product);
    }
  }

  selectItem(item: ProductExtended): void {
    const product = {
      ...item,
      categories: this.gaProductCategories,
    };
    this.googleAnalyticsService.sendGaEvent('select_item', {
      items: [this.googleAnalyticsService.mapProductToGaItem(product, 0, undefined, this.gaListName, this.gaListName)],
      item_list_name: this.gaListName || '',
      item_list_id: this.gaListName ? this.gaListName.toLowerCase().trim().replace(/ /g, '_') : '',
      currency: product.price?.currencyIso,
    });
  }

  getDisplayPrice(product: any) {
    const quantity = this.productService.getQuantity(product.code);
    let matchingPrice = this.product.price?.formattedValue;

    if (this.product.scaleQuantityToPrice?.length > 1) {
      const sortedScales = [...this.product.scaleQuantityToPrice].sort(
        (a: ProductScaleQuantityToPrice, b: ProductScaleQuantityToPrice) => parseInt(a.scale, 10) - parseInt(b.scale, 10)
      );

      for (let i = 0; i < sortedScales.length; i++) {
        const currentScale = parseInt(sortedScales[i].scale, 10);

        if (quantity >= currentScale) {
          matchingPrice = sortedScales[i].price?.formattedValue;
        } else {
          break;
        }
      }
    }

    return matchingPrice;
  }

  openContactFactory() {
    const contactFactoryInfo: ContactFactoryInfo = {
      accountNumber: this.user.orgUnit.uid,
      companyName: this.user.orgUnit.name,
      supportPhoneNumber: '1-800-883-7535',
      subject: 'Service Part Contact Request',
      itemNumber: this.product.code,
      itemDescription: this.product.name
    };

    const dialog = this.launchDialogService.openDialog(
      'CONTACT_FACTORY',
      undefined,
      undefined,
      contactFactoryInfo
    );

    if (dialog) {
      dialog.pipe(take(1)).subscribe();
    }
    this.subscription.add(
      this.launchDialogService.dialogClose
        .subscribe()
    );
  }

  private initListConfig() {
    this.isMobileView = this.winRef?.nativeWindow.innerWidth < 768;
    this.isServicePartsEnabled = JSON.parse(this.winRef.localStorage.getItem('isServicePartsEnabled'));
  }

  private gerUser() {
    this.subscription.add(
      this.userAccount.get()
        .pipe(
          filter(Boolean),
          tap(user => this.user = user)
        ).subscribe()
    );
  }
}
