/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { MiniCartComponent } from './mini-cart.component';
import { CountEntryGroupsQuantityModule } from "../../../../../shared/pipes/count-entry-groups-quantity/count-entry-groups-quantity.module";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    IconModule,
    I18nModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        MiniCartComponent: {
          component: MiniCartComponent
        },
      },
    }),
    CountEntryGroupsQuantityModule
  ],
  declarations: [MiniCartComponent],
  exports: [MiniCartComponent],
})
export class MiniCartModule {}
