import { AfterViewInit, Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[cxHorizontalScrollOverflowHide]'
})
export class HorizontalScrollOverflowHideDirective implements AfterViewInit  {

  @HostListener('scroll', ['$event'])
  onScroll(event: any): void {
    const element: HTMLElement = event.target;
    this.addOrRemoveHidingClass(element);
  }

  constructor(
    private renderer: Renderer2,
    private element: ElementRef
  ) {
  }

  ngAfterViewInit() {
    this.addOrRemoveHidingClass(this.element.nativeElement);
  }

  private addOrRemoveHidingClass(element: HTMLElement) {
    const isAtLeft = element.scrollLeft === 0;
    const isAtRight = element.scrollWidth === element.scrollLeft + element.clientWidth;
    const cssClasses = ['hide-overflow-x-start', 'hide-overflow-x-end', 'hide-overflow-x'];
    const existingCSSClass = cssClasses.find(cssClass => element.classList.contains(cssClass));

    if (!isAtLeft && !isAtRight) {
      if (existingCSSClass === 'hide-overflow-x') {
        return;
      }
      this.renderer.removeClass(element, existingCSSClass);
      this.renderer.addClass(element, 'hide-overflow-x');
      return;
    } else if (isAtLeft && !isAtRight) {
      if (existingCSSClass === 'hide-overflow-x-end') {
        return;
      }
      this.renderer.removeClass(element, existingCSSClass);
      this.renderer.addClass(element, 'hide-overflow-x-end');
    } else if (!isAtLeft && isAtRight) {
      if (existingCSSClass === 'hide-overflow-x-start') {
        return;
      }
      this.renderer.removeClass(element, existingCSSClass);
      this.renderer.addClass(element, 'hide-overflow-x-start');
    }
  }

}
