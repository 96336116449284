import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CheckoutStepService } from "@spartacus/checkout/base/components";
import { ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup } from "@angular/forms";
import { HandlingOption, OptionCode } from "../../../../../interfaces/cart";
import { CheckoutService } from "../../../../../services/checkout.service";
import { catchError, finalize, first, map, switchMap, take, tap } from "rxjs/operators";
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";
import { User, UserAccountFacade } from '@spartacus/user/account/root';
import { CartExtended } from 'src/app/interfaces/cart.d'
import { GeneracActiveCartService } from '../../../cart/core/facade/active-cart.service';
import { WindowRef } from '@spartacus/core';
import { cart } from "@spartacus/cart/base/assets/translations/en/cart.i18n";
import { PaymentType } from 'src/app/enums/payment-type.enum';

@Component({
  selector: 'cx-checkout-special-handling',
  templateUrl: './checkout-special-handling.component.html',
  styleUrls: ['./checkout-special-handling.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutSpecialHandlingComponent implements OnInit, OnDestroy {
  @Input() shippingGroupName: string;
  formGroup = new FormGroup({});
  handlingOptions$ = new BehaviorSubject<HandlingOption[]>([]);
  optionsUpdated$ = new Subject();
  currentUser: User;
  internalCalculation: boolean;
  isResidentialDeliverySpecialOptionSelected: boolean;
  selectedAddress: any;
  isServicePartsEnabled: boolean;
  skipPaymentTypeStep: boolean = false;

  private formGroupSubscription: Subscription;
  private cartNumber: string;

  constructor(
      protected checkoutStepService: CheckoutStepService,
      protected activatedRoute: ActivatedRoute,
      protected winRef: WindowRef,
      private checkoutService: CheckoutService,
      private activeCartFacade: GeneracActiveCartService,
      private userAccount: UserAccountFacade,
  ) {
  }

  ngOnInit(): void {
    this.isServicePartsEnabled = JSON.parse(this.winRef.localStorage.getItem('isServicePartsEnabled'));
    this.userAccount.get().pipe(
      first(),
      switchMap(user => {
        this.currentUser = user;
        return this.initSpecialHandlingOptions();
      }))
    .subscribe();
  }

  ngOnDestroy(): void {
    this.formGroupSubscription.unsubscribe();
  }

  private initSpecialHandlingOptions(): Observable<HandlingOption[]> {
    return this.activeCartFacade.getActive().pipe(
        take(1),
        switchMap((cart: CartExtended) => {
          this.cartNumber = cart.code;
          this.selectedAddress = cart.deliveryAddress;
          this.skipPaymentTypeStep = cart?.rootCartCode && cart?.paymentType?.code === PaymentType.WELLS_FARGO
          this.checkoutService.disableCheckoutSteps(cart.cartTypes);
          this.internalCalculation = cart.internalCalculation;
          if(!this.isServicePartsEnabled) {
            this.shippingGroupName = cart?.shippingGroups?.[0]?.shippingGroupName;
          }
          return this.getSpecialHandlingOptions(cart.code, this.currentUser.uid, this.shippingGroupName);
        }),
        tap((options: HandlingOption[]) => {
          this.handlingOptions$.next(options);
          this.initFormGroup(options);
          const convertedOptions = this.convertOptionsFromHandlingOptions(options);
          this.updateSpecialHandlingOptions(convertedOptions);
          this.isResidentialDeliverySpecialOptionSelected = this.selectedAddress?.residential && options?.length > 0;
        })
      );
  }

  private getSpecialHandlingOptions(cartNumber: string, userId: string, shippingGroupName: string): Observable<HandlingOption[]> {
    return this.checkoutService.getSpecialHandlingOptions(cartNumber, userId, shippingGroupName);
  }

  private updateSpecialHandlingOptions(handlingOptions: string): void {
    this.checkoutService.updateSpecialHandlingOptions(this.cartNumber, this.currentUser.uid, this.shippingGroupName, handlingOptions)
        .pipe(
            take(1),
            tap(() => this.activeCartFacade.reloadActiveCart()),
            catchError(() => this.getSpecialHandlingOptions(this.cartNumber, this.currentUser.uid, this.shippingGroupName).pipe(
                tap((options: HandlingOption[]) => {
                  this.handlingOptions$.next(options);
                  this.initFormGroup(options);
                })
            )),
            finalize(() => this.optionsUpdated$.next())
        )
        .subscribe();
  }

  private initFormGroup(options: HandlingOption[]) {
      this.formGroup = new FormGroup({});
    options.forEach((option: HandlingOption) => {
      this.formGroup.addControl(option.code, new FormControl({value: option.selected, disabled: option.disabled}), { emitEvent: false})
    });

    if(this.formGroupSubscription) this.formGroupSubscription.unsubscribe();
    this.subscribeToFormValueChanges();
  }

  private subscribeToFormValueChanges(): void {
    this.formGroupSubscription =
        this.formGroup.valueChanges.pipe(
            map(() => this.formGroup.getRawValue()),
            switchMap((options: OptionCode) => {
                const convertedOptions = this.convertOptionsToString(options);
                this.updateSpecialHandlingOptions(convertedOptions);
                return this.optionsUpdated$;
            }),
            switchMap(() => this.getSpecialHandlingOptions(this.cartNumber, this.currentUser.uid, this.shippingGroupName)),
            tap((options: HandlingOption[]) => {
                this.handlingOptions$.next(options);
                const formGroupCodes = Object.keys(this.formGroup.getRawValue());
                const optionCodes = options.map(option => option.code);

                if (JSON.stringify(formGroupCodes) !== JSON.stringify(optionCodes)) {
                    this.initFormGroup(options);
                }
            })
        ).subscribe()
  }

  private convertOptionsToString(options: OptionCode): string {
    return Object.keys(Object.fromEntries(Object.entries(options).filter(([_, value]) => value))).join(',');
  }

  private convertOptionsFromHandlingOptions(options: HandlingOption[]): string {
      return options
          .filter(option => option.selected)
          .map(option => option.code)
          .join(',');
  }
}
