<ng-container *ngIf="servicePart">
  <!-- <div class="sp-info-item">
    <span class="sp-info-item-label">On Hand</span>
    <span class="sp-info-item-value">{{servicePart.unit}}</span>
  </div> -->
  <div class="sp-info-item">
    <span class="sp-info-item-label">Unit Of Measure</span>
    <span class="sp-info-item-value">{{servicePart.unit}}</span>
  </div>
  <div class="sp-info-item">
    <span class="sp-info-item-label">Item Class</span>
    <span class="sp-info-item-value">{{servicePart.itemClass}}</span>
  </div>
</ng-container>