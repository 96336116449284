<div [cxFocus]="focusConfig" (esc)="dismiss('Escape clicked')" class="split-payment-dialog">
  <div class="cx-modal-container">
    <div class="cx-modal-content split-payment-dialog-container">
      <!-- Modal Header -->
      <ng-container>
        <div class="cx-dialog-header modal-header split-payment-dialog-header">
          <div class="cx-dialog-title modal-title">
            {{ 'checkoutAddress.checkoutSplitPaymentDialog.title' | cxTranslate }}
          </div>
          <button type="button" class="close split-payment-dialog-close" (click)="dismiss('Cross click')"
            attr.aria-label="{{ 'addToCart.closeModal' | cxTranslate }}">
            <img src="../../../../../../../assets/icons/close_black_24.svg">
          </button>
        </div>
        <!-- Separator -->
        <div class="cx-dialog-separator col-sm-12"></div>

        <!-- Modal Body -->
        <div class="modal-body split-payment-dialog-body">
          <div *ngIf="compatibleProducts" class="cx-dialog-item">
            <div class="cx-dialog-item-title">
              {{ 'checkoutAddress.checkoutSplitPaymentDialog.compatibleProductsText' | cxTranslate }}
            </div>
            <div class="cx-dialog-item-description">
              <ng-container *ngFor="let product of compatibleProducts">
                <cx-custom-cart-item
                    [item]="{ product }"
                    [compact]="true"
                    [readonly]="true"
                ></cx-custom-cart-item>
              </ng-container>
            </div>
          </div>
          <div *ngIf="nonCompatibleProducts" class="cx-dialog-item">
            <div class="cx-dialog-item-title">
              {{ 'checkoutAddress.checkoutSplitPaymentDialog.nonCompatibleProductsText' | cxTranslate }}
            </div>
            <div class="cx-dialog-item-description">
              <ng-container *ngFor="let product of nonCompatibleProducts">
                <cx-custom-cart-item
                  [item]="{ product }"
                  [compact]="true"
                  [readonly]="true"
                ></cx-custom-cart-item>
              </ng-container>
            </div>
          </div>
          <div class="cx-dialog-item">
            {{ 'checkoutAddress.checkoutSplitPaymentDialog.infoText' | cxTranslate }}
          </div>

          <div class="split-payment-dialog-footer">
            <div class="cx-dialog-buttons">
              <button (click)="dismiss('Cancel click')" [disabled]="loading" class="cx-btn btn btn-block btn-secondary mr-2">
                {{ 'common.cancel' | cxTranslate }}
              </button>
              <button (click)="continue()" [disabled]="loading" class="cx-btn btn btn-block btn-primary mt-0">
                {{ 'common.continue' | cxTranslate }}
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>