<ng-container *ngIf="warranties$ | async as warranties">
  <div class="product-warranties" *ngIf="warranties?.length > 0">
    <div class="product-warranties-title" *ngIf="title$ | async as title">
      <img src="../../../../../../assets/icons/shield.svg">
      <span>{{ title }}</span>
      <img
        src="../../../../../assets/icons/info-grey.svg"
        (mouseenter)="warrantiesTooltipMessage=warrantiesTooltipText"
        (mouseleave)="warrantiesTooltipMessage=''"
      />
      <cx-info-message [message]="warrantiesTooltipMessage" [position]="'position-right'"></cx-info-message>
    </div>
    <div *ngFor="let warranty of warranties" class="warranty-block">
      <generac-product-warranty-item
              [warranty]="warranty"
              [userUid]="user?.uid"
              [tooltipMessage]="tooltipMessage"
              [phoneNumberOrEmail]="phoneOrEmail"
      ></generac-product-warranty-item>
    </div>
  </div>
</ng-container>