<cx-carousel
        *ngIf="items$ | async as items"
        [title]="title$ | async"
        [items]="items"
        [template]="carouselItem"
        itemWidth="285px"
>
</cx-carousel>

<ng-template #carouselItem let-item="item">
    <cx-product-grid-item [tooltipMessage]="tooltipMessage" [phoneNumberOrEmail]="phoneOrEmail" [product]="item" [gaListName]="gaListName"></cx-product-grid-item>
</ng-template>
