<ng-container *ngIf="state$ | async as state">
  <button class="heading" (click)="toggleGroup($event)" [attr.aria-expanded]="isExpanded" [attr.aria-label]="
      'productFacetNavigation.filterBy.name' | cxTranslate: { name: facet.name }
    ">
    {{ facet.name }}
    <cx-icon class="collapse-icon" [type]="collapseIcon"></cx-icon>
    <cx-icon class="expand-icon" [type]="expandIcon"></cx-icon>
  </button>

  <div>
    <a *ngFor="let value of facet.values | slice: 0:state.topVisible" #facetValue routerLink="./"
      [queryParams]="getLinkParams(value)" queryParamsHandling="merge" class="value" [class.selected]="value.selected"
      [cxFocus]="{ key: value.name }" (keydown.space)="openLink($any($event))"
      [attr.role]="facet.multiSelect ? 'checkbox' : null"
      [attr.aria-checked]="facet.multiSelect ? value.selected : null" attr.aria-label="{{
        'productFacetNavigation.ariaLabelItemsAvailable'
          | cxTranslate: { name: value.name, count: value.count }
      }}">
      <span aria-hidden="true">{{ value.name
        }}<span aria-hidden="true" class="count">{{ value.count }}</span></span>
    </a>

    <div class="more">
      <a *ngFor="
          let value of facet.values
            | slice: state.topVisible ?? 0:state.maxVisible
        " #facetValue routerLink="./" [queryParams]="getLinkParams(value)" class="value"
        [class.selected]="value.selected" [cxFocus]="{ key: value.name }" (keydown.space)="openLink($any($event))">
        <span>{{ value.name }}<span class="count">{{ value.count }}</span></span>
      </a>

      <button *ngIf="
          state.maxVisible &&
          state.topVisible &&
          state.maxVisible > state.topVisible
        " (click)="decreaseVisibleValues()" [cxAtMessage]="
          'productFacetNavigation.decreaseOptionsVisibility' | cxTranslate
        " aria-expanded="true" attr.aria-label="{{
          'productFacetNavigation.ariaLabelShowLess' | cxTranslate
        }}" class="cx-action-link" [cxFocus]="{ key: 'moreorless' }">
        {{ 'productList.showLess' | cxTranslate }}
      </button>

      <button *ngIf="
          state.maxVisible &&
          facet.values &&
          state.maxVisible > 0 &&
          state.maxVisible < facet.values.length
        " (click)="increaseVisibleValues()" [cxAtMessage]="
          'productFacetNavigation.increaseOptionsVisibility' | cxTranslate
        " aria-expanded="false" attr.aria-label="{{
          'productFacetNavigation.ariaLabelShowMore' | cxTranslate
        }}" class="cx-action-link" [cxFocus]="{ key: 'moreorless' }">
        {{ 'productList.showMore' | cxTranslate }}
      </button>
    </div>
  </div>
</ng-container>