import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { OrderConfirmationOrderEntriesContextToken, OrderFacade } from '@spartacus/order/root';
import { CardModule, FormErrorsModule, OutletModule, PromotionsModule, PwaModule, PasswordVisibilityToggleModule } from '@spartacus/storefront';
import { OrderConfirmationGuard, OrderConfirmationOrderEntriesContext, OrderDetailsService } from "@spartacus/order/components";
import { GeneracOrderConfirmationItemsComponent } from "./order-confirmation-items/order-confirmation-items.component";
import { CartSharedModule } from "../../../cart/cart-shared";
import { OrderDetailShippingComponent } from "../order-details/order-detail-shipping/order-detail-shipping.component";
import { GeneracOrderConfirmationTotalsComponent } from "./order-confirmation-totals/order-confirmation-totals.component";
import { CustomOrderSummaryModule } from "../../../checkout/components/custom-order-summary/custom-order-summary.module";
import { OrderOverviewModule } from '../order-details/order-overview';

@NgModule({
    imports: [
        CommonModule,
        CardModule,
        PwaModule,
        PromotionsModule,
        I18nModule,
        ReactiveFormsModule,
        FormErrorsModule,
        OrderOverviewModule,
        OutletModule,
        PasswordVisibilityToggleModule,
        CartSharedModule,
        CustomOrderSummaryModule
    ],
    providers: [
        provideConfig(<CmsConfig>{
            cmsComponents: {
                OrderConfirmationItemsComponent: {
                    component: GeneracOrderConfirmationItemsComponent,
                    guards: [OrderConfirmationGuard],
                },
                OrderConfirmationTotalsComponent: {
                    component: GeneracOrderConfirmationTotalsComponent,
                    guards: [OrderConfirmationGuard],
                },
                OrderConfirmationOverviewComponent: {
                    component: OrderDetailShippingComponent,
                    providers: [
                        {
                            provide: OrderDetailsService,
                            useExisting: OrderFacade,
                        },
                    ],
                    guards: [OrderConfirmationGuard],
                },
                ReplenishmentConfirmationOverviewComponent: {
                    component: OrderDetailShippingComponent,
                    providers: [
                        {
                            provide: OrderDetailsService,
                            useExisting: OrderFacade,
                        },
                    ],
                    guards: [OrderConfirmationGuard],
                },
            },
        }),
        {
            provide: OrderConfirmationOrderEntriesContextToken,
            useExisting: OrderConfirmationOrderEntriesContext,
        },
    ],
    declarations: [
        GeneracOrderConfirmationItemsComponent,
        GeneracOrderConfirmationTotalsComponent
    ],
    exports: [
        GeneracOrderConfirmationItemsComponent,
        GeneracOrderConfirmationTotalsComponent
    ],
})
export class OrderConfirmationModule {}
