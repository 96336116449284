<div
  *ngIf="content"
  class="cx-card"
  [tabindex]="border ? 0 : -1"
  [attr.role]="content.role ?? null"
  [attr.aria-label]="
    content.label ? (content.label | cxTranslate: { number: this.index }) : null
  "
  [class.cx-card-border]="border"
  [class.selected-card-border]="content.header && !editMode"
  [class.cx-card-fit-to-container]="fitToContainer"
>
  <!-- Card Header -->
  <!-- <div
    *ngIf="content.header && !editMode"
    class="card-header"
    [id]="'content-header' + (index >= 0 ? '-' + index : '')"
  >
    {{ content.header }}
  </div> -->
  <label class="form-check">
    <input
      *ngIf="content.header && !editMode"
      type="checkbox"
      role="checkbox"
      [checked]="true"
      disabled
    />
  </label>
  <!-- Card Body -->
  <div class="card-body cx-card-body" [class.cx-card-delete]="editMode">
    <!-- Edit message -->
    <div *ngIf="editMode" role="alert" class="cx-card-delete-msg">
      {{ content.deleteMsg }}
    </div>
    <!-- Card title -->
    <span *ngIf="content.title" class="cx-card-title">
      {{ content.title }}
    </span>
    <!-- Card Content -->
    <div class="cx-card-container">
      <!-- Card Label -->
      <div class="cx-card-label-container">
        <div *ngIf="content.textBold" class="cx-card-label-bold">
          {{ content.textBold }}
        </div>
        <div *ngFor="let line of content.text">
          <div
            *ngIf="!truncateText; else truncate"
            class="cx-card-label"
            [ngClass]="content.customClass"
          >
            {{ line }}
          </div>

          <ng-template #truncate>
            <cx-truncate-text-popover
              [charactersLimit]="charactersLimit"
              [content]="line"
              [customClass]="content.customClass"
            ></cx-truncate-text-popover>
          </ng-template>
        </div>
        <div class="cx-card-paragraph" *ngFor="let item of content.paragraphs">
          <div class="cx-card-paragraph-title">{{ item.title }}</div>
          <div *ngFor="let text of item.text">
            <div class="cx-card-paragraph-text">
              {{ text }}
            </div>
          </div>
        </div>
      </div>
      <!-- Image -->
      <div *ngIf="content.img" class="cx-card-img-container">
        <cx-icon [type]="content.img"></cx-icon>
      </div>
    </div>
    <!-- Edit Mode Actions -->
    <div *ngIf="editMode" class="row cx-card-body-delete">
      <div class="col-md-6">
        <button
          [cxAtMessage]="'assistiveMessage.actionCancelled' | cxTranslate"
          class="btn btn-block btn-secondary"
          (click)="cancelEdit()"
        >
          {{ 'common.cancel' | cxTranslate }}
        </button>
      </div>
      <div class="col-md-6" [cxFocus]="{ autofocus: true }">
        <button class="btn btn-block btn-primary" (click)="delete()">
          {{ 'common.delete' | cxTranslate }}
        </button>
      </div>
    </div>
    <!-- Actions -->
    <div *ngIf="content.actions && !editMode" class="cx-card-actions">
      <div *ngFor="let action of content.actions">
        <div>
          <ng-container *ngIf="isCardAction(action)" [ngSwitch]="action.event">
            <button
              type="button"
              *ngSwitchCase="'delete'"
              class="link cx-action-link"
              (click)="delete()"
            >
              {{ action.name }}
            </button>
            <button
              type="button"
              *ngSwitchCase="'default'"
              class="link cx-action-link"
              (click)="setDefault()"
            >
              {{ action.name }}
            </button>
            <ng-container *ngIf="!content.header">
              <button
                type="button"
                *ngSwitchCase="'send'"
                class="send-btn btn btn-block btn-secondary"
                (click)="send()"
                [attr.aria-describedby]="
                  index >= 0 && content.header && !editMode
                    ? 'content-header-' + index
                    : null
                "
              >
                {{ action.name }}
              </button>
            </ng-container>
            <button
              type="button"
              *ngSwitchCase="'edit'"
              class="link cx-action-link"
              (click)="edit()"
            >
              {{ action.name }}
            </button>
          </ng-container>
          <a
            *ngIf="isCardLinkAction(action)"
            href="{{ action.link }}"
            class="link cx-action-link"
          >
            {{ action.name }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
