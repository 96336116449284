import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ICON_TYPE } from "@spartacus/storefront";
import { ProductExtended } from 'src/app/interfaces/product';
import { SubscriptionInformationItem } from 'src/app/interfaces/checkout';
import { CheckoutStepType } from '@spartacus/checkout/base/root';
import { CheckoutStepService } from '@spartacus/checkout/base/components';
import { CheckoutStepTypeExtended } from 'src/app/enums/checkout-step-type';
import { WindowRef } from '@spartacus/core';

@Component({
  selector: 'cx-custom-subscriptions-table',
  templateUrl: './custom-subscriptions-table.component.html',
  styleUrls: ['./custom-subscriptions-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomSubscriptionsTableComponent {
  @Input() subscriptionInfo: SubscriptionInformationItem[];
  @Input() product: ProductExtended;

  isOrderConfirmation: boolean;
  checkoutStepTypeSubscriptionInformation = CheckoutStepTypeExtended.SUBSCRIPTION_INFORMATION;
  
  protected readonly ICON_TYPE = ICON_TYPE;

  constructor(
    protected checkoutStepService: CheckoutStepService,
    protected winRef: WindowRef,
  ) {
    this.isOrderConfirmation = this.winRef.location.href.includes('order-confirmation');
   }

  getCheckoutStepUrl(stepType: CheckoutStepType | string): string | undefined {
    const step = this.checkoutStepService.getCheckoutStep(
        stepType as CheckoutStepType
    );
    return step?.routeName;
  }
}
