<cx-spinner *ngIf="loading === LoadingEnum.Idle; else shippingAccounts;" class="spinner-center"></cx-spinner>

<ng-template #shippingAccounts>
    <div class="shipping-accounts-wrap" *ngIf="!isMobileView; else shippingAccountsMobileTmpl">
        <cx-spinner *ngIf="loading === LoadingEnum.Loading" class="spinner-overlay"></cx-spinner>
        <ng-container *ngIf="shippingAccounts$ | async as shippingAccounts">
            <table class="shipping-accounts" *ngIf="shippingAccounts?.length > 0; else noShippingAccounts;">
                <thead>
                    <th *ngFor="let column of shippingAccountsColumns" [class]="column.cssClass">
                        {{ column.displayName }}
                    </th>
                </thead>
                <ng-container *ngFor="let shippingAccount of shippingAccounts; trackBy: trackByShippingAccounts;">
                    <tr *ngFor="let shippingCondition of shippingAccount.shippingConditions"
                        class="shipping-accounts-item-wrap">
                        <td *ngFor="let column of shippingAccountsColumns" [class]="column.cssClass">
                            <ng-container
                                *ngIf="getShippingAccountValue(shippingAccount, shippingCondition, column.fieldName) === true || 
                                getShippingAccountValue(shippingAccount, shippingCondition, column.fieldName) === false; else textValue">
                                <img *ngIf="getShippingAccountValue(shippingAccount, shippingCondition, column.fieldName) === true"
                                    src="../../../assets/icons/check_circle_outline_green_24.svg">
                            </ng-container>
                            <ng-template #textValue>
                                {{ getShippingAccountValue(shippingAccount, shippingCondition, column.fieldName) }}
                            </ng-template>
                        </td>
                    </tr>
                </ng-container>
            </table>

        </ng-container>
    </div>

</ng-template>

<ng-template #shippingAccountsMobileTmpl>
    <ng-container *ngIf="shippingAccounts$ | async as shippingAccounts">
        <table *ngIf="shippingAccounts?.length > 0; else noShippingAccounts;" class="mobile-table">
            <ng-container *ngFor="let shippingAccount of shippingAccounts; trackBy: trackByShippingAccounts;">
                <tr *ngFor="let shippingCondition of shippingAccount.shippingConditions">
                    <div class="mobile-table-column-names">
                        <span *ngFor="let column of shippingAccountsColumns">{{ column.displayName }}:</span>
                    </div>
                    <div class="mobile-table-values">
                        <span *ngFor="let column of shippingAccountsColumns">
                            <ng-container
                                *ngIf="getShippingAccountValue(shippingAccount, shippingCondition, column.fieldName) === true || 
                                getShippingAccountValue(shippingAccount, shippingCondition, column.fieldName) === false; else textValue">
                                <img *ngIf="getShippingAccountValue(shippingAccount, shippingCondition, column.fieldName) === true"
                                    src="../../../assets/icons/check_circle_outline_green_24.svg">
                            </ng-container>
                            <ng-template #textValue>
                                {{ getShippingAccountValue(shippingAccount, shippingCondition, column.fieldName) }}
                            </ng-template>
                        </span>
                    </div>
                </tr>
            </ng-container>
        </table>
    </ng-container>
</ng-template>
<ng-template #noShippingAccounts>
    <div class="no-shipping-accounts-wrap">
        <img src="../../../assets/icons/no_shipping_accounts_icon.svg"
            alt="{{ 'shippingAccounts.noShippingAccountsAvailable' | cxTranslate}}">
        <p>
            {{ 'shippingAccounts.noShippingAccountsAvailable' | cxTranslate}}
        </p>
    </div>
</ng-template>