import { Injectable } from '@angular/core';
import { MultiCartFacade } from '@spartacus/cart/base/root';

@Injectable({
  providedIn: 'root',
})
export abstract class GeneracMultiCartFacade extends MultiCartFacade {
  /**
 * Add multiple entries to cart
 *
 * @param userId
 * @param cartId
 * @param products Array with items (productCode and quantity)
 */
  abstract override addEntries(
    userId: string,
    cartId: string,
    products: Array<{ productCode: string; quantity: number, itemListName?: string, navigatedToPdpFrom?: string, }>
  ): void;

  /**
* Add entry to cart
*
* @param userId
* @param cartId
* @param productCode
* @param quantity
* @param pickupStore
*/
  abstract override addEntry(
    userId: string,
    cartId: string,
    productCode: string,
    quantity: number,
    itemListName?: string,
    navigatedToPdpFrom?: string, 
    pickupStore?: string,
  ): void;

}
