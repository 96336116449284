/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Component, OnDestroy } from '@angular/core';
import { CurrentProductService } from '@spartacus/storefront';
import { first } from 'rxjs/operators';
import { ProductExtended } from 'src/app/interfaces/product';
import { Subscription } from "rxjs";

@Component({
  selector: 'cx-download-link',
  templateUrl: './custom-download-link.component.html',
  styleUrls: ['./custom-download-link.component.scss'],
})
export class CustomDownloadLinkComponent implements OnDestroy {

  product!: ProductExtended;
  private subscriptions = new Subscription();

  constructor(
    protected currentProductService: CurrentProductService,
  ) {
    this.subscriptions.add(
      this.currentProductService
        .getProduct()
        .subscribe(product => this.product = product)
    )
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
