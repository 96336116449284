import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BundleItem, ExtendedBundle } from "../../../interfaces/bundle.model";
import { LoadingEnum } from "../../../enums/loading.enum";

@Component({
  selector: 'cx-bundle-slide',
  templateUrl: './bundle-slide.component.html',
  styleUrls: ['./bundle-slide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BundleSlideComponent {
  @Input() isActive: boolean;
  @Input() bundle: ExtendedBundle;
  @Input() errorMessages: string[];
  @Input() loading: LoadingEnum;
  @Output() addBundleToTheCart = new EventEmitter<ExtendedBundle>();

  LoadingEnum = LoadingEnum;

  constructor() {
  }

  trackByProduct(_: number, item: BundleItem) {
    return item.code;
  }
}
