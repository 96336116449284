<ng-container *ngIf="product$ | async as product">
    <div class="container specifications">
        <h2>{{ 'productDetails.specification' | cxTranslate }}</h2>
        <div class="specifications-container">
            <table>
                <tr *ngFor="let features of (product?.classifications | combineArrays: 'features') | arraySplit;">
                    <td *ngFor="let feature of features">
                        <div class="specifications-table-cell">{{ feature.name }}</div>
                        <ul class="specifications-table-cell">
                            <li *ngFor="let featureValue of feature?.featureValues;">
                                {{ featureValue?.value }}
                                <span
                                        *ngIf="
                                      feature.featureUnit &&
                                      feature.featureUnit.symbol &&
                                      feature.featureUnit.symbol.length > 0 &&
                                      feature.featureUnit?.unitType !== '300'
                                    "
                                >
                                    {{ feature.featureUnit?.symbol }}
                                  </span>
                            </li>
                        </ul>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</ng-container>
