import { Inject, Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { DOCUMENT } from "@angular/common";
import { ProductExtended } from '../interfaces/product';
import { OrderEntry } from '@spartacus/cart/base/root';
import { filter, map, take, tap } from 'rxjs/operators';
import { BaseSiteService, User } from '@spartacus/core';
import { EntryGroup } from '../interfaces/cart';
import { UserAccountFacade } from '@spartacus/user/account/root';

declare const gtag: Function;

export class GaItem {
  item_id: string;
  item_name: string;
  price: string | number;
  //item_brand: string;
  item_category: string;
  item_category2?: string;
  item_list_name?: string;
  item_list_id?: string;
  navigatedToPpdFrom?: string;
  bundle_template_id?: string;
  bundle_name?: string;
  discount?: string;
  index: number;
  quantity?: number;
}
@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  gaIdentifierName: keyof Object;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    protected userAccountFacade: UserAccountFacade,
    protected baseSiteService: BaseSiteService
  ) { }

  private blockedUserRoles = ['restrictedplaceordergroup', 'testorderplacegroup'];

  isRestrictedUser(userRoles: string[]) {
    return userRoles.some(role => this.blockedUserRoles.includes(role))
  }

  sendGaEvent(eventName: string, eventData: Object) {
    this.userAccountFacade.get().pipe(
      filter((user): user is User => !!user && Object.keys(user).length > 0),
      map((user) => (user as User & { roles?: string[] }).roles),
      tap((roles) => {
        if(this.isRestrictedUser(roles)) {
          return;
        }
        gtag('event',eventName, eventData);
      })
    ).subscribe();
  }

  init() {
    this.baseSiteService.getActive()
      .pipe(take(1))
      .subscribe((baseSiteName) => {
        this.gaIdentifierName = baseSiteName.replace(/Site/g, '') + 'GoogleAnalyticsId' as keyof Object;
        if (this.gaIdentifierName) {
          let googleAnalyticsId = environment[this.gaIdentifierName];

          const customGtagScriptEl = this.document.createElement('script');
          customGtagScriptEl.async = true;
          customGtagScriptEl.src = 'https://www.googletagmanager.com/gtag/js?id=' + googleAnalyticsId;
          this.document.head.prepend(customGtagScriptEl);

          const gtagEl = this.document.createElement('script');
          const gtagBody = this.document.createTextNode(`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
          `);
          gtagEl.appendChild(gtagBody);
          this.document.body.appendChild(gtagEl);
          gtag('config', googleAnalyticsId, { 'debug_mode': !environment.production });
        }
      });
  }

  public mapProductToGaItem(product: any, index: number, quantity: number = undefined, listName: string = undefined, navigatedToPpdFrom: string = undefined, bundleTemplateId: string = undefined, bundleName: string = undefined): GaItem {
    return {
      item_id: product.code,
      item_name: product.name,
      price: product.price?.value,
      //item_brand: 'Generac',
      item_category: product.categories ? product.categories[0]?.name : '',
      item_category2: product.categories ? product.categories[1]?.name : '',
      item_list_name: listName,
      item_list_id: listName ? listName.toLowerCase().trim().replace(/ /g, '_') : undefined,
      navigatedToPpdFrom: navigatedToPpdFrom ? navigatedToPpdFrom.toLowerCase().trim().replace(/ /g, '_') : undefined,
      bundle_template_id: bundleTemplateId,
      bundle_name: bundleName,
      discount: product.discount,
      index,
      quantity
    }
  }

  private getProductDiscountByOrderEntryNumber(orderEntryNumber: number, productPrice: number, appliedProductPromotions: any) {
    const productPromoEntity = appliedProductPromotions.find((item: any) => item.consumedEntries[0].orderEntryNumber == orderEntryNumber);
    return productPromoEntity ? this.getGaDiscount(productPromoEntity, productPrice) : undefined;
  }

  private getGaDiscount(productPromoEntity: any, productPrice: number): string {
    return Number(productPrice - productPromoEntity.consumedEntries[0].adjustedUnitPrice).toFixed(2);
  }

  private mapEntryGroupsToGaItems(entryGroup: EntryGroup, lastIndex: number, appliedProductPromotions: any, bundleTemplateId: string = undefined, bundleName: string = undefined): GaItem[] {
    let mappedItems = [];
    for (const [index, orderEntry] of entryGroup.entries.entries()) {
      const product = {
        ...orderEntry.product,
        price: {
          value: orderEntry.basePrice.value
        },
       discount: appliedProductPromotions ? this.getProductDiscountByOrderEntryNumber(orderEntry.entryNumber, orderEntry.basePrice.value, appliedProductPromotions) : undefined,
      } as ProductExtended;
      mappedItems.push(this.mapProductToGaItem(product, index + lastIndex, orderEntry.quantity, orderEntry.itemListName, orderEntry.navigatedToPdpFrom, bundleTemplateId, bundleName));
    }
    return mappedItems;
  }

  public buildGaItems(entryGroups: EntryGroup[], appliedProductPromotions?: any): GaItem[] {
    let items: GaItem[] = [];
    for(const entryGroup of entryGroups) {
      if(entryGroup.type == 'CONFIGURABLEBUNDLE' && entryGroup.entryGroups) {
        const bundleTemplateId = entryGroup.externalReferenceId ? entryGroup.externalReferenceId : undefined;
        const bundleName = entryGroup.label ? entryGroup.label : undefined;
        for(const entryGroupChild of entryGroup.entryGroups) {
          items = [...items, ...this.mapEntryGroupsToGaItems(entryGroupChild, items.length, appliedProductPromotions, bundleTemplateId, bundleName)]
        }
      }
      items = [...items, ...this.mapEntryGroupsToGaItems(entryGroup, items.length, appliedProductPromotions)]
    }
    return items;
  }
}
