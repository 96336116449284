import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomItemCounterComponent } from "./custom-item-counter.component";
import { ReactiveFormsModule } from "@angular/forms";
import { I18nModule } from "@spartacus/core";
import { InfoMessageModule } from "../info-message/info-message.module";
import { SharedModule } from "../../shared.module";

@NgModule({
  declarations: [CustomItemCounterComponent],
  imports: [
      CommonModule,
      ReactiveFormsModule,
      I18nModule,
      InfoMessageModule,
      SharedModule
  ],
  exports: [CustomItemCounterComponent]
})

export class CustomItemCounterModule { }
