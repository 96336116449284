import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AddToCartModule } from '@spartacus/cart/base/components/add-to-cart';
import { CmsConfig, FeaturesConfig, FeaturesConfigModule, I18nModule, provideConfig, UrlModule } from '@spartacus/core';
import { CardModule, IconModule, KeyboardFocusModule, OutletModule, PromotionsModule, SpinnerModule } from '@spartacus/storefront';
import { OrderDetailItemsComponent } from "./order-detail-items";
import { CartSharedModule } from "../../../cart/cart-shared";
import { OrderOverviewModule } from "./order-overview";
import { OrderDetailShippingComponent } from "./order-detail-shipping/order-detail-shipping.component";
import { OrderDetailsModule } from '@spartacus/order/components';
import { MatExpansionModule } from '@angular/material/expansion';

const moduleComponents = [
    OrderDetailShippingComponent,
    OrderDetailItemsComponent,
];

@NgModule({
    imports: [
        CardModule,
        CommonModule,
        I18nModule,
        FeaturesConfigModule,
        PromotionsModule,
        UrlModule,
        SpinnerModule,
        RouterModule,
        OutletModule,
        AddToCartModule,
        KeyboardFocusModule,
        OrderOverviewModule,
        IconModule,
        CartSharedModule,
        OrderDetailsModule,
        MatExpansionModule,
    ],
    providers: [
        provideConfig(<CmsConfig | FeaturesConfig>{
            cmsComponents: {
                AccountOrderDetailsItemsComponent: {
                    component: OrderDetailItemsComponent,
                    data: {
                        enableAddToCart: true,
                    },
                },
                AccountOrderDetailsShippingComponent: {
                    component: OrderDetailShippingComponent,
                },
            },
            features: {
                consignmentTracking: '1.2',
            },
        }),
    ],
    declarations: [...moduleComponents],
    exports: [...moduleComponents],
})
export class GeneracOrderDetailsModule {}
