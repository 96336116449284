import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { ConverterService, GlobalMessageService, GlobalMessageType, OccEndpointsService, PRODUCT_NORMALIZER, Product } from '@spartacus/core';
import { ProductExtended } from '../interfaces/product';
import { catchError, finalize, map, share, tap } from 'rxjs/operators';
import { LoadingEnum } from '../enums/loading.enum';
import { FavoritesListItem, FavoritesListResponse, FavoritesResponse } from '../interfaces/favorites.model';

@Injectable({
    providedIn: "root"
})
export class FavoritesService {
    favoritesListChanged$ = new BehaviorSubject(null);
    private loading = new BehaviorSubject<LoadingEnum>(LoadingEnum.Idle);
    loading$ = this.loading.asObservable();

    constructor(
        private httpClient: HttpClient,
        private occEndpoints: OccEndpointsService,
        private converter: ConverterService,
        protected globalMessageService: GlobalMessageService,
    ) { }

    getFavorites(userId: string, page: number = 0, sort: string, listId?: string): Observable<FavoritesResponse> {
        let url = `${this.occEndpoints.getBaseUrl()}/users/${userId}/favorites?currentPage=${page}&pageSize=9&sort=${sort}`;
        if(listId) url += `&listId=${listId}`;
        return this.httpClient.get(url)
            .pipe(
                map((response: any): FavoritesResponse => {
                    const mappedPagination = {
                        currentPage: response.pagination.page,
                        totalPages: response.pagination.totalPages,
                        totalResults: response.pagination.totalCount,
                        pageSize: response.pagination.count,
                    };
                    const mappedProducts: ProductExtended[] = response.products.map((product: Product)=> {
                        return this.converter.convert({
                            ...product,
                            productCode: product.code,
                        }, PRODUCT_NORMALIZER);
                    });
                    return {
                        products: mappedProducts,
                        pagination: mappedPagination,
                    };
                }),
                catchError(res => {
                    this.loading.next(LoadingEnum.Failed);
                    res.error.errors
                        .forEach((error: any) => {
                            this.globalMessageService.add(
                                error.message
                                ? error.message
                                : { key: 'httpHandlers.unknownIdentifier' },
                                GlobalMessageType.MSG_TYPE_ERROR
                            );
                        });
                    return throwError(res.error.errors[0]);
                  }),
                finalize(() => this.loading.next(LoadingEnum.Loaded))
            );
    }

    addToFavorites(userId: string, code: string, listId?: string, listName?: string): Observable<ProductExtended> {
        const url = `${this.occEndpoints.getBaseUrl()}/users/${userId}/favorites/add`;
        return this.httpClient.post(url, { code, listId, listName });
    }

    deleteFromFavorites(userId: string, code: string, listId?: string): Observable<ProductExtended> {
        const url = `${this.occEndpoints.getBaseUrl()}/users/${userId}/favorites/delete`;
        return this.httpClient.delete(url, { body: { code, listId } });
    }

    setFavoritesListChanged(isChanged: boolean): void {
        this.favoritesListChanged$.next(isChanged);
    }

    getFavoritesLists(userId: string, productCode?: string): Observable<FavoritesListResponse> {
        const url = `${this.occEndpoints.getBaseUrl()}/users/${userId}/favoritesLists?&code=${productCode}`;
        return this.httpClient.get<FavoritesListResponse>(url)
            .pipe(
                catchError(res => {
                    res.error.errors
                        .forEach((error: any) => {
                            this.globalMessageService.add(
                                error.message
                                ? error.message
                                : { key: 'httpHandlers.unknownIdentifier' },
                                GlobalMessageType.MSG_TYPE_ERROR
                            );
                        });
                    return throwError(res.error.errors[0]);
                  }),
            );
    }

    createFavoritesList(userId: string, listName: string): Observable<FavoritesListItem> {
        const url = `${this.occEndpoints.getBaseUrl()}/users/${userId}/favoritesLists/create`;
        return this.httpClient.post<FavoritesListItem>(url, { listName });
    }

    renameFavoritesList(userId: string, listId: string, listName: string): Observable<FavoritesListItem> {
        const url = `${this.occEndpoints.getBaseUrl()}/users/${userId}/favoritesLists/rename?listId=${listId}`;
        return this.httpClient.post<FavoritesListItem>(url, { listName });
    }

    removeFavoritesList(userId: string, listId: string): Observable<{}> {
        const url = `${this.occEndpoints.getBaseUrl()}/users/${userId}/favoritesLists/delete?listId=${listId}`;
        return this.httpClient.delete(url);
    }

    cleanFavoritesList(userId: string, listId: string): Observable<FavoritesListItem> {
        const url = `${this.occEndpoints.getBaseUrl()}/users/${userId}/favoritesLists/clean`;
        return this.httpClient.post<FavoritesListItem>(url, { listId });
    }

    addAllItemsToCart(userId: string, cartId: string, listId: string): Observable<any> {
        const url = `${this.occEndpoints.getBaseUrl()}/users/${userId}/carts/${cartId}/favoritesLists/addToCart?listId=${listId}`;
        return this.httpClient.post(url, {});
    }
}
