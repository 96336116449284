<div class="cx-review" *ngIf="cart$ | async as cart">

    <div class="d-flex justify-content-between align-items-center">
        <h2 class="cx-review-title">
            {{ 'checkoutReview.review' | cxTranslate }}
        </h2>
        <cx-offline-badge *ngIf="!isServicePartsEnabled" [internalCalculation]="cart.internalCalculation"></cx-offline-badge>
    </div>

    <div class="wf-info" *ngIf="cart.wellsFargoCart">
        <cx-icon class="regular-icon blue not-active" [type]="iconTypes.INFO"></cx-icon>
        <div class="info-content">
            <p class="info-title">{{'checkoutReview.wellsFargoInfoMessage.title' | cxTranslate}}</p> 
            <p class="info-desc">{{'checkoutReview.wellsFargoInfoMessage.description' | cxTranslate}}</p> 
        </div>
    </div>

    <div class="delivery-info">
        <cx-icon class="regular-icon grey not-active" [type]="iconTypes.INFO"></cx-icon>
        <span class="info-desc" *ngIf="cart.internalCalculation; else otherInfo">
        {{ 'checkoutReview.offlineInfoMessage' | cxTranslate }}
    </span>
        <ng-template #otherInfo>
        <span class="info-desc">{{ 'checkoutReview.infoMessage' | cxTranslate }}</span>
        </ng-template>
    </div>

    <ng-container>
        <div class="cx-review-summary row">
            <div class="col-md-12 col-lg-6 col-xl-6 cx-review-payment-col">
                <ng-container *ngTemplateOutlet="purchaseOrderNumber"></ng-container>
                <ng-container *ngIf="cart?.shippingGroups?.[0]?.handlingOptions?.length && !isSubscription && !isServicePartsEnabled">
                    <ng-container *ngTemplateOutlet="specialHandling"></ng-container>
                </ng-container>
                <ng-container *ngIf="isServicePartsEnabled">
                    <ng-container *ngTemplateOutlet="deliveryAddress"></ng-container>
                </ng-container>
                <ng-container *ngIf="cart?.replenishmentSchedule?.endDate && !isServicePartsEnabled">
                    <ng-container *ngTemplateOutlet="endDateInformation"></ng-container>
                </ng-container>
                <ng-container *ngIf="cart?.replenishmentSchedule?.interval && !isServicePartsEnabled">
                    <ng-container *ngTemplateOutlet="frequencyInformation"></ng-container>
                </ng-container>
                <ng-container *ngIf="cart?.replenishmentSchedule?.startDate && !isServicePartsEnabled">
                    <ng-container *ngTemplateOutlet="nextOrderInformation"></ng-container>
                </ng-container>
            </div>
            <div class="col-md-12 col-lg-6 col-xl-6 cx-review-shipping-col">
                <ng-container *ngIf="cart?.paymentType">
                    <ng-container *ngTemplateOutlet="paymentType"></ng-container>
                </ng-container>
                <ng-container *ngIf="cart?.replenishmentSchedule?.desiredShipmentDate && !isServicePartsEnabled">
                    <ng-container *ngTemplateOutlet="desiredShipmentDate"></ng-container>
                </ng-container>
                <ng-container *ngIf="!isServicePartsEnabled">
                    <ng-container *ngTemplateOutlet="deliveryAddress"></ng-container>
                </ng-container>
                <ng-container *ngIf="cart?.freightForwarderAddress && !isSubscription">
                    <ng-container *ngTemplateOutlet="freightForwarderAddress"></ng-container>
                </ng-container>
            </div>
        </div>
        <!-- DELIVERY ADDRESS SECTION -->
        <ng-template #deliveryAddress>
            <div *ngIf="deliveryAddress$ | async as deliveryAddress"
                class="cx-review-summary-card cx-review-card-address"
            >
                <cx-card [content]="getDeliveryAddressCard(deliveryAddress) | async"></cx-card>
                <div class="cx-review-summary-edit-step">
                    <a [attr.aria-label]="'checkoutReview.editDeliveryAddressDetails' | cxTranslate"
                        [routerLink]="{cxRoute: getCheckoutStepUrl(checkoutStepTypeDeliveryAddress)} | cxUrl"
                    >
                        <div class="edit-icon"></div>
                    </a>
                </div>
            </div>
        </ng-template>

        <ng-template #freightForwarderAddress>
            <div class="cx-review-summary-card cx-review-card-address">
                <cx-card [content]="getFreightForwarderCard(cart?.freightForwarderAddress) | async"></cx-card>
                <div class="cx-review-summary-edit-step">
                    <a
                        [attr.aria-label]="'checkoutReview.editDeliveryAddressDetails' | cxTranslate"
                        [routerLink]="{cxRoute: getCheckoutStepUrl(checkoutStepTypeDeliveryAddress)} | cxUrl"
                    >
                        <div class="edit-icon"></div>
                    </a>
                </div>
            </div>
        </ng-template>

        <ng-template #purchaseOrderNumber>
            <div class="cx-review-summary-card cx-review-card-payment">
                <cx-card
                        [content]="getPurchaseOrderNumber(cart?.purchaseOrderNumber) | async"
                        [truncateText]="true"
                        [charactersLimit]="26"
                ></cx-card>
                <div class="cx-review-summary-edit-step">
                    <a  [attr.aria-label]="'checkoutReview.editDeliveryAddressDetails' | cxTranslate"
                        [routerLink]="{cxRoute: getCheckoutStepUrl(checkoutStepTypeDeliveryAddress)} | cxUrl"
                    >
                        <div class="edit-icon"></div>
                    </a>
                </div>
            </div>
        </ng-template>

        <ng-template #endDateInformation>
            <div class="cx-review-summary-card cx-review-card-payment">
                <div>
                    <cx-card
                            [content]="getEndDateCard(cart?.replenishmentSchedule?.endDate) | async"
                            [truncateText]="true"
                            [charactersLimit]="26"
                    ></cx-card>
                </div>
                <div class="cx-review-summary-edit-step">
                    <a
                        [attr.aria-label]="'checkoutReview.endDate' | cxTranslate"
                        [routerLink]="{ cxRoute: 'cart' } | cxUrl"
                    >
                        <div class="edit-icon"></div>
                    </a>
                </div>
            </div>
        </ng-template>

        <ng-template #frequencyInformation>
            <div class="cx-review-summary-card cx-review-card-payment">
                <div>
                    <cx-card
                            [content]="getFrequencyCard(cart?.replenishmentSchedule?.interval) | async"
                            [truncateText]="true"
                            [charactersLimit]="26"
                    ></cx-card>
                </div>
                <div class="cx-review-summary-edit-step">
                    <a
                        [attr.aria-label]="'checkoutReview.frequency' | cxTranslate"
                        [routerLink]="{ cxRoute: 'checkoutFinishedGoods' } | cxUrl"
                    >
                        <div class="edit-icon"></div>
                    </a>
                </div>
            </div>
        </ng-template>

        <ng-template #desiredShipmentDate>
            <div class="cx-review-summary-card cx-review-card-payment">
                <div>
                    <cx-card
                            [content]="getDesiredDateCard(cart?.replenishmentSchedule?.desiredShipmentDate) | async"
                            [truncateText]="true"
                            [charactersLimit]="26"
                    ></cx-card>
                </div>
                <div class="cx-review-summary-edit-step">
                    <a
                        [attr.aria-label]="'checkoutReview.desiredDate' | cxTranslate"
                        [routerLink]="{ cxRoute: isServicePartsEnabled ? 'checkoutFinishedGoods' : 'cart' } | cxUrl"
                    >
                        <div class="edit-icon"></div>
                    </a>
                </div>
            </div>
        </ng-template>

        <ng-template #nextOrderInformation>
            <div class="cx-review-summary-card cx-review-card-payment">
                <div>
                    <cx-card
                            [content]="getNextOrderCard(cart?.replenishmentSchedule) | async"
                            [truncateText]="true"
                            [charactersLimit]="26"
                    ></cx-card>
                </div>
                <div class="cx-review-summary-edit-step">
                    <a
                        [attr.aria-label]="'checkoutReview.nextOrder' | cxTranslate"
                        [routerLink]="{ cxRoute: 'checkoutFinishedGoods' } | cxUrl"
                    >
                        <div class="edit-icon"></div>
                    </a>
                </div>
            </div>
        </ng-template>

        <ng-template #specialHandling>
            <div class="cx-review-summary-card cx-review-card-payment">
                <div>
                    <cx-card [content]="getSpecialHandlingCard(cart.shippingGroups?.[0]?.handlingOptions) | async"></cx-card>
                </div>
                <div class="cx-review-summary-edit-step">
                    <a
                        [attr.aria-label]="'checkoutReview.editPaymentDetails' | cxTranslate"
                        [routerLink]="{ cxRoute: getCheckoutStepUrl(checkoutStepTypeSpecialHandling, cart.shippingGroups?.[0].shippingGroupName) } | cxUrl"
                    >
                        <div class="edit-icon"></div>
                    </a>
                </div>
            </div>
        </ng-template>

        <!-- PAYMENT METHOD SECTION -->
        <ng-template #paymentType>
            <div class="cx-review-summary-card cx-review-card-payment">
                <div>
                    <cx-card [content]="getPaymentTypeCard(cart.paymentType) | async"></cx-card>
                </div>
                <div class="cx-review-summary-edit-step">
                    <a
                        [attr.aria-label]="'checkoutReview.editPaymentDetails' | cxTranslate"
                        [routerLink]="{ cxRoute: getCheckoutStepUrl(checkoutStepTypePaymentType) } | cxUrl"
                    >
                        <div class="edit-icon"></div>
                    </a>
                </div>
            </div>
        </ng-template>

        <!-- CART ITEM SECTION -->
        <ng-container *ngIf="!isServicePartsEnabled; else deliveryGroups">
            <div class="total-block">
                <div class="cx-review-cart-total">
                    {{
                    'cartItems.orderItems'
                        | cxTranslate: { count: cart.entryGroups?.length }
                    }}:
                    {{ cart.totalPrice?.formattedValue }}
                </div>
                <div
                        class="cx-review-cart-item col-md-12"
                        *ngIf="entries$ | async as entries"
                >
                    <cx-promotions [promotions]="(cart.appliedOrderPromotions || [])
                    .concat(cart.potentialOrderPromotions || [])">
                    </cx-promotions>
                    <cx-cart-item-list
                            [groups]="entries"
                            [readonly]="true"
                            [promotionLocation]="promotionLocation"
                    ></cx-cart-item-list>
                </div>
            </div>
        </ng-container>

        <ng-template #deliveryGroups>
            <ng-container *ngIf="cart$ | async as cart">
                <generac-shipping-groups [shippingGroups]="cart.shippingGroups"></generac-shipping-groups>
            </ng-container>
        </ng-template>
    </ng-container>
</div>
