import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { GeneracLogoutGuard } from './logout.guard';
import { CmsPageGuard, PageLayoutComponent, PageLayoutModule } from '@spartacus/storefront';

@NgModule({
  imports: [
    PageLayoutModule,
    RouterModule.forChild([
      {
        // @ts-ignore
        path: null,
        canActivate: [GeneracLogoutGuard, CmsPageGuard],
        component: PageLayoutComponent,
        data: { cxRoute: 'logout' },
      },
    ]),
  ],
})
export class LogoutModule {}
