import { APP_BOOTSTRAP_LISTENER, ComponentRef, NgModule } from '@angular/core';
import { CustomThemeService } from 'src/app/services/custom-theme.service';

export function initTheme(themeService: CustomThemeService) {
  return () => themeService.init();
}

@NgModule({
  providers: [
    {
      provide: APP_BOOTSTRAP_LISTENER,
      multi: true,
      useFactory: initTheme,
      deps: [CustomThemeService],
    },
  ],
})
export class CustomThemeModule {}
