import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { OrderDetailsService } from "@spartacus/order/components";
import { take, tap } from 'rxjs/operators';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { EntryGroup } from 'src/app/interfaces/cart';
import { OrderExtended } from 'src/app/interfaces/order';
import { WindowRef } from '@spartacus/core';
@Component({
    selector: 'cx-order-details-shipping',
    templateUrl: './order-detail-shipping.component.html',
})
export class OrderDetailShippingComponent implements OnInit {
    constructor(
        protected orderDetailsService: OrderDetailsService,
        protected googleAnalyticsService: GoogleAnalyticsService,
        protected winRef: WindowRef,
    ) {}

    order$: Observable<OrderExtended | undefined>;
    isOrderLoading$: Observable<boolean>;
    isSplitWfOrderConfirmationPage: boolean;

    ngOnInit() {
        this.order$ = (this.orderDetailsService.getOrderDetails() as Observable<OrderExtended | undefined>).pipe(
            take(1),
            tap((order: OrderExtended) => {
                const bundleEntryGroups = this.getBundleEntryGroups(order.entryGroups);
                if(bundleEntryGroups.length > 0) {
                    bundleEntryGroups.forEach((bundle) => {
                        this.googleAnalyticsService.sendGaEvent('purchase_bundle', {
                            currency: bundle.entryGroups[0].totalPrice.currencyIso,
                            value: bundle.entryGroups[0].totalPrice.value,
                            bundle_name: bundle.label,
                            bundle_template_id: bundle.entryGroups[0].externalReferenceId,
                            transaction_id: order.code,
                            quantity: bundle.entryGroups[0].entries[0].quantity
                        });
                    })
                }
                this.googleAnalyticsService.sendGaEvent('purchase', {
                    currency: order.totalPriceWithTax?.currencyIso,
                    payment_type: order.paymentType.displayName,
                    transaction_id: order.code,
                    tax: order.totalTax.value,
                    shipping: order.deliveryCost.value,
                    value: order.totalPriceWithTax?.value,
                    orderDiscount: order?.promoDiscounts?.value < 0 ? Math.abs(order?.promoDiscounts?.value) : undefined,
                    coupon: order?.appliedVouchers?.length > 0 ? order?.appliedVouchers[0].voucherCode : undefined,
                    items: this.googleAnalyticsService.buildGaItems(order?.entryGroups, order?.appliedProductPromotions)
                });
            })
        );

        this.isOrderLoading$ =
            typeof this.orderDetailsService.isOrderDetailsLoading === 'function'
                ? this.orderDetailsService.isOrderDetailsLoading()
                : of(false);

        this.isSplitWfOrderConfirmationPage = this.winRef.location.href.includes('order-confirmation?isSplitWfOrder=true');
    }

    private getBundleEntryGroups(entryGroups: EntryGroup[]): EntryGroup[] | [] {
        return entryGroups.filter((group)=> group.type == "CONFIGURABLEBUNDLE");
    }
}
