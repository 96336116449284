/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { provideDefaultConfig, RoutingConfig } from '@spartacus/core';
import { PageLayoutComponent, PageLayoutModule, PRODUCT_DETAILS_URL_MATCHER } from "@spartacus/storefront";
import { CustomCmsPageGuard } from "../../../guards/custom-cms-page.guard";

@NgModule({
  imports: [
    PageLayoutModule,
    RouterModule.forChild([
      {
        // @ts-ignore
        path: null,
        canActivate: [CustomCmsPageGuard],
        component: PageLayoutComponent,
        data: { cxRoute: 'product' },
      },
    ]),
  ],
  providers: [
    provideDefaultConfig(<RoutingConfig>{
      routing: {
        routes: {
          product: {
            matchers: [PRODUCT_DETAILS_URL_MATCHER],
          },
        },
      },
    }),
  ],
})
export class ProductDetailsPageModule {}
