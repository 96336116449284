import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { distinctUntilChanged, filter, map, switchMap, take, tap } from "rxjs/operators";
import { PaymentType } from "@spartacus/cart/base/root";
import { isNotUndefined, RoutingService, User, WindowRef } from "@spartacus/core";
import { CheckoutPaymentTypeFacade } from "@spartacus/checkout/b2b/root";
import { CheckoutStepService } from "@spartacus/checkout/base/components";
import { ActivatedRoute } from "@angular/router";
import { CheckoutService } from "../../../../../services/checkout.service";
import { PaymentTypeExtended } from "../../../../../interfaces/payment";
import { UserAccountFacade } from '@spartacus/user/account/root';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { CartExtended } from 'src/app/interfaces/cart.d';
import { GeneracActiveCartService } from '../../../cart/core/facade/active-cart.service';
import { CartType } from 'src/app/enums/cart-type.enum';

@Component({
  selector: 'cx-custom-checkout-payment-type',
  templateUrl: './custom-checkout-payment-type.component.html',
  styleUrls: ['./custom-checkout-payment-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomCheckoutPaymentTypeComponent implements OnInit {

  protected busy$ = new BehaviorSubject<boolean>(false);
  private isSubscription = false;

  typeSelected?: string;
  isAvailable: boolean;
  typeNameSelected?: string;
  cart: CartExtended;
  internalCalculation: boolean;
  user: User;
  isServicePartsEnabled: boolean;

  isUpdating$ = combineLatest([
    this.busy$,
    this.checkoutPaymentTypeFacade
        .getSelectedPaymentTypeState()
        .pipe(map((state) => state.loading)),
  ]).pipe(
      map(([busy, loading]) => busy || loading),
      distinctUntilChanged()
  );

  paymentTypes$: Observable<PaymentTypeExtended[]> =
    combineLatest([this.activeCartFacade.getActive(), this.userAccount.get()])
        .pipe(
          take(1),
          switchMap(([cart, user]: [CartExtended, User]) => {
            this.cart = cart;
            this.isSubscription = cart.cartTypes?.length === 1 && cart.cartTypes[0] === CartType.SUBSCRIPTION.toString();
            this.checkoutService.disableCheckoutSteps(this.cart?.cartTypes);
            this.internalCalculation = cart.internalCalculation;
            this.user = user;
            return this.checkoutService.getPaymentTypes(cart.code, user?.uid)
          })
        );

  typeSelected$: Observable<PaymentTypeExtended> = combineLatest([
    this.checkoutPaymentTypeFacade.getSelectedPaymentTypeState().pipe(
        filter((state) => !state.loading),
        map((state) => state.data)
    ),
    this.paymentTypes$,
  ]).pipe(
      map(
          ([selectedPaymentType, availablePaymentTypes]: [
                PaymentType | undefined,
            PaymentTypeExtended[]
          ]) => {
            const selectedPayment = availablePaymentTypes.find((availablePaymentType) => {
              return availablePaymentType.code === selectedPaymentType.code;
            });
            if (selectedPaymentType && selectedPayment) {
              return selectedPayment;
            }
            if (availablePaymentTypes.length) {
              this.busy$.next(true);
              this.checkoutPaymentTypeFacade
                  .setPaymentType(
                      availablePaymentTypes[0].code as string,
                  )
                  .subscribe({
                    complete: () => this.onSuccess(),
                    error: () => this.onError(),
                  });
              return availablePaymentTypes[0];
            }
            return undefined;
          }
      ),
      filter(isNotUndefined),
      distinctUntilChanged(),
      tap((selected : any) => {
        this.typeSelected = selected?.code;
        this.isAvailable = selected?.available;
        this.typeNameSelected = selected?.displayName;
      })
  );

  cartPoNumber$: Observable<string> = this.checkoutPaymentTypeFacade
      .getPurchaseOrderNumberState()
      .pipe(
          filter((state) => !state.loading),
          map((state) => state.data),
          filter(isNotUndefined),
          distinctUntilChanged()
      );

  constructor(
      protected checkoutPaymentTypeFacade: CheckoutPaymentTypeFacade,
      private checkoutService: CheckoutService,
      private routing: RoutingService,
      protected activeCartFacade: GeneracActiveCartService,
      protected checkoutStepService: CheckoutStepService,
      protected activatedRoute: ActivatedRoute,
      protected winRef: WindowRef,
      protected googleAnalyticsService: GoogleAnalyticsService,
      private userAccount: UserAccountFacade
  ) {}


  ngOnInit() {
    this.isServicePartsEnabled = JSON.parse(this.winRef.localStorage.getItem('isServicePartsEnabled'));
  }

  changeType(type: PaymentTypeExtended): void {
    this.busy$.next(true);
    this.typeSelected = type.code;
    this.isAvailable = type.available;
    this.typeNameSelected = type.displayName;

    this.checkoutPaymentTypeFacade
      .setPaymentType(type.code)
      .subscribe({
        complete: () => this.onSuccess(),
        error: () => this.onError(),
      });
  }

  next(): void {
    if (!this.typeSelected) {
      return;
    }

    this.busy$.next(true);
    this.checkoutPaymentTypeFacade
        .setPaymentType(this.typeSelected)
        .subscribe({
            // we don't call onSuccess here, because it can cause a spinner flickering
            complete: () => {
              this.checkoutStepService.next(this.activatedRoute);
              this.googleAnalyticsService.sendGaEvent('add_payment_info', {
                currency: this.cart.totalPriceWithTax?.currencyIso,
                payment_type: this.typeNameSelected,
                value: this.cart.totalPriceWithTax?.value,
                orderDiscount: this.cart?.promoDiscounts?.value < 0 ? Math.abs(this.cart?.promoDiscounts?.value) : undefined,
                coupon: this.cart?.appliedVouchers?.length ? this.cart?.appliedVouchers[0].voucherCode : undefined,
                items: this.googleAnalyticsService.buildGaItems(this.cart.entryGroups, this.cart.appliedProductPromotions)
              });
            },
            error: () => this.onError(),
        });
  }

  protected onSuccess(): void {
    this.busy$.next(false);
  }

  protected onError(): void {
    this.busy$.next(false);
  }
}
