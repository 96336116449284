import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActiveCartFacade, DeliveryMode, PaymentDetails, PromotionLocation } from "@spartacus/cart/base/root";
import { Card, ICON_TYPE } from "@spartacus/storefront";
import { CheckoutDeliveryAddressFacade, CheckoutDeliveryModesFacade, CheckoutPaymentFacade, CheckoutStep, CheckoutStepType } from "@spartacus/checkout/base/root";
import { Address, TranslationService } from "@spartacus/core";
import { CheckoutStepService } from "@spartacus/checkout/base/components";
import { combineLatest, Observable } from "rxjs";
import { PaymentType } from "@spartacus/cart/base/root/models/cart.model";
import { distinctUntilChanged, filter, map, tap } from "rxjs/operators";
import { CheckoutStepTypeExtended } from "../../../../../enums/checkout-step-type";
import { CartExtended, ContactInformation, HandlingOption, SubscriptionInfo } from "../../../../../interfaces/cart";
import { SubscriptionsService } from "../../../../../services/subscriptions.service";
import { isEqual } from 'src/app/shared/helpers/is-equal';
import { concatStrings } from 'src/app/shared/helpers/concat-strings';
import { CartType } from 'src/app/enums/cart-type.enum';
import { CheckoutService } from 'src/app/services/checkout.service';
import { ExtAddress } from 'src/app/interfaces/checkout';
import moment from 'moment';

@Component({
  selector: 'cx-review-submit',
  templateUrl: './checkout-review-submit.component.html',
  styleUrls: ['./checkout-review-submit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutReviewSubmitComponent implements OnInit {
  iconTypes = ICON_TYPE;
  isSubscription = false;
  isCartMixed: boolean;
  checkoutStepTypeDeliveryAddress = CheckoutStepType.DELIVERY_ADDRESS;
  checkoutStepTypePaymentType = CheckoutStepType.PAYMENT_TYPE;
  checkoutStepTypeSpecialHandling = CheckoutStepTypeExtended.SPECIAL_HANDLING;
  checkoutStepTypeSubscriptionInformation = CheckoutStepTypeExtended.SUBSCRIPTION_INFORMATION;
  promotionLocation: PromotionLocation = PromotionLocation.ActiveCart;
  cart$: Observable<CartExtended>;
  entries$: Observable<any[]>;

  constructor(
      protected checkoutDeliveryAddressFacade: CheckoutDeliveryAddressFacade,
      protected checkoutPaymentFacade: CheckoutPaymentFacade,
      protected activeCartFacade: ActiveCartFacade,
      protected translationService: TranslationService,
      protected checkoutStepService: CheckoutStepService,
      protected checkoutDeliveryModesFacade: CheckoutDeliveryModesFacade,
      protected subscriptionsService: SubscriptionsService,
      private checkoutService: CheckoutService,
  ) {}

  steps$: Observable<CheckoutStep[]> = this.checkoutStepService.steps$;

  deliveryAddress$: Observable<Address | undefined> =
      this.checkoutDeliveryAddressFacade.getDeliveryAddressState().pipe(
          filter((state) => !state.loading && !state.error),
          map((state) => state.data)
      );

  deliveryMode$: Observable<DeliveryMode | undefined> =
      this.checkoutDeliveryModesFacade.getSelectedDeliveryModeState().pipe(
          filter((state) => !state.loading && !state.error),
          map((state) => state.data)
      );

  paymentDetails$: Observable<PaymentDetails | undefined> =
      this.checkoutPaymentFacade.getPaymentDetailsState().pipe(
          filter((state) => !state.loading && !state.error),
          map((state) => state.data),
      );

  ngOnInit() {
    this.setCart();
    this.createEntryGroupsStream();
  }

  getDeliveryAddressCard(
      deliveryAddress: Address,
      countryName?: string
  ): Observable<Card> {
    const deliveryTranslation$ = this.isSubscription ?
      this.translationService.translate('addressCard.deliveryAddress') :
      this.translationService.translate('addressCard.shipTo');
    return combineLatest([
      deliveryTranslation$,
      this.translationService.translate('addressCard.phoneNumber'),
      this.translationService.translate('addressCard.mobileNumber'),
    ]).pipe(
        map(([textTitle, textPhone, textMobile]) => {
          if (!countryName) {
            countryName = deliveryAddress?.country?.name as string;
          }
          const numbers = deliveryAddress.phone;
          return {
            title: textTitle,
            text: [
              deliveryAddress.companyName,
              concatStrings(
                ' ',
                deliveryAddress?.firstName,
                deliveryAddress?.lastName
              ),
              deliveryAddress.line1,
              deliveryAddress.line2,
              concatStrings(
                ', ',
                deliveryAddress.town,
                deliveryAddress.region?.name,
                deliveryAddress?.country?.isocode
              ),
              deliveryAddress.postalCode,
              numbers,
            ],
          } as Card;
        })
    );
  }

  getFreightForwarderCard(freightForwarderAddress: ExtAddress): Observable<Card> {
    return combineLatest([
      this.translationService.translate('checkoutAddress.ffAddress'),
      this.translationService.translate('addressCard.phoneNumber'),
      this.translationService.translate('addressCard.mobileNumber'),
    ]).pipe(
        map(([textTitle, textPhone, textMobile]) => {
          const numbers = freightForwarderAddress.phone;
          return {
            title: textTitle,
            text: [
              freightForwarderAddress.companyName,
              freightForwarderAddress.firstName,
              freightForwarderAddress.line1,
              freightForwarderAddress.line2,
              concatStrings(
                ', ',
                freightForwarderAddress.town,
                freightForwarderAddress.region?.name,
                freightForwarderAddress?.country?.isocode
              ),
              freightForwarderAddress.postalCode,
              numbers,
            ],
          } as Card;
        })
    );
  }

  getDeliveryModeCard(deliveryMode: DeliveryMode): Observable<Card> {
    return combineLatest([
      this.translationService.translate('checkoutMode.deliveryMethod'),
    ]).pipe(
        map(([textTitle]) => {
          return {
            title: textTitle,
            textBold: deliveryMode.name,
            text: [
              deliveryMode.description,
              deliveryMode.deliveryCost?.formattedValue || ''
            ],
          } as Card;
        })
    );
  }

  getPaymentMethodCard(paymentDetails: PaymentDetails): Observable<Card> {
    return combineLatest([
      this.translationService.translate('paymentForm.payment'),
      this.translationService.translate('paymentCard.expires', {
        month: paymentDetails.expiryMonth,
        year: paymentDetails.expiryYear,
      }),
      this.translationService.translate('paymentForm.billingAddress'),
    ]).pipe(
        map(([textTitle, textExpires, billingAddress]) => {
          return {
            title: textTitle,
            textBold: paymentDetails.accountHolderName,
            text: [paymentDetails.cardNumber, textExpires],
            paragraphs: [
              {
                title: billingAddress + ':',
                text: [
                  concatStrings(
                    ' ',
                    paymentDetails.billingAddress?.firstName,
                    paymentDetails.billingAddress?.lastName
                  ),
                  paymentDetails.billingAddress?.line1,
                  concatStrings(
                    ', ',
                    paymentDetails.billingAddress?.town,
                    paymentDetails.billingAddress?.region?.isocode,
                    paymentDetails.billingAddress?.country?.isocode
                  ),
                  paymentDetails.billingAddress?.postalCode,
                ],
              },
            ],
          } as Card;
        })
    );
  }

  getCheckoutStepUrl(stepType: CheckoutStepType | string): string | undefined {
    const step = this.checkoutStepService.getCheckoutStep(
        stepType as CheckoutStepType
    );
    return step?.routeName;
  }

  getPaymentTypeCard(paymentType: PaymentType): Observable<Card> {
      return combineLatest([
          this.translationService.translate('checkoutProgress.paymentDetails'),
      ]).pipe(
          map(([textTitle]) => {
              return {
                  title: textTitle,
                  textBold: paymentType.displayName,
              } as Card;
          })
      );
  }

    getPurchaseOrderNumber(purchaseOrderNumber: string): Observable<Card> {
        return combineLatest([
            this.translationService.translate('checkoutAddress.detailsForm.poNumber'),
        ]).pipe(
            map(([textTitle]) => {
                return {
                    title: textTitle,
                    text: [purchaseOrderNumber ? purchaseOrderNumber : 'None']
                } as Card;
            })
        );
    }

  getEndDateCard(endDate: string): Observable<Card> {
    const formattedDate = new Date(endDate).toISOString().split('T')[0];
    return combineLatest([
      this.translationService.translate('checkoutReview.endDate'),
    ]).pipe(
      map(([textTitle]) => {
        return {
          title: textTitle,
          text: [formattedDate]
        } as Card;
      })
    );
  }

  getFrequencyCard(frequency: number): Observable<Card> {
    return combineLatest([
      this.translationService.translate('checkoutReview.frequency'),
    ]).pipe(
      map(([textTitle]) => {
        return {
          title: textTitle,
          text: [`Every ${frequency} days`]
        } as Card;
      })
    );
  }

  getDesiredDateCard(desiredDate: string): Observable<Card> {
    const formattedDate = moment(desiredDate).format('YYYY-MM-DD');
    return combineLatest([
      this.translationService.translate('checkoutReview.desiredDate'),
    ]).pipe(
      map(([textTitle]) => {
        return {
          title: textTitle,
          text: [formattedDate]
        } as Card;
      })
    );
  }

  getNextOrderCard(replenishment: any): Observable<Card> {
    return combineLatest([
      this.translationService.translate('checkoutReview.nextOrder'),
    ]).pipe(
      map(([textTitle]) => {
        return {
          title: textTitle,
          text: [this.addDays(new Date(replenishment.startDate), replenishment.interval).toISOString().split('T')[0]]
        } as Card;
      })
    );
  }

    getSpecialHandlingCard(handlingOptions: HandlingOption[]): Observable<Card> {
        return combineLatest([
            this.translationService.translate('checkoutProgress.specialHandlingTab'),
        ]).pipe(
            map(([textTitle]) => {
                return {
                    title: textTitle,
                    text: handlingOptions.map(option => option.name),
              } as Card;
            })
        );
    }

  private setCart() {
    this.cart$ = this.activeCartFacade.getActive()
      .pipe(
        distinctUntilChanged(isEqual),
        tap(cart => {
          this.isSubscription = cart.cartTypes?.length === 1 && cart.cartTypes[0] === CartType.SUBSCRIPTION.toString();
          this.checkoutService.disableCheckoutSteps(cart?.cartTypes);
        })
      );
  }

  private createEntryGroupsStream() {
    this.entries$ = this.activeCartFacade
      .getActive()
      .pipe(
        map((cart: any) => cart.entryGroups),
        filter((entryGroups) => entryGroups?.length > 0)
      )
  }

  private addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }
}
