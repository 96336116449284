<div [class.pb-0]="!replenishmentEnabled" class="cx-order-type-card" [class.is-service-parts]="isServicePartsEnabled">
  <div class="cx-label-container">
    <h5 *ngIf="replenishmentEnabled" class="cx-order-replenishment-header">
      {{ 'checkoutScheduledReplenishment.autoReplenishOrder' | cxTranslate }}
    </h5>
    <div 
      *ngIf="!replenishmentEnabled" 
      class="desired-date-label-container d-flex" 
      (mouseenter)="desiredDateMessage=tooltipMessage"
      (mouseleave)="desiredDateMessage=''">
      <h5 *ngIf="!isServicePartsEnabled" class="mb-0 pr-2 align-self-end form-data-label">Desired Shipment Date</h5>
      <div class="position-relative">
        <img
        src="../../../../../assets/icons/info-grey.svg"
      />
      <cx-info-message [icon]="null" [message]="desiredDateMessage" [position]="'position-right'"></cx-info-message>
      </div>
    </div>
  </div>
  <ng-container *ngIf="replenishmentEnabled">
    <div
      class="cx-order-type-container form-check"
      *ngFor="let type of orderTypes | keyvalue"
    >
      <input
        id="orderType-{{ type.value }}"
        class="scaled-input form-check-input"
        role="radio"
        type="radio"
        formControlName="orderType"
        aria-checked="true"
        (change)="changeOrderType(type.value)"
        [value]="type.value"
        [checked]="type.value === (selectedOrderType$ | async)"
      />
      <label
        class="order-type-label form-check-label form-radio-label"
        for="orderType-{{ type.value }}"
      >
        <div class="order-type">
          {{
            'checkoutScheduledReplenishment.orderType_' + type?.value
              | cxTranslate
          }}
        </div>
      </label>
    </div>
  </ng-container>
  <ng-container
    *ngIf="
      replenishmentEnabled &&
      scheduleReplenishmentFormData &&
      (selectedOrderType$ | async) === orderTypes.SCHEDULE_REPLENISHMENT_ORDER
    "
  >
    <div class="cx-replenishment-form-data-container">
      <div *ngIf="!isMonthly" class="cx-days">
        <span class="form-data-label">{{
          'checkoutScheduledReplenishment.every' | cxTranslate
        }}</span>
        <ng-container *ngIf="isWeekly; else isDaily">
          <select
            class="form-control"
            (change)="changeNumberOfWeeks($event)"
          >
            <option
              *ngFor="let nWeeks of numberOfWeeks"
              [value]="nWeeks"
              [selected]="
                nWeeks === scheduleReplenishmentFormData.numberOfWeeks
              "
            >
              {{ nWeeks }}
            </option>
          </select>
        </ng-container>
        <ng-template #isDaily>
          <select
            class="form-control"
            (change)="changeNumberOfDays($event)"
          >
            <option
              *ngFor="let nDays of numberOfDays"
              [value]="nDays"
              [selected]="nDays === scheduleReplenishmentFormData.interval"
            >
              {{ nDays }}
            </option>
          </select>
        </ng-template>
      </div>
    </div>

    <div class="date-wrapper d-flex">
      <div class="cx-replenishment-form-data-container">
        <span class="form-data-label">{{
          'checkoutScheduledReplenishment.startOn' | cxTranslate
        }}</span>
        <div class="cx-replenishment-date">
          <input
            type="date"
            placeholder="yyyy-mm-dd"
            [min]="currentDate"
            [value]="currentDate"
            (change)="changeReplenishmentStartDate($event)"
          />
        </div>
      </div>
  
      <div class="cx-replenishment-form-data-container">
        <span class="form-data-label">End Date</span>
        <div class="cx-replenishment-date">
          <input
            type="date"
            placeholder="yyyy-mm-dd"
            [min]="minDateForEndDate"
            [value]="endDate"
            (change)="changeReplenishmentStartDate($event)"
          />
        </div>
      </div>
    </div>

    <div
      *ngIf="isWeekly"
      class="cx-replenishment-form-data-container cx-repeat-days-container"
    >
      <span class="cx-repeat-days form-data-label">{{
        'checkoutScheduledReplenishment.repeatOnDays' | cxTranslate
      }}</span>
      <div *ngFor="let day of daysOfWeek" class="form-check">
        <label for="day-{{ day }}" class="cx-week-day">{{
          day | titlecase
        }}</label
        ><input
          id="day-{{ day }}"
          type="checkbox"
          class="form-check-input"
          [checked]="hasDaysOfWeekChecked(day)"
          (change)="changeRepeatDays(day, $event)"
        />
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!replenishmentEnabled">
    <div class="cx-replenishment-form-data-container desired">
      <mat-form-field appearance="outline" color="primary" >
        <div class="position-relative" *ngIf="!isCartPage && (showShipmentHint || showShipmentDescription)">
          <img  src="../../../../../assets/icons/info-grey.svg" />
          <cx-info-message class="info-error" [icon]="null" [message]="getInfoMessage()" [position]="'position-left'"></cx-info-message>
        </div>
        <input matInput (dateChange)="onDateChange($event)" placeholder="Desired Shipment Date" [OnlyNumber]="true" (blur)="autoCompleteDate()" [formControl]="$any(desiredDateControl)" [matDatepicker]="picker" [matDatepickerFilter]="desiredDayFilter">
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-hint *ngIf="showShipmentHint && isCartPage" [textContent]="shipmentNearestDate"></mat-hint>
        <mat-hint *ngIf="showShipmentDescription && isCartPage" [textContent]="dateFellOnWeekend"></mat-hint>
      </mat-form-field>
    </div>
  </ng-container>
</div>
