import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CartValidationGuard } from '@spartacus/cart/base/core';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { AddressFormModule, FormErrorsModule, IconModule, KeyboardFocusModule, NgSelectA11yModule, SpinnerModule } from '@spartacus/storefront';
import { CustomCheckoutDeliveryAddressComponent } from "./custom-checkout-delivery-address.component";
import { CheckoutAuthGuard } from "@spartacus/checkout/base/components";
import { AddressDetailsComponent } from "../address-details/address-details.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { CardModule } from 'src/app/shared/components/card';
import { AddressValidationService } from '../../../../../services/address-validation.service';
import { VerifyAddressComponent } from './verify-address/verify-address.component';
import { FreightForwordFormModule } from '../../../address-book/address-form/freight-forword-form/freight-forword-form.module';
import { CustomMessageModule } from "../../../../../shared/components/custom-message/custom-message.module";
import { InfoMessageModule } from 'src/app/shared/components/info-message/info-message.module';
import { OfflineBadgeModule } from 'src/app/shared/components/offline-badge/offline-badge.module';
import { SharedModule } from "../../../../../shared/shared.module";
import { GeneracAddressFormComponent } from '../../../address-book/address-form/address-form.component';
import { CartReloadGuard } from "../../../../../guards/cart-reload.guard";
import { CustomCartNotEmptyGuard } from "../../../../../guards/custom-cart-not-empty.guard";
import { MatExpansionModule } from '@angular/material/expansion';
import { AddressDetailsFormComponent } from '../address-details/address-details-form/address-details-form.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    AddressFormModule,
    CardModule,
    SpinnerModule,
    FormsModule,
    KeyboardFocusModule,
    ReactiveFormsModule,
    IconModule,
    I18nModule,
    ReactiveFormsModule,
    NgSelectModule,
    FormErrorsModule,
    FreightForwordFormModule,
    CustomMessageModule,
    InfoMessageModule,
    OfflineBadgeModule,
    SharedModule,
    NgSelectA11yModule,
    MatExpansionModule,
  ],
  providers: [
    AddressValidationService,
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutDeliveryAddress: {
          component: CustomCheckoutDeliveryAddressComponent,
          guards: [CheckoutAuthGuard, CustomCartNotEmptyGuard, CartValidationGuard, CartReloadGuard],
        },
        CheckoutSubscriptionInfo: {
          component: CustomCheckoutDeliveryAddressComponent,
          guards: [CheckoutAuthGuard, CustomCartNotEmptyGuard, CartValidationGuard, CartReloadGuard],
        }
      },
    }),
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutCostCenterComponent: {
          component: [],
        },
      },
    }),
  ],
  declarations: [
      CustomCheckoutDeliveryAddressComponent,
      AddressDetailsComponent,
      AddressDetailsFormComponent,
      VerifyAddressComponent,
      GeneracAddressFormComponent,
  ],
  exports: [CustomCheckoutDeliveryAddressComponent, GeneracAddressFormComponent],
})
export class CustomCheckoutDeliveryAddressModule {}
