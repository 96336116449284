import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnDestroy } from '@angular/core';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthActions, GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import { Actions, ofType } from '@ngrx/effects';
import { LoginFormComponentService } from '@spartacus/user/account/components';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { AuthHelperService } from "../../spartacus/features/auth/services/auth-helper.service";
export const tokenKey = 'spartacus⚿⚿auth';
export const postponedErrorKey = 'postponedError';

@Component({
  selector: 'cx-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenaracLoginFormComponent implements OnDestroy {
  @HostBinding('class.user-form') style = true;

  isLocal = environment.isLocal;
  isLoading = false;
  companyNumber: string;
  salesOrgId: string;
  orderNbr: string;
  form = this.service.form;
  isUpdating$ = this.service.isUpdating$;
  private subscriptions = new Subscription();
  constructor(
    protected service: LoginFormComponentService,
    protected googleAnalyticsService: GoogleAnalyticsService,
    public auth: AuthService,
    private http: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private actions$: Actions,
    private globalMessageService: GlobalMessageService,
    private authHelperService: AuthHelperService,
    private cdr: ChangeDetectorRef
  ) {
    this.subscribeToAuthProcess();
    this.subscribeToLoginEvents();
    this.checkPreviousError();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onSubmit(): void {
    this.service.login();
  }

  loginClick(): Observable<void> {
    this.googleAnalyticsService.sendGaEvent('login', {
      method: "Login Button Click"
    });
    return this.auth.loginWithRedirect();
  }

  private subscribeToAuthProcess() {
    this.subscriptions.add(
      this.auth.user$
        .pipe(
          switchMap((user) => {
            this.geAdditionalState();
            if (!user && !this.companyNumber) return EMPTY;
            this.isLoading = true;
            this.cdr.detectChanges();
            return this.auth.getAccessTokenSilently().pipe(
              catchError((err) => {
                if (this.companyNumber && this.salesOrgId) {
                  this.googleAnalyticsService.sendGaEvent('login', {
                    method: "D360 Shop Button"
                  });
                  return this.auth.loginWithRedirect({
                    appState: {
                      companyNumber: this.companyNumber,
                      salesOrgId: this.salesOrgId,
                      orderNbr: this.orderNbr
                    }
                  });
                }
                this.globalMessageService.add(
                  'Login required',
                  GlobalMessageType.MSG_TYPE_ERROR
                );
                this.isLoading = false;
                this.cdr.detectChanges();
                return EMPTY;
              })
            );
          }),
          filter(Boolean),
          switchMap((token: any) => this.authHelperService.loginToSSOAndSAP(token, this.salesOrgId, this.companyNumber)),
          switchMap(() => this.internalLogin()),
          catchError(error => {
            localStorage.setItem(
              postponedErrorKey,
              JSON.stringify({
                message: error?.error?.error_description || 'Something Went Wrong',
                type: GlobalMessageType.MSG_TYPE_ERROR,
                full: JSON.stringify(error)
              })
            );
            localStorage.removeItem(tokenKey);
            return this.auth.logout({ logoutParams: { returnTo: window.location.origin + '/login' }});
          })
        )
        .subscribe()
    );
  }

  private subscribeToLoginEvents() {
    this.subscriptions.add(
      this.actions$.pipe(
        ofType(AuthActions.LOGIN),
        filter(action => !!action)
      ).subscribe(action => this.router.navigate(['/']))
    )
  }

  private geAdditionalState() {
    const params = this.activatedRoute.snapshot.queryParams;
    if (params?.salesOrgId) {
      this.salesOrgId = params.salesOrgId;
    }
    if (params?.companyNumber) {
      this.companyNumber = params.companyNumber;
      localStorage.setItem('companyNumber', this.companyNumber);
    }
    if (params?.orderNbr) {
      this.orderNbr = params.orderNbr;
    }
    this.subscriptions.add(
      this.auth.appState$
        .pipe(take(1))
        .subscribe((appState) => {
          this.companyNumber = appState?.companyNumber;
          this.salesOrgId = appState?.salesOrgId;
          this.orderNbr = appState?.orderNbr;
        })
    );
  }

  private internalLogin(): Observable<void> {
    this.isLoading = false;
    return this.auth.loginWithRedirect({
      appState: {
        companyNumber: this.companyNumber,
        salesOrgId: this.salesOrgId,
        orderNbr: this.orderNbr
      }
    });
  }

  private checkPreviousError() {
    const postponedError = localStorage.getItem(postponedErrorKey);

    if (postponedError) {
      const error = JSON.parse(postponedError);
      this.globalMessageService.add(
        error.message,
        error.type
      );
      localStorage.removeItem(postponedErrorKey);
    }
  }
}
