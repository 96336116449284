<ng-container *ngIf="shippingGroup$ | async as shippingGroup">
    <cx-spinner *ngIf="loading === LoadingEnum.Loading" class="spinner-overlay"></cx-spinner>
    <generac-checkout-delivery-groups 
        [shippingGroup]="shippingGroup"
        [cartCode]="cartCode"
        [purchaseOrderNumber]="purchaseOrderNumber">
    </generac-checkout-delivery-groups>
    <cx-checkout-shipping-accounts-selector 
        [shippingAccountSelected]="selectedShippingAccount"
        [disableDealerShippingAccount]="isLtlItemExists || !dealerShippingAccounts || dealerShippingAccounts?.length < 0"
        (shippingAccountChanged)="getDeliveryMethods($event)"
    ></cx-checkout-shipping-accounts-selector>
    <cx-checkout-delivery-method-selector
        [updateDeliveryMethods]="updateDeliveryMethodsTrigger$"
        [generacShippingAccounts]="generacShippingAccounts"
        [dealerShippingAccounts]="dealerShippingAccounts"
        [selectedCarrier]="selectedCarrier"
        [selectedShippingCondition]="selectedShippingCondition"
        (carrierOptionSelected)="setCarrier($event)"
    ></cx-checkout-delivery-method-selector>
    <cx-checkout-special-handling [shippingGroupName]="shippingGroup.shippingGroupName">
    </cx-checkout-special-handling>
</ng-container>

<generac-checkout-control-btns
    [isContinueDisabled]="!selectedCarrier || !selectedShippingCondition || !selectedShippingAccount || loading === LoadingEnum.Loading"
    [isNextActionCustom]="true"
    [skipPaymentTypeStep]="skipPaymentTypeStep"
    (nextBtnClicked)="next()">
</generac-checkout-control-btns>