import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArraySplitPipe } from "./array-split.pipe";

@NgModule({
  declarations: [ArraySplitPipe],
  imports: [
    CommonModule
  ],
  exports: [ArraySplitPipe]
})
export class ArraySplitModule { }
