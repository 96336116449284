import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Address, Region, UserAddressService } from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { ICON_TYPE } from '@spartacus/storefront';
import { AddressType } from "../../../../../enums/address-type.enum";
import { CardWithAddress, ExtAddress } from 'src/app/interfaces/checkout';

@Component({
  selector: 'app-freight-forword-form',
  templateUrl: './freight-forword-form.component.html',
  styleUrls: ['./freight-forword-form.component.scss'],
})
 export class FreightForwordFormComponent implements OnInit, OnDestroy {

  AddressType = AddressType;

  _fFAddresses: Array<ExtAddress>;
  get fFAddresses(): Array<ExtAddress> {
    return this._fFAddresses;
  }
  @Input() set fFAddresses(value: Array<ExtAddress>) {
    this._fFAddresses = value.map(x => ({ ...x, disabled: x.erpStatus === 'BLOCK' }));
  }
  @Input()
  selectedCountry: string;
  @Input()
  selectedFFAddress: Address;
   
  @Output() fFAddressesChange = new EventEmitter<any>();

  selectedCard: Address;
  subscription: Subscription = new Subscription();

  addressForm: UntypedFormGroup = this.fb.group({
    country: this.fb.group({
      isocode: [null, Validators.required],
    }),
    titleCode: [''],
    firstName: ['', Validators.required],
    companyName: [{ value: '', disabled: true }],
    line1: [{ value: '', disabled: true }],
    line2: [{ value: '', disabled: true }],
    town: [{ value: '', disabled: true }],
    region: this.fb.group({
      isocode: [{ value: null, disabled: true }],
    }),
    postalCode: [{ value: '', disabled: true }],
    phone: ['', Validators.required],
    cellphone: '',
    defaultAddress: [false]
  });
  doneAutoSelect = false;
  test: any;
  test$:Observable<CardWithAddress[]>;
  regions: Region[];
  iconTypes = ICON_TYPE;

  constructor(
    protected fb: UntypedFormBuilder,
    protected userAddressService: UserAddressService,    
  ) {}

  ngOnInit() {
    this.onFormChange();
    this.subscription.add(this.userAddressService.getRegions(this.selectedCountry).subscribe(res => this.regions = res));
    this.addressForm.get('country.isocode').setValue(this.selectedCountry);

    if(this.selectedFFAddress) {
      this.selectedCard = this.selectedFFAddress;
      this.addressForm.patchValue(this.selectedFFAddress);
    } else if(this.fFAddresses.length){
      this.selectedCard = this.fFAddresses.filter(x => x.erpStatus !== 'BLOCK')[0];
      this.addressForm.patchValue(this.selectedCard);
      this.fFAddressesChange.emit({
        ffAddressValue: this.addressForm.getRawValue(), 
        selectedAddress: this.selectedCard
      });
    }
  }

  onFormChange() {
    this.addressForm.valueChanges
      .subscribe((res) => {
        if(this.addressForm.valid && JSON.stringify(res) === JSON.stringify(this.addressForm.value)) {
          this.fFAddressesChange.emit({
          ffAddressValue: this.addressForm.getRawValue(),
          selectedAddress: this.selectedCard
      });
      }
 });
  }

  onFFAddressChange($event:any) {
    this.addressForm.patchValue(this.selectedCard);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

