import { ComponentRef, Injectable } from "@angular/core";
import { CustomLaunchDialogService } from "./custom-launch-dialog.service";
import { LAUNCH_CALLER } from "@spartacus/storefront";
import { catchError } from "rxjs/operators";
import { GlobalMessageService, GlobalMessageType } from "@spartacus/core";
import { Order, OrderFacade } from "@spartacus/order/root";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { Router } from "@angular/router";
import { PaymentType } from "../enums/payment-type.enum";
import { OrderExtended } from "../interfaces/order";

@Injectable()
export class CustomOrderService {
  placedOrder: void | Observable<ComponentRef<any> | undefined>;

  private isPlaceOrderBtnAvailable = new BehaviorSubject(true);
  isPlaceOrderBtnAvailable$ = this.isPlaceOrderBtnAvailable.asObservable();

  constructor(
    protected orderFacade: OrderFacade,
    protected launchDialogService: CustomLaunchDialogService,
    private globalMessageService: GlobalMessageService,
    private router: Router,
  ) {

  }

  placeOrder(isFormValid: boolean, isDelego: boolean = false): void {
    this.placedOrder = this.launchDialogService.openDialogAndSubscribe(
      LAUNCH_CALLER.PLACE_ORDER_SPINNER,
      undefined,
      null
    );
    this.isPlaceOrderBtnAvailable.next(false);
    this.orderFacade.placeOrder(isFormValid).pipe(
      catchError((error) => {
        this.isPlaceOrderBtnAvailable.next(true);
        if (isDelego) this.closeDelegoModal();
        if (error && error.status === 400) {
          this.globalMessageService.add(
            {
              key: 'checkoutReview.errorPlacingOrder',
            },
            GlobalMessageType.MSG_TYPE_ERROR
          );
          return throwError(error);
        }
        return of({} as Order);
      }),
    ).subscribe((order: Order) => {
      let orderExt = order as OrderExtended
      const isSplitWfOrder = orderExt?.subCartCode && orderExt?.paymentType?.code === PaymentType.WELLS_FARGO
      this.confirmOrderWithPlaceOrder(isDelego, isSplitWfOrder);
    })
  }

  getIsPlaceOrderBtnAvailable() {
    return this.isPlaceOrderBtnAvailable.getValue();
  }

  setIsPlaceOrderBtnAvailable(val: boolean) {
    this.isPlaceOrderBtnAvailable.next(val);
  }

  private confirmOrderWithPlaceOrder(isDelego: boolean = false, isSplitWfOrder: boolean = false) {
    if (this.placedOrder) {
      this.placedOrder
        .subscribe((component) => this.navigateToOrderConfirmation(component, isDelego, isSplitWfOrder))
        .unsubscribe();
      return;
    }
    this.navigateToOrderConfirmation(undefined, isDelego, isSplitWfOrder);
  }

  private navigateToOrderConfirmation(component?: ComponentRef<any> | undefined, isDelego: boolean = false, isSplitWfOrder: boolean = false) {
    this.router.navigateByUrl(`/order-confirmation${isSplitWfOrder ? '?isSplitWfOrder=true' : ''}`).then(() => {
      this.globalMessageService.add(
        {
          key: 'checkoutReview.successPlacingOrder',
        },
        GlobalMessageType.MSG_TYPE_CONFIRMATION
      );
      if (isDelego) this.closeDelegoModal();
      if (!this.placedOrder) {
        return;
      }
      this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
      this.isPlaceOrderBtnAvailable.next(true);
      if (component) {
        component.destroy();
      }
    })
  }

  private closeDelegoModal() {
    this.launchDialogService.closeDialog('destroy', LAUNCH_CALLER.PAY_BY_DELEGO);
  }
}