import { Injectable } from '@angular/core';
import { PageMetaResolver, PageTitleResolver, TranslationService } from '@spartacus/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SavedAddressesPageMetaResolver extends PageMetaResolver implements PageTitleResolver {
  
  constructor(
    protected translation: TranslationService,
  ) {
    super();
    this.pageUid = 'saved-addresess' as any;
  }

  resolveTitle(): Observable<string> {
    return this.translation.translate('pageMetaResolver.savedAddresses.title');
  }
}
