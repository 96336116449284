import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActiveFacetsModule, FacetListModule, IconModule, ProductFacetNavigationComponent } from '@spartacus/storefront';
import { I18nModule } from '@spartacus/core';
import { GeneracFacetListComponent } from './facet-list/facet-list.component';
import { GeneracActiveFacetsComponent } from './active-facets/active-facets.component';

@Component({
  selector: 'cx-product-facet-navigation',
  templateUrl: './product-facet-navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FacetListModule,
    ActiveFacetsModule,
    IconModule,
    I18nModule,
    GeneracFacetListComponent,
    GeneracActiveFacetsComponent,
  ]
})
export class GeneracProductFacetNavigationComponent extends ProductFacetNavigationComponent {

}
