import { NgModule } from '@angular/core';
import { savedCartTranslationChunksConfig, savedCartTranslations } from "@spartacus/cart/saved-cart/assets";
import { SavedCartRootModule } from "@spartacus/cart/saved-cart/root";
import { I18nConfig, provideConfig } from "@spartacus/core";
import { SavedCartModule } from "../saved-cart/saved-cart.module";

@NgModule({
  declarations: [],
  imports: [
    SavedCartRootModule,
    SavedCartModule,
  ],
  providers: [
    provideConfig(<I18nConfig>{
      i18n: {
        resources: savedCartTranslations,
        chunks: savedCartTranslationChunksConfig,
      },
    }),
  ]
})
export class CartSavedCartFeatureModule {
}
