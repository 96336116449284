
import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { LoadingEnum } from 'src/app/enums/loading.enum';
import { Document, DocumentsListPagination, DocumentsListPaginationMapped, DocumentsResponse } from 'src/app/interfaces/document';
import { DocumentService } from 'src/app/services/document.service';

@Component({
  selector: 'gc-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss'],
})
export class GeneracDocumentListComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  @Input() searchBy: string;
  @Input() spSearchCode: string;
  currentPage: number = 0;
  isMobileView: boolean;
  documents: Document[];
  pagination: DocumentsListPaginationMapped | DocumentsListPagination;
  LoadingEnum = LoadingEnum;
  loading: LoadingEnum = LoadingEnum.Idle;

  @HostListener('window:resize')
  onResize() {
    this.isMobileView = this.winRef?.nativeWindow.innerWidth < 768;
  }

  constructor(
    protected documentService: DocumentService,
    protected winRef: WindowRef,
  ) {
    this.isMobileView = this.winRef?.nativeWindow.innerWidth < 768;
  }

  ngOnInit(): void {
    this.subscription.add(
      this.getDocuments().subscribe()
    )
  }

  getDocuments() {
    this.loading = LoadingEnum.Loading;
    return this.documentService.getDocuments(this.searchBy, this.spSearchCode, this.currentPage).pipe(
      tap((res: DocumentsResponse) => {
        this.documents = res.documents;
        this.pagination = res.pagination;
      }),
      catchError(() => this.loading = LoadingEnum.Failed),
      finalize(() => this.loading = LoadingEnum.Loaded)
    )
  }

  onPageChange(page: number): void {
    this.currentPage = page;
    this.subscription.add(
      this.getDocuments().subscribe()
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
