import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ActiveCartFacade } from "@spartacus/cart/base/root";
import { Observable } from "rxjs";
import { CartExtended } from "../../../../../interfaces/cart";
import { OrderExtended } from "../../../../../interfaces/order";
import { PaymentType } from "../../../../../enums/payment-type.enum";

@Component({
  selector: 'cx-custom-order-summary',
  templateUrl: './custom-order-summary.component.html',
  styleUrls: ['./custom-order-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomOrderSummaryComponent implements OnInit {
  @Input() set data(data: Observable<CartExtended | OrderExtended>) {
    this.data$ = data;
  };

  @Input() showInfoDAOrder = true;

  data$: Observable<CartExtended | OrderExtended>;

  constructor(
      protected activeCartFacade: ActiveCartFacade
  ) {
  }

  ngOnInit() {
    if(!this.data$) {
      this.data$ = this.activeCartFacade.getActive() as unknown as Observable<CartExtended>;
    }
  }

  protected readonly PaymentType = PaymentType;
}
