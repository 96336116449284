<ng-container *ngIf="slots$ | async">
    <div class="desktop-tab-paragraph-container container">
        <ul class="desktop-tab-paragraph-tabs">
            <ng-container *ngFor="let slot of slots; let i = index">
                <li *ngIf="slot && !slot.hidden" class="desktop-tab-paragraph-tab">
                    <a routerLink="." fragment="{{slot.anchor}}" replaceUrl queryParamsHandling="merge">
                        <input [id]="i" type="radio" name="tabs" [value]="i" />
                        <label [class.active]="activeTabNum === i"
                            (click)="scrollToSlot(slot.className)" [for]="i"
                            role="tab">{{slot?.title}}</label>
                    </a>
                </li>
            </ng-container>
        </ul>
    </div>
</ng-container>