import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, I18nModule, provideDefaultConfig } from '@spartacus/core';
import { GeneracProductWarrantiesComponent } from './generac-product-warranties.component';
import { IconModule, MediaModule } from '@spartacus/storefront';
import { GeneracProductWarrantyItemComponent } from './generac-product-warranty-item/generac-product-warranty-item.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CustomProductAddToCartModule } from '../../../cart/components/custom-product-add-to-cart/custom-product-add-to-cart.module';
import { InfoMessageModule } from 'src/app/shared/components/info-message/info-message.module';
import { InfoPriceWrapperComponent } from "../info-price-wrapper/info-price-wrapper.component";

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    CustomProductAddToCartModule,
    SharedModule,
    IconModule,
    MediaModule,
    InfoMessageModule,
    InfoPriceWrapperComponent
  ],
  providers: [
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        GeneracProductWarrantiesComponent: {
          component: GeneracProductWarrantiesComponent,
        },
      },
    }),
  ],
  declarations: [GeneracProductWarrantiesComponent, GeneracProductWarrantyItemComponent],
  exports: [GeneracProductWarrantiesComponent, GeneracProductWarrantyItemComponent],
})
export class GeneracProductWarrantiesModule {}
