import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { DaysOfWeek, ORDER_TYPE, recurrencePeriod } from '@spartacus/order/root';
import moment from 'moment';
import { CmsComponentData, ICON_TYPE } from '@spartacus/storefront';
import { Observable, of, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { CartExtended, GeneracScheduleReplenishmentForm, ManufactureGroup } from 'src/app/interfaces/cart';
import { GeneracCheckoutReplenishmentFormService } from 'src/app/services/checkout-replenishment-form.service';
import { WindowRef } from '@spartacus/core';

@Component({
  selector: 'cx-schedule-replenishment-order',
  styleUrls: ['./checkout-schedule-replenishment-order.component.scss'],
  templateUrl: './checkout-schedule-replenishment-order.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutScheduleReplenishmentOrderComponent implements OnInit, OnDestroy {
  protected subscription = new Subscription();

  @Input() deliveryGroup: ManufactureGroup;
  @Input() cartCode: string;
  @Input() shippingGroupName: string;
  @Input() manufactureGroupID: string;
  @Output() dateSelected = new EventEmitter();

  desiredDateControl: FormControl = new FormControl({});
  iconTypes = ICON_TYPE;
  orderTypes = ORDER_TYPE;
  daysOfWeek = Object.values(DaysOfWeek);
  recurrencePeriodType = Object.values(recurrencePeriod);
  indicatorIcon = ICON_TYPE.INFO;
  desiredDateMessage = '';
  tooltipMessage = 'This date will drive the start of the shipping window. Actual shipment date is not guaranteed and is subject to change based on product availability.';
  shipmentNearestDate: string = "Your entry cannot be accommodated at this time. The delivery date has been changed to the closest available date to your entry.";
  dateFellOnWeekend: string = "Your entry fell on a weekend, it's been rescheduled for the next business day.";
  earliestShipmentDay = this.getEarliestShipmentDay();
  latestShipmentDay: Date;
  showShipmentHint: boolean;
  showShipmentDescription: boolean;
  selectedOrderType$: Observable<ORDER_TYPE> =
    this.checkoutReplenishmentFormService.getOrderType();

  isMonthly: Boolean = false;
  isWeekly: Boolean = false;
  currentDaysOfWeek: DaysOfWeek[] = [];
  numberOfDays: number[];
  numberOfWeeks: string[];
  currentDate: string | undefined;
  minDateForEndDate: string;
  endDate: string | undefined;
  scheduleReplenishmentFormData: GeneracScheduleReplenishmentForm;
  replenishmentEnabled: boolean;
  isServicePartsEnabled: boolean;
  cart: CartExtended;
  isCartPage: boolean;

  constructor(
    protected checkoutReplenishmentFormService: GeneracCheckoutReplenishmentFormService,
    protected activeCartFacade: ActiveCartFacade,
    protected cdr: ChangeDetectorRef,
    protected winRef: WindowRef,
    public component: CmsComponentData<any>,
  ) {
    this.subscription.add(
      this.activeCartFacade.getActive().pipe(
        tap(cart => this.cart = cart as CartExtended)
      ).subscribe()
    )
   }

   onDateChange(date: any, hideHints: boolean = true): void {
    this.subscription.add(
      of(date?.value).pipe(
        debounceTime(500),
        tap(val => {
          if(hideHints) {
            this.showShipmentHint =false;
            this.showShipmentDescription = false;
          }
          const desiredShipmentDate = this.desiredDateControl.valid && val ? moment(val).format('YYYY-MM-DD') : null;
          this.checkoutReplenishmentFormService.setScheduleReplenishmentFormData({
            ...this.scheduleReplenishmentFormData,
            desiredShipmentDate
          });
        }),
        filter(() => !this.desiredDateControl.invalid),
        switchMap(() => {
          return this.checkoutReplenishmentFormService.setReplenishmentOrder(this.cartCode || this.cart?.code, 
            this.shippingGroupName || this.cart?.shippingGroups[0].shippingGroupName, this.manufactureGroupID || this.cart?.shippingGroups[0].manufactureGroups[0]?.manufactureGroupID
          )
        }),
        tap(() => this.dateSelected.emit())
      ).subscribe()
    )
   }
   
  ngOnInit(): void {
    this.isServicePartsEnabled = JSON.parse(this.winRef.localStorage.getItem('isServicePartsEnabled'));
    this.isCartPage = this.winRef.location.href.includes('/cart');
    const replenishmentSchedule = this.isServicePartsEnabled ? this.deliveryGroup?.replenishmentSchedule :
      this.cart?.shippingGroups[0].manufactureGroups[0]?.replenishmentSchedule;

    if (replenishmentSchedule) {
      this.replenishmentEnabled = replenishmentSchedule?.replenishmentEnabled === 'true';
      this.earliestShipmentDay = moment(replenishmentSchedule?.earliestDesiredShipmentDate).toDate();
      this.latestShipmentDay = moment(replenishmentSchedule?.latestDesiredShipmentDate).toDate();

      if (this.replenishmentEnabled) {
        this.checkoutReplenishmentFormService.setScheduleReplenishmentFormData({
          interval: 14,
          startDate: new Date().toISOString(),
          endDate: this.addDays(new Date, 14).toISOString(),
          mode: 'REPLENISHMENT'
        });
      } else {
        this.checkoutReplenishmentFormService.setScheduleReplenishmentFormData({
          mode: 'ONE_TIME'
        });
      }
    };

    this.subscription.add(
      this.checkoutReplenishmentFormService
        .getScheduleReplenishmentFormData()
        .subscribe((data) => {
          this.scheduleReplenishmentFormData = data;
        })
    );

    if (replenishmentSchedule?.desiredShipmentDate) {
      this.desiredDateControl.setValue(moment(replenishmentSchedule?.desiredShipmentDate).toDate().toISOString());
      this.checkoutReplenishmentFormService.setScheduleReplenishmentFormData({
        ...this.scheduleReplenishmentFormData,
        desiredShipmentDate: replenishmentSchedule?.desiredShipmentDate
      });
    }

    if (this.replenishmentEnabled) {
      this.initConfig();
    }
  }

  desiredDayFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    const isBeforeStartDate = moment(this.earliestShipmentDay).isSameOrBefore(d, 'day');
    const isAfterYearFromToday = moment(this.latestShipmentDay).isAfter(d);
    return day !== 0 && day !== 6 && isBeforeStartDate && isAfterYearFromToday;
  };

  getEarliestShipmentDay(): Date {
    const currentDay = moment().toDate().getDay();
    return currentDay === 0
      ? moment().add(4, 'day').toDate()
      : currentDay === 6
        ? moment().add(5, 'day').toDate()
        : moment().add(1, 'week').toDate();
  }

  autoCompleteDate(): void {
    if (this.desiredDateControl.invalid) {
      if (!moment(this.desiredDateControl.value).isValid()) {
        this.desiredDateControl.patchValue(this.earliestShipmentDay);
        this.desiredDateControl.updateValueAndValidity();
        this.showShipmentHint = true;
      } else {
        const selectedDay = moment(this.desiredDateControl.value).toDate().getDay();
        const isBeforeStartDate = moment(this.earliestShipmentDay).isSameOrBefore(this.desiredDateControl.value);
        const isAfterYearFromToday = moment(this.earliestShipmentDay).add(6, 'month').isAfter(this.desiredDateControl.value);

        if (!isBeforeStartDate || !isAfterYearFromToday) {
          this.desiredDateControl.patchValue(this.earliestShipmentDay);
          this.desiredDateControl.updateValueAndValidity();
          this.showShipmentHint = true;
        } else {
          this.desiredDateControl.patchValue(
            selectedDay === 0
              ? moment(this.desiredDateControl.value).add(1, 'day').toDate()
              : selectedDay === 6
                ? moment(this.desiredDateControl.value).add(2, 'day').toDate()
                : this.desiredDateControl.value);
          this.desiredDateControl.updateValueAndValidity();
          this.showShipmentDescription = true;
        }
      }
      this.onDateChange({value: this.desiredDateControl.value}, false);
    }
  }

  getInfoMessage(): string {
    if(this.showShipmentHint) {
      return this.shipmentNearestDate;
    }
    else if (this.showShipmentDescription) {
     return  this.dateFellOnWeekend;
    }
    else {
      return '';
    }
  }

  changeOrderType(orderType: ORDER_TYPE): void {
    this.checkoutReplenishmentFormService.setOrderType(orderType);
  }

  changeNumberOfDays(nDaysEvent: any): void {
    const nDays = nDaysEvent?.target?.value;
    this.scheduleReplenishmentFormData.interval = nDays;

    const nextDate = moment(this.scheduleReplenishmentFormData.startDate).add(nDays, 'day').toDate();
    if (moment(this.scheduleReplenishmentFormData.endDate).isBefore(nextDate)) {
      this.scheduleReplenishmentFormData.endDate = nextDate.toISOString();
      this.minDateForEndDate = this.endDate = nextDate.toISOString().split('T')[0];
    } else {
      this.minDateForEndDate = nextDate.toISOString().split('T')[0];
    }
    this.checkoutReplenishmentFormService.setScheduleReplenishmentFormData({
      ...this.scheduleReplenishmentFormData,
      interval: nDays,
    });
  }

  changeNumberOfWeeks(nWeeksEvent: any): void {
    this.checkoutReplenishmentFormService.setScheduleReplenishmentFormData({
      ...this.scheduleReplenishmentFormData,
      numberOfWeeks: nWeeksEvent?.target?.value,
    });
  }

  changeRecurrencePeriodType(typeEvent: any): void {
    this.isWeekly = typeEvent?.target.value === recurrencePeriod.WEEKLY;
    this.isMonthly = typeEvent?.target.value === recurrencePeriod.MONTHLY;

    this.numberOfDays = this.isMonthly
      ? this.createNumberStringArray(31)
      : this.createNumberStringArray(30);

    this.checkoutReplenishmentFormService.setScheduleReplenishmentFormData({
      ...this.scheduleReplenishmentFormData,
      recurrencePeriod: typeEvent?.target.value,
    });
  }

  changeDayOfTheMonth(dayOfMonthEvent: any): void {
    this.checkoutReplenishmentFormService.setScheduleReplenishmentFormData({
      ...this.scheduleReplenishmentFormData,
      nthDayOfMonth: dayOfMonthEvent.target.value,
    });
  }

  changeReplenishmentStartDate(dateEvent: any): void {
    const startDate = dateEvent?.target?.value;

    if (moment(this.scheduleReplenishmentFormData.endDate).isBefore(startDate)) {
      this.scheduleReplenishmentFormData.endDate = moment(startDate).add(1, 'day').toDate().toISOString();
      this.minDateForEndDate = this.endDate = this.scheduleReplenishmentFormData.endDate.split('T')[0];
      this.scheduleReplenishmentFormData.interval = 1;
    }

    this.checkoutReplenishmentFormService.setScheduleReplenishmentFormData({
      ...this.scheduleReplenishmentFormData,
      startDate: startDate.toISOString(),
    });
  }

  changeReplenishmentEndDate(dateEvent: any): void {
    if (Boolean(dateEvent?.target?.value)) {
      this.checkoutReplenishmentFormService.setScheduleReplenishmentFormData({
        ...this.scheduleReplenishmentFormData,
        endDate: dateEvent?.target?.value,
      });
    }
  }

  changeRepeatDays(day: DaysOfWeek, isCheckedEvent: any): void {
    if (isCheckedEvent?.target.checked) {
      this.currentDaysOfWeek = [...this.currentDaysOfWeek];

      this.currentDaysOfWeek.push(day);

      this.checkoutReplenishmentFormService.setScheduleReplenishmentFormData({
        ...this.scheduleReplenishmentFormData,
        daysOfWeek: this.currentDaysOfWeek,
      });
    } else {
      const foundDay = this.currentDaysOfWeek.find((data) => day === data);

      if (!foundDay) {
        return;
      }

      const index = this.currentDaysOfWeek.indexOf(foundDay);
      this.currentDaysOfWeek.splice(index, 1);

      this.checkoutReplenishmentFormService.setScheduleReplenishmentFormData({
        ...this.scheduleReplenishmentFormData,
        daysOfWeek: this.currentDaysOfWeek,
      });
    }
  }

  hasDaysOfWeekChecked(day: DaysOfWeek): boolean {
    return this.currentDaysOfWeek.includes(day);
  }

  private initConfig(): void {
    this.isMonthly =
      this.scheduleReplenishmentFormData.recurrencePeriod ===
      recurrencePeriod.MONTHLY;

    this.isWeekly =
      this.scheduleReplenishmentFormData.recurrencePeriod ===
      recurrencePeriod.WEEKLY;

    this.currentDaysOfWeek = [
      ...(this.scheduleReplenishmentFormData.daysOfWeek ?? []),
    ];

    this.numberOfDays = this.isMonthly
      ? this.createNumberStringArray(31)
      : this.createNumberStringArray(30);

    // this.numberOfWeeks = this.createNumberStringArray(12);

    this.currentDate =
      this.scheduleReplenishmentFormData.startDate.split('T')[0];

    this.minDateForEndDate = this.endDate =
      this.scheduleReplenishmentFormData.endDate.split('T')[0];
  }

  private createNumberStringArray(n: number): number[] {
    return Array(n)
      .fill(0)
      .map((_, y) => (y + 1));
  }

  private addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
