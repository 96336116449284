import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ConverterService, PRODUCT_NORMALIZER, Product, ProductReferenceService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { CmsComponentData, CurrentProductService } from "@spartacus/storefront";
import { BaseStoreService } from 'src/app/services/base-store.service';
import { GaItem, GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { ProductReferencesComponent } from '../product-references/product-references.component';
import { ProductService } from 'src/app/services/product.service';
import { GeneracCmsProductReferencesComponent, ProductExtended, ProductTabReference } from 'src/app/interfaces/product';

@Component({
  selector: 'generac-product-accessories',
  templateUrl: './product-accessories.component.html',
  styleUrls: ['product-accessories.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneracProductAccessoriesComponent extends ProductReferencesComponent {
  gaAccessories: GaItem[] = [];

  get viewAllLink$(): Observable<string | undefined> {
    return this.componentData$.pipe(map((data) => data?.viewAllLink));
  }

  constructor(
    override cmsComponentData: CmsComponentData<GeneracCmsProductReferencesComponent>,
    override currentProductService: CurrentProductService,
    baseStoreService: BaseStoreService,
    cdr: ChangeDetectorRef,
    override productReferenceService: ProductReferenceService,
    override googleAnalyticsService: GoogleAnalyticsService,
    protected productService: ProductService,
    private converter: ConverterService,
  ) {
    super(cmsComponentData, currentProductService, baseStoreService, cdr, productReferenceService, googleAnalyticsService);
  }

  override get componentData$(): Observable<GeneracCmsProductReferencesComponent> {
    return this.cmsComponentData.data$.pipe(filter((data) => Boolean(data)));
  }

  itemsWithTabs$: Observable<ProductTabReference[]> =
    this.productCode$.pipe(
      withLatestFrom(this.componentData$),
      tap(([productCode, data]) => {
        this.gaListName = data.title;
      }),
      switchMap(([productCode, data]) =>
        this.getProductTabsReferences(productCode, data.productReferenceTypes ?? '')
      )
    );

  private getProductTabsReferences(productCode: string, referenceType: string): Observable<ProductTabReference[]> {
    return this.productService.getProductTabReferences(productCode, referenceType).pipe(
      take(1),
      map((productTabReferences: ProductTabReference[]) => {
        return productTabReferences.map(productTabReference => {
          if(productTabReference.tab == 'All') {
            for(const [index, reference] of productTabReference.products?.entries()) {
              this.gaAccessories.push(
                this.googleAnalyticsService.mapProductToGaItem(reference, index, undefined, this.gaListName)
              );
              if (this.gaAccessories.length == productTabReference.products?.length) {
                this.googleAnalyticsService.sendGaEvent('view_item_list', {
                  items: this.gaAccessories,
                  item_list_name: this.gaListName || undefined,
                  item_list_id: this.gaListName ? this.gaListName.toLowerCase().trim().replace(/ /g, '_') : undefined,
                });
                this.gaAccessories = [];
              }
            }
          };
          return {
            tab: productTabReference.tab,
            products: productTabReference.products.map((product: Product) => {
              return this.converter.convert({
                ...product,
                productCode: product.code,
              }, PRODUCT_NORMALIZER);
            }) as ProductExtended[],
          }
        })
      })
    )
  }
}
