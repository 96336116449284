/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, of } from "rxjs";
import { ProductService } from 'src/app/services/product.service';
import { catchError, tap } from 'rxjs/operators';
import { MSRPPrice } from 'src/app/interfaces/product';

@Component({
  selector: 'cx-availability-date',
  templateUrl: './custom-availability-date.component.html',
  styleUrls: ['./custom-availability-date.component.scss'],
})
export class CustomAvailabilityDateComponent implements OnInit, OnDestroy {

  subscription = new Subscription();
  price: MSRPPrice;

  constructor(
    protected productService: ProductService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.productService
          .cpiPriceRes$$
          .pipe(
            tap(price => this.price = price),
            catchError(() => {
              return of(null);
            })).subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
