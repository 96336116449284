import { Injectable } from '@angular/core';
import { switchMap, tap } from "rxjs/operators";
import { environment } from "../../../../../environments/environment";
import { tokenKey } from "../../../../user-account/login-form";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AuthHelperService {

  constructor(private http: HttpClient) {
  }

  loginToSSOAndSAP(ssoToken: string, salesOrgId?: string, customerNbr?: string) {
    const params = new HttpParams({
      fromObject: {
        client_id: 'mobile_android',
        client_secret: 'secret',
        grant_type: 'custom',
        site: 'gcSite',
        auth0Token: ssoToken,
        salesOrgId,
        customerNbr
      }
    });
    return this.http.post(
      `${environment.backend.occ.baseUrl}authorizationserver/oauth/token`,
      null,
      {params}
    ).pipe(
      tap((token: any) => {
        const stored = Math.floor(Date.now() / 1000);
        localStorage.setItem(
          tokenKey,
          JSON.stringify({
            redirectUrl: '/',
            token: {
              access_token: token.access_token,
              access_token_stored_at: stored,
              expires_at: stored + token.expires_in,
              granted_scopes: ['basic', 'openid'],
              token_type: token.token_type,
            },
            userId: 'current',
          })
        );
      })
    )
  }
}
