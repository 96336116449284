import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, provideConfig, UrlModule } from '@spartacus/core';
import { MediaModule } from "@spartacus/storefront";
import { ProductGridItemModule } from '../../product-list/product-grid-item/product-grid-item.module';
import { GeneracCarouselModule } from 'src/app/shared/components/generac-carousel/generac-carousel.module';
import { GeneracProductAccessoriesComponent } from './product-accessories.component';

@NgModule({
  imports: [
    CommonModule,
    MediaModule,
    RouterModule,
    UrlModule,
    ProductGridItemModule,
    GeneracCarouselModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        TabsProductCarouselComponent: {
          component: GeneracProductAccessoriesComponent,
        },
      },
    }),
  ],
  declarations: [GeneracProductAccessoriesComponent],
  exports: [GeneracProductAccessoriesComponent],
})
export class GeneracProductAccessoriesModule {}
