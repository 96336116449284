import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { I18nModule } from '@spartacus/core';
import { AtMessageModule, FacetComponent, FormErrorsModule, IconModule, KeyboardFocusModule } from '@spartacus/storefront';

@Component({
  selector: 'gc-facet',
  standalone: true,
  templateUrl: './facet.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    I18nModule,
    IconModule,
    FormErrorsModule,
    ReactiveFormsModule,
    FormsModule,
    KeyboardFocusModule,
    AtMessageModule,
    RouterModule,
  ]
})
export class GeneracFacetComponent extends FacetComponent {

}
