<div class="ipw">
    <cx-icon
            (cxClickOutside)="isOpen = false"
            class="regular-icon grey"
            [type]="iconTypes.INFO"
            (click)="getPrice(product)"
    ></cx-icon>
    <div
            *ngIf="isOpen"
            class="ipw-tooltip"
            [ngClass]="{'left': position === TooltipPosition.Left, 'right': position === TooltipPosition.Right}"
    >

        <div
                *ngIf="loading === LoadingEnum.Loading || (loading === LoadingEnum.Loaded || netPrice)"
                class="ipw-tooltip-item spinner"
        >
            <span class="ipw-tooltip-item-label">NET</span>
            <span *ngIf="loading === LoadingEnum.Loaded else spinner">{{ netPrice | currency }}</span>
        </div>

        <div
                *ngIf="product?.price?.formattedValue"
                class="ipw-tooltip-item"
        >
            <span class="ipw-tooltip-item-label">{{product.servicePart ? 'MSLP' : 'MSRP'}}</span>
            <span>{{ product.price.formattedValue }}</span>
        </div>

        <ng-container *ngIf="product?.servicePart">
            <div class="ipw-tooltip-item">
                <span class="ipw-tooltip-item-label">Item Class</span>
                <span>{{product.itemClass}}</span>
            </div>
            <div class="ipw-tooltip-item">
                <span class="ipw-tooltip-item-label">U/M</span>
                <span>{{product.unit}}</span>
            </div>
        </ng-container>
        <ng-template #spinner>
            <div class="cx-spinner">
                <cx-spinner></cx-spinner>
            </div>
        </ng-template>
    </div>
</div>
<ng-content></ng-content>
