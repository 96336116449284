import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ProductImageZoomProductImagesComponent } from '@spartacus/product/image-zoom/components';
import { Observable } from 'rxjs';
import { ProductExtended } from 'src/app/interfaces/product';

@Component({
  selector: 'generac-product-images',
  templateUrl: './product-image-zoom-product-images.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneracProductImageZoomProductImagesComponent extends ProductImageZoomProductImagesComponent {
  override product$: Observable<ProductExtended> = this.product$;

}
