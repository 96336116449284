import { Component } from '@angular/core';

@Component({
  selector: 'generac-green-check-icon',
  templateUrl: './generac-green-check-icon.component.html',
  styleUrls: ['./generac-green-check-icon.component.scss'],
  standalone: true
})
export class GeneracGreenCheckIconComponent {

}
