import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActiveCartFacade } from "@spartacus/cart/base/root";
import { Observable, Subject } from "rxjs";
import { CartExtended } from "../../../../../interfaces/cart";
import { OrderExtended } from "../../../../../interfaces/order";
import { PaymentType } from "../../../../../enums/payment-type.enum";
import { WindowRef } from "@spartacus/core";
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cx-custom-order-summary',
  templateUrl: './custom-order-summary.component.html',
  styleUrls: ['./custom-order-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomOrderSummaryComponent implements OnInit {
  private destroy$ = new Subject();
  protected readonly PaymentType = PaymentType;

  @Input() set data(data: Observable<CartExtended | OrderExtended>) {
    this.data$ = data;
  };

  @Input() showInfoDAOrder = true;
  isServicePartsEnabled: boolean = false;
  data$: Observable<CartExtended | OrderExtended>;
  cartOrDeliveryAddressStep: boolean;

  constructor(
    protected activeCartFacade: ActiveCartFacade,
    protected winRef: WindowRef,
    protected cdr: ChangeDetectorRef,
    private router: Router,
  ) {
    this.router.events.pipe(
      takeUntil(this.destroy$),
    )
      .subscribe((event: any) => {
      if(event.url) {
        this.cartOrDeliveryAddressStep = event.url?.indexOf('/cart') > -1 || event.url?.indexOf('/checkout/delivery-address') > -1;
      }
      this.cdr.markForCheck();
    });
  }

  ngOnInit() {
    if (!this.data$) {
      this.cartOrDeliveryAddressStep = this.winRef.location.href?.indexOf('/cart') > -1 || this.winRef.location.href?.indexOf('/checkout/delivery-address') > -1;
      this.data$ = this.activeCartFacade.getActive() as unknown as Observable<CartExtended>;
    }
    this.isServicePartsEnabled = JSON.parse(this.winRef.localStorage.getItem('isServicePartsEnabled'));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
