import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { SubscriptionsService } from '../../services/subscriptions.service';
import { filter, retry, switchMap, tap } from 'rxjs/operators';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, Subscription } from 'rxjs';
import { GlobalMessageService, GlobalMessageType, RoutingService, WindowRef } from '@spartacus/core';
import { CheckoutStepService } from '@spartacus/checkout/base/components';
import { CartExtended } from '../../interfaces/cart';
import { GeneracActiveCartService } from 'src/app/spartacus/features/cart/core/facade/active-cart.service';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionsComponent implements OnInit, OnDestroy {
  subscriptions$: any;
  cart$: Observable<CartExtended> = this.activeCartFacade.getActive() as unknown as Observable<CartExtended>;
  isReady = true;
  subscriptions = new Subscription();

  constructor(
    private subscriptionsService: SubscriptionsService,
    private userAccount: UserAccountFacade,
    private routing: RoutingService,
    private activeCartFacade: GeneracActiveCartService,
    protected checkoutStepService: CheckoutStepService,
    protected winRef: WindowRef,
    private cdr: ChangeDetectorRef,
    private globalMessageService: GlobalMessageService
  ) { }

  ngOnInit(): void {
    this.subscriptions.add(this.userAccount
      .get()
      .pipe(
        filter(user => !!user),
        tap(() => {
          this.isReady = false;
          this.cdr.detectChanges();
        }),
        switchMap(user => {
          return this.subscriptionsService.getSubscriptions(user?.uid).pipe(retry(2))
        }),
        tap(subs => {
          if (subs?.products?.length) {
            this.isReady = true;
            this.subscriptions$ = subs;
            this.cdr.detectChanges();
          } else {
            this.routing.go('/');
            this.globalMessageService.add('Subscriptions are not available for the current company', GlobalMessageType.MSG_TYPE_WARNING);
          }
        })
      ).subscribe());
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
