import { ModuleWithProviders, NgModule } from '@angular/core';
import { ConfigModule } from '@spartacus/core';
import { SubscriptionsComponent } from './subscriptions.component';
import { ProductGridItemModule } from '../../spartacus/features/product/product-list/product-grid-item/product-grid-item.module';
import { CommonModule } from '@angular/common';
import { SpinnerModule } from "@spartacus/storefront";

@NgModule({
  declarations: [SubscriptionsComponent],
    imports: [
        CommonModule,
        ProductGridItemModule,
        ConfigModule.withConfig({
            cmsComponents: {
                SubscriptionsComponent: {
                    component: SubscriptionsComponent
                },
            }
        }),
        SpinnerModule,
    ],
})
export class SubscriptionsModule {
  static forRoot(): ModuleWithProviders<SubscriptionsModule> {
    return {
      ngModule: SubscriptionsModule,
    };
  }
}
