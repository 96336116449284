import { NgModule} from '@angular/core';
import { CommonModule} from '@angular/common';
import { CustomCategoryNavigationComponent } from './components/custom-category-navigation/custom-category-navigation.component';
import { CmsConfig, I18nModule, provideConfig} from "@spartacus/core";
import { GenericLinkModule, HamburgerMenuModule, IconModule } from "@spartacus/storefront";
import { SharedModule } from "../../../shared/shared.module";
import { HeaderNavigationComponent } from "./components/header-navigation/header-navigation.component";

@NgModule({
    imports: [
        CommonModule,
        I18nModule,
        IconModule,
        GenericLinkModule,
        HamburgerMenuModule,
        SharedModule
    ],
    providers: [
        provideConfig(<CmsConfig>{
            cmsComponents: {
                CategoryNavigationComponent: {
                    component: CustomCategoryNavigationComponent,
                    data: {
                        resetMenuOnClose: true,
                    },
                },
            },
        }),
        provideConfig(<CmsConfig>{
            cmsComponents: {
                NavigationUIComponent: {
                    component: HeaderNavigationComponent
                },
            },
        }),
    ],
    declarations: [ CustomCategoryNavigationComponent, HeaderNavigationComponent ],
    exports: [CustomCategoryNavigationComponent, HeaderNavigationComponent]
})
export class CategoryNavigationModule {
}
