import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomOrderSummaryComponent } from "./custom-order-summary.component";
import { I18nModule } from "@spartacus/core";

@NgModule({
  declarations: [CustomOrderSummaryComponent],
    imports: [CommonModule, I18nModule],
  exports: [CustomOrderSummaryComponent]
})
export class CustomOrderSummaryModule { }
