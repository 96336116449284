import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { CmsProductReferencesComponent, isNotNullable, Product, ProductReference, ProductReferenceService } from '@spartacus/core';
import { Observable, Subscription, combineLatest, of } from 'rxjs';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { CmsComponentData, CurrentProductService } from "@spartacus/storefront";
import { BaseStoreService } from 'src/app/services/base-store.service';
import { GaItem, GoogleAnalyticsService } from 'src/app/services/google-analytics.service';

@Component({
  selector: 'cx-product-references',
  templateUrl: './product-references.component.html',
  styleUrls: ['product-references.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductReferencesComponent {
  tooltipMessage: string;
  phoneOrEmail: string;
  gaItems: GaItem[] = [];
  gaListName: string;

  private subscription = new Subscription();

  constructor(
      protected cmsComponentData: CmsComponentData<CmsProductReferencesComponent>,
      protected currentProductService: CurrentProductService,
      private baseStoreService: BaseStoreService,
      private cdr: ChangeDetectorRef,
      protected productReferenceService: ProductReferenceService,
      protected googleAnalyticsService: GoogleAnalyticsService,
  ) {
    this.subscription.add(
      combineLatest([
        this.baseStoreService.getTooltipMessage(),
        this.baseStoreService.getPhoneNumber(),
      ]).subscribe(([messsage, phoneOrEmail]) => {
        this.tooltipMessage = messsage?.message;
        this.phoneOrEmail = phoneOrEmail?.contactInfo;
        this.cdr.markForCheck();
      })
    );
  }

  protected get componentData$(): Observable<CmsProductReferencesComponent> {
    return this.cmsComponentData.data$.pipe(filter((data) => Boolean(data)));
  }

  /**
   * Returns an Observable String for the product code
   */
  protected get productCode$(): Observable<string> {
    return this.currentProductService.getProduct().pipe(
        filter(isNotNullable),
        map((product) => product.code ?? ''),
        tap((_) => this.productReferenceService.cleanReferences())
    );
  }

  /**
   * Returns an Observable String for the title
   */
  get title$(): Observable<string | undefined> {
    return this.componentData$.pipe(map((data) => data?.title));
  }

  /**
   * Observable with an Array of Observables. This is done so that
   * the component UI could consider to lazy load the UI components when they're
   * in the viewpoint.
   */
  items$: Observable<Observable<Product | undefined>[]> =
      this.productCode$.pipe(
          withLatestFrom(this.componentData$),
          tap(([productCode, data]) => {
            this.gaListName = data.title;
            return  this.productReferenceService.loadProductReferences(
              productCode,
              data.productReferenceTypes
            )}
          ),
          switchMap(([productCode, data]) =>
              this.getProductReferences(productCode, data.productReferenceTypes ?? '')
          )
      );

  /**
   * Returns an observable for product references
   */
  private getProductReferences(
      code: string,
      referenceType: string
  ): Observable<Observable<Product | undefined>[]> {
    return this.productReferenceService
        .getProductReferences(code, referenceType)
        .pipe(
            filter((references) => Boolean(references)),
            map((references: ProductReference[]) => {
              if(references.length > 0) {
                for(const [index, reference] of references.entries()) {
                  this.gaItems.push(
                    this.googleAnalyticsService.mapProductToGaItem(reference.target, index, undefined, this.gaListName)
                  );
                  if (this.gaItems.length == references.length) {
                    this.googleAnalyticsService.sendGaEvent('view_item_list', {
                      items: this.gaItems,
                      item_list_name: this.gaListName || undefined,
                      item_list_id: this.gaListName ? this.gaListName.toLowerCase().trim().replace(/ /g, '_') : undefined,
                    });
                    this.gaItems = [];
                  }
                }
              }
              return references.map((reference) => of(reference.target));
            }),
            
        );
  }
}
