import { Component, OnInit } from '@angular/core';
import { FocusConfig, ICON_TYPE } from '@spartacus/storefront';
import { Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CheckoutService } from 'src/app/services/checkout.service';
import { CustomLaunchDialogService } from 'src/app/services/custom-launch-dialog.service';

@Component({
  selector: 'split-payment-dialog',
  templateUrl: './split-payment-dialog.component.html',
})
export class SplitPaymentDialogComponent implements OnInit {
  focusConfig: FocusConfig = {
    trap: true,
    block: true,
    autofocus: 'button',
    focusOnEscape: true,
  };

  iconTypes = ICON_TYPE;
  compatibleProducts: string[];
  nonCompatibleProducts: string[];
  cartId: string;
  userId: string;
  loading: boolean = false;

  protected subscription = new Subscription();

  constructor(
    protected launchDialogService: CustomLaunchDialogService,
    protected checkoutService: CheckoutService,
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.launchDialogService.data$.subscribe(
        (dialogData: any) => {
          this.compatibleProducts = dialogData?.checkPaymentResponse.compatibleProducts;
          this.nonCompatibleProducts = dialogData?.checkPaymentResponse.nonCompatibleProducts;
          this.cartId = dialogData?.cartId;
          this.userId = dialogData?.userId;
        }
      )
    );
  }

  continue(): void {
    this.loading = true;
    this.checkoutService.sanitizeCartForWellsFargo(this.cartId, this.userId).pipe(
      catchError((error) => {
        this.loading = false;
        return throwError(error);
      }),
    ).subscribe(() => {
      this.loading = false;
      this.dismiss('splitPayment');
    });
  }

  dismiss(reason?: any): void {
    this.launchDialogService.closeDialog(reason, 'CHECKOUT_SPLIT_PAYMENT_DIALOG');
  }
}
