import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from "@spartacus/core";
import { CustomOrderSummaryComponent } from "../checkout/components/custom-order-summary/custom-order-summary.component";
import { CustomOrderSummaryModule } from "../checkout/components/custom-order-summary/custom-order-summary.module";

@NgModule({
  declarations: [],
  imports: [
    CustomOrderSummaryModule,
  ],
  providers: [
      provideConfig(<CmsConfig>{
        cmsComponents: {
          CartTotalsComponent: {
            component: CustomOrderSummaryComponent,
          },
        },
      })
  ]
})
export class CartTotalsModule { }
