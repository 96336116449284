import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';
import { ICON_TYPE } from "@spartacus/storefront";
import { ExtendedBundle } from "../../../../interfaces/bundle.model";
import { Router } from '@angular/router';
import { transformNameToUrl } from "../../../helpers/transform-name-to-url";
import { GaItem, GoogleAnalyticsService } from 'src/app/services/google-analytics.service';

@Component({
  selector: 'cx-bundle-corner-item',
  templateUrl: './bundle-corner-item.component.html',
  styleUrls: ['./bundle-corner-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BundleCornerItemComponent {
  @Input() bundle: ExtendedBundle;
  @Input() isSummary = false;
  @Input() isBundlePage: boolean;
  @Output() addBundleToTheCart = new EventEmitter<ExtendedBundle>();
  iconTypes = ICON_TYPE;

  constructor(private router: Router, protected googleAnalyticsService: GoogleAnalyticsService) {
  }

  goToProduct(): void {
    this.router.navigate(['/product', this.bundle.product.code, transformNameToUrl(this.bundle.product.name)]);
  }

  addToCart(): void {
    const bundleItems = [this.bundle.product, ...this.bundle.items];
    let items: GaItem[] = [];
    bundleItems.forEach((item, index)=> {
      items.push(this.googleAnalyticsService.mapProductToGaItem(item, index, 1, undefined, undefined, this.bundle.id, this.bundle.rootBundleTemplateName))
    });
    this.googleAnalyticsService.sendGaEvent('add_bundle_to_cart', {
      currency: this.bundle?.product?.price?.currency,
      value: this.bundle?.totalPrice,
      bundle_name: this.bundle.rootBundleTemplateName,
      bundle_template_id: this.bundle.id,
      quantity: 1,
    });
    this.googleAnalyticsService.sendGaEvent('add_to_cart', {
      currency: this.bundle?.product?.price?.currency,
      value: this.bundle?.totalPrice,
      items: items
    });
    this.addBundleToTheCart.emit(this.bundle);
  }

}
