import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, Subscription, combineLatest, of } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { CustomerService } from 'src/app/services/customer.service';
import { FavoritesService } from 'src/app/services/favorites.service';
import { ICON_TYPE, LaunchDialogService } from "@spartacus/storefront";
import { ActivatedRoute, Router } from '@angular/router';
import { FAVORITES_DIALOGS, FavoritesListItem, FavoritesListResponse, FavotitesDialogActionModes } from 'src/app/interfaces/favorites.model';

@Component({
  selector: 'favorites-navigation',
  templateUrl: './favorites-navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavoritesNavigationComponent implements OnInit, OnDestroy {
  @Output() activeListChanged = new EventEmitter();
  @ViewChild('contextMenu') contextMenu: ElementRef;
  @ViewChild('listEl') listEl: ElementRef;

  favoritesLists: FavoritesListItem[];
  iconTypes = ICON_TYPE;
  userId: string;
  activeListIndex: number;
  offset: number = 0;
  openListsInPopup: boolean = false;

  private subscription = new Subscription();

  constructor(
    private favoritesService: FavoritesService,
    private userAccount: UserAccountFacade,
    protected customerService: CustomerService,
    protected cd: ChangeDetectorRef,
    protected launchDialogService: LaunchDialogService,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    private renderer: Renderer2,
  ) {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.favoritesLists?.some((list) => list.showContextMenu == true) && e.target !== this.contextMenu?.nativeElement) {
        this.hideContextMenu();
      }
    });
  }

  ngOnInit(): void {
    this.activeListIndex = null;
    this.getFavoritesLists().subscribe();
    this.subscribeToUnitChange();
    this.subscribeToFavoritesListChange();
  }

  private getFavoritesLists(): Observable<FavoritesListResponse> {
    return this.userAccount.get().pipe(
      filter((user) => !!user?.uid),
      take(1),
      tap((user) => this.userId = user?.uid),
      switchMap((user) => this.favoritesService.getFavoritesLists(user?.uid)),
      tap((res: FavoritesListResponse) => {
        this.favoritesLists = res.lists || [];
        if (!this.activeListIndex) {
          this.activeListIndex = this.getIndexOfListById(this.activatedRoute.snapshot?.queryParams?.listId);
        }
        this.setActiveList(this.activeListIndex);
        this.cd.markForCheck();
      })
    );
  };

  private getIndexOfListById(listId: string): number {
    if (this.favoritesLists && listId) {
      const index = this.favoritesLists.findIndex((list) => list.id == listId);
      return index > -1 ? index : 0;
    };
    return 0;
  }

  private subscribeToFavoritesListChange(): void {
    this.subscription.add(
      this.favoritesService.favoritesListChanged$.pipe(
        filter((isFavoritesListChanged) => !!isFavoritesListChanged),
        switchMap(() => this.getFavoritesLists()),
      ).subscribe(() => {
        this.favoritesService.setFavoritesListChanged(false);
        this.cd.markForCheck();
      })
    )
  }

  private subscribeToUnitChange(): void {
    this.subscription.add(
      this.customerService.getB2bUnitChanged().pipe(
        filter((isB2bUnitChanged) => !!isB2bUnitChanged),
        tap(() => {
          this.router.navigate([], {
            queryParams: {
              'listId': null,
            },
            queryParamsHandling: 'merge'
          });
          this.favoritesLists = [];
          this.activeListIndex = 0;
        }),
        switchMap(() => this.getFavoritesLists())
      ).subscribe()
    );
  }

  private hideContextMenu(): void {
    const index = this.favoritesLists.findIndex((list) => list.showContextMenu == true);
    if (index > -1) {
      this.favoritesLists[index].showContextMenu = false;
    }
    this.cd.markForCheck();
  }

  private scrollToActiveList() {
    if (this.listEl && this.activeListIndex && this.activeListIndex > 9) {
      this.listEl.nativeElement?.scrollTo(0, 42 * this.activeListIndex);
    }
  }

  public setActiveList(index: number): void {
    if (!this.favoritesLists || this.favoritesLists.length < 1) {
      return this.activeListChanged.emit();
    }
    this.favoritesLists[this.activeListIndex].active = false;
    this.favoritesLists[index].active = true;
    this.activeListIndex = index;
    const queryParams = { listId: this.favoritesLists[this.activeListIndex].id };
    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      preserveFragment: true,
    });
    if(this.openListsInPopup) this.changeMobilePopupState();
    this.activeListChanged.emit(this.favoritesLists[index]);
  }

  public openCreateOrEditListModal($event: Event, list?: FavoritesListItem): void {
    $event.stopPropagation();
    this.hideContextMenu();

    const modalData = {
      list: list?.id ? list : {},
      userId: this.userId
    }
    const dialog = this.launchDialogService.openDialog(
      FAVORITES_DIALOGS.CREATE_OR_EDIT_FAVORITES_LIST,
      undefined,
      undefined,
      modalData
    );

    if (dialog) {
      dialog.pipe(take(1)).subscribe();
    }

    this.subscription.add(
      this.launchDialogService.dialogClose
        .pipe(
          filter((reason) => reason == FavotitesDialogActionModes.Edit || reason == FavotitesDialogActionModes.Create),
          take(1),
          switchMap((reason: string) => combineLatest([of(reason), this.getFavoritesLists()])),
          filter(([reason]) => reason == FavotitesDialogActionModes.Create),
          tap(() => {
            // if new list created, scroll to the bottom of the list and mark new list as active
            this.setActiveList(this.favoritesLists.length - 1);
            this.scrollToActiveList();
          })
        )
        .subscribe()
    );
  }

  public openDeleteFavoriteListModal($event: Event, list: FavoritesListItem): void {
    $event.stopPropagation();
    this.hideContextMenu();

    const modalData = {
      list,
      mode: FavotitesDialogActionModes.RemoveList,
      userId: this.userId
    }
    const dialog = this.launchDialogService.openDialog(
      FAVORITES_DIALOGS.FAVORITES_CONFIRM_ACTION,
      undefined,
      undefined,
      modalData
    );

    if (dialog) {
      dialog.pipe(take(1)).subscribe();
    }

    this.subscription.add(
      this.launchDialogService.dialogClose
        .pipe(
          filter((reason) => reason == FavotitesDialogActionModes.RemoveList),
          take(1),
          tap(() => this.activeListIndex = 0),
          switchMap(() => this.getFavoritesLists())
        )
        .subscribe()
    );
  }

  public openContextMenu($event: Event, index: number) {
    $event.stopPropagation();
    this.hideContextMenu();
    this.offset = 16 - this.listEl.nativeElement.scrollTop;
    this.favoritesLists[index].showContextMenu = true;
  }


  public changeMobilePopupState(): void {
    this.openListsInPopup = !this.openListsInPopup;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
