import { FormConfig } from "../../interfaces/address-details";
import { InputType } from "../../enums/input-type.enum";
import { validateEmail } from "../../shared/validators/validate-email";
import { AsyncValidation } from "../../enums/async-validation.enum";

export const formConfig: FormConfig[] = [
  {
    items: [
      {
        label: 'checkoutAddress.detailsForm.poNumber',
        formControlName: 'purchaseOrderNumber',
        placeholder: 'checkoutAddress.detailsForm.poNumberPlaceholder',
        value: '',
        type: InputType.TEXT,
        maxLength: 35,
        isRequired: true,
        id: 'purchaseOrderNumber'
      }
    ]
  },
];

export const renewalConfig: FormConfig[] = [
  {
    items: [
      {
        formControlName: 'entryNumber',
        value: '',
        type: InputType.TEXT,
        isRequired: false,
        hidden: true,
      },
      {
        formControlName: 'materialNumber',
        value: '',
        type: InputType.TEXT,
        isRequired: false,
        hidden: true,
      },
      {
        formControlName: 'type',
        value: '',
        type: InputType.TEXT,
        isRequired: false,
        hidden: true,
      },
      {
        formControlName: 'id',
        value: '',
        type: InputType.TEXT,
        isRequired: false,
        hidden: true,
      },
      {
        formControlName: 'email',
        value: '',
        type: InputType.TEXT,
        isRequired: false,
        hidden: true,
      },
    ]
  },
  {
    items: [
      {
        label: 'checkoutAddress.detailsForm.firstName',
        formControlName: 'firstName',
        placeholder: 'checkoutAddress.detailsForm.firstNamePowerPlaySellingPlaceholder',
        value: '',
        type: InputType.TEXT,
        isRequired: true
      },
      {
        label: 'checkoutAddress.detailsForm.lastName',
        formControlName: 'lastName',
        placeholder: 'checkoutAddress.detailsForm.lastNamePowerPlaySellingPlaceholder',
        value: '',
        type: InputType.TEXT,
        isRequired: true
      },
    ]
  },
  {
    items: [
      {
        formControlName: 'subscriptionId',
        value: null,
        type: InputType.SELECT,
        isRequired: true
      }
    ]
  }
];

export const sellingConfig: FormConfig[] = [
  {
    items: [
      {
        formControlName: 'entryNumber',
        value: '',
        type: InputType.TEXT,
        isRequired: false,
        hidden: true,
      },
      {
        formControlName: 'materialNumber',
        value: '',
        type: InputType.TEXT,
        isRequired: false,
        hidden: true,
      },
      {
        formControlName: 'type',
        value: '',
        type: InputType.TEXT,
        isRequired: false,
        hidden: true,
      },
      {
        formControlName: 'id',
        value: '',
        type: InputType.TEXT,
        isRequired: false,
        hidden: true,
      },
    ]
  },
  {
    items: [
      {
        label: 'checkoutAddress.detailsForm.firstName',
        formControlName: 'firstName',
        placeholder: 'checkoutAddress.detailsForm.firstNamePowerPlaySellingPlaceholder',
        value: '',
        type: InputType.TEXT,
        isRequired: true
      },
      {
        label: 'checkoutAddress.detailsForm.lastName',
        formControlName: 'lastName',
        placeholder: 'checkoutAddress.detailsForm.lastNamePowerPlaySellingPlaceholder',
        value: '',
        type: InputType.TEXT,
        isRequired: true
      },
    ]
  },
  {
    items: [
      {
        label: 'checkoutAddress.detailsForm.role',
        formControlName: 'userRole',
        placeholder: 'checkoutAddress.detailsForm.rolePlaceholder',
        value: null,
        type: InputType.SELECT,
        isRequired: true
      },
      {
        label: 'checkoutAddress.detailsForm.email',
        formControlName: 'email',
        placeholder: 'checkoutAddress.detailsForm.emailPlaceholder',
        value: '',
        type: InputType.TEXT,
        isRequired: true,
        validators: [validateEmail],
        asyncValidator: AsyncValidation?.Email
      },
    ]
  }
];
