import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ICON_TYPE } from "@spartacus/storefront";

@Component({
  selector: 'cx-info-message',
  templateUrl: './info-message.component.html',
  styleUrls: ['./info-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoMessageComponent {
  @Input() icon = ICON_TYPE.INFO;
  @Input() position = 'position-left';
  @Input() message!: string;
}
