<div class="cx-order-summary">
    <div class="container">
        <ng-container *ngIf="order?.replenishmentOrderCode; else otherOrder">
            <div class="cx-summary-card">
                <cx-card [content]="getReplenishmentCodeCardContent(order?.replenishmentOrderCode) | async"></cx-card>
                <cx-card [content]="getReplenishmentActiveCardContent(order?.active) | async"></cx-card>
            </div>

            <div class="cx-summary-card">
                <cx-card [content]="getReplenishmentStartOnCardContent(order?.firstDate | cxDate) | async"></cx-card>
                <cx-card [content]="getReplenishmentFrequencyCardContent(order?.trigger?.displayTimeTable) | async"></cx-card>
                <cx-card [content]="getReplenishmentNextDateCardContent(order?.trigger?.activationTime | cxDate) | async"></cx-card>
            </div>
        </ng-container>

        <ng-template #otherOrder>
            <div class="cx-summary-card">
                <cx-card [content]="getOrderCodeCardContent(order?.code) | async"></cx-card>
                <cx-card [content]="getOrderCurrentDateCardContent(order?.created | cxDate) | async"></cx-card>
                <cx-card [content]="getOrderStatusCardContent(order?.statusDisplay) | async"></cx-card>
                <ng-container *ngIf="order?.replenishmentSchedule?.desiredShipmentDate && !isServicePartsEnabled">
                    <cx-card [content]="getDesiredDateCard(order?.replenishmentSchedule.desiredShipmentDate) | async"></cx-card>
                </ng-container>
            </div>
        </ng-template>

        <ng-container *ngIf="order?.purchaseOrderNumber || order?.purchaseOrderNumber === ''">
            <div class="cx-summary-card">
                <cx-card [content]="getPurchaseOrderNumber(order?.purchaseOrderNumber) | async"></cx-card>
                <cx-card
                    *ngIf="order?.deliveryAddress"
                    [content]="getAddressCardContent(order?.deliveryAddress) | async"
                ></cx-card>
                <ng-container *ngIf="order?.costCenter">
                    <cx-card [content]="getCostCenterCardContent(order?.costCenter) | async"></cx-card>
                </ng-container>
                <ng-container *ngIf="!isSubscription && order?.handlingOptions?.length && !isServicePartsEnabled">
                    <cx-card [content]="getSpecialHandlingCard(order.handlingOptions) | async"></cx-card>
                </ng-container>
            </div>
        </ng-container>

        <div class="cx-summary-card">
            <cx-card [content]="getMethodOfPaymentCardContent(order?.paymentType) | async"></cx-card>
            <cx-card
                    *ngIf="order?.deliveryMode && !isServicePartsEnabled"
                    [content]="getDeliveryModeCardContent(order?.deliveryMode) | async"
            ></cx-card>
        </div>

        <div
                *ngIf="order?.freightForwarderAddress || order?.paymentInfo"
                class="cx-summary-card">
            <cx-card
                    *ngIf="order?.freightForwarderAddress"
                    [content]="getFreightForwarderAddressCardContent(order?.freightForwarderAddress) | async"
            ></cx-card>
            <cx-card
                    *ngIf="order?.paymentInfo"
                    [content]="getPaymentInfoCardContent(order?.paymentInfo) | async"
            ></cx-card>
            <cx-card
                    *ngIf="order?.replenishmentSchedule?.endDate"
                    [content]="getEndDateCard(order?.replenishmentSchedule.endDate) | async"
            ></cx-card>
            <cx-card
                    *ngIf="order?.replenishmentSchedule?.interval"
                    [content]="getFrequencyCard(order?.replenishmentSchedule.interval) | async"
            ></cx-card>
            <cx-card
                    *ngIf="order?.replenishmentSchedule?.endDate"
                    [content]="getNextOrderCard(order?.replenishmentSchedule) | async"
            ></cx-card>
        </div>
    </div>
</div>
