import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { FeaturesConfigModule, I18nModule } from '@spartacus/core';
import { FreightForwordFormComponent } from './freight-forword-form.component';
import { FormErrorsModule, IconModule, KeyboardFocusModule, NgSelectA11yModule } from '@spartacus/storefront';

@NgModule({
  imports: [
    NgSelectA11yModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    NgSelectModule,
    IconModule,
    I18nModule,
    FormErrorsModule,
    KeyboardFocusModule,
    FeaturesConfigModule,
  ],
  declarations: [FreightForwordFormComponent],
  exports: [FreightForwordFormComponent],
})
export class FreightForwordFormModule {}
