import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, SimpleChanges, } from '@angular/core';
import { Product } from '@spartacus/core';
import { ProductListItemContext, ProductListItemContextSource } from '../../product-list';
import { Subscription, combineLatest } from 'rxjs';
import { BaseStoreService } from 'src/app/services/base-store.service';

@Component({
  selector: 'cx-product-carousel-item',
  templateUrl: './product-carousel-item.component.html',
  styleUrls: ['product-carousel-item.component.scss'],
  providers: [
    ProductListItemContextSource,
    {
      provide: ProductListItemContext,
      useExisting: ProductListItemContextSource,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCarouselItemComponent implements OnChanges, OnDestroy {
  @Input() item: Product;
  @Input() gaListName: string;
  tooltipMessage: string;
  phoneOrEmail: string;
  private subscription = new Subscription();

  constructor(
      protected productListItemContextSource: ProductListItemContextSource,
      private baseStoreService: BaseStoreService,
      private cdr: ChangeDetectorRef
  ) {
    this.subscription.add(
      combineLatest([
        this.baseStoreService.getTooltipMessage(),
        this.baseStoreService.getPhoneNumber(),
      ]).subscribe(([messsage, phoneOrEmail]) => {
        this.tooltipMessage = messsage?.message;
        this.phoneOrEmail = phoneOrEmail?.contactInfo;
        this.cdr.markForCheck();
      })
    );
  }

  ngOnChanges(changes?: SimpleChanges): void {
    if (changes?.item) {
      this.productListItemContextSource.product$.next(this.item);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
