import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClickOutsideDirective } from "./directives/click-outside/click-outside.directive";
import { BaseSitePipe } from "./pipes/base-site/base-site.pipe";
import { AppendAnchorDirective } from "./directives/append-anchor/append-anchor.directive";
import { SizeChangedDirective } from "./directives/size-changed/size-changed.directive";
import { HorizontalScrollOverflowHideDirective } from './directives/horizontal-scroll-overflow-hide/horizontal-scroll-overflow-hide.directive';

@NgModule({
  declarations: [
    ClickOutsideDirective,
    BaseSitePipe,
    SizeChangedDirective,
    AppendAnchorDirective,
    HorizontalScrollOverflowHideDirective
  ],
  imports: [CommonModule],
  exports: [
    ClickOutsideDirective,
    BaseSitePipe,
    SizeChangedDirective,
    AppendAnchorDirective,
    HorizontalScrollOverflowHideDirective
  ]
})
export class SharedModule { }
