import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, I18nModule, PageMetaResolver, RoutingModule, UrlModule, provideDefaultConfig } from '@spartacus/core';
import { IconModule, KeyboardFocusModule, ListNavigationModule, SpinnerModule } from '@spartacus/storefront';
import { FavoritesListComponent } from './favorites-list.component';
import { ProductGridItemModule } from '../../product/product-list/product-grid-item/product-grid-item.module';
import { RouterModule } from '@angular/router';
import { FavoritesListPageMetaResolver } from './favorites-list-page-meta.resolver';
import { FavoritesNavigationComponent } from '../favorites-navigation/favorites-navigation.component';
import { FavoritesConfirmActionDialogComponent } from '../favorites-confirm-action-dialog/favorites-confirm-action-dialog.component';
import { CreateOrEditFavoritesListDialogComponent } from '../create-or-edit-favorites-list-dialog/create-or-edit-favorites-list-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FavoriteProductsAddedToCartDialogComponent } from '../favorite-products-added-to-cart-dialog/favorite-products-added-to-cart-dialog.component';

@NgModule({
  declarations: [ 
    FavoritesListComponent,
    FavoritesNavigationComponent,
    CreateOrEditFavoritesListDialogComponent,
    FavoritesConfirmActionDialogComponent,
    FavoriteProductsAddedToCartDialogComponent,
  ],
  imports: [
    CommonModule,
    I18nModule,
    ProductGridItemModule,
    ListNavigationModule,
    UrlModule,
    RoutingModule,
    RouterModule,
    SpinnerModule,
    IconModule,
    KeyboardFocusModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  providers: [
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        FavoritesComponent: {
          component: FavoritesListComponent,
        }
      }
    }),
    {
      provide: PageMetaResolver,
      useExisting: FavoritesListPageMetaResolver,
      multi: true,
    },
  ]
})
export class FavoritesListModule {}
