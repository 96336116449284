<div (cxClickOutside)="toggleServicePartsSearch(false, searchInput)">
  <div [attr.aria-label]="'searchBox.productSearch' | cxTranslate" role="search">
    <label class="searchbox" [class.dirty]="!!searchInput.value"
      [ngClass]="{'sp-searchbox-opened': servicePartsSearchOpen}">
      <input #searchInput [placeholder]="'searchBox.placeholder' | cxTranslate" autocomplete="off"
        aria-describedby="initialDescription" aria-controls="results"
        [attr.aria-label]="'searchBox.placeholder' | cxTranslate" (focus)="openProductSearch()"
        (click)="openProductSearch()" (input)="search(searchInput.value);updateChosenWord(searchInput.value);" (keydown.enter)="
        close($any($event), true);
        launchSearchResult($any($event), searchInput.value);
        updateChosenWord(searchInput.value);
        navigateToSearchWithExtraParams(searchInput.value);
      " (keydown.arrowup)="focusPreviousChild($any($event))" (keydown.arrowdown)="focusNextChild($any($event))"
        value="{{ chosenWord }}" />

      <button [attr.aria-label]="'common.reset' | cxTranslate" (mousedown)="clear(searchInput)"
        (keydown.enter)="clear(searchInput)" class="reset" [ngClass]="{'has-sp-selector': isServicePartsEnabled}">
        <cx-icon [type]="iconTypes.RESET"></cx-icon>
      </button>

      <div role="presentation" class="search-icon" *ngIf="!isServicePartsEnabled">
        <cx-icon [type]="iconTypes.SEARCH"></cx-icon>
      </div>

      <div role="presentation" class="sp-search-menu" [ngClass]="{'sp-search-menu-opened': servicePartsSearchOpen}"
        *ngIf="isServicePartsEnabled">
        <button (click)="toggleServicePartsSearch()">
          <ng-container *ngIf="spModelSaved?.code; else partsSearchText">
            <span class="model-name"
              title="{{spModelSaved?.name ? spModelSaved?.code + ' - ' + spModelSaved?.name : spModelSaved?.code }}">
              {{activeSpSearchCriteria == 'model' ? (spModelSaved?.name ? spModelSaved?.code + ' - ' +
              spModelSaved?.name : spModelSaved?.code) : spModelSaved?.code}}
            </span>
            <cx-icon [type]="ICON_TYPE.RESET" (click)="clearSpSearchSaved($event)"></cx-icon>
          </ng-container>
          <ng-template #partsSearchText>
            Parts Search
            <span class="sp-search-menu-icon"><cx-icon [type]="
              servicePartsSearchOpen ? ICON_TYPE.CARET_UP : ICON_TYPE.CARET_DOWN
            "></cx-icon></span>
          </ng-template>
        </button>
      </div>

      <button [attr.aria-label]="'common.search' | cxTranslate" class="search" (click)="openProductSearch()">
        <cx-icon [type]="iconTypes.SEARCH"></cx-icon>
      </button>
    </label>
  </div>

  <div *ngIf="servicePartsSearchOpen" class="sp-search-results" role="dialog">
    <div class="sp-search-results-tabs">
      <div class="desktop-tab-paragraph-container">
        <ul cxHorizontalScrollOverflowHide class="desktop-tab-paragraph-tabs">
          <ng-container *ngFor="let item of SP_SEARCH_CRITERIAS; let i = index">
            <li class="desktop-tab-paragraph-tab">
              <a>
                <input [id]="'tabReference_' + i" type="radio" name="tab-references" [value]="item.value" />
                <label [class.active]="activeSpSearchCriteria === item.value"
                  (click)="selectSpSeachTab(item.value);clearSpSearch(spSearchInput);" [for]="'tabReference_' + i"
                  role="tab">By {{ item.name }}</label>
              </a>
            </li>
          </ng-container>
        </ul>
      </div>

    </div>
    <div class="sp-search-results-searchbox">
      <div [attr.aria-label]="'searchBox.productSearch' | cxTranslate" role="search">
        <label class="searchbox" [class.dirty]="!!spSearchInput.value">
          <input #spSearchInput
            [placeholder]="(activeSpSearchCriteria == 'model' ? 'searchBox.spSearchBox.placeholderModelNumber' : 'searchBox.spSearchBox.placeholderSerialNumber') | cxTranslate"
            autocomplete="off" aria-describedby="initialDescription" aria-controls="results"
            [attr.aria-label]="'searchBox.placeholder' | cxTranslate" (input)="spSearch(spSearchInput.value)"
            (keydown.enter)="spSearch(spSearchInput.value)" (keydown.arrowup)="focusPreviousChild($any($event))"
            (keydown.arrowdown)="focusNextChild($any($event))"
            (focus)="spSearchInput.value = selectedSpSearchProduct?.code || spModelSaved?.code;spSearch(spSearchInput.value)"
            [value]="getSpSearchInputValue(spSearchInput.value)" />
          <button [attr.aria-label]="'common.reset' | cxTranslate" (mousedown)="clearSpSearch(spSearchInput)"
            (keydown.enter)="clearSpSearch(spSearchInput)" class="reset">
            <cx-icon [type]="iconTypes.RESET"></cx-icon>
          </button>

          <div role="presentation" class="search-icon">
            <cx-icon [type]="iconTypes.SEARCH"></cx-icon>
          </div>

          <button [attr.aria-label]="'common.search' | cxTranslate" class="search">
            <cx-icon [type]="iconTypes.SEARCH"></cx-icon>
          </button>
        </label>
      </div>
    </div>

    <ng-container *ngIf="spSearchInput.value?.length > 2">
      <div *ngIf="results$ | async as result" class="results"
        [ngClass]="{'results-hidden': !!selectedSpSearchProduct?.code}" id="results" role="dialog">
        <div *ngIf="result.message" class="message sp-search-message" [innerHTML]="result.message"></div>
        <ul class="products" *ngIf="result.products && result.products?.length > 0"
          attr.aria-label="{{ 'searchBox.ariaLabelProducts' | cxTranslate }}" role="listbox">
          <li *ngFor="let product of result.products">
            <a class="list-link" role="option" (mousedown)="preventDefault($event)"
              (click)="selectSpSearchProduct(product);spSearchInput.blur();">
              <div class="name">
                {{product?.code}} {{ product?.nameHtml?.length > 0 ? ' - ' : '' }}
                <span [innerHTML]="product.nameHtml"></span>
              </div>
            </a>
          </li>
        </ul>
        <span id="initialDescription" class="cx-visually-hidden">
          {{ 'searchBox.initialDescription' | cxTranslate }}
        </span>
        <div *ngIf="result.suggestions?.length || result.products?.length" aria-live="assertive"
          class="cx-visually-hidden">
          {{
          'searchBox.suggestionsResult'
          | cxTranslate: { count: result.suggestions?.length }
          }}
          {{
          'searchBox.productsResult'
          | cxTranslate: { count: result.products?.length }
          }}
          {{ 'searchBox.initialDescription' | cxTranslate }}
        </div>
      </div>
    </ng-container>
    <div class="sp-search-results-actions">
      <button class="btn btn-secondary" (click)="closeSpSearch(spSearchInput)">Cancel</button>
      <button class="btn btn-primary" [disabled]="(activeSpSearchCriteria == 'model' && (!selectedSpSearchProduct?.code || (selectedSpSearchProduct?.code == spModelSaved?.code))) 
        || (activeSpSearchCriteria == 'serial-number' && !spSearchInput?.value)" (click)="saveSpModel(spSearchInput)">
        Save {{activeSpSearchCriteria == 'model' ? 'Model' : 'Serial Number'}}</button>
    </div>
  </div>

  <ng-container *ngIf="searchInput?.value?.length > 0">
    <div *ngIf="results$ | async as result" class="results" id="results" (click)="close($any($event), true)"
      role="dialog">
      <div *ngIf="result.message" class="message" [innerHTML]="result.message"></div>

      <ul class="suggestions" attr.aria-label="{{ 'searchBox.ariaLabelSuggestions' | cxTranslate }}" role="listbox">
        <li *ngFor="let suggestion of result.suggestions">
          <a class="list-link" role="option" [innerHTML]="suggestion | cxHighlight: searchInput.value"
            (keydown.arrowup)="focusPreviousChild($any($event))" (keydown.arrowdown)="focusNextChild($any($event))"
            (keydown.enter)="close($any($event), true)" (keydown.escape)="close($any($event), true)"
            (blur)="close($event)" (mousedown)="preventDefault($event)" (click)="
            dispatchSuggestionEvent({
              freeText: searchInput.value,
              selectedSuggestion: suggestion,
              searchSuggestions: result.suggestions ?? []
            });
            updateChosenWord(suggestion);
            navigateToSearchWithExtraParams(suggestion);
          ">
          </a>
        </li>
      </ul>

      <ul class="products" *ngIf="result.products" attr.aria-label="{{ 'searchBox.ariaLabelProducts' | cxTranslate }}"
        role="listbox">
        <li *ngFor="let product of result.products">
          <a class="list-link" role="option" [routerLink]="
            {
              cxRoute: 'product',
              params: product
            } | cxUrl
          " [class.has-media]="config?.displayProductImages" (keydown.arrowup)="focusPreviousChild($any($event))"
            (keydown.arrowdown)="focusNextChild($any($event))" (keydown.enter)="close($any($event), true)"
            (keydown.escape)="close($any($event), true)" (blur)="close($any($event))"
            (mousedown)="preventDefault($event)" (click)="
            dispatchProductEvent({
              freeText: searchInput.value,
              productCode: product.code
            })
          ">
            <cx-media *ngIf="config?.displayProductImages && ((product.servicePart && product.images?.PRIMARY) || !product.servicePart); else spGearThumb" [container]="product.images?.PRIMARY" format="thumbnail"
              role="presentation"></cx-media>
            <ng-template #spGearThumb>
              <img src="../../../../../../assets/icons/sp_gear_thumb.svg" alt="gear" class="sp-gear-thumb">
            </ng-template>
            <div class="name" [innerHTML]="product.nameHtml"></div>
            <span class="price">{{ product.price?.formattedValue }}</span>
          </a>
        </li>
      </ul>
      <span id="initialDescription" class="cx-visually-hidden">
        {{ 'searchBox.initialDescription' | cxTranslate }}
      </span>
      <div *ngIf="result.suggestions?.length || result.products?.length" aria-live="assertive"
        class="cx-visually-hidden">
        {{
        'searchBox.suggestionsResult'
        | cxTranslate: { count: result.suggestions?.length }
        }}
        {{
        'searchBox.productsResult'
        | cxTranslate: { count: result.products?.length }
        }}
        {{ 'searchBox.initialDescription' | cxTranslate }}
      </div>
    </div>
  </ng-container>
</div>