
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { CmsBreadcrumbsComponent, isNotNullable, PageMetaService, ProductSearchService, TranslationService, WindowRef } from '@spartacus/core';
import { BreadcrumbComponent, CmsComponentData } from '@spartacus/storefront';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { GeneracActiveCartService } from '../cart/core/facade/active-cart.service';
import { CartExtended } from 'src/app/interfaces/cart';
import { Router, Event, NavigationEnd } from '@angular/router';


@Component({
  selector: 'cx-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneracBreadcrumbComponent extends BreadcrumbComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  protected modelNumber$: Observable<string | null>;
  isServicePartsEnabled: boolean;
  isCartOrCheckoutPage: boolean;
  isSplitWfOrderConfirmationPage: boolean;
  isOfflineMode$: Observable<boolean> = this.activeCartFacade.getActive().pipe(
    take(1),
    map((cart: CartExtended) => cart.internalCalculation),
  );

  constructor(
    public override component: CmsComponentData<CmsBreadcrumbsComponent>,
    protected override pageMetaService: PageMetaService,
    protected productSearchService: ProductSearchService,
    protected winRef: WindowRef,
    protected activeCartFacade: GeneracActiveCartService,
    protected router: Router,
    private translationService: TranslationService
  ) {
    super(component, pageMetaService, translationService);
  }

  private setModelNumber(): void {
    this.productSearchService.clearResults();
    this.modelNumber$ = this.productSearchService
      .getResults()
      .pipe(
        map((results: any) => results.modelNumber),
        switchMap((modelNumber) => 
          modelNumber ? this.translationService
          .translate('pageMetaResolver.search.modelNumber', {
            modelNumber
          }) : of(null)
        )
      );

  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.setTitleVal();
    this.setCrumbsVal();
    this.setModelNumber();
    this.subscribeToRouterEvents();
    this.setData();
  }

  private subscribeToRouterEvents() {
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter((event: Event) => event instanceof NavigationEnd),
      )
      .subscribe(() => this.setData());
  }

  private setData() {
    this.isServicePartsEnabled = JSON.parse(this.winRef.localStorage.getItem('isServicePartsEnabled'));
    this.isCartOrCheckoutPage = this.winRef.location.href.includes('cart') || this.winRef.location.href.includes('checkout');
    this.isSplitWfOrderConfirmationPage = this.winRef.location.href.includes('order-confirmation?isSplitWfOrder=true');
    this.setModelNumber()
  }

  private setCrumbsVal(): void {
    this.crumbs$ = combineLatest([
      this.pageMetaService.getMeta(),
      this.translationService.translate('common.home'),
    ]).pipe(
      map(([meta, textHome]) =>
        meta?.breadcrumbs ? meta.breadcrumbs : [{ label: textHome, link: '/' }]
      )
    );
  }

  private setTitleVal(): void {
    this.title$ = this.pageMetaService.getMeta().pipe(
      filter(isNotNullable),
      map((meta) => this.isSplitWfOrderConfirmationPage ? ('WF ' + meta.title) : (meta.heading || meta.title) ?? ''),
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
