import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';
import { CmsService, CMSTabParagraphContainer, ProductReference, ProductReferenceService, WindowRef } from '@spartacus/core';
import { Observable, combineLatest, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { CmsComponentData, CurrentProductService, PageLayoutService } from "@spartacus/storefront";
import { TabParagraphContainerComponent } from '../../../content/tab-paragraph-container/tab-paragraph-container.component';
import { Router } from '@angular/router';
import { ProductService } from 'src/app/services/product.service';
import { ProductTabReference } from 'src/app/interfaces/product';

enum pdpTabs {
  Summary = 'About Product',
  Bundles = 'Bundles',
  Tabs = 'Product Details',
  UpSelling = 'Accessories',
  CrossSelling = 'Related Products',
  RecentlyReviewed = 'Recently Viewed',
}

@Component({
  selector: 'generac-product-header-menu',
  templateUrl: './product-header-menu.component.html',
  styleUrls: ['product-header-menu.component.scss', '../../../content/tab-paragraph-container/tab-paragraph-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class GeneracProductHeaderMenuComponent extends TabParagraphContainerComponent {
  section = 'ProductDetailsPageTemplate';
  slots: any[];
  scrollByClick: boolean = false;

  slots$: Observable<any | undefined[]> = this.currentProductService.getProduct().pipe(
    switchMap((product) => combineLatest([
      of(product),
      this.pageLayoutService.getSlots(this.section),
      this.isReferencesExist(product.code, 'SIMILAR'),
      this.isTabReferencesExist(product.code, 'ACCESSORIES')
    ])),
    map(([product, slots, isSimilarExist, isAccessoriesExist]) => {
      return slots.map((slot: string) => {
        const slotName = slot as keyof typeof pdpTabs;
        if (!pdpTabs[slotName]) {
          return null;
        }
        return {
          title: pdpTabs[slotName],
          anchor: pdpTabs[slotName].replace(/\s/g, ''),
          className: slotName,
          hidden: !this.isSlotShown(slotName, product, isSimilarExist, isAccessoriesExist)
        }
      })
    }),
    tap(slots => this.slots = slots),
    tap(() => this.setTabActive('Summary'))
  );

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event) {
    if (event && !this.scrollByClick) {
      this.observeTabPostition();
    }
  }

  constructor(
    public override componentData: CmsComponentData<CMSTabParagraphContainer>,
    protected override cmsService: CmsService,
    protected override winRef: WindowRef,
    protected pageLayoutService: PageLayoutService,
    protected override currentProductService: CurrentProductService,
    protected productReferenceService: ProductReferenceService,
    protected override productService: ProductService,
    private router: Router,
  ) {
    super(componentData, cmsService, winRef, currentProductService, productService);
  }

  private observeTabPostition(): void {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && ((entry.intersectionRatio > 0.3 &&
          entry.boundingClientRect.height / entry.rootBounds.height > 1.2)) || entry.intersectionRatio > 0.95) {
          const tab = entry.target.classList[0];
          if (this.activeTabNum !== this.getIndexOfTab(tab)) {
              this.setTabActive(tab);
          }
        }
      });
    });
    this.slots?.forEach((slot) => {
      if (slot && !slot.hidden) {
        const elementToTrack = document.getElementsByClassName(slot.className)[0] as HTMLElement;
        if (elementToTrack) {
          observer.observe(elementToTrack);
        }
      }
    })
  }

  private setTabActive(tabName: string): void {
    this.activeTabNum = this.getIndexOfTab(tabName);
    this.router.navigate([], {
      fragment: this.slots[this.activeTabNum].anchor,
      replaceUrl: true,
      queryParamsHandling: 'merge',
    });
    if(this.scrollByClick) {
      setTimeout(()=> {
        this.scrollByClick = false;
      }, 500);
    }
  }

  private getIndexOfTab(tabName: string): number {
    return this.slots.findIndex((slot) => slot?.className == tabName);
  }

  private isReferencesExist(code: string, referenceType: string): Observable<boolean> {
    return this.productReferenceService
      .getProductReferences(code, referenceType)
      .pipe(
        filter((references) => Boolean(references)),
        map((references: ProductReference[]) => {
          return references.map((reference) => of(reference.target));
        }),
        switchMap((products) => {
          return of(products.length > 0 ? true : false)
        })
      );
  }

  private isTabReferencesExist(code: string, referenceType: string): Observable<boolean> {
    return this.productService
      .getProductTabReferences(code, referenceType)
      .pipe(
        filter((references) => Boolean(references)),
        switchMap((tabReferences: ProductTabReference[]) => {
          return of(tabReferences.some((tabReference)=> tabReference.products.length > 0))
        })
      );
  }

  public scrollToSlot(slotName: string): void {
    this.scrollByClick = true;
    const target = document.getElementsByClassName(slotName)[0] as HTMLElement;
    if (target) {
      window.scrollTo({
        top: target.offsetTop - 65,
        behavior: 'smooth'
      });
      this.setTabActive(slotName);
    }
  }

  public isSlotShown(slotName: string, product: any, isSimilarExist: boolean, isAccessoriesExist: boolean): boolean {
    if ((slotName == 'Bundles' && !product.bundleAvailable) || (slotName == 'UpSelling' && !isAccessoriesExist) ||
      (slotName == 'CrossSelling' && !isSimilarExist)) {
      return false;
    }
    return true;
  }
}
