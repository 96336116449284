import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountShortcutPipe } from "./account-shortcut.pipe";

@NgModule({
  declarations: [
    AccountShortcutPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    AccountShortcutPipe
  ]
})
export class AccountShortcutModule { }
