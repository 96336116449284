import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18nModule } from "@spartacus/core";
import { IconModule, SpinnerModule, TabParagraphContainerModule } from "@spartacus/storefront";
import { GeneracPaginationComponent } from './generac-pagination.component';

@NgModule({
  declarations: [
    GeneracPaginationComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    IconModule,
    SpinnerModule,
    TabParagraphContainerModule,
  ],
  exports: [
    GeneracPaginationComponent,
  ]
})
export class GeneracPaginationModule { }
