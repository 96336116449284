<cx-address-details
    *ngIf="isFormInitialized else loading"
    [formGroup]="formGroup"
    [subscriptionProducts]="subscriptionProducts"
    [internalCalculation]="internalCalculation"
    [isCartMixed]="isCartMixed"
    [currentStepId]="currentStep.id"
    [checkoutInfo]="checkoutInfo"
    [singleConsignmentInfo]="singleConsignmentInfo"
    [subscriptionInfo]="subscriptionInfo"
    [cartCode]="cart?.code"
    [subsShippingGroup]="subsShippingGroup"
    [isServicePartsEnabled]="isServicePartsEnabled"
    (loaded)="addressLoaded()"
></cx-address-details>

<ng-template #loading>
    <div class="spinner-overlay">
        <cx-spinner></cx-spinner>
    </div>
</ng-template>

<ng-container *ngIf="(currentStep.id == 'subscriptionInfo' && isSubscription) || currentStep.id == 'deliveryAddress'">
    <div
            cxAppendAnchor="deliveryAddress"
            [anchorOffset]="false"
            class="d-flex pb-2 pt-2 delivery-address-block"
    >
        <h2 class="d-none d-lg-block">
            {{ 'checkoutAddress.shippingAddress' | cxTranslate }}
        </h2>
    </div>

    <div class="delivery-info">
        <cx-icon class="regular-icon grey not-active" [type]="iconTypes.INFO"></cx-icon>
        <span class="info-desc">
            {{ 'checkoutAddress.shippingDetailsInfo' | cxTranslate }}
        </span>
    </div>

    <div class="pb-2 pt-2">{{ 'checkoutAddress.addressSectionTitle' | cxTranslate }}</div>
    <ng-select
            [items]="cards$ | async"
            placeholder="Select address"
            [class.text-class]="selectedCard?.address?.addressType?.code === AddressType.DROP_SHIP"
            [clearable]="false"
            [searchable]="true"
            [(ngModel)]="selectedCard"
            [searchFn]="addressSearchFn"
            (ngModelChange)="verifiedAddress = null;fFAddresses=[];fFAddressFormValue=null;"
            (change)="onChangeCurrentAddress($event)"
    >
        <ng-template ng-label-tmp let-item="item">
            <div class="bold-title">
                <strong>{{ item.address.addressType.name }}</strong>
                <ng-container *ngIf="item.address.addressType.code !== AddressType.DROP_SHIP">:
                    <strong> {{ item.address.companyName }}</strong>
                </ng-container>
                <ng-container *ngIf="item.address.addressType.code === AddressType.DROP_SHIP && dropShipForm?.addressForm?.value.residential">
                    <div class="delivery-residential">
                        <cx-icon class="favorite-icon small active" [type]="iconTypes.PIN"></cx-icon>
                        <div>{{ 'checkoutAddress.residentialAddress' | cxTranslate }}</div>
                    </div>
                </ng-container>
            </div>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index">
            <div class="address">
                <div class="address-option">
                    <div class="bold-title">
                        <strong>{{ item.address.addressType.name }}</strong>
                        <ng-container *ngIf="item.address.addressType.code !== AddressType.DROP_SHIP">:
                            <strong> {{ item.address.companyName }}</strong>
                        </ng-container>
                    </div>
                    <ng-container *ngIf="item.address.addressType.code !== AddressType.DROP_SHIP">
                        <span>{{ item.address.formattedAddress }}</span><span>{{ item.address.cellphone }}</span>
                    </ng-container>
                </div>
                <div *ngIf="item.card.header" class="selected-block">
                    <img src="../../../../../../assets/icons/done_orange_24.svg" />
                    <span>Selected</span>
                </div>
            </div>
        </ng-template>
    </ng-select>

    <generac-address-form
            *ngIf="selectedCard && selectedCard.address.addressType.code !== AddressType.DROP_SHIP"
            [generacAddressData]="selectedCard.address"
            [setAsDefaultField]="false"
            [disableForm]="true"
            [fromCheckout]="true"
            [showTitleCode]="false"
            [showSubmitBtn]="false"
            [showCancelBtn]="false"
            [addressType]="selectedCard.address.addressType.code"
    ></generac-address-form>

    <generac-address-form
            *ngIf="selectedCard && selectedCard.address.addressType.code === AddressType.DROP_SHIP"
            #dropShipForm
            [generacAddressData]="verifiedAddress"
            [setAsDefaultField]="false"
            [fromCheckout]="true"
            [disableForm]="false"
            [showTitleCode]="false"
            [actionBtnLabel]="'Ship Here'"
            [showSubmitBtn]="!verifiedAddress"
            [showCancelBtn]="false"
            (submitAddress)="onVerify($event)"
            [isEditable]="true"
            [addressType]="selectedCard.address.addressType.code"
            (saveDropShipAddressChanged)="saveDropShipAddressChanged($event)"
            (limitCountOfAddressesMessage)="limitCountOfAddressesMessageChange($event)"
    ></generac-address-form>

    <cx-custom-message
            [messages]="floorPlanErrorMessage"
            [isPermanent]="true"
            [isCornered]="true"
            [isIconVisible]="true"
            [hideCloseButton]="true"
    ></cx-custom-message>

    <ng-container *ngIf="fFAddresses?.length">
        <app-freight-forword-form
                #ffAddress
                [selectedFFAddress]="freightForwarderAddress"
                [fFAddresses]="fFAddresses"
                [selectedCountry]="selectedCard?.address?.country?.isocode"
                (fFAddressesChange)="getFFCustomeAddress($event)"
        ></app-freight-forword-form>
    </ng-container>

    <div *ngIf="orderInstructionsNotes?.header && orderInstructionsNotes?.notes?.length" class="order-instructions-notes">
        <div class="order-instructions-notes-title pb-2 pt-2">
            <span>{{ 'checkoutAddress.additionalInstructionsTitle' | cxTranslate }}</span>
            <span *ngIf="orderInstructionsNotes.required">*</span>
        </div>
        <span class="order-instructions-notes-header">{{orderInstructionsNotes.header}}</span>
        <ol *ngIf="orderInstructionsNotes.notes?.length > 1" class="order-instructions-notes-list">
            <li *ngFor="let note of orderInstructionsNotes.notes; let i = index;">
                {{note}}
            </li>
        </ol>
        <p *ngIf="orderInstructionsNotes.notes?.length == 1" class="order-instructions-notes-text">
            {{ orderInstructionsNotes.notes[0] }}
        </p>
        <textarea  name="order-notes" id="order-notes" class="order-instructions-notes-note-value" [formControl]="orderInstructionsControl"></textarea>
        <cx-form-errors
            [control]="orderInstructionsControl"
        ></cx-form-errors>
    </div>


    <div role="status" [attr.aria-label]="'common.loaded' | cxTranslate"></div>
    <ng-container *ngIf="!subscriptionProducts?.length && !isServicePartsEnabled">
        <generac-checkout-single-consignment
            [shippingGroupName]="cart?.shippingGroups?.[0]?.shippingGroupName"
            [cartCode]="cart?.code"
            [manufactureGroupID]="cart?.shippingGroups?.[0]?.manufactureGroups?.[0]?.manufactureGroupID"
            [singleConsignmentInfo]="cart?.shippingGroups?.[0]?.manufactureGroups?.[0]?.singleConsignmentInfo"
            [purchaseOrderNumber]="checkoutInfo?.purchaseOrderNumber"
            [updateData]="null"
            (singleConsignmentInfoData)="singleConsignmentInfoDataSet($event)">
        </generac-checkout-single-consignment>
    </ng-container>
</ng-container>

<generac-checkout-control-btns
    [customBackBtnText]="!cart?.powerPlayOrder && !isSubscription ? null : 'Back to Subscriptions'"
    [isContinueDisabled]="(!(verifiedAddress || cart?.deliveryAddress && selectedCard?.address?.addressType?.code !== AddressType.DROP_SHIP) || formGroup.status === 'INVALID' || (continueDisabled$ | async) || ffAddress?.addressForm?.invalid) || (floorPlanErrorMessage?.length && !checkPaymentResponse?.splitPaymentAvailable)"
    [isNextActionCustom]="true"
    [isBackActionCustom]="true"
    [skipPaymentTypeStep]="skipPaymentTypeStep"
    (backBtnClicked)="!cart?.powerPlayOrder && !isSubscription ? back() : backToSubs()"
    (nextBtnClicked)="next()"
>
</generac-checkout-control-btns>
