<cx-spinner
        *ngIf="loading === LoadingEnum.Idle; else list;"
        class="spinner-center"
></cx-spinner>

<ng-template #list>
    <div class="product-bundle-list container">

        <cx-spinner
                *ngIf="loading === LoadingEnum.Loading"
                class="spinner-overlay"
        ></cx-spinner>

        <div *ngIf="pagination?.totalPages > 1" class="product-bundle-list-header">
            <cx-pagination
                    [pagination]="pagination"
                    (viewPageEvent)="onPaginate($event)"
                    [defaultPage]="0"
            ></cx-pagination>
        </div>

        <div class="bundles">
            <div *ngFor="let bundle of bundles; trackBy: trackByBundle;" class="bundle-slide-wrapper">
                <div class="bundle-slide-wrapper-title">{{ bundle.rootBundleTemplateName }}</div>

                <cx-bundle-slide
                        [bundle]="bundle"
                        [errorMessages]="bundle.errorMessages"
                        [isActive]="true"
                        (addBundleToTheCart)="addBundleToTheCart($event)"
                ></cx-bundle-slide>
            </div>
        </div>

        <div class="cx-sorting bundles-sorting bottom">
            <cx-pagination
                    [pagination]="pagination"
                    (viewPageEvent)="onPaginate($event)"
                    [defaultPage]="0"
            ></cx-pagination>
        </div>
    </div>
</ng-template>