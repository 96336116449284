import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { transformNameToUrl } from "../../../helpers/transform-name-to-url";
import { Router } from "@angular/router";


@Component({
  selector: 'cx-bundle-carousel-item',
  templateUrl: './bundle-carousel-item.component.html',
  styleUrls: ['./bundle-carousel-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BundleCarouselItemComponent {
  @Input() product: any;

  transformNameToUrl = transformNameToUrl;

  constructor(private router: Router) {
  }

  navigateToProduct() {
    this.router.navigate(['product', this.product.code, transformNameToUrl(this.product.name)]);
  }

}
