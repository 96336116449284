import { Injectable } from "@angular/core";
import { BasePageMetaResolver, CategoryPageMetaResolver, CmsService, ProductScope, ProductSearchPage, ProductSearchService, ProductService, TranslationService } from "@spartacus/core";
import { Observable } from "rxjs";
import { distinctUntilChanged, filter, switchMap } from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class GeneracCategoryPageMetaResolver extends CategoryPageMetaResolver {
  ACCESSORIES_PRODUCT_REF =  'accessoriesProductRef';
  ACCESSORIES_REF_TYPE_TITLE = 'Accessories';

  constructor(
    override productSearchService: ProductSearchService,
    override cms: CmsService,
    override translation: TranslationService,
    override basePageMetaResolver: BasePageMetaResolver,
    protected productService: ProductService,
  ) {
    super(productSearchService, cms, translation, basePageMetaResolver);
  }

  override resolveTitle(): Observable<string> {
    return (<Observable<ProductSearchPage>>this.searchPage$).pipe(
      filter((page: ProductSearchPage) => !!page.pagination),
      switchMap((p: ProductSearchPage) => {
        if(p.currentQuery.query.value.includes(this.ACCESSORIES_PRODUCT_REF)) {
          const refType = this.ACCESSORIES_REF_TYPE_TITLE;
          const params = p.currentQuery.query.value.split(':');
          const productCode = params[params.indexOf(this.ACCESSORIES_PRODUCT_REF) + 1];
          return this.productService.get(productCode, ProductScope.LIST).pipe(
            distinctUntilChanged(),
            filter(product => !!product),
            switchMap(product => this.translation.translate('pageMetaResolver.category.titleReferences', {
              refType,
              productName: product.name,
            }))
          )
        } else {
          return this.translation.translate('pageMetaResolver.category.title', {
            count: p.pagination?.totalResults,
            query: p.breadcrumbs?.length
              ? p.breadcrumbs[0].facetValueName
              : undefined,
          });
        }
      })
    );
  }
}
