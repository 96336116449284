import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, I18nModule, provideConfig, UrlModule } from '@spartacus/core';
import { ProductCarouselComponent } from './product-carousel.component';
import { ProductCarouselItemComponent } from '../product-carousel-item/product-carousel-item.component';
import { CarouselModule, MediaModule, PageComponentModule } from "@spartacus/storefront";
import { ProductGridItemModule } from '../../product-list/product-grid-item/product-grid-item.module';

@NgModule({
  imports: [
    CommonModule,
    CarouselModule,
    MediaModule,
    RouterModule,
    UrlModule,
    I18nModule,
    PageComponentModule,
    ProductGridItemModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        ProductCarouselComponent: {
          component: ProductCarouselComponent,
        },
        RecentlyViewedProductsCarouselComponent: {
          component: ProductCarouselComponent,
        }
      },
    }),
  ],
  declarations: [ProductCarouselComponent, ProductCarouselItemComponent],
  exports: [ProductCarouselComponent, ProductCarouselItemComponent],
})
export class ProductCarouselModule {}
