<form [formGroup]="addToCartForm" (submit)="addToCart()">
    <div class="row favorites-block">
        <ng-container *ngIf="product && !isSavedCart && !isSubs">
            <add-to-favorites
                [product]="product"
                [gaListName]="gaListName"
                [gaProductCategories]="gaProductCategories"
                [class.border-grey]="isIcon && product.erpStatus !== 'DISCONTINUED'"
            ></add-to-favorites>
            <generac-product-badge [badge]="marketingBadge"></generac-product-badge>
            <generac-sp-ltl *ngIf="product.ltl && product.servicePart" [ltl]="product.ltl"></generac-sp-ltl>
            <generac-sp-flammable *ngIf="product.flammable && product.servicePart" [flammable]="product.flammable"></generac-sp-flammable>
        </ng-container>
    </div>
    <div class="quantity-add-to-cart-block">
        <div *ngIf="showQuantity" class="quantity">
            <label *ngIf="purchasable">{{ 'addToCart.quantity' | cxTranslate }}</label>
            <div class="cx-counter-stock">
                <cx-custom-item-counter
                        *ngIf="productCode && purchasable"
                        [min]="+minQuantity"
                        [step]="+addToCartQtyMultiplier"
                        [max]="+maxQuantity"
                        [control]="quantityControl"
                        [productCode]="productCode"
                ></cx-custom-item-counter>

                <div *ngIf="!purchasable"></div>
            </div>
        </div>
        <div *ngIf="!purchasable && (component.data$ | async)?.otherProperties?.shouldUseCallForPricingTooltip"
            class="call-for-price" (mouseenter)="callForPricingMessage=tooltipMessage" (mouseleave)="callForPricingMessage=''">
            <ng-container *ngIf="product?.servicePart; else regularProduct;">
                <div *ngIf="product?.unavailabilityDetail?.type === UnavailabilityDetailType.SeeNote; else notSeeNote" class="see-note-pdp">
                    <cx-icon [type]="iconTypes.INFO" class="regular-icon active"></cx-icon>

                    <a class="call-for-pricing link cx-action-btn-link" (click)="scrollToNote()">
                        {{product?.unavailabilityDetail?.label}}
                    </a>
                </div>
                <ng-template #plainText>
                    <span class="call-for-pricing">{{product?.unavailabilityDetail?.label}}</span>
                </ng-template>
                <ng-template #notSeeNote>
                    <cx-icon [type]="iconTypes.INFO" class="regular-icon active"></cx-icon>

                    <a *ngIf="product?.unavailabilityDetail?.type === UnavailabilityDetailType.ContactFactory; else plainText;"
                        class="call-for-pricing link cx-action-btn-link" (click)="openContactFactory()">
                        {{product?.unavailabilityDetail?.label}}
                    </a>
                </ng-template>
            </ng-container>
        
            <ng-template #regularProduct>
                <cx-icon [type]="iconTypes.HEADSET" class="regular-icon active"></cx-icon>
                <a [href]="contactForPricingHref">Contact For Pricing</a>
                <cx-info-message [message]="callForPricingMessage" [position]="'position-left'"></cx-info-message>
            </ng-template>
        </div>
        <button
            *ngIf="productCode && purchasable"
            [ngClass]="
      options?.displayAddToCart
        ? 'link cx-action-link'
        : 'btn btn-primary btn-block'
    "
            [class.is-icon]="isIcon"
            [class.is-mobile-btn]="isMobileBtn"
            [class.add-to-cart]="isAddModal"
            type="submit"
            [disabled]="quantity <= 0 || quantity > maxQuantity"
    >
        <span *ngIf="isSubs && !isAddModal">{{ 'addToCart.buy' | cxTranslate }}</span>
        <div *ngIf="isAddModal">
            <img src="../../../../../../assets/icons/shopping_cart_orange.svg">
            <span>{{ 'addToCart.add' | cxTranslate }}</span>
        </div>
        <span *ngIf="!isAddModal && !isSubs && (!isIcon || isMobileBtn)">{{ options?.addToCartString || ('addToCart.addToCart' | cxTranslate) }}</span>
        <span *ngIf="!isAddModal && !isSubs && (isIcon || isMobileBtn)">
            <img src="../../../../../../assets/icons/shopping_cart_orange.svg">
        </span>
    </button>
    </div>
</form>

