import { Injectable } from '@angular/core';
import { GlobalMessageService, OccEndpointsService } from '@spartacus/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandleService {
  constructor(
    private httpClient: HttpClient,
    private occEndpoints: OccEndpointsService,
    protected globalMessageService: GlobalMessageService,
  ) {}

  logError(error: string): Observable<any> {
    const url = `${this.occEndpoints.getBaseUrl()}/users/current/log`;
    return this.httpClient.post(url, {
      url: location.href,
      message: error
    });
  }
}
