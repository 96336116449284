<table role="table">
    <caption class="cx-visually-hidden">
        {{
        'cartItems.caption' | cxTranslate
        }}
    </caption>
    <thead *ngIf="hasHeader && !isServicePartsEnabled">
        <tr role="row" class="cx-item-list-header">
            <th role="columnheader" class="cx-item-list-desc">
                {{ 'cartItems.description' | cxTranslate }}
            </th>
            <th role="columnheader" class="cx-item-list-qty">
                {{ 'cartItems.quantity' | cxTranslate }}
            </th>
            <ng-container *ngIf="options.isSaveForLater; else totalHeader">
                <th role="columnheader" class="cx-item-list-total">
                    {{ 'saveForLaterItems.stock' | cxTranslate }}
                </th>
            </ng-container>
            <ng-container
                *ngIf="!readonly || options.isSaveForLater || options.displayAddToCart">
                <th role="columnheader" class="cx-item-list-actions">
                    {{ 'cartItems.actions' | cxTranslate }}
                </th>
            </ng-container>
        </tr>
    </thead>
    <tbody class="cx-item-list-items" [class.sp-table]="isServicePartsEnabled && !isSavedCart">
        <ng-container *ngIf="groups?.length || shippingGroups?.length">
            <ng-container *ngIf="isServicePartsEnabled && shippingGroups && !isSavedCart">
                <ng-container *ngFor="let shippingGroup of shippingGroups">
                    <tr class="sp-shipping-group-name">
                        <h3>{{ getShippingGroupDisplayName(shippingGroup?.shippingGroupName) }}</h3>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <ng-container *ngFor="let manufactureGroup of shippingGroup?.manufactureGroups">
                        <ng-container *ngTemplateOutlet="entryGroups; context: { groups: manufactureGroup?.entryGroups }"></ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="!isServicePartsEnabled || isSavedCart">
                <ng-container *ngTemplateOutlet="entryGroups; context: { groups: groups }"></ng-container>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="items?.length">
            <ng-container *ngFor="let item of items; let i = index;">
                <ng-container
                        *ngIf="getControl(item) as control"
                        [class.is-changed]="$any(control).get('quantity')"
                >
                    <tr
                        cx-cart-item-list-row
                        role="row"
                        class="cx-item-list-row"
                        [item]="item"
                        [quantityControl]="$any(control).get('quantity')"
                        [readonly]="readonly"
                        [promotionLocation]="promotionLocation"
                        [options]="options"
                    ></tr>
                </ng-container>
            </ng-container>
        </ng-container>
    </tbody>
</table>

<ng-template #totalHeader>
    <th role="columnheader" class="cx-item-list-total">
        {{ 'cartItems.total' | cxTranslate }}
    </th>
</ng-template>

<ng-template #entryGroups let-groups="groups">
    <ng-container *ngFor="let group of groups; let i = index;">
        <ng-container
            *ngIf="getControl(group.entry) as control"
            [class.is-changed]="$any(control).get('quantity').disabled">
            <tr
                cx-cart-item-list-row
                role="row"
                *ngIf="!(group?.entry?.product?.serviceProductType && isReviewOrder)"
                class="cx-item-list-row position-relative"
                [ngClass]="{
                    'bundle-main-item': group.bundleId,
                    'border-top-hidden': i > 0 && groups[i - 1].entry?.product?.serviceProductType && isReviewOrder,
                    'sp-table-row': isServicePartsEnabled,
                }"
                [item]="group.entry"
                [isBundleCollapsed]="group.isBundleCollapsed"
                [bundleId]="group.bundleId"
                [maxItemsAllowed]="group.maxItemsAllowed"
                [quantityControl]="$any(control).get('quantity')"
                [readonly]="readonly"
                [promotionLocation]="promotionLocation"
                [options]="options"
                (onBundleCollapse)="onBundleCollapse(group)"
            ></tr>
            <table
                *ngIf="group?.entry?.product?.serviceProductType && isReviewOrder"
                class="cx-item-list-row cx-item-list-subscription-item">
                <tr class="table-subscription-row-header top-header"></tr>
                <tr
                    cx-cart-item-list-row
                    role="row"
                    class="cx-item-list-row"
                    [item]="group.entry"
                    [isBundleItem]="false"
                    [quantityControl]="$any(control).get('quantity')"
                    [readonly]="true"
                    [promotionLocation]="promotionLocation"
                    [options]="options"
                ></tr>
                <cx-custom-subscriptions-table
                    [product]="group?.entry?.product"
                    [subscriptionInfo]="group?.entry?.subscriptions"
                ></cx-custom-subscriptions-table>
                <tr class="table-subscription-row-footer bottom-footer"></tr>
            </table>
            <table
                    *ngIf="group.entries?.length && group.isBundleCollapsed"
                    class="cx-item-list-row cx-item-list-bundle-item">
                <tr class="table-bundle-row-header top-header">
                    <p>{{group.entries[0]?.product?.name}}</p>
                </tr>
                <tr
                    cx-cart-item-list-row
                    role="row"
                    class="cx-item-list-row"
                    [item]="group.entries[0]"
                    [isBundleItem]="true"
                    [quantityControl]="$any(control).get('quantity')"
                    [readonly]="true"
                    [promotionLocation]="promotionLocation"
                    [options]="options"
                ></tr>

                <tr class="table-bundle-row-header">
                    <p>Child Accessories</p>
                </tr>
                <tr
                    *ngFor="let entry of group.entries | slice: 1"
                    cx-cart-item-list-row
                    role="row"
                    class="cx-item-list-row bundle-item"
                    [item]="entry"
                    [isBundleItem]="true"
                    [quantityControl]="$any(control).get('quantity')"
                    [readonly]="true"
                    [promotionLocation]="promotionLocation"
                    [options]="options"
                ></tr>
            </table>
        </ng-container>
    </ng-container>
</ng-template>
