import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideConfig, CmsConfig } from '@spartacus/core';
import { PageComponentModule, CarouselModule, MediaModule } from '@spartacus/storefront';
import { CustomBannerCarouselComponent } from './custom-banner-carousel.component';

@NgModule({
  imports: [CommonModule, PageComponentModule, CarouselModule, MediaModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        RotatingImagesComponent: {
          component: CustomBannerCarouselComponent,
        },
      },
    }),
  ],
  declarations: [CustomBannerCarouselComponent],
  exports: [CustomBannerCarouselComponent],
})
export class CustomBannerCarouselModule {}
