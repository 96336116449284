
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, I18nModule, provideDefaultConfig } from '@spartacus/core';
import { CustomProductIntroComponent } from './custom-product-intro.component';
import { StarRatingModule } from '@spartacus/storefront';
import { AddToFavoritesComponent } from '../../../favorites/add-to-favorites/add-to-favorites.component';

@NgModule({
  imports: [CommonModule, I18nModule, StarRatingModule, AddToFavoritesComponent],
  providers: [
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        ProductIntroComponent: {
          component: CustomProductIntroComponent,
        },
      },
    }),
  ],
  declarations: [CustomProductIntroComponent],
  exports: [CustomProductIntroComponent],
})
export class CustomProductIntroModule {}
