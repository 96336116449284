import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'combineArrays'
})
export class CombineArraysPipe implements PipeTransform {

  transform(arr: any[], combineByField: string) {
    return arr?.reduce((acc, item) => {
      acc.push(...item[combineByField]);
      return acc;
    },[])
  }

}
