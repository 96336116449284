<cx-spinner
        *ngIf="loading === LoadingEnum.Idle || (loading === LoadingEnum.Loading && !bundles?.length); else carousel;"
></cx-spinner>

<ng-template #carousel>
    <div
            *ngIf="bundles?.length > 0"
            class="bundle-carousel container"
    >
        <div class="bundle-carousel-header">
            <h2>{{ 'bundle.title' | cxTranslate }}</h2>
            <div class="bundle-carousel-header-nav">
                <button
                        class="previous"
                        (click)="navigateBack()"
                        [disabled]="activeSlide === 0"
                        [attr.aria-label]="'carousel.previousSlide' | cxTranslate"
                >
                    <cx-icon [type]="previousIcon"></cx-icon>
                </button>
                <button
                        class="next"
                        (click)="navigateForward()"
                        tabindex="-1"
                        [disabled]="activeSlide >= bundles.length - 1"
                        [attr.aria-label]="'carousel.nextSlide' | cxTranslate"
                >
                    <cx-icon [type]="nextIcon"></cx-icon>
                </button>
            </div>
        </div>

        <div class="bundle-carousel-slides">
            <cx-bundle-slide
                    *ngFor="let bundle of bundles; trackBy: trackByBundle; let i = index;"
                    [bundle]="bundle"
                    [isActive]="i === activeSlide"
                    [loading]="loading"
                    (addBundleToTheCart)="addBundleToTheCart($event)"
            ></cx-bundle-slide>
        </div>

        <div class="bundle-carousel-indicators">
            <ng-container *ngFor="let _ of bundles; let i = index">
                <button
                        *ngIf="bundles.length > 1"
                        (click)="activeSlide = i"
                        [disabled]="i === activeSlide"
                        tabindex="-1"
                >
                    <cx-icon [type]="indicatorIcon" aria-hidden="true"></cx-icon>
                </button>
            </ng-container>
        </div>

    </div>
</ng-template>