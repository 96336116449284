import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardModule, IconModule, OutletModule, PromotionsModule } from "@spartacus/storefront";
import { CmsConfig, I18nModule, provideConfig, UrlModule } from "@spartacus/core";
import { RouterModule } from "@angular/router";
import { CheckoutReviewSubmitComponent } from "./checkout-review-submit.component";
import { CheckoutAuthGuard } from "@spartacus/checkout/base/components";
import { CartSharedModule } from "../../../cart/cart-shared";
import { InfoMessageModule } from 'src/app/shared/components/info-message/info-message.module';
import { CountEntryGroupsQuantityModule } from "../../../../../shared/pipes/count-entry-groups-quantity/count-entry-groups-quantity.module";
import { OfflineBadgeModule } from 'src/app/shared/components/offline-badge/offline-badge.module';
import { CustomCartNotEmptyGuard } from "../../../../../guards/custom-cart-not-empty.guard";
import { GeneracShippingGroupsComponent } from 'src/app/shared/components/generac-shipping-groups/generac-shipping-groups.component';

@NgModule({
  imports: [
    CommonModule,
    CardModule,
    I18nModule,
    UrlModule,
    RouterModule,
    PromotionsModule,
    InfoMessageModule,
    IconModule,
    OutletModule,
    CartSharedModule,
    OfflineBadgeModule,
    CountEntryGroupsQuantityModule,
    GeneracShippingGroupsComponent,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutReviewOrder: {
          component: CheckoutReviewSubmitComponent,
          guards: [CheckoutAuthGuard, CustomCartNotEmptyGuard],
        },
      },
    }),
  ],
  declarations: [CheckoutReviewSubmitComponent],
  exports: [CheckoutReviewSubmitComponent],
})
export class CheckoutReviewSubmitModule {
}
