import { NgModule } from '@angular/core';
import {
  AnonymousConsentsModule, AuthModule,
  CostCenterOccModule, ExternalRoutesModule, ProductModule, ProductOccModule, UserModule, UserOccModule
} from "@spartacus/core";
import {
  AddressBookModule,
  AnonymousConsentManagementBannerModule,
  AnonymousConsentsDialogModule,
  BannerCarouselModule,
  BannerModule,
  BreadcrumbModule,
  CategoryNavigationModule,
  CmsParagraphModule,
  ConsentManagementModule,
  HamburgerMenuModule,
  HomePageEventModule,
  LinkModule,
  LoginRouteModule,
  MyCouponsModule,
  MyInterestsModule,
  NavigationEventModule,
  NotificationPreferenceModule,
  PageTitleModule,
  PaymentMethodsModule,
  ProductCarouselModule,
  ProductFacetNavigationModule,
  ProductImagesModule,
  ProductIntroModule,
  ProductListingPageModule,
  ProductPageEventModule,
  ProductReferencesModule,
  ProductTabsModule,
  ScrollToTopModule,
  SiteContextSelectorModule,
  StockNotificationModule,
  TabParagraphContainerModule,
} from "@spartacus/storefront";
import { UserFeatureModule } from './features/user/user-feature.module';
import { GeneracCartBaseFeatureModule } from './features/cart/cart-base-feature.module';
import { WishListFeatureModule } from './features/cart/wish-list-feature.module';
import { CartQuickOrderFeatureModule } from './features/cart/cart-quick-order-feature.module';
import { CartImportExportFeatureModule } from './features/cart/cart-import-export-feature.module';
import { OrderFeatureModule } from './features/order/order-feature.module';
import { CheckoutFeatureModule } from './features/checkout/checkout-feature.module';
import { TagManagementFeatureModule } from './features/tracking/tag-management-feature.module';
import { ProductConfiguratorFeatureModule } from './features/product-configurator/product-configurator-feature.module';
import { DigitalPaymentsFeatureModule } from './features/digital-payments/digital-payments-feature.module';
import { SmartEditFeatureModule } from './features/smartedit/smart-edit-feature.module';
import { ProductVariantsFeatureModule } from './features/product/product-variants-feature.module';
import { ProductImageZoomFeatureModule } from './features/product/product-image-zoom-feature.module';
import { ProductBulkPricingFeatureModule } from './features/product/product-bulk-pricing-feature.module';
import { OrganizationOrderApprovalFeatureModule } from './features/organization/organization-order-approval-feature.module';
import { OrganizationAdministrationFeatureModule } from './features/organization/organization-administration-feature.module';
import { AsmFeatureModule } from './features/asm/asm-feature.module';
import { LogoutModule } from '../user-account/logout/logout.module';
import { CartTotalsModule } from './features/cart/cart-totals.module';
import { FooterNavigationModule } from './features/footer-navigation/footer-navigation.module';
import { CustomThemeModule } from './features/theme/custom-theme.module';
import { FavoritesListModule } from './features/favorites/favorites-list/favorites-list.module';
import { ProductSuggestionsModule } from './features/product/components/product-suggestions/product-suggestions.module';
import { CartSavedCartFeatureModule } from './features/cart/cart-saved-cart-feature.module';
import { GeneracProductWarrantiesModule } from './features/product/components/generac-product-warranties/generac-product-warranties.module';
import { ProductDetailsPageModule } from "./features/product/product-details-page.module";
import { CheckoutScheduleReplenishmentOrderModule } from './features/checkout/components/checkout-schedule-replenishment-order/checkout-schedule-replenishment-order.module';
import { SavedAddressesModule } from './features/saved-addresses/saved-addresses.module';
import { GeneracSearchBoxModule } from './features/search-box/search-box.module';

@NgModule({
  declarations: [],
  imports: [
    AuthModule.forRoot(),
    LogoutModule,
    LoginRouteModule,
    HamburgerMenuModule,
    SiteContextSelectorModule,
    LinkModule,
    BannerModule,
    CmsParagraphModule,
    TabParagraphContainerModule,
    BannerCarouselModule,
    CategoryNavigationModule,
    FooterNavigationModule,
    BreadcrumbModule,
    ScrollToTopModule,
    PageTitleModule,
    UserModule,
    UserOccModule,
    AddressBookModule,
    PaymentMethodsModule,
    NotificationPreferenceModule,
    MyInterestsModule,
    StockNotificationModule,
    ConsentManagementModule,
    MyCouponsModule,
    AnonymousConsentsModule.forRoot(),
    AnonymousConsentsDialogModule,
    AnonymousConsentManagementBannerModule,
    ProductModule.forRoot(),
    ProductOccModule,
    ProductListingPageModule,
    GeneracSearchBoxModule,
    ProductFacetNavigationModule,
    ProductTabsModule,
    ProductCarouselModule,
    ProductReferencesModule,
    ProductSuggestionsModule,
    ProductImagesModule,
    ProductIntroModule,
    CostCenterOccModule,
    NavigationEventModule,
    HomePageEventModule,
    ProductPageEventModule,
    ExternalRoutesModule.forRoot(),
    UserFeatureModule,
    GeneracCartBaseFeatureModule,
    CheckoutScheduleReplenishmentOrderModule,
    CartSavedCartFeatureModule,
    WishListFeatureModule,
    CartQuickOrderFeatureModule,
    CartImportExportFeatureModule,
    OrderFeatureModule,
    CheckoutFeatureModule,
    CartTotalsModule,
    TagManagementFeatureModule,
    ProductConfiguratorFeatureModule,
    DigitalPaymentsFeatureModule,
    SmartEditFeatureModule,
    ProductVariantsFeatureModule,
    ProductImageZoomFeatureModule,
    ProductBulkPricingFeatureModule,
    OrganizationOrderApprovalFeatureModule,
    OrganizationAdministrationFeatureModule,
    AsmFeatureModule,
    CustomThemeModule,
    FavoritesListModule,
    GeneracProductWarrantiesModule,
    ProductDetailsPageModule,
    SavedAddressesModule,
  ]
})
export class SpartacusFeaturesModule { }
