import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CheckoutStepService } from '@spartacus/checkout/base/components';
import { CheckoutStepType } from '@spartacus/checkout/base/root';
import { I18nModule } from '@spartacus/core';

@Component({
  selector: 'generac-checkout-control-btns',
  templateUrl: './checkout-control-btns.component.html',
  styleUrls: ['./checkout-control-btns.component.scss'],
  standalone: true,
  imports: [ I18nModule ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutControlBtnsComponent {
  @Input() customBackBtnText: string;
  @Input() isContinueDisabled: boolean = false;
  @Input() isNextActionCustom: boolean = false;
  @Input() isBackActionCustom: boolean = false;
  @Input() skipPaymentTypeStep: boolean = false;
  @Output() backBtnClicked: EventEmitter<void> = new EventEmitter();
  @Output() nextBtnClicked: EventEmitter<void> = new EventEmitter();


  get backBtnText(): string {
    return this.checkoutStepService.getBackBntText(this.activatedRoute);
  }

  constructor(
    private checkoutStepService: CheckoutStepService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.checkoutStepService.disableEnableStep(
      CheckoutStepType.PAYMENT_TYPE as unknown as CheckoutStepType, false
    );
  }

  goNext(): void {
    if (this.checkoutStepService.getNextCheckoutStepUrl(this.activatedRoute).includes('payment-type') && this.skipPaymentTypeStep) {
      this.checkoutStepService.disableEnableStep(
        CheckoutStepType.PAYMENT_TYPE as unknown as CheckoutStepType, true
      );
    }
    this.isNextActionCustom ? this.nextBtnClicked.emit() : this.checkoutStepService.next(this.activatedRoute);
  }

  goBack(): void {
    this.isBackActionCustom ? this.backBtnClicked.emit() : this.checkoutStepService.back(this.activatedRoute);
  }
}
