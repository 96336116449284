import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressButtonModule } from "@spartacus/storefront";
import { RouterModule } from "@angular/router";
import { CmsConfig, I18nModule, provideConfig, UrlModule } from "@spartacus/core";
import { CartProceedToCheckoutComponent } from "./cart-proceed-to-checkout.component";

@NgModule({
  imports: [
    CommonModule,
    ProgressButtonModule,
    RouterModule,
    I18nModule,
    UrlModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CartProceedToCheckoutComponent: {
          component: CartProceedToCheckoutComponent,
        },
      },
    }),
  ],
  declarations: [CartProceedToCheckoutComponent],
  exports: [CartProceedToCheckoutComponent],
})

export class CartProceedToCheckoutModule { }
