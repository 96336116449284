/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CmsConfig, I18nModule, provideDefaultConfig } from '@spartacus/core';
import { ProductAttributesComponent } from './product-attributes.component';
import { ArraySplitModule } from "../../../../../shared/pipes/array-split/array-split.module";
import { CombineArraysModule } from "../../../../../shared/pipes/combine-arrays/combine-arrays.module";

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    ArraySplitModule,
    CombineArraysModule
  ],
  providers: [
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        ProductSpecsTabComponent: {
          component: ProductAttributesComponent,
        },
      },
    }),
  ],
  declarations: [ProductAttributesComponent],
  exports: [ProductAttributesComponent],
})
export class ProductAttributesModule {}
