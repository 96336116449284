import { ComponentRef, Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, EMPTY, Observable, throwError } from "rxjs";
import { OccEndpointsService } from '@spartacus/core';

@Injectable({
  providedIn: 'root'
})
export class DelegoService {
  delegoModal = new BehaviorSubject<ComponentRef<any>>({} as ComponentRef<any>);
  delegoModal$ = this.delegoModal.asObservable();

  constructor(
    private httpClient: HttpClient,
    private occEndpoints: OccEndpointsService,
  ) { }

  getSecretParams(): Observable<any> {
    const url = `${this.occEndpoints.getBaseUrl()}/cart/delego/gc/parameters`;
    return this.httpClient.get(url);
  }

  sendPaymentDetails(cartNumber: string | undefined, body: any): Observable<any> {
    if (!cartNumber) return throwError('No cartNumber was provided');

    const url = `${this.occEndpoints.getBaseUrl()}/cart/${cartNumber}/gc/payment`;
    return this.httpClient.post(url, body);
  }

  createOrder(cartNumber: string | undefined): Observable<any> {
    if (!cartNumber) return EMPTY;

    const params = {
      fields: 'FULL',
      cartId: cartNumber,
      termsChecked: true
    };
    const url = `${this.occEndpoints.getBaseUrl()}/orgUsers/current/orders`;
    return this.httpClient.post(url, {}, { params });
  }

  getCurrentCart(cartNumber: string | undefined): Observable<any> {
    if (!cartNumber) return EMPTY;

    const params = {
      fields: [
        'deliveryAddress(FULL)',
        'deliveryMode(FULL)',
        'paymentInfo(FULL)',
        'costCenter(FULL)',
        'purchaseOrderNumber',
        'paymentType(FULL)'
      ]
    };
    const url = `${this.occEndpoints.getBaseUrl()}/users/current/carts/${cartNumber}`;
    return this.httpClient.get(url, { params });
  }

  getCarts(): Observable<any> {
    const url = `${this.occEndpoints.getBaseUrl()}/users/current/carts?fields=carts(DEFAULT,potentialProductPromotions,appliedProductPromotions,potentialOrderPromotions,appliedOrderPromotions,entries(totalPrice(formattedValue),product(images(FULL),stock(FULL)),basePrice(formattedValue,value),updateable),totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),subTotal(formattedValue),deliveryItemsQuantity,deliveryCost(formattedValue),totalTax(formattedValue,%20value),pickupItemsQuantity,net,appliedVouchers,productDiscounts(formattedValue),user,saveTime,name,description)`;
    return this.httpClient.get(url);
  }
}
