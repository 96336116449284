import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, I18nModule, provideConfig, UrlModule } from '@spartacus/core';
import { GeneracProductHeaderMenuComponent } from './product-header-menu.component';
import { CarouselModule, MediaModule, OutletModule, PageComponentModule, TabParagraphContainerModule } from "@spartacus/storefront";
import { ProductGridItemModule } from '../../product-list/product-grid-item/product-grid-item.module';

@NgModule({
  imports: [
    CommonModule,
    CarouselModule,
    MediaModule,
    RouterModule,
    UrlModule,
    ProductGridItemModule,
    I18nModule,
    PageComponentModule,
    TabParagraphContainerModule,
    OutletModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        ProductHeaderMenuComponent: {
          component: GeneracProductHeaderMenuComponent,
        },
      },
    }),
  ],
  declarations: [GeneracProductHeaderMenuComponent],
  exports: [GeneracProductHeaderMenuComponent],
})
export class GeneracProductHeaderMenuModule {}
