import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PaginationComponent } from "@spartacus/storefront";

@Component({
  selector: 'generac-pagination',
  templateUrl: './generac-pagination.component.html',
  styleUrls: ['./generac-pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeneracPaginationComponent extends PaginationComponent {}
