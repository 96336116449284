import { isEqual } from "./is-equal";

export function findChangedInnerObject(previous: any, current: any) {
  for (const key in current) {
    if (previous.hasOwnProperty(key)) {
      if (!isEqual(previous[key], current[key])) {
        return { key, changedValue: current[key] };
      }
    }
  }
  return null;
}