<ng-container *ngIf="savedCarts$ | async as savedCarts">
  <ng-container *ngIf="{ showMyCart: showMyCart$ | async } as data">
    <ng-container *ngIf="!(isLoading$ | async); else loading">
      <div
        [attr.aria-label]="'common.loaded' | cxTranslate"
        role="status"
      ></div>
      <div class="cx-saved-cart-list-header">
        <h2>
          {{
            "savedCartList.savedCarts"
              | cxTranslate : { count: savedCarts.length }
          }}
        </h2>
      </div>
      <div
        class="cx-menu-bar"
        role="tablist"
      >
        <button
          [attr.aria-selected]="data.showMyCart"
          [class.active]="data.showMyCart"
          (click)="toggleShowMyCart(true)"
          role="tab"
        >
          {{ "savedCartList.myCart" | cxTranslate }}
        </button>
        <button
          *ngIf="hasSharedCarts$ | async"
          [attr.aria-selected]="!data.showMyCart"
          [class.active]="!data.showMyCart"
          (click)="toggleShowMyCart(false)"
          role="tab"
        >
          {{ "savedCartList.sharedCarts" | cxTranslate }}
        </button>
      </div>
      <ng-container *ngIf="savedCarts?.length > 0; else noSavedCarts">
        <table class="table cx-saved-cart-list-table">
          <thead class="cx-saved-cart-list-thead-mobile">
            <th scope="col">{{ "savedCartList.cartName" | cxTranslate }}</th>
            <th scope="col">{{ "savedCartList.cartId" | cxTranslate }}</th>
            <th
              *ngIf="!data.showMyCart"
              scope="col"
            >
              {{ "savedCartList.owner" | cxTranslate }}
            </th>
            <th scope="col">{{ "savedCartList.dateSaved" | cxTranslate }}</th>
            <th
              scope="col"
              class="cx-saved-cart-list-th-qty"
            >
              {{ "savedCartList.quantity" | cxTranslate }}
            </th>
            <th
              scope="col"
              class="cx-saved-cart-list-th-total"
            >
              {{ "savedCartList.total" | cxTranslate }}
            </th>
            <th scope="col">{{ "savedCartList.actions" | cxTranslate }}</th>
          </thead>
          <tbody>
            <tr
              *ngFor="let savedCart of savedCarts"
              (click)="goToSavedCartDetails(savedCart)"
            >
              <td class="cx-saved-cart-list-cart-name">
                <div class="cx-table-label-mobile cx-saved-cart-list-label">
                  {{ "savedCartList.cartName" | cxTranslate }}
                </div>
                <a
                  [routerLink]="
                    {
                      cxRoute: 'savedCartsDetails',
                      params: { savedCartId: savedCart?.code }
                    } | cxUrl
                  "
                  [queryParams]="{ owner: savedCart?.cartOwner }"
                  class="cx-saved-cart-list-value"
                >
                  {{ savedCart?.name }}</a
                >
              </td>
              <td class="cx-saved-cart-list-cart-id">
                <div class="cx-table-label-mobile cx-saved-cart-list-label">
                  {{ "savedCartList.cartId" | cxTranslate }}
                </div>
                <a
                  [routerLink]="
                    {
                      cxRoute: 'savedCartsDetails',
                      params: { savedCartId: savedCart?.code }
                    } | cxUrl
                  "
                  [queryParams]="{ owner: savedCart?.cartOwner }"
                  class="cx-saved-cart-list-value"
                >
                  {{ savedCart?.code }}
                </a>
              </td>
              <td
                *ngIf="!data.showMyCart"
                class="cx-saved-cart-list-cart-id"
              >
                <div class="cx-table-label-mobile cx-saved-cart-list-label">
                  {{ "savedCartList.owner" | cxTranslate }}
                </div>
                <a
                  [routerLink]="
                    {
                      cxRoute: 'savedCartsDetails',
                      params: { savedCartId: savedCart?.code }
                    } | cxUrl
                  "
                  [queryParams]="{ owner: savedCart?.cartOwner }"
                  class="cx-saved-cart-list-value"
                >
                  {{ savedCart?.cartOwnerName }}
                </a>
              </td>
              <td class="cx-saved-cart-list-date-saved">
                <div class="cx-table-label-mobile cx-saved-cart-list-label">
                  {{ "savedCartList.dateSaved" | cxTranslate }}
                </div>
                <a
                  [routerLink]="
                    {
                      cxRoute: 'savedCartsDetails',
                      params: { savedCartId: savedCart?.code }
                    } | cxUrl
                  "
                  [queryParams]="{ owner: savedCart?.cartOwner }"
                  class="cx-saved-cart-list-value"
                >
                  {{ savedCart?.saveTime | cxDate : "longDate" }}
                </a>
              </td>
              <td class="cx-saved-cart-list-quantity">
                <div class="cx-table-label-mobile cx-saved-cart-list-label">
                  {{ "savedCartList.quantity" | cxTranslate }}
                </div>
                <a
                  [routerLink]="
                    {
                      cxRoute: 'savedCartsDetails',
                      params: { savedCartId: savedCart?.code }
                    } | cxUrl
                  "
                  [queryParams]="{ owner: savedCart?.cartOwner }"
                  class="cx-saved-cart-list-value"
                >
                  {{ savedCart?.entryGroups | countEntryGroupsQuantity }}
                </a>
              </td>
              <td class="cx-saved-cart-list-total">
                <div class="cx-table-label-mobile cx-saved-cart-list-label">
                  {{ "savedCartList.total" | cxTranslate }}
                </div>
                <a
                  [routerLink]="
                    {
                      cxRoute: 'savedCartsDetails',
                      params: { savedCartId: savedCart?.code }
                    } | cxUrl
                  "
                  [queryParams]="{ owner: savedCart?.cartOwner }"
                  class="cx-saved-cart-list-value"
                >
                  {{ savedCart?.totalPrice?.formattedValue }}
                </a>
              </td>
              <td class="cx-saved-cart-list-make-cart-active">
                <div
                  class="cx-table-label-mobile cx-saved-cart-list-label"
                ></div>
                <button
                  #element
                  [disabled]="isAsm"
                  class="link cx-action-link cx-saved-cart-make-active"
                  (click)="openDialog($event, savedCart)"
                >
                  {{ "savedCartList.makeCartActive" | cxTranslate }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
      <!-- NO SAVED CART CONTAINER -->
      <ng-template #noSavedCarts>
        <div class="cx-saved-cart-list-no-saved-carts row">
          <div class="col-sm-12 col-md-6 col-lg-4">
            <div>{{ "savedCartList.notFound" | cxTranslate }}</div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>
  <ng-template #loading>
    <div class="cx-spinner">
      <cx-spinner></cx-spinner>
    </div>
  </ng-template>
</ng-container>
