import { HttpErrorHandler } from "@spartacus/core";
import { GeneracActiveCartService } from "../features/cart/core/facade/active-cart.service";
import { ActiveCartService, CartVoucherAdapter, MultiCartService, ProductImportInfoService } from "@spartacus/cart/base/core";
import { GeneracMultiCartService } from "../features/cart/core/facade/multi-cart.service";
import { GeneracCartEntryAdapter } from "../features/cart/core/connectors/entry/cart-entry.adapter";
import { OccCartEntryAdapter } from "../features/cart/occ/adapters/occ-cart-entry.adapter";
import { OccCartVoucherAdapter } from "../features/cart/occ/adapters/occ-cart-voucher.adapter";
import { OccCartAdapter } from "../features/cart/occ/adapters/occ-cart.adapter";
import { LaunchDialogService, ProductFacetService, ProductListComponentService } from "@spartacus/storefront";
import { CustomLaunchDialogService } from "src/app/services/custom-launch-dialog.service";
import { CustomProductImportInfoService } from "src/app/services/custom-product-import-info.service";
import { CartAdapter } from "../features/cart/core/connectors/cart/cart.adapter";
import { ForbiddenHandler } from "../global-message/http-interceptors/forbidden/forbidden.handler";
import { CustomProductFacetService } from "src/app/services/custom-product-facet.service";
import { CustomProductListComponentService } from "../features/product/product-list";

export const providersConfig = [
  {
    provide: HttpErrorHandler,
    useExisting: ForbiddenHandler,
    multi: true,
  },
  {
    provide: GeneracActiveCartService,
  },
  {
    provide: ActiveCartService,
    useClass: GeneracActiveCartService,
  },
  {
    provide: GeneracMultiCartService,
  },
  {
    provide: MultiCartService,
    useClass: GeneracMultiCartService,
  },
  {
    provide: GeneracCartEntryAdapter,
    useClass: OccCartEntryAdapter,
  },
  {
    provide: CartVoucherAdapter,
    useExisting: OccCartVoucherAdapter,
  },
  {
    provide: CartAdapter,
    useClass: OccCartAdapter,
  },
  {
    provide: LaunchDialogService,
    useExisting: CustomLaunchDialogService,
  },
  {
    provide: ProductImportInfoService,
    useExisting: CustomProductImportInfoService,
  },
  {
    provide: ProductFacetService,
    useExisting: CustomProductFacetService,
  },
  {
    provide: ProductListComponentService,
    useExisting: CustomProductListComponentService,
  },
]