import { MetaReducer, ActionReducer, Action } from '@ngrx/store';
import { CustomProductActions } from '../actions';

function clearProductScope(productScope: any) {
  const cleanProductScope = { ...productScope };
  Object.keys(cleanProductScope).forEach(
    (key) => (cleanProductScope[key] = undefined)
  );
  return cleanProductScope;
}

function clearProductState(action: Action, state: { product: any }) {
  if (action.type === CustomProductActions.CUSTOM_CLEAR_PRODUCT_SCOPE) {
    return {
      ...state,
      product: {
        ...clearProductScope(state.product),
      },
    };
  } else {
    return state;
  }
}

export function clearDetailProductStateMetaReducer(): MetaReducer<any> {
  return (reducer: ActionReducer<any>) => (state, action) => {
    const newState = clearProductState(action, state);
    return reducer(newState, action);
  };
}
