<section class="saved-addresses-section">
    <ng-container *ngIf="savedAddresses$ | async as savedAddresses">
        <div class="row" [class]="{'saved-address-items': savedAddresses?.length > 0}">
            <saved-address-item
                *ngFor="let savedAddress of savedAddresses; trackBy: trackById"
                [savedAddress]="savedAddress"
                [isUserRestricted]="isUserRestricted"
                (savedAddressDeleted)="savedAddressDeleted()"
            ></saved-address-item>
        </div>
    </ng-container>
    <div class="row add-address-wrap">
        <button (click)="openAddSavedAddressModal()" [disabled]="isUserRestricted" class="add-address-btn btn-primary">
            {{ 'savedAddress.addAddress' | cxTranslate}}
        </button>
    </div>
</section>