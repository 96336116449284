import { NgModule } from '@angular/core';
import { CmsConfig, I18nConfig, provideConfig } from "@spartacus/core";
import { orderTranslationChunksConfig, orderTranslations } from "@spartacus/order/assets";
import { OrderRootModule, ORDER_FEATURE, ORDER_NORMALIZER } from "@spartacus/order/root";
import { OrderConfirmationModule } from "./components/order-confirmation/order-confirmation.module";
import { OccOrderNormalizer } from "./occ/adapters/converters/occ-order-normalizer";
import { GeneracOrderDetailsModule } from "./components/order-details/order-details.module";

@NgModule({
  declarations: [],
  imports: [
    OrderRootModule,
    OrderConfirmationModule,
    GeneracOrderDetailsModule,
  ],
  providers: [provideConfig(<CmsConfig>{
    featureModules: {
      [ORDER_FEATURE]: {
        module: () =>
          import('@spartacus/order').then((m) => m.OrderModule),
      },
    }
  }),
  provideConfig(<I18nConfig>{
    i18n: {
      resources: orderTranslations,
      chunks: orderTranslationChunksConfig,
    },
  }),
    {
      provide: ORDER_NORMALIZER,
      useExisting: OccOrderNormalizer,
      multi: true,
    },
  ]
})
export class OrderFeatureModule { }
