<form [formGroup]="itemFormGroup">
    <div *ngFor="let row of formConfig;" class="row">
        <div *ngIf="isSelling && formConfig[0].items[0]?.formControlName == 'firstName' && row.items[0]?.formControlName == 'firstName'"
            class="power-play-mixed-info">
            <cx-icon class="regular-icon grey not-active" [type]="iconTypes.INFO"></cx-icon>
            <span class="info-desc">
                {{ 'checkoutAddress.powerPlayCreationMixedCartInfo' | cxTranslate }}
            </span>
        </div>
        <div *ngFor="let item of row.items;" class="col-md-6 col-lg-6" [cxAppendAnchor]="item.id">
            <label *ngIf="item.type === InputType.TEXT && !item.hidden && (!((isSelling || isRenewal) && item.formControlName == 'purchaseOrderNumber')
                 && !(isRenewal && (item.formControlName == 'firstName' || item.formControlName == 'lastName')))">
                <span class="label-content">
                    {{ item.label | cxTranslate}}<span *ngIf="item.isRequired">*</span>
                </span>
                <input class="form-control" [formControlName]="item.formControlName" [maxlength]="item.maxLength"
                    type="text" placeholder="{{ item.placeholder | cxTranslate }}" value="{{ item.value }}" />
                <cx-form-errors [control]="itemFormGroup.get(item.formControlName)"></cx-form-errors>
            </label>
            <label *ngIf="item.formControlName === 'subscriptionId'">
                <span class="label-content required">{{ 'checkoutAddress.detailsForm.renewSubscription' |
                    cxTranslate
                    }}*</span>
                <ng-select [inputAttrs]="{ required: 'true' }" class="renewal-select" id="renewal-select"
                    formControlName="subscriptionId" [searchable]="true" [clearable]="false" [items]="renewals"
                    bindLabel="firstName" bindValue="subscriptionId" appendTo="body" dropdownPosition="bottom" (change)="onRenewalSelect($event)"
                    placeholder="{{ 'checkoutAddress.detailsForm.renewSubscriptionPlaceholder' | cxTranslate }}">
                    <ng-template class="renewal-select-header" ng-header-tmp>
                        <span>First Name</span>
                        <span>Last Name</span>
                        <span>Expiration Date</span>
                    </ng-template>

                    <ng-template ng-label-tmp let-item="item">
                        <span>{{ item.firstName }}</span>
                        <span>{{ item.lastName }}</span>
                        <span>{{ item.subEndDate | date: 'MM/dd/yy' }}</span>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                        <span class="option-first-name">{{ item.firstName }}</span>
                        <span class="option-last-name">{{ item.lastName }}</span>
                        <span class="option-end-date">{{ item.subEndDate | date: 'MM/dd/yy' }}</span>
                    </ng-template>
                </ng-select>
                <cx-form-errors [control]="itemFormGroup.get('subscriptionId')"></cx-form-errors>
            </label>
            <label *ngIf="item.formControlName === 'userRole'">
                <span class="label-content required">{{ item.label | cxTranslate}}*</span>
                <ng-select class="role-select" id="role-select" formControlName="userRole" [searchable]="false"
                    [clearable]="false" [items]="roles" bindLabel="name" bindValue="code"
                    placeholder="{{ 'checkoutAddress.detailsForm.rolePlaceholder' | cxTranslate }}"></ng-select>
                <cx-form-errors [control]="itemFormGroup.get(item.formControlName)"></cx-form-errors>
            </label>
        </div>
    </div>
    <div *ngIf="product?.type == serviceProductTypes.SELLING" class="selling-note">
        <strong>Please Note:</strong><br>
        <span>Upon successfully placing this order, a PowerPlay user will be created using the name, email address,
            and
            role included above.</span>
    </div>
</form>