import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CmsConfig, I18nModule, UrlModule, provideDefaultConfig } from '@spartacus/core';
import { ApplicableProductsComponent } from './applicable-products.component';
import { MediaModule, PaginationModule } from '@spartacus/storefront';
import { RouterModule } from '@angular/router';
import { GeneracPaginationModule } from 'src/app/shared/components/generac-pagination/generac-pagination.module';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    PaginationModule,
    MediaModule,
    UrlModule,
    RouterModule,
    GeneracPaginationModule,
  ],
  providers: [
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        GeneracWarrantyApplicableProductsComponent: {
          component: ApplicableProductsComponent,
        },
      },
    }),
  ],
  declarations: [ApplicableProductsComponent],
  exports: [ApplicableProductsComponent],
})
export class ApplicableProductsModule {}
