<div class="cx-checkout-btns row">
    <div class="col-md-6 col-lg-6">
        <button class="cx-btn btn btn-block btn-secondary" (click)="goBack()">
            {{ customBackBtnText || (backBtnText | cxTranslate) }}
        </button>
    </div>
    <div class="col-md-6 col-lg-6">
        <button class="cx-btn btn btn-block btn-primary"
            [disabled]="isContinueDisabled"
            (click)="goNext()">
            {{ 'common.continue' | cxTranslate }}
        </button>
    </div>
</div>