import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { CardModule } from '@spartacus/storefront';
import { GeneracOrderOverviewComponent } from './order-overview.component';

@NgModule({
    imports: [CommonModule, I18nModule, CardModule],
    declarations: [GeneracOrderOverviewComponent],
    exports: [GeneracOrderOverviewComponent],
})
export class OrderOverviewModule {}
