<cx-spinner
        *ngIf="(loading$ | async) === LoadingEnum.Loading;"
        class="spinner-overlay"
></cx-spinner>

<ng-container *ngIf="savedCart">
  <ng-container *ngIf="savedCart?.entryGroups?.length > 0; else emptyCartItems">
    <div role="status" [attr.aria-label]="'common.loaded' | cxTranslate"></div>
    <div class="cart-details-wrapper">
      <cx-cart-item-list
        [isSavedCart]="true"
        [groups]="savedCart.entryGroups"
        [cartIsLoading]="!(cartLoaded$ | async)"
        [promotionLocation]="CartLocation.SavedCart"
        [cartId]="savedCart.code"
        [options]="{
          isSaveForLater: false,
          displayAddToCart: true,
          addToCartString: (buyItAgainTranslation$ | async),
          optionalBtn: addToCartBtn
        }"
      ></cx-cart-item-list>
    </div>
  </ng-container>
</ng-container>
<ng-template #emptyCartItems>
  <div class="cx-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>

<ng-template let-ctx #addToCartBtn>
  <cx-add-to-cart
    *ngIf="!ctx.bundleId"
    [productCode]="ctx.item.product?.code"
    [product]="ctx.item.product"
    [showQuantity]="false"
    [options]="ctx.options"
    [bundleId]="ctx.bundleId"
    [isSavedCart]="true"
    (onBundleAdded)="onBundleAdded($event)"
  >
  </cx-add-to-cart>
</ng-template>
