<nav [attr.aria-label]="'common.breadcrumbs' | cxTranslate" tabindex="-1">
  <ol [attr.aria-label]="'common.breadcrumbs' | cxTranslate">
    <li *ngFor="let crumb of crumbs$ | async">
      <a
        [attr.aria-label]="
          'navigation.navigateTo' | cxTranslate: { nav: crumb.label }
        "
        [routerLink]="crumb.link"
        [innerHTML]="crumb.label"
      ></a>
    </li>
  </ol>
</nav>
<!-- Hidden page title for Screen Reader initialized after view to avoid old values -->
<span class="cx-visually-hidden" aria-live="polite" aria-atomic="true">
  {{ lastestTitle$ | async }}
</span>
<div class="breadcrumbs-title" *ngIf="isServicePartsEnabled && isCartOrCheckoutPage; else title">
  <h1>{{ title$ | async }} </h1>
  <ng-container>
    <cx-offline-badge *ngIf="isOfflineMode$ | async as internalCalculation" [internalCalculation]="internalCalculation"></cx-offline-badge>
  </ng-container>
</div>
<p *ngIf="modelNumber$ | async" class="model-number">{{ modelNumber$ | async }}</p>

<ng-template #title>
  <h1>{{ title$ | async }} </h1>
</ng-template>
