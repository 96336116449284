<a
        *ngIf="product"
        [routerLink]="['/product/' + product.code + '/' + transformNameToUrl(product.name)]"
        class="bundle-carousel-item"
>
    <cx-media
            class="bundle-carousel-item-image"
            [container]="product.images[0]"
            [alt]="product.name"
            format="product"
    ></cx-media>
    <div class="bundle-carousel-item-description">
        <p class="bundle-carousel-item-name">{{product.name}}</p>
        <p class="bundle-carousel-item-price">{{ product.price?.value | currency }}</p>
    </div>
</a>
