import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { CustomMessageType } from "./custom-message-type";
import { BehaviorSubject, fromEvent, merge, NEVER, Observable, Subject, Subscription, timer } from "rxjs";
import { filter, mapTo, scan, switchMap, take, tap } from "rxjs/operators";

@Component({
  selector: 'cx-custom-message',
  templateUrl: './custom-message.component.html',
  styleUrls: ['./custom-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomMessageComponent implements OnInit, OnDestroy {
  @Input() type = CustomMessageType.Warning;
  @Input() set messages(messages: string[]) {
    this._messages = messages;
    this.newMessage.next(true);
  };
  @Input() isPermanent = false;
  @Input() isCornered = false;
  @Input() isIconVisible = false;
  @Input() timeout = 30;
  @Input() hideCloseButton: boolean = false;

  _messages: string[];
  private currentTimerValue: number;
  private newMessage = new BehaviorSubject<boolean>(true);
  private subscription = new Subscription();

  constructor(
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.subscribeShutDownTimer();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  trackById(index: number, item: string) {
    return item;
  }

  clearMessages() {
    this._messages = [];
    this.currentTimerValue = 0;
  }

  prolongMessageTime() {
    if (this.isPermanent) {
      return;
    }
  }

  private subscribeShutDownTimer() {
    if (this.isPermanent) {
      return;
    }
    this.subscription.add(
      merge(
        this.newMessage,
        fromEvent(this.elementRef.nativeElement, 'mouseleave').pipe(mapTo(true)),
        fromEvent(this.elementRef.nativeElement, 'mouseover').pipe(mapTo(false))
      ).pipe(
        filter(() => !!this._messages?.length),
        switchMap(isTicking => isTicking ? this.getCurrentTimer() : NEVER),
      ).subscribe(() => {
        this._messages = [];
        this.currentTimerValue = 0;
        this.cdr.markForCheck();
      })
    )
  }

  private getCurrentTimer(): Observable<any> {
    if (this.currentTimerValue) {
      return this.createTimer(this.currentTimerValue);
    }
    return this.createTimer();
  }

  private createTimer(timerEnd: number = this.timeout * 1000): Observable<number> {
    this.currentTimerValue = this.currentTimerValue || timerEnd;
    return timer(0, 1000)
      .pipe(
        scan((acc, value, index) => {
          this.currentTimerValue = acc;
          return acc - 1000;
        }, this.currentTimerValue),
        filter(value => value <= 0),
        take(1)
      )
  }

}
