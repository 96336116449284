/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { AfterContentChecked, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ProductListItemContextSource } from '../model/product-list-item-context-source.model';
import { ProductListItemContext } from '../model/product-list-item-context.model';
import { LaunchDialogService, ProductListOutlets } from '@spartacus/storefront';
import { ProductExtended } from "../../../../../interfaces/product";
import { UserAccountFacade } from "@spartacus/user/account/root";
import { filter, take, takeUntil, tap } from "rxjs/operators";
import { Subject } from "rxjs";
import { TranslationService, User, WindowRef } from "@spartacus/core";
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { normalizeSlug } from '../../helpers/slug.helper';
import { UnavailabilityDetailType } from "../../../../../enums/unavailability-detail-type.enum";
import { ContactFactoryInfo } from "../../../../../interfaces/contact-info";
import { TooltipPosition } from "../../../../../enums/tooltip-position.enum";

@Component({
  selector: 'cx-product-grid-item',
  templateUrl: './product-grid-item.component.html',
  styleUrls: ['./product-grid-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ProductListItemContextSource,
    {
      provide: ProductListItemContext,
      useExisting: ProductListItemContextSource,
    },
  ],
})
export class ProductGridItemComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  readonly ProductListOutlets = ProductListOutlets;
  @Input() isSubs = false;
  @Input() isAddModal = false;

  _phoneNumberOrEmail: string;
  get phoneNumberOrEmail(): string {
    return this._phoneNumberOrEmail;
  }
  @Input() set phoneNumberOrEmail(value: string) {
    this.contactForPricingHref = value?.includes('@') ? `mailto: ${value}` : `tel: ${value}`;
    this._phoneNumberOrEmail = value;
  }
  @Input() tooltipMessage: string;
  @Input() gaProductCategories: {name: string}[];
  @Input() gaListName: string;

  callForPricingMessage = '';
  contactForPricingHref: string;
  TooltipPosition = TooltipPosition;
  isServicePartsEnabled = false;
  showMoreNotes: boolean = true;
  materialNotesLength: number;

  _product: any;
  get product(): any {
    return this._product;
  }
  @Input() set product(value: any) {
    if (this.isSubs) {
      const product = value.images?.find((i: any) => i.format === 'product');
      const thumbnail = value.images?.find((i: any) => i.format === 'thumbnail');
      value.images = {
        PRIMARY: {
          product,
          thumbnail
        }
      };
      value.slug = normalizeSlug(value.name);
    }

    this._product = value;
  }

  @Output() addToCart: EventEmitter<ProductExtended> = new EventEmitter();
  @Output() onAddModalAdded = new EventEmitter<{productCode: string, quantity: string}>();
  protected readonly UnavailabilityDetailType = UnavailabilityDetailType;
  private destroy$ = new Subject();
  private user: User;

  constructor(
    protected productListItemContextSource: ProductListItemContextSource,
    protected googleAnalyticsService: GoogleAnalyticsService,
    protected translation: TranslationService,
    protected winRef: WindowRef,
    private userAccount: UserAccountFacade,
    private launchDialogService: LaunchDialogService,
    private elRef: ElementRef,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnChanges(changes?: SimpleChanges): void {
    if (changes?.product && !this.isSubs) {
      this.productListItemContextSource.product$.next(this.product);
    }
  }

  ngOnInit(): void {
    this.getUser();
    this.isServicePartsEnabled = JSON.parse(this.winRef.localStorage.getItem('isServicePartsEnabled'));
  }

  ngAfterViewInit(): void {
    const test = this.elRef.nativeElement.getElementsByClassName('sp-note');
    if(test.length > 0) {
      this.materialNotesLength = test[0].textContent.length;
      this.cdr.detectChanges();
    }
  }
 
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  selectItem(item: ProductExtended): void {
    const product = {
      ...item,
      categories: this.gaProductCategories,
    };
    this.googleAnalyticsService.sendGaEvent('select_item', {
      items: [ this.googleAnalyticsService.mapProductToGaItem(product, 0, undefined, this.gaListName, this.gaListName) ],
      item_list_name: this.gaListName || '',
      item_list_id: this.gaListName ? this.gaListName.toLowerCase().trim().replace(/ /g, '_') : '',
      currency: product.price?.currencyIso,
    });
  }

  openContactFactory() {
    const contactFactoryInfo: ContactFactoryInfo = {
      accountNumber: this.user.orgUnit.uid,
      companyName: this.user.orgUnit.name,
      supportPhoneNumber: '1-800-883-7535',
      subject: 'Service Part Contact Request',
      itemNumber: this.product.code,
      itemDescription: this.product.name
    };

    const dialog = this.launchDialogService.openDialog(
      'CONTACT_FACTORY',
      undefined,
      undefined,
      contactFactoryInfo
    );

    if (dialog) {
      dialog.pipe(take(1)).subscribe();
    }
      this.launchDialogService.dialogClose
        .pipe(takeUntil(this.destroy$))
        .subscribe()
  }

  toggleMoreLessNotes() {
    this.showMoreNotes = !this.showMoreNotes;
  }

  private getUser(): void {
    this.userAccount.get()
        .pipe(
            takeUntil(this.destroy$),
            filter(Boolean),
            tap(user => this.user = user)
        )
        .subscribe()
  }

}
