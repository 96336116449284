import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { CmsProductReferencesComponent, OccEndpointsService, Product } from '@spartacus/core';
import { Observable, Subscription, combineLatest, of } from 'rxjs';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { CmsComponentData, CurrentProductService } from "@spartacus/storefront";
import { BaseStoreService } from 'src/app/services/base-store.service';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { HttpClient } from '@angular/common/http';
import { normalizeSlug } from '../../helpers/slug.helper';
import { isEmpty } from "../../../cart/core/utils/utils";
import { GaItem, GoogleAnalyticsService } from 'src/app/services/google-analytics.service';

@Component({
  selector: 'cx-product-suggestions',
  templateUrl: './product-suggestions.component.html',
  styleUrls: ['product-suggestions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductSuggestionsComponent {
  tooltipMessage: string;
  phoneOrEmail: string;
  gaListName: string;
  gaItems: GaItem[] = [];

  private subscription = new Subscription();

  constructor(
    protected cmsComponentData: CmsComponentData<any>,
    protected currentProductService: CurrentProductService,
    protected googleAnalyticsService: GoogleAnalyticsService,
    private baseStoreService: BaseStoreService,
    private cdr: ChangeDetectorRef,
    private activeCartFacade: ActiveCartFacade,
    private occEndpoints: OccEndpointsService,
    private http: HttpClient,
  ) {
    this.subscription.add(
      combineLatest([
        this.baseStoreService.getTooltipMessage(),
        this.baseStoreService.getPhoneNumber()
      ]).subscribe(([messsage, phoneOrEmail]) => {
        this.tooltipMessage = messsage?.message;
        this.phoneOrEmail = phoneOrEmail?.contactInfo;
        this.cdr.markForCheck();
      })
    );
  }

  protected get componentData$(): Observable<CmsProductReferencesComponent> {
    return this.cmsComponentData.data$.pipe(filter((data) => Boolean(data)));
  }

  /**
   * Returns an Observable String for the title
   */
  get title$(): Observable<string | undefined> {
    return this.componentData$.pipe(map((data) => data?.title));
  }

  /**
   * Observable with an Array of Observables. This is done so that
   * the component UI could consider to lazy load the UI components when they're
   * in the viewpoint.
   */
  items$: Observable<Product | any> = this.activeCartFacade.getActive()
    .pipe(
      filter(cart => !isEmpty(cart)),
      switchMap(cart => this.http.get(`${this.occEndpoints.getBaseUrl()}/users/current/carts/${cart.code}/suggestions`)
        .pipe(
          withLatestFrom(this.title$),
          map(([suggestions, title]) => {
            const products = (suggestions as any)?.products;
            this.gaListName = title;
            if(products?.length > 0) {
              for(const [index, product] of products.entries()) {
                this.gaItems.push(
                  this.googleAnalyticsService.mapProductToGaItem(product, index, undefined, this.gaListName)
                );
                if (this.gaItems.length == products.length) {
                  this.googleAnalyticsService.sendGaEvent('view_item_list', {
                    items: this.gaItems,
                    item_list_name: this.gaListName || undefined,
                    item_list_id: this.gaListName ? this.gaListName.toLowerCase().trim().replace(/ /g, '_') : undefined,
                  });
                  this.gaItems = [];
                }
              }
            }
            return products?.map((pr: any) => {
              const product = pr.images?.find((i: any) => i.format === 'product');
              const thumbnail = pr.images?.find((i: any) => i.format === 'thumbnail');
              pr.images = {
                PRIMARY: {
                  product,
                  thumbnail
                }
              };
              return of({...pr, routerLink: `/product/${pr.code}/${normalizeSlug(pr.name)}`});
            });
          })
        )
      ),
    )
}
