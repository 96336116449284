import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, provideConfig, UrlModule } from '@spartacus/core';
import { CarouselModule, MediaModule } from "@spartacus/storefront";
import { ProductGridItemModule } from '../../product-list/product-grid-item/product-grid-item.module';
import { ProductSuggestionsComponent } from './product-suggestions.component';
import { ProductCarouselModule } from '../product-carousel/product-carousel.module';

@NgModule({
  imports: [
    CommonModule,
    CarouselModule,
    MediaModule,
    ProductCarouselModule,
    RouterModule,
    UrlModule,
    ProductGridItemModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CartSuggestionComponent: {
          component: ProductSuggestionsComponent,
        },
      },
    }),
  ],
  declarations: [ProductSuggestionsComponent],
  exports: [ProductSuggestionsComponent],
})
export class ProductSuggestionsModule {}
