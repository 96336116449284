import { NgModule } from '@angular/core';
import { ProductBundleListComponent } from "./product-bundle-list.component";
import { PaginationModule, SpinnerModule } from "@spartacus/storefront";
import { ConfigModule, PageMetaResolver, provideConfig } from "@spartacus/core";
import { BundleSlideModule } from "../../shared/components/bundle-slide/bundle-slide.module";
import { RouterModule } from "@angular/router";
import { CommonModule } from '@angular/common';
import { ProductBundleListResolver } from "./product-bundle-list.resolver";

@NgModule({
  declarations: [ProductBundleListComponent],
  imports: [
    CommonModule,
    SpinnerModule,
    BundleSlideModule,
    RouterModule,
    PaginationModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ProductBundleListComponent: {
          component: ProductBundleListComponent,
        },
      },
    }),
  ],
  providers: [
    provideConfig({
      layoutSlots: {
        BundlesPageTemplate: {
          lg: {
            pageFold: 'UpSelling',
          },
          pageFold: 'Summary',
          slots: [
            'BundleListSlot',
            'PlaceholderContentSlot'
          ],
        },
      },
    }),
    {
      provide: PageMetaResolver,
      useExisting: ProductBundleListResolver,
      multi: true,
    },
  ]
})
export class ProductBundleListModule { }
