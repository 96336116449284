import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { CmsService, CmsProductCarouselComponent as model, PageContext, PageType, Product, ProductScope, ProductService } from '@spartacus/core';
import { Observable, Subject, merge } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { CmsComponentData } from '@spartacus/storefront';
import { CustomerService } from 'src/app/services/customer.service';
import { CustomProductStateService } from 'src/app/services/custom-product-state.service';
import { GaItem, GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'cx-product-carousel',
  templateUrl: './product-carousel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCarouselComponent implements OnInit, OnDestroy {
  gaItems: GaItem[] = [];
  gaListName: string;

  protected readonly PRODUCT_SCOPE = [
    ProductScope.DETAILS,
  ];

  private componentData$: Observable<model> = this.componentData.data$.pipe(
    filter((data) => Boolean(data))
  );

  /**
   * returns an Observable string for the title.
   */
  title$: Observable<string | undefined> = this.componentData$.pipe(
    map((data) => data.title)
  );

  /**
   * Observable that holds an Array of Observables. This is done, so that
   * the component UI could consider to lazy load the UI components when they're
   * in the viewpoint.
   */
  items$: Observable<Observable<Product | undefined>[]> =
    this.componentData$.pipe(
      map((data) => {
        this.gaListName = data.title;
        return data.productCodes?.trim().split(' ') ?? []
      }),
      map((codes) =>
        codes.map((code) =>
          this.productService.get(code, [...this.PRODUCT_SCOPE])
            .pipe(
              tap((product) => {
                if (product) {
                  this.gaItems.push(
                    this.googleAnalyticsService.mapProductToGaItem(product, this.gaItems.length, undefined, this.gaListName)
                  );
                  if (this.gaItems.length == codes.length) {
                    this.googleAnalyticsService.sendGaEvent('view_item_list', {
                      items: this.gaItems
                    });
                    this.gaItems = [];
                  }
                }
              })
            )
        )
      )
    );

  private unsubscribe$ = new Subject();

  constructor(
    protected componentData: CmsComponentData<model>,
    protected productService: ProductService,
    protected cmsService: CmsService,
    protected customerService: CustomerService,
    protected customProductStateService: CustomProductStateService,
    protected googleAnalyticsService: GoogleAnalyticsService,
    protected activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    merge(
      this.componentData.data$,
      this.customerService.getB2bUnitChanged().pipe(
        map(() => {
          let pageContext: PageContext;
          const snapshotUrl = this.activatedRoute.snapshot;
          if (snapshotUrl?.data) {
            pageContext = {
              id: snapshotUrl?.params?.productCode ? snapshotUrl?.params?.productCode : snapshotUrl?.params?.categoryCode,
              type: snapshotUrl.data?.cxRoute === 'category' ? PageType.CATEGORY_PAGE : PageType.PRODUCT_PAGE,
            }
          } else {
            pageContext = {
              id: 'current',
            }
          }

          return this.cmsService.refreshComponent(this.componentData.uid, pageContext);
        }),
        tap(() => this.customProductStateService.clearProductScope())
      )
    )
      .pipe(filter(Boolean), takeUntil(this.unsubscribe$))
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
