import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { ICON_TYPE } from '@spartacus/storefront';
import { ServiceProductType } from 'src/app/enums/service-product-type';
import { Observable, Subscription, of, timer } from 'rxjs';
import { catchError, distinctUntilChanged, finalize, first, map, switchMap, take, tap } from 'rxjs/operators';
import { User } from '@auth0/auth0-angular';
import { Renewal, Role } from 'src/app/interfaces/subscription';
import { LoadingEnum } from 'src/app/enums/loading.enum';
import { InputType } from 'src/app/enums/input-type.enum';
import { formConfig, renewalConfig, sellingConfig } from '../../../../../configs/address-details-form-config';
import { FormConfig, FormConfigItem, UserRoleValue } from '../../../../../../interfaces/address-details';
import { AsyncValidation } from 'src/app/enums/async-validation.enum';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';
import { UserAccountFacade } from '@spartacus/user/account/root';

@Component({
  selector: 'cx-address-details-form',
  templateUrl: './address-details-form.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AddressDetailsFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() product: any;
  @Input() formGroup: FormGroup;
  @Input() checkoutInfo: any;
  @Input() index: number;
  @Output() loaded = new EventEmitter<LoadingEnum>();
  renewals: Renewal[] | null;
  isSelling: boolean;
  isRenewal: boolean;
  roles: Role[] | null;
  formConfig: FormConfig[] = [];
  InputType = InputType;
  iconTypes = ICON_TYPE;
  userAccount$: Observable<User> = this.userAccount.get();
  itemFormGroup: FormGroup = new FormGroup({});
  public get serviceProductTypes(): typeof ServiceProductType {
    return ServiceProductType;
  }

  private subscriptions = new Subscription();

  constructor(
    protected subscriptionsService: SubscriptionsService,
    private userAccount: UserAccountFacade,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.initConfig();
    this.initForm(this.checkoutInfo);
    this.getRenewalsOrRolesByProductType();
  }

  ngAfterViewInit(): void {
    this.loaded.emit(LoadingEnum.Loaded);
  }

  public initForm(checkoutInfo: any) {
    let controlToHighlight: string;

    if (this.formGroup.get(['subscriptions']) && this.itemFormGroup) {
      (this.formGroup.get(['subscriptions']) as FormArray).push(this.itemFormGroup as FormGroup);
    } else {
      this.formGroup.addControl('order', this.itemFormGroup);
    }

    this.formConfig.forEach(field => {
      field.items.forEach(item => {
        const value = this.getFormGroupValue({ ...checkoutInfo, ... this.product }, item);

        const validators = item.validators?.length ? item.validators : [];
        const asyncValidator = null || this.selectAsyncValidator(item.asyncValidator);

        if (item.isRequired) {
          validators.push(Validators.required);
        }
        this.itemFormGroup.addControl(item.formControlName, new FormControl(value, { validators, asyncValidators: asyncValidator, updateOn: 'blur' }));
      });
    });

    if (controlToHighlight) {
      this.formGroup.get(controlToHighlight).markAsDirty();
    }

    this.cdr.markForCheck();
  }

  onRenewalSelect(value: any) {
    if (value.firstName) {
      this.itemFormGroup.get('firstName').setValue(value.firstName);
    }
    if (value.lastName) {
      this.itemFormGroup.get('lastName').setValue(value.lastName);
    }
    if (value.email) {
      this.itemFormGroup.get('email').setValue(value.email);
    }
  }

  private selectAsyncValidator(asyncValidator: AsyncValidation) {
    switch (asyncValidator) {
      case AsyncValidation.Email:
        return this.validateSubscriptionEmail();
      default:
        return null;
    }
  }

  private validateOtherEmails(control: AbstractControl, emailsFromSubs: Array<string>) {
    const otherEmailsFromSubs = (this.formGroup.getRawValue().subscriptions as Array<any>)
      .filter((subs: any) => !!subs && subs?.type == ServiceProductType.SELLING && subs?.email !== control.value)
      .map((subs: any) => subs?.email?.length ? subs?.email : null);
    const formControls = (this.formGroup.controls['subscriptions'] as FormArray).controls as Array<FormGroup<any>>;
    otherEmailsFromSubs.forEach((item) => {
      const indexOfDuplicateInSubs = emailsFromSubs.indexOf(item);
      if (indexOfDuplicateInSubs > -1) {
        formControls?.filter((subs: any) => !!subs && subs?.value.type == ServiceProductType.SELLING).forEach(
          (formGroup: FormGroup, index) => {
            if (indexOfDuplicateInSubs == index) {
              formGroup.get('email')?.setErrors(null);
            }
          });
      }
    })
  }

  private validateDuplicateEmails(control: AbstractControl) {
    if (this.formGroup.getRawValue().subscriptions?.length > 0) {
      const emailsList = (this.formGroup.getRawValue().subscriptions as Array<any>)
        .filter((subs: any) => !!subs && subs?.type == ServiceProductType.SELLING)
        .map((subs: any) => subs?.email?.length ? subs?.email : null);
      const matches = emailsList.filter(e => e == control.value);
      let message;
      if (matches.length > 1) {
        const indexOfDuplicateInEmailsList = emailsList?.indexOf(control.value);
        message = indexOfDuplicateInEmailsList > -1 ? { duplicate: true } : null;
      }
      this.validateOtherEmails(control, emailsList);
      control.markAsTouched();
      control.setErrors(null);
      return message;
    }
    return null;
  }

  private validateSubscriptionEmail() {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      const duplicateMessage = this.validateDuplicateEmails(control);
      if (duplicateMessage) {
        return of(duplicateMessage);
      }
      return timer(300)
        .pipe(
          switchMap(() => {
            return this.subscriptionsService.validateEmail(control.value, this.product?.materialNumber)
              .pipe(catchError((err) => { return null }))
          }),
          map((message: string) =>
            message ? message.includes('This email address is already in use') ? { duplicate: message } : { email: true } : null
          ),
          finalize(() => {
            this.itemFormGroup.markAsTouched();
            this.cdr.markForCheck()
          })
        );
    }
  }

  private initConfig(): void {
    switch (this.product?.type) {
      case ServiceProductType.SELLING:
        this.formConfig = sellingConfig as FormConfig[];
        this.isSelling = true;
        return;
      case ServiceProductType.RENEWAL:
        this.formConfig = renewalConfig as FormConfig[];
        this.isRenewal = true;
        return;
      default:
        this.formConfig = formConfig as FormConfig[];
        return;
    }
  }

  private getFormGroupValue(info: any, item: FormConfigItem): string | UserRoleValue {
    if (info) {
      switch (item?.formControlName) {
        case 'userRole':
          return info[item?.formControlName]?.code;
        default:
          return info[item?.formControlName]
      }
    }
    return item.value;
  }

  private getRenewalsOrRolesByProductType(): void {
    if (this.product?.type === ServiceProductType.RENEWAL) {
      this.getRenewals();
    } else if (this.product?.type === ServiceProductType.SELLING) {
      this.getRoles();
    }
  }

  private getRoles(): void {
    this.subscriptions.add(this.userAccount$
      .pipe(
        take(1),
        switchMap((user: User) => this.subscriptionsService.getUserRoles(user?.uid)),
        tap(roles => {
          this.roles = roles.userRoles;
          this.cdr.detectChanges();
        })
      )
      .subscribe());
  }

  private getRenewals(): void {
    this.subscriptions.add(this.userAccount$
      .pipe(
        take(1),
        switchMap((user: User) => this.subscriptionsService.getCurrentSubscriptions(user?.uid, this.product?.materialNumber)),
        tap(renewals => {
          if (renewals) {
            this.renewals = renewals;
            this.cdr.detectChanges();
          }
        })
      )
      .subscribe());
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
