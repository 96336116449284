import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { B2BUnit, EventService, OccEndpointsService } from '@spartacus/core';
import { B2BUnitExtended } from '../interfaces/login';
import { map, tap } from 'rxjs/operators';
import { CartActions } from '@spartacus/cart/base/core';
import { Store } from '@ngrx/store';
import { UserAccountChangedEvent } from '@spartacus/user/account/root';
@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  private currentB2bUnit$: BehaviorSubject<string> = new BehaviorSubject('');
  private b2bUnitChanged$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  constructor(
    private httpClient: HttpClient,
    protected store: Store,
    protected eventService: EventService,
    private occEndpoints: OccEndpointsService,
  ) {}

  public getB2BUnits(): Observable<B2BUnitExtended[]> {
    const url = `${this.occEndpoints.getBaseUrl()}/B2BUnits`;
    return this.httpClient
      .get(url)
      .pipe(map((val: { b2BUnit?: B2BUnit[] }) => val?.b2BUnit));
  }

  public setB2BUnit(unitId: string, userId: string): Observable<any> {
    localStorage.setItem('companyNumber', unitId);
    this.setCurrentB2bUnit(unitId);
    const url = `${this.occEndpoints.getBaseUrl()}/users/${userId}/B2BUnits/unit/${unitId}`;
    return this.httpClient.post(url, {}).pipe(
      tap(() => {
        this.eventService.dispatch({ user: {} }, UserAccountChangedEvent);
        this.store.dispatch(new CartActions.ClearCartState());
        this.b2bUnitChanged$.next(true);
      })
    );
  }

  public getB2bUnitChanged(): Observable<boolean> {
    return this.b2bUnitChanged$.asObservable();
  }

  public getCurrentB2bUnit(): Observable<string> {
    return this.currentB2bUnit$.asObservable()
      .pipe(map(unit => unit || localStorage.getItem('companyNumber')));
  }

  public setCurrentB2bUnit(unitId: string): void {
    this.currentB2bUnit$.next(unitId);
    localStorage.setItem('B2BChanged', 'true');
  }
}
