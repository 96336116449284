/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, I18nModule, provideDefaultConfig } from '@spartacus/core';
import { FooterNavigationComponent } from './footer-navigation.component';
import { GenericLinkModule, IconModule } from '@spartacus/storefront';
import { CustomNavigationUiComponent } from "./custom-navigation-ui/custom-navigation-ui.component";
import { SharedModule } from "../../../shared/shared.module";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    GenericLinkModule,
    I18nModule,
    IconModule,
    SharedModule
  ],
  providers: [
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        GeneracFooterNavigationComponent: {
          component: FooterNavigationComponent,
        },
      },
    }),
  ],
  declarations: [FooterNavigationComponent, CustomNavigationUiComponent],
  exports: [FooterNavigationComponent, CustomNavigationUiComponent],
})
export class FooterNavigationModule {}
