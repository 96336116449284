import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { I18nModule, TranslationService, UrlModule } from '@spartacus/core';
import { combineLatest, Observable } from 'rxjs';
import { HandlingOption, ShippingAccount, ShippingGroup } from 'src/app/interfaces/cart';
import { CheckoutDeliveryGroupsComponent } from 'src/app/spartacus/features/checkout/components/checkout-delivery-groups/checkout-delivery-groups.component';
import { ShippingAccountType } from 'src/app/enums/shipping-account-type.enum';
import { Card, CardModule } from '../card';
import { map } from 'rxjs/operators';
import { CheckoutStepTypeExtended } from 'src/app/enums/checkout-step-type';
import { CheckoutStepType } from '@spartacus/checkout/base/root';
import { CartType } from 'src/app/enums/cart-type.enum';
import { CheckoutStepService } from '@spartacus/checkout/base/components';
import { RouterModule } from '@angular/router';
import { CheckoutService } from 'src/app/services/checkout.service';

@Component({
  selector: 'generac-shipping-groups',
  templateUrl: './generac-shipping-groups.component.html',
  styleUrls: ['./generac-shipping-groups.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    UrlModule,
    RouterModule,
    I18nModule,
    CheckoutDeliveryGroupsComponent,
  ]
})
export class GeneracShippingGroupsComponent {
  @Input() shippingGroups: ShippingGroup[];
  @Input() isOrderConfirmPage: boolean = false;
  checkoutStepTypeServiceParts = CheckoutStepTypeExtended.SERVICE_PARTS;
  checkoutStepTypeSpecialHandling = CheckoutStepTypeExtended.SPECIAL_HANDLING;

  constructor(
    protected translationService: TranslationService,
    protected checkoutStepService: CheckoutStepService,
    private checkoutService: CheckoutService,
  ) {

  }

  getSpecialHandlingCard(handlingOptions: HandlingOption[]): Observable<Card> {
    return combineLatest([
        this.translationService.translate('checkoutProgress.specialHandlingTab'),
    ]).pipe(
        map(([textTitle]) => {
            return {
                title: textTitle,
                text: handlingOptions.map(option => option.name),
          } as Card;
        })
    );
  }

  getSpCarrierCard(shippingGroup: ShippingGroup): Observable<Card> {
    const data = this.getSpShippingInfo(shippingGroup.generacShippingAccounts, shippingGroup.dealerShippingAccounts);
      return combineLatest([
          this.translationService.translate(data?.selectedShippingAccount == ShippingAccountType.GENERAC ? 
            'checkoutReview.generacPrimaryCarrier' : 'checkoutReview.dealerPrimaryCarrier'),
      ]).pipe(
          map(([textTitle]) => {
              return {
                  title: textTitle,
                  text: [data?.selectedCarrier],
            } as Card;
          })
      );
  }

  getSpShippingConditionsCard(shippingGroup: ShippingGroup): Observable<Card> {
    const data = this.getSpShippingInfo(shippingGroup.generacShippingAccounts, shippingGroup.dealerShippingAccounts);
    return combineLatest([
        this.translationService.translate('checkoutReview.shippingConditions'),
    ]).pipe(
        map(([textTitle]) => {
            return {
                title: textTitle,
                text: [data?.selectedShippingCondition],
          } as Card;
        })
    );
  }

  getCheckoutStepUrl(stepType: CheckoutStepType | string, shippingGroupName?: string): string | undefined {
    const step = this.checkoutStepService.getCheckoutStep(
        stepType as CheckoutStepType
    );
    if(stepType == this.checkoutStepTypeSpecialHandling && shippingGroupName) {
      return shippingGroupName == CartType.FINISHED_GOODS ? 'checkoutFinishedGoods' : 'checkoutServiceParts';
    }
    return step?.routeName;
  }

  getShippingGroupDisplayName(cartType: string): string {
    return this.checkoutService.getShippingGroupDisplayName(cartType);
  }

  private getSpShippingInfo(generacShippingAccounts: ShippingAccount[], dealerShippingAccounts: ShippingAccount[]): any {
    let selectedDeliveryOption: ShippingAccount;
    let selectedShippingAccount: ShippingAccountType;
    [generacShippingAccounts, dealerShippingAccounts].forEach(carrier => {
      if (carrier) {
        carrier.forEach(shippingAccount => {
          const selectedShippingCondition = shippingAccount.shippingConditions?.find(sc => sc?.selected);
          if (selectedShippingCondition) {
            selectedDeliveryOption = {
              ...shippingAccount,
              shippingConditions: [selectedShippingCondition]
            }
          }
        })
      }
    });
    const selectedCarrier = selectedDeliveryOption?.vendorName || generacShippingAccounts[0].vendorName;
    const selectedShippingCondition = selectedDeliveryOption?.shippingConditions[0].shippingConditionName || 
      generacShippingAccounts[0].shippingConditions[0].shippingConditionName;
    if (selectedDeliveryOption) {
      selectedShippingAccount = !!selectedDeliveryOption?.generacShippingAccount ?
        ShippingAccountType.GENERAC : ShippingAccountType.DEALER;
    }
    return {
      selectedShippingAccount,
      selectedCarrier,
      selectedShippingCondition,
    }
  }
}
