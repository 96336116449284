<div class="cx-order-items container" *ngIf="order$ | async as order">
    <h4 class="cx-order-items-header">
        {{ 'checkoutOrderConfirmation.orderItems' | cxTranslate }}
    </h4>

    <cx-cart-item-list
        [groups]="order.entryGroups"
        [readonly]="true"
        [promotionLocation]="promotionLocation"
    ></cx-cart-item-list>
</div>
