import { Cart } from '@spartacus/cart/base/root';
import { Observable } from 'rxjs';
import { CartExtended } from 'src/app/interfaces/cart';

export abstract class SavedCartAdapter {
  /**
   *
   * Abstract method used to load a single saved cart
   */
  abstract load(userId: String, cartId: String, cartOwner: string): Observable<Cart>;
  /**
   *
   * Abstract method used to load a list of saved carts
   */
  abstract loadList(userId: string): Observable<Cart[]>;
  /**
   *
   * Abstract method used to restore a saved cart to an active cart
   */
  abstract restoreSavedCart(userId: string, cartId: string, cartOwner: string): Observable<CartExtended>;
  /**
   *
   * Abstract method used to clone a saved cart
   */
  abstract cloneSavedCart(
    userId: string,
    cartId: string,
    saveCartName?: string,
    cartOwner?: string
  ): Observable<Cart>;
}
