import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription, combineLatest } from "rxjs";
import { ProductService } from 'src/app/services/product.service';
import { filter, map, take, tap } from 'rxjs/operators';
import { CmsComponentData, CurrentProductService } from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { ConverterService, PRODUCT_NORMALIZER, Product, User } from '@spartacus/core';
import { ProductExtended } from 'src/app/interfaces/product';
import { BaseStoreService } from 'src/app/services/base-store.service';
import { GaItem, GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { GaListNames } from 'src/app/enums/ga-list-names.enum';

@Component({
  selector: 'generac-product-warranties',
  templateUrl: './generac-product-warranties.component.html',
  styleUrls: ['./generac-product-warranties.component.scss'],
})
export class GeneracProductWarrantiesComponent implements OnInit, OnDestroy {
  warranties$: Observable<ProductExtended[]>;
  subscription = new Subscription();
  user: User;
  phoneOrEmail: any;
  tooltipMessage: any;
  title$: Observable<string | undefined> | undefined =
    this.component?.data$.pipe(map((data) => data.title));
  warrantiesTooltipMessage: string;
  warrantiesTooltipText: string;
  gaItems: GaItem[] = [];
  gaListName: GaListNames = GaListNames.WARRANTIES;

  constructor(
    public component: CmsComponentData<any>,
    protected productService: ProductService,
    protected currentProductService: CurrentProductService,
    protected baseStoreService: BaseStoreService,
    protected googleAnalyticsService: GoogleAnalyticsService,
    private userAccount: UserAccountFacade,
    private cdr: ChangeDetectorRef,
    private converter: ConverterService,
  ) {
    this.subscribeToUser();
    this.subscribeToContactInfoAndTooltip();
  }

  ngOnInit(): void {
    this.warranties$ = combineLatest([this.currentProductService.getProduct(), this.component?.data$]).pipe(
      take(1),
      map(([product, componentData]) => {
        const productExt = product as ProductExtended;
        const maximumNumberProducts = Number(componentData.maximumNumberProducts);
        this.warrantiesTooltipText = componentData.warrantyTooltipMessage;
        const warranties: ProductExtended[] = productExt?.warranties.map((product: Product) => {
          return this.converter.convert({
            ...product,
            productCode: product.code,
          }, PRODUCT_NORMALIZER);
        });
        if(warranties.length > 0) {
          for(let [index, product] of warranties.slice(0, maximumNumberProducts).entries()) {
            this.gaItems.push(
              this.googleAnalyticsService.mapProductToGaItem(product, index, undefined, this.gaListName)
            );
            if (this.gaItems.length == warranties.slice(0, maximumNumberProducts).length) {
              this.googleAnalyticsService.sendGaEvent('view_item_list', {
                items: this.gaItems,
                item_list_name: this.gaListName || undefined,
                item_list_id: this.gaListName ? this.gaListName.toLowerCase().trim().replace(/ /g, '_') : undefined,
              });
              this.gaItems = [];
            }
          }
        }
        return warranties.slice(0, maximumNumberProducts);
      })
    )
  }

  private subscribeToContactInfoAndTooltip(): void {
    this.subscription.add(
      combineLatest([
        this.baseStoreService.getTooltipMessage(),
        this.baseStoreService.getPhoneNumber(),
      ]).subscribe(([messsage, phoneOrEmail]) => {
        this.tooltipMessage = messsage?.message;
        this.phoneOrEmail = phoneOrEmail?.contactInfo;
        this.cdr.markForCheck();
      })
    );
  }

  private subscribeToUser(): void {
    this.subscription.add(
      this.userAccount.get()
        .pipe(
          filter(Boolean),
          tap(user => this.user = user)
        )
        .subscribe()
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
