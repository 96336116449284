import { Component, ComponentRef, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { catchError, filter, take, tap } from 'rxjs/operators';
import { DelegoService } from '../../../../../services/delego.service';
import { environment } from '../../../../../../environments/environment';
import { TransactionStatus } from '../../../../../enums/delego.enum';
import { CheckoutDeliveryAddressFacade } from '@spartacus/checkout/base/root';
import { Cart } from '@spartacus/cart/base/root/models/cart.model';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import { BehaviorSubject, Observable, of, Subscription, throwError } from 'rxjs';
import { LAUNCH_CALLER } from '@spartacus/storefront';
import { OrderFacade } from '@spartacus/order/root';
import { BaseStoreService } from 'src/app/services/base-store.service';
import { CustomLaunchDialogService } from 'src/app/services/custom-launch-dialog.service';
import { CustomOrderService } from 'src/app/services/custom-order.service';

declare var xdm: any;

@Component({
  selector: 'cx-delego',
  templateUrl: './delego.component.html',
  styleUrls: ['./delego.component.scss'],
})
export class DelegoComponent implements OnInit, OnDestroy {
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  placedOrder: void | Observable<ComponentRef<any> | undefined>;

  private formattedDeliveryAddress: string;
  private cartNumber: string;
  private componentRef: ComponentRef<any>;
  private socket: any;
  private isPlaceOrderBtnAvailable: BehaviorSubject<boolean>;
  private subscriptions = new Subscription();

  constructor(
    protected orderFacade: OrderFacade,
    protected vcr: ViewContainerRef,
    private delegoService: DelegoService,
    private checkoutDeliveryAddressFacade: CheckoutDeliveryAddressFacade,
    private activeCartFacade: ActiveCartFacade,
    private launchDialogService: CustomLaunchDialogService,
    private globalMessageService: GlobalMessageService,
    private baseStoreService: BaseStoreService,
    private customOrderService: CustomOrderService,
  ) {
  }

  ngOnInit(): void {
    this.subscribeToSecretParams();
    this.subscribeToDeliveryAddressState();
    this.subscribeToActiveCart();
    this.getComponentRef();
    this.subscribeProvidedData();
  }

  ngOnDestroy(): void {
    this.closeModal();
    this.socket.removeEventListener();
  }

  closeModal() {
    this.launchDialogService.closeDialog('destroy', LAUNCH_CALLER.PAY_BY_DELEGO);
  }

  private getComponentRef(): void {
    this.delegoService.delegoModal$
      .pipe(take(1))
      .subscribe((modalComponent) => this.componentRef = modalComponent);
  }

  private subscribeToSecretParams(): void {
    this.delegoService
      .getSecretParams()
      .pipe(
        take(1),
        tap((res) => this.generateIframe(res))
      ).subscribe();
  }

  private generateIframe(params: any): void {
    const iframe: any = document.getElementById('delegoFrame');
    this.socket = new xdm.Socket({
      isHost: true,
      frameId: iframe?.id,
      remoteDomain: environment.delegoRemoteDomain,
      onMsg: this.onPartnerMessage.bind(this),
    });
    this.baseStoreService.currentSite$
      .pipe(take(1))
      .subscribe(siteId => iframe.src = `${environment.delegoRemoteDomain}/DelegoCE/?sorg=B2B01&sec1=${params.sec1}&sec2=${params.sec2}&siteId=${siteId}`)
  }

  private onPartnerMessage(params: any) {
    if (params === 'CLOSE') return;
    this.isLoading$.next(true);
    this.isPlaceOrderBtnAvailable.next(false);
    const response = JSON.parse(params);
    const requestBody = {
      accountHolderName: response.CardHolder,
      cardType: {code: response.CardType},
      expiryMonth: response.CardExpirationDate.slice(0, 2),
      expiryYear: response.CardExpirationDate.slice(3),
      cardNumber: response.CardToken,
      subscriptionId: response.CardToken,
      billingAddress: this.formattedDeliveryAddress,
    };
    this.delegoService
      .sendPaymentDetails(this.cartNumber, requestBody)
      .pipe(
        take(1),
        catchError((error) => {
          this.isLoading$.next(false);
          this.isPlaceOrderBtnAvailable.next(true);
          this.closeModal();
          this.globalMessageService.add(
            {
              key: 'checkoutReview.errorPlacingOrder',
            },
            GlobalMessageType.MSG_TYPE_ERROR
          );
          return throwError(error);
        }),
        tap(() => this.customOrderService.placeOrder(true, true)),
      ).subscribe(response => {
      if (response.transactionStatusDetails === TransactionStatus.successful) {
        this.isLoading$.next(false);
        this.isPlaceOrderBtnAvailable.next(true);
        this.closeModal();
      }
    })
  }

  private subscribeToDeliveryAddressState(): void {
    this.checkoutDeliveryAddressFacade
      .getDeliveryAddressState()
      .pipe(
        take(1),
        tap((address: any) => (this.formattedDeliveryAddress = address?.data))
      )
      .subscribe();
  }

  private subscribeToActiveCart(): void {
    this.activeCartFacade
      .getActive()
      .pipe(
        filter(Boolean),
        take(1),
      ).subscribe((cart: Cart) => this.cartNumber = cart?.code);
  }

  private subscribeProvidedData() {
    this.subscriptions.add(
      this.launchDialogService.data$
        .pipe(
          filter(data => data?.isButtonDisable)
        ).subscribe(data => this.isPlaceOrderBtnAvailable = new BehaviorSubject(data.isButtonDisable))
    )
  }
}
