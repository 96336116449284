<div class="row plp-desktop-item" *ngIf="!isMobileView; else plpMbileTmpl">
  <a [attr.aria-label]="
        !product.images?.hasOwnProperty('PRIMARY')
          ? ('productDetails.noProductImage'
            | cxTranslate: { product: product.name })
          : undefined
      " [routerLink]="{ cxRoute: 'product', params: product } | cxUrl" (click)="selectItem(product)"
      [queryParams]="{gaListName: gaListName}"
    class="cx-product-image-container">
    <cx-media class="cx-product-image" *ngIf="(product.servicePart && product.images?.PRIMARY) || !product.servicePart; else spGearThumb"
      [container]="product.images?.PRIMARY" 
      format="product" [alt]="product.name || product.summary"></cx-media>
      <ng-template #spGearThumb>
        <img src="../../../../../../assets/icons/sp_gear_thumb.svg" alt="gear" class="sp-gear-thumb">
      </ng-template>
  </a>
  <div class="product-name-container">
    <a [attr.aria-label]="
        !product.images?.hasOwnProperty('PRIMARY')
          ? ('productDetails.noProductImage'
            | cxTranslate: { product: product.name })
          : undefined
      " [routerLink]="{ cxRoute: 'product', params: product } | cxUrl" (click)="selectItem(product)"
      [queryParams]="{gaListName: gaListName}"
      [style.padding-right]="product.servicePart ? '5rem' : '1rem'"
      >
      <span class="name">{{ product.name }}</span>
    </a>
    <a *ngIf="product.bundleAvailable"
      [routerLink]="{ cxRoute: 'bundles', params: { productCode: product.code, name: product.slug } } | cxUrl"
      class="buy-in-bundle call-for-pricing link cx-action-btn-link">
      Buy In Bundle >
    </a>
  </div>
  <div class="price" *ngIf="product.purchasable">
    <div class="sp-product-icons">
      <generac-sp-ltl *ngIf="product.ltl && product.servicePart" [ltl]="product.ltl"></generac-sp-ltl>
      <generac-sp-flammable *ngIf="product.flammable && product.servicePart" [flammable]="product.flammable"></generac-sp-flammable>
    </div>
    <cx-info-price-wrapper [product]="product">
      {{ getDisplayPrice(product) }}
    </cx-info-price-wrapper>
  </div>
  <div
          *ngIf="!product.purchasable"
          [class.service-parts]="product?.servicePart"
          class="cx-product-price-container non-price"
  >
    <add-to-favorites
            [product]="product"
            [class.border-grey]="product.erpStatus !== 'DISCONTINUED'"
    ></add-to-favorites>
    <div
            class="position-relative non-price-status"
    >
      <ng-container *ngIf="product?.servicePart; else regularProduct;">
        <cx-info-price-wrapper [product]="product"></cx-info-price-wrapper>
        <a
                *ngIf="product?.unavailabilityDetail?.type === UnavailabilityDetailType.ContactFactory; else plainText;"
                class="call-for-pricing link cx-action-btn-link"
                (click)="openContactFactory()"
        >
          {{product?.unavailabilityDetail?.label}}
        </a>
        <ng-template #plainText>
          <span class="call-for-pricing">{{product?.unavailabilityDetail?.label}}</span>
        </ng-template>
      </ng-container>

      <ng-template #regularProduct>
        <div
                class="position-relative non-price-status-product"
                (mouseenter)="callForPricingMessage=tooltipMessage"
                (mouseleave)="callForPricingMessage=''">
          <a
                  [href]="contactForPricingHref"
                  class="call-for-pricing link cx-action-btn-link">
            Contact For Pricing
            <cx-info-message [message]="callForPricingMessage" [position]="'position-right'"></cx-info-message>
          </a>
        </div>
      </ng-template>

    </div>
  </div>
  <cx-add-to-cart
          *ngIf="product.purchasable"
          [product]="product"
          [purchasable]="true"
          [productCode]="product.code"
          [showQuantity]="true"
          [isIcon]="true"
          [gaListName]="gaListName"
  ></cx-add-to-cart>
  <div class="sp-note-container" *ngIf="isServicePartsEnabled && product?.materialNotes && product?.servicePart">
    <span>
      <strong>Note: </strong>
      <span [innerHTML]="(product?.materialNotes  || '') | safe"></span>
    </span>
  </div>
</div>

<ng-template #plpMbileTmpl>
  <div class="row plp-mobile-item">
    <a [attr.aria-label]="
        !product.images?.hasOwnProperty('PRIMARY')
          ? ('productDetails.noProductImage'
            | cxTranslate: { product: product.name })
          : undefined
      " [routerLink]="{ cxRoute: 'product', params: product } | cxUrl" (click)="selectItem(product)"
      [queryParams]="{gaListName: gaListName}"
      class="cx-product-image-container">
      <cx-media class="cx-product-image" *ngIf="(product.servicePart && product.images?.PRIMARY) || !product.servicePart; else spGearThumb"
      [container]="product.images?.PRIMARY" 
      format="product" [alt]="product.name || product.summary"></cx-media>
      <ng-template #spGearThumb>
        <img src="../../../../../../assets/icons/sp_gear_thumb.svg" alt="gear" class="sp-gear-thumb">
      </ng-template>
    </a>
    <div class="plp-mobile-product-data">
      <div class="product-name-container">
        <a [attr.aria-label]="
        !product.images?.hasOwnProperty('PRIMARY')
          ? ('productDetails.noProductImage'
            | cxTranslate: { product: product.name })
          : undefined
      " [routerLink]="{ cxRoute: 'product', params: product } | cxUrl" (click)="selectItem(product)"
      [queryParams]="{gaListName: gaListName}"
      >
          <span class="name">{{ product.name }}</span>
        </a>
        <div *ngIf="product.purchasable" class="price">
          {{ product.price?.formattedValue }}
          <cx-info-price-wrapper [product]="product"></cx-info-price-wrapper>
        </div>
        <a
                *ngIf="product.bundleAvailable"
                [routerLink]="{ cxRoute: 'bundles', params: { productCode: product.code, name: product.slug } } | cxUrl"
                class="buy-in-bundle call-for-pricing link cx-action-btn-link">
          Buy In Bundle >
        </a>
        <div class="sp-icons-mobile">
          <generac-sp-ltl *ngIf="product.ltl && product.servicePart" [ltl]="product.ltl"></generac-sp-ltl>
          <generac-sp-flammable *ngIf="product.flammable && product.servicePart" [flammable]="product.flammable"></generac-sp-flammable>
        </div>
      </div>
      <div
              *ngIf="!product.purchasable"
              class="cx-product-price-container non-price"
              [class.service-parts]="product?.servicePart"
      >
        <add-to-favorites
                [product]="product"
                [class.favorite-placeholder]="product.erpStatus === 'DISCONTINUED'"
        ></add-to-favorites>
        <div
                class="position-relative non-price-status"
                (mouseenter)="callForPricingMessage=tooltipMessage"
                (mouseleave)="callForPricingMessage=''"
        >
          <ng-container *ngIf="product?.servicePart; else regularProduct;">
            <cx-info-price-wrapper
                    [product]="product"
                    [position]="TooltipPosition.Right"
            ></cx-info-price-wrapper>
            <a
                    *ngIf="product?.unavailabilityDetail?.type === UnavailabilityDetailType.ContactFactory; else plainText;"
                    class="call-for-pricing link cx-action-btn-link"
                    (click)="openContactFactory()"
            >
              {{product?.unavailabilityDetail?.label}}
            </a>
            <ng-template #plainText>
              <span class="call-for-pricing">{{product?.unavailabilityDetail?.label}}</span>
            </ng-template>
          </ng-container>

          <ng-template #regularProduct>
            <div
                    class="position-relative"
                    (mouseenter)="callForPricingMessage=tooltipMessage"
                    (mouseleave)="callForPricingMessage=''">
              <a
                      [href]="contactForPricingHref"
                      class="call-for-pricing link cx-action-btn-link">
                Contact For Pricing
                <cx-info-message [message]="callForPricingMessage" [position]="'position-right'"></cx-info-message>
              </a>
            </div>
          </ng-template>

        </div>
      </div>
      <cx-add-to-cart
              *ngIf="product.purchasable"
              [product]="product"
              [purchasable]="true"
              [productCode]="product.code"
              [showQuantity]="true"
              [isMobileBtn]="true"
              [gaListName]="gaListName"
      ></cx-add-to-cart>
    </div>
  </div>
</ng-template>