import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslationService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { InfoMessageModule } from '../info-message/info-message.module';

@Component({
  selector: 'generac-sp-flammable',
  templateUrl: './generac-sp-flammable.component.html',
  styleUrls: ['./generac-sp-flammable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    InfoMessageModule,
  ]
})
export class GeneracSpFlammableComponent {
  @Input() flammable: boolean;
  @Input() position: string = 'position-right';

  flammableMessage: string = '';
  tooltipFlammableMessage$: Observable<string>;

  constructor(
    protected translation: TranslationService,
  ) {
    this.tooltipFlammableMessage$ = this.translation.translate('productList.tooltipFlammableMessage');
  }
}
