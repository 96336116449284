import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { debounceTime, filter, switchMap, take, tap } from 'rxjs/operators';
import { BaseStoreService } from 'src/app/services/base-store.service';
import { CustomerService } from 'src/app/services/customer.service';
import { FavoritesService } from 'src/app/services/favorites.service';
import { ProductExtended } from 'src/app/interfaces/product';
import { LoadingEnum } from 'src/app/enums/loading.enum';
import { ICON_TYPE, LaunchDialogService } from "@spartacus/storefront";
import { ActiveCartFacade, MultiCartFacade } from '@spartacus/cart/base/root';
import { User } from '@spartacus/core';
import { FAVORITES_DIALOGS, FavoritesListItem, FavoritesListPagination, FavoritesListPaginationMapped, FavoritesListSortConfig, FavoritesResponse, FavotitesDialogActionModes } from '../../../../interfaces/favorites.model';
import { ActivatedRoute, Router } from '@angular/router';
import { favoritesListSortConfig } from './favorites-list-sort-config';
import { GaListNames } from 'src/app/enums/ga-list-names.enum';
import { GaItem, GoogleAnalyticsService } from 'src/app/services/google-analytics.service';

@Component({
  selector: 'favorites-list',
  templateUrl: './favorites-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class FavoritesListComponent implements OnInit, OnDestroy {
  favoritesList$: Observable<FavoritesResponse>;
  currentListId: string;
  tooltipMessage: string;
  phoneOrEmail: string;
  currentPage: number = 0;
  productsCount: number;
  favoritesList: ProductExtended[];
  pagination: FavoritesListPaginationMapped | FavoritesListPagination;
  loading: LoadingEnum = LoadingEnum.Idle;
  LoadingEnum = LoadingEnum;
  iconTypes = ICON_TYPE;
  userId: string;
  currentList: FavoritesListItem;
  sorts: FavoritesListSortConfig[];
  gaListName: GaListNames = GaListNames.FAVORITES;

  private subscription = new Subscription();
  gaItems: GaItem[] = [];

  constructor(
    private favoritesService: FavoritesService,
    private userAccount: UserAccountFacade,
    protected customerService: CustomerService,
    protected cd: ChangeDetectorRef,
    protected launchDialogService: LaunchDialogService,
    protected multiCartFacade: MultiCartFacade,
    protected activeCartFacade: ActiveCartFacade,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected googleAnalyticsService: GoogleAnalyticsService,
    private baseStoreService: BaseStoreService,
  ) { }

  ngOnInit(): void {
    this.loading = LoadingEnum.Loading;
    this.setSortOptions();
    if(this.activatedRoute.snapshot?.queryParams.sort) {
      const sortToBeSelected = this.sorts.find(sort => sort.code == this.activatedRoute.snapshot?.queryParams.sort);
      sortToBeSelected.selected = true;
    } else {
      this.sorts[0].selected = true;
    }
    this.subscribeToLoadingState();
    this.subscribeToContactInfoAndTooltip();
    this.subscribeToActiveListChange();
  }

  private getFavorites(page: number = 0, sort: string, listId?: string): Observable<FavoritesResponse> {
    return this.userAccount.get().pipe(
      filter(user => !!user?.uid),
      take(1),
      tap((user: User) => this.userId = user?.uid),
      switchMap((user: User) => this.favoritesService.getFavorites(user?.uid, page, sort, listId).pipe(take(1))),
      debounceTime(500),
      tap((res: FavoritesResponse) => {
        this.router.navigate([], {
          queryParams: {
            'sort': sort,
          },
          queryParamsHandling: 'merge'
        });
        this.favoritesList = res.products;
        this.pagination = res.pagination;
        this.productsCount = res.products.length;
        if(this.productsCount > 0) {
          for(let [index, product] of this.favoritesList.entries()) {
            this.gaItems.push(
              this.googleAnalyticsService.mapProductToGaItem(product, index, undefined, this.gaListName )
            );
            if (this.gaItems.length == this.favoritesList.length) {
              this.googleAnalyticsService.sendGaEvent('view_item_list', {
                items: this.gaItems,
                item_list_name: this.gaListName || undefined,
                item_list_id: this.gaListName ? this.gaListName.toLowerCase().trim().replace(/ /g, '_') : undefined,
              });
              this.gaItems = [];
            }
          }
        }
        this.cd.markForCheck();
      })
    )
  }

  private subscribeToLoadingState(): void {
    this.subscription.add(
      this.favoritesService.loading$
        .subscribe(loading => {
          this.loading = loading;
          this.cd.detectChanges();
        })
    );
  }

  private subscribeToContactInfoAndTooltip(): void {
    this.subscription.add(
      combineLatest([
        this.baseStoreService.getTooltipMessage(),
        this.baseStoreService.getPhoneNumber(),
      ]).subscribe(([messsage, phoneOrEmail]) => {
        this.tooltipMessage = messsage?.message;
        this.phoneOrEmail = phoneOrEmail?.contactInfo;
        this.cd.markForCheck();
      })
    );
  }

  private subscribeToActiveListChange(): void {
    this.subscription.add(
      this.activatedRoute.queryParams
        .pipe(
          filter(e => e?.listId?.length > 0),
          tap(e => {
            this.fetchItemsForList(e.listId);
          })
        ).subscribe()
    )
  }

  private openFavoriteProductsAddedToCartDialog(productsAmount: number) {
    const dialog = this.launchDialogService.openDialog(
      FAVORITES_DIALOGS.FAVORITE_PRODUCTS_ADDED_TO_CART,
      undefined,
      undefined,
      { productsAmount }
    );

    if (dialog) {
      dialog.pipe(take(1)).subscribe();
    }
  }

  private setSortOptions(): void {
    this.sorts = favoritesListSortConfig;
  }

  private setSortOptionsUnselected(): void {
    this.sorts = this.sorts.map(item => { return {...item, selected: false} });
  }

  private setSelectedSortOption(code: string): void {
    const index = this.sorts.findIndex((item: FavoritesListSortConfig) => item.code == code);
    this.sorts[index].selected = true;
  }

  public onPageChange(page: number): void {
    this.loading = LoadingEnum.Loading;
    this.currentPage = page;
    this.getFavorites(page, this.getSelectedSortOption(), this.currentListId).subscribe(() => {
      this.cd.detectChanges();
    });
  }

  public fetchItemsForList(listId?: string): Subscription {
    let page = 0;
    if (this.currentListId && (listId == this.currentListId)) {
      page = (this.productsCount > 1 || this.currentPage == 0) ? this.currentPage : (this.currentPage - 1);
    }
    this.currentListId = null;
    this.currentListId = listId;
    return this.getFavorites(page, this.getSelectedSortOption(), this.currentListId).pipe(take(1)).subscribe();
  }

  public setActiveList(list: FavoritesListItem): void {
    this.currentList = list;
    if(this.currentList.id == this.currentListId) {
      this.fetchItemsForList(this.currentList.id);
    }
  }

  public openDeleteAllItemsFromListModal(list: FavoritesListItem): void {
    const modalData = {
      list,
      mode: FavotitesDialogActionModes.DeleteItems,
      userId: this.userId
    }

    const dialog = this.launchDialogService.openDialog(
      FAVORITES_DIALOGS.FAVORITES_CONFIRM_ACTION,
      undefined,
      undefined,
      modalData
    );

    if (dialog) {
      dialog.pipe(take(1)).subscribe();
    }

    this.subscription.add(
      this.launchDialogService.dialogClose
        .pipe(
          filter((reason) => reason == FavotitesDialogActionModes.DeleteItems),
          tap(() => this.favoritesService.setFavoritesListChanged(true))
        )
        .subscribe()
    );
  }

  public openAddAllItemsToCartModal(list: FavoritesListItem): void {
    const modalData = {
      list,
      mode: FavotitesDialogActionModes.AddAll,
      userId: this.userId
    }
    const dialog = this.launchDialogService.openDialog(
      FAVORITES_DIALOGS.FAVORITES_CONFIRM_ACTION,
      undefined,
      undefined,
      modalData
    );

    if (dialog) {
      dialog.pipe(take(1)).subscribe();
    }

    this.subscription.add(
      this.launchDialogService.dialogClose
        .pipe(
          filter(reason => reason == FavotitesDialogActionModes.AddAll),
          switchMap(() => this.activeCartFacade.getActiveCartId()),
          tap(cartId => this.multiCartFacade.reloadCart(cartId)),
          tap(() => this.openFavoriteProductsAddedToCartDialog(list.count))
        )
        .subscribe()
    );
  }

  public getSelectedSortOption(): string {
    const index = this.sorts.findIndex((item: FavoritesListSortConfig) => item.selected);
    return this.sorts[index].code;
  }

  public sortList(code: string): void {
    this.setSortOptionsUnselected();
    this.setSelectedSortOption(code);
    this.getFavorites(0, this.getSelectedSortOption(), this.currentListId)
      .subscribe(() => this.cd.markForCheck());
  }

  public trackByFavorites(index: number, item: ProductExtended): string {
    return item.code;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
