<ng-container *ngIf="shippingGroup">
    <mat-accordion *ngIf="isOrderReview ? shippingGroup?.deliveryDateGroups : shippingGroup?.manufactureGroups" [class.is-order-review]="isOrderReview" [multi]="true">
        <mat-expansion-panel *ngFor="let deliveryGroup of (isOrderReview ? shippingGroup?.deliveryDateGroups : shippingGroup?.manufactureGroups); let i = index;"
            (opened)="panelOpenState[i] = true" (closed)="panelOpenState[i] = false" [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span *ngIf="!isSubsStep">Delivery Group #{{getDeliveryGroupTitle(deliveryGroup) || shippingGroup?.shippingGroupID + 1 }}</span>
                    <span *ngIf="isSubsStep">Subscriptions</span>
                </mat-panel-title>
                <mat-panel-description (click)="$event.stopPropagation();">
                    <cx-schedule-replenishment-order
                        *ngIf="desiredShipmentDateAvailable && isManufactureGroup(deliveryGroup) && !isSpStep && !isSubsStep && !isOrderReview"
                        [deliveryGroup]="deliveryGroup"
                        [shippingGroupName]="shippingGroup.shippingGroupName"
                        [manufactureGroupID]="getManufactureGroupID(deliveryGroup)"
                        [cartCode]="cartCode"
                        (dateSelected)="getSingleConsignmentInfo(getManufactureGroupID(deliveryGroup))"
                    ></cx-schedule-replenishment-order>
                    <span
                        *ngIf="isOrderReview && getGroupDeliveryDate(deliveryGroup)"
                        class="desired-shipment-date-text">
                        <span class="desired-shipment-date-text-title">{{ 'checkoutReview.desiredDate' | cxTranslate}}:</span>
                        <span class="desired-shipment-date-text-val">
                            {{ getGroupDeliveryDate(deliveryGroup) | date: 'MM.dd.yyyy'}}
                        </span>
                    </span>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <table>
                <tbody>
                    <ng-container *ngFor="let entryGroup of deliveryGroup.entryGroups">
                        <tr *ngFor="let entry of (entryGroup.type == 'CONFIGURABLEBUNDLE' ? entryGroup.entryGroups[0]?.entries : entryGroup.entries)">
                            <cx-custom-cart-item
                                [item]="entry"
                                [compact]="true"
                                [readonly]="true"
                            ></cx-custom-cart-item>
                            <cx-custom-subscriptions-table
                                *ngIf="isOrderReview"
                                [product]="entry?.product"
                                [subscriptionInfo]="entry?.subscriptions"
                            ></cx-custom-subscriptions-table>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
            <generac-checkout-single-consignment
                *ngIf="!isSubsStep && !isOrderReview && isManufactureGroup(deliveryGroup)"
                [shippingGroupName]="shippingGroup.shippingGroupName"
                [cartCode]="cartCode"
                [manufactureGroupID]="getManufactureGroupID(deliveryGroup)" 
                [singleConsignmentInfo]="getDeliveryGroupSingleConsigmentInfo(deliveryGroup)"
                [purchaseOrderNumber]="purchaseOrderNumber"
                [updateData]="updateDataTrigger$">
            </generac-checkout-single-consignment>
        </mat-expansion-panel>
    </mat-accordion>
</ng-container>
