import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule, PageMetaResolver } from '@spartacus/core';
import { IconModule, KeyboardFocusModule } from '@spartacus/storefront';
import { SavedAddressesComponent } from './saved-addresses.component';
import { SavedAddressItemComponent } from './saved-address-item/saved-address-item.component';
import { SavedAddressesPageMetaResolver } from './saved-addresses-page-meta.resolver';
import { EditSavedAddressDialogComponent } from './edit-saved-address-dialog/edit-saved-address-dialog.component';
import { CustomCheckoutDeliveryAddressModule } from '../checkout/components/custom-checkout-delivery-address/custom-checkout-delivery-address.module';
import { DeleteSavedAddressDialogComponent } from './delete-saved-address-dialog/delete-saved-address-dialog.component';

@NgModule({
  declarations: [ 
    SavedAddressesComponent,
    SavedAddressItemComponent,
    EditSavedAddressDialogComponent,
    DeleteSavedAddressDialogComponent,
  ],
  imports: [
    CommonModule,
    I18nModule,
    IconModule,
    KeyboardFocusModule,
    CustomCheckoutDeliveryAddressModule,
  ],
  providers: [
    {
      provide: PageMetaResolver,
      useExisting: SavedAddressesPageMetaResolver,
      multi: true,
    },
  ]
})
export class SavedAddressesModule {}
