import { ChangeDetectionStrategy, Component, ComponentRef, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { OrderFacade } from "@spartacus/order/root";
import { GlobalMessageService, GlobalMessageType, RoutingService } from "@spartacus/core";
import { LAUNCH_CALLER } from "@spartacus/storefront";
import { ActiveCartFacade } from "@spartacus/cart/base/root";
import { catchError, filter, map, take, tap } from "rxjs/operators";
import { PaymentType } from "../../../../../enums/payment-type.enum";
import { Router } from "@angular/router";
import { DelegoService } from "../../../../../services/delego.service";
import { CartExtended } from "../../../../../interfaces/cart";
import { UserGroup } from "../../../../../enums/user.enum";
import { UserAccountFacade } from "@spartacus/user/account/root";
import { CustomLaunchDialogService } from 'src/app/services/custom-launch-dialog.service';

@Component({
  selector: 'cx-place-order',
  templateUrl: './checkout-place-order.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutPlaceOrderComponent implements OnInit, OnDestroy {
  placedOrder: void | Observable<ComponentRef<any> | undefined>;
  cart: CartExtended;
  isTestPlaceOrder$!: Observable<boolean>;

  checkoutSubmitForm: UntypedFormGroup = this.fb.group({
    termsAndConditions: [false, Validators.requiredTrue],
  });

  get termsAndConditionInvalid(): boolean {
    return this.checkoutSubmitForm.invalid;
  }

  isPlaceOrderBtnAvailable = new BehaviorSubject(true);

  constructor(
    protected orderFacade: OrderFacade,
    protected routingService: RoutingService,
    protected fb: UntypedFormBuilder,
    protected launchDialogService: CustomLaunchDialogService,
    protected vcr: ViewContainerRef,
    protected activeCartFacade: ActiveCartFacade,
    private router: Router,
    private globalMessageService: GlobalMessageService,
    private delegoService: DelegoService,
    private userAccount?: UserAccountFacade
  ) {
  }

  ngOnInit(): void {
    this.getCart();
    this.getUserAccount();
  }

  ngOnDestroy(): void {
    this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
    this.launchDialogService.clear(LAUNCH_CALLER.PAY_BY_DELEGO);
  }

  submitForm(): void {
    if (this.checkoutSubmitForm.valid) {
      if (this.cart.paymentType.code === PaymentType.CARD) {
        this.openDelegoModal();
      } else {
        this.placeOrder();
      }
    } else {
      this.checkoutSubmitForm.markAllAsTouched();
    }
  }

  openDelegoModal(): void {
    const dialog = this.launchDialogService.openDialog(
      LAUNCH_CALLER.PAY_BY_DELEGO,
      undefined,
      this.vcr,
      {
        isButtonDisable: this.isPlaceOrderBtnAvailable
      }
    );

    if (dialog) {
      dialog
        .subscribe(modalRef => this.delegoService.delegoModal.next(modalRef));
    }
  }

  placeOrder(): void {
    this.placedOrder = this.launchDialogService.openDialogAndSubscribe(
      LAUNCH_CALLER.PLACE_ORDER_SPINNER,
      undefined,
      null
    );
    this.isPlaceOrderBtnAvailable.next(false);
    this.orderFacade.placeOrder(this.checkoutSubmitForm.valid).pipe(
      catchError((error) => {
        this.isPlaceOrderBtnAvailable.next(true);
        if (error && error.status === 400) {
          this.globalMessageService.add(
            {
              key: 'checkoutReview.errorPlacingOrder',
            },
            GlobalMessageType.MSG_TYPE_ERROR
          );
          return throwError(error);
        }
        return of(true);
      })
    ).subscribe(() => this.confirmOrderWithPlaceOrder())
  }

  private getCart(): void {
    this.activeCartFacade.getActive()
      .pipe(
        take(1),
        tap((cart: any) => this.cart = cart)
      )
      .subscribe();
  }

  private confirmOrderWithPlaceOrder() {
    if (this.placedOrder) {
      this.placedOrder
        .subscribe((component) => this.navigateToOrderConfirmation(component))
        .unsubscribe();
      return;
    }
    this.navigateToOrderConfirmation();
  }

  private getUserAccount(): void {
    this.isTestPlaceOrder$ = this.userAccount.get()
      .pipe(
        filter(user => !!user),
        map(user => user?.roles?.includes(UserGroup.testorderplacegroup))
      );
  }

  private navigateToOrderConfirmation(component?: ComponentRef<any> | undefined) {
    this.router.navigateByUrl('/order-confirmation').then(() => {
      this.globalMessageService.add(
        {
          key: 'checkoutReview.successPlacingOrder',
        },
        GlobalMessageType.MSG_TYPE_CONFIRMATION
      );
      if(!this.placedOrder) {
        return;
      }
      this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
      this.isPlaceOrderBtnAvailable.next(true);
      if (component) {
        component.destroy();
      }
    })
  }
}
