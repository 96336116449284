import { NgModule } from '@angular/core';
import { StoreModule } from './store/store.module';
import { PAGE_LAYOUT_HANDLER, SpinnerModule } from "@spartacus/storefront";
import { ProductTabsModule } from "./product-tabs";
import { BundleCarouselModule } from "../../../pages/bundle-carousel/bundle-carousel.module";
import { PageMetaResolver, provideConfig } from "@spartacus/core";
import { LayoutHandlerService } from "../../../services/layout-handler.service";
import { GeneracSearchPageMetaResolver } from './product-list/model/search-page-meta.resolver';
import { GeneracCategoryPageMetaResolver } from './product-list/model/category-page-meta.resolver';

@NgModule({
  imports: [
    StoreModule,
    SpinnerModule,
    ProductTabsModule,
    BundleCarouselModule,
  ],
  providers: [
    provideConfig({
      layoutSlots: {
        ProductDetailsPageTemplate: {
          lg: {
            pageFold: 'UpSelling',
          },
          pageFold: 'Summary',
          slots: [
            'ProductHeaderMenu',
            'Summary',
            'Bundles',
            'Tabs',
            'UpSelling',
            'CrossSelling',
            'RecentlyReviewed',
            // 'PlaceholderContentSlot'
          ],
        },
      },
    }),
    {
      provide: PAGE_LAYOUT_HANDLER,
      useExisting: LayoutHandlerService,
      multi: true,
    },
    {
      provide: PageMetaResolver,
      useExisting: GeneracCategoryPageMetaResolver,
      multi: true,
    },
    {
      provide: PageMetaResolver,
      useExisting: GeneracSearchPageMetaResolver,
      multi: true,
    },
  ]
})
export class ProductModule {
}
