import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomAddedToCartDialogComponent } from "./custom-added-to-cart-dialog.component";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { IconModule, ItemCounterModule, KeyboardFocusModule, PromotionsModule, SpinnerModule } from "@spartacus/storefront";
import { I18nModule, UrlModule } from "@spartacus/core";
import { CustomAddedToCartDialogEventListener } from "./custom-added-to-cart-dialog-event.listener";
import { CartSharedModule } from '../../cart-shared';
import { NoReturnWarningModule } from '../../../product/components/no-return-warning/no-return-warning.module';
import { GeneracCarouselModule } from "../../../../../shared/components/generac-carousel/generac-carousel.module";
import { ProductGridItemModule } from "../../../product/product-list/product-grid-item/product-grid-item.module";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CartSharedModule,
    RouterModule,
    SpinnerModule,
    PromotionsModule,
    UrlModule,
    IconModule,
    I18nModule,
    ItemCounterModule,
    KeyboardFocusModule,
    NoReturnWarningModule,
    GeneracCarouselModule,
    ProductGridItemModule
  ],
  declarations: [CustomAddedToCartDialogComponent],
  exports: [CustomAddedToCartDialogComponent],
})
export class CustomAddedToCartDialogModule {
  constructor(_addToCartDialogEventListener: CustomAddedToCartDialogEventListener) {
    // Intentional empty constructor
  }
}
