import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentRef, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Observable } from "rxjs";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { OrderFacade } from "@spartacus/order/root";
import { RoutingService } from "@spartacus/core";
import { LAUNCH_CALLER } from "@spartacus/storefront";
import { ActiveCartFacade } from "@spartacus/cart/base/root";
import { filter, map, take, tap } from "rxjs/operators";
import { PaymentType } from "../../../../../enums/payment-type.enum";
import { DelegoService } from "../../../../../services/delego.service";
import { CartExtended } from "../../../../../interfaces/cart";
import { UserGroup } from "../../../../../enums/user.enum";
import { UserAccountFacade } from "@spartacus/user/account/root";
import { CustomLaunchDialogService } from 'src/app/services/custom-launch-dialog.service';
import { CustomOrderService } from 'src/app/services/custom-order.service';

@Component({
  selector: 'cx-place-order',
  templateUrl: './checkout-place-order.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutPlaceOrderComponent implements OnInit, OnDestroy {
  placedOrder: void | Observable<ComponentRef<any> | undefined>;
  cart: CartExtended;
  isTestPlaceOrder$!: Observable<boolean>;
  isPlaceOrderBtnAvailable: boolean;

  checkoutSubmitForm: UntypedFormGroup = this.fb.group({
    termsAndConditions: [false, Validators.requiredTrue],
  });

  get termsAndConditionInvalid(): boolean {
    return this.checkoutSubmitForm.invalid;
  }

  constructor(
    protected orderFacade: OrderFacade,
    protected routingService: RoutingService,
    protected fb: UntypedFormBuilder,
    protected launchDialogService: CustomLaunchDialogService,
    protected vcr: ViewContainerRef,
    protected activeCartFacade: ActiveCartFacade,
    private delegoService: DelegoService,
    private customOrderService: CustomOrderService,
    private cdr: ChangeDetectorRef,
    private userAccount?: UserAccountFacade,
  ) {
  }

  ngOnInit(): void {
    this.getCart();
    this.getUserAccount();
    this.subscribeToPlaceOrderBtnState();
  }

  ngOnDestroy(): void {
    this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
    this.launchDialogService.clear(LAUNCH_CALLER.PAY_BY_DELEGO);
  }

  submitForm(): void {
    if (this.checkoutSubmitForm.valid) {
      if (this.cart.paymentType.code === PaymentType.CARD) {
        this.openDelegoModal();
      } else {
        this.placeOrder();
      }
    } else {
      this.checkoutSubmitForm.markAllAsTouched();
    }
  }

  openDelegoModal(): void {
    const dialog = this.launchDialogService.openDialog(
      LAUNCH_CALLER.PAY_BY_DELEGO,
      undefined,
      this.vcr,
      {
        isButtonDisable: this.isPlaceOrderBtnAvailable
      }
    );

    if (dialog) {
      dialog
        .subscribe(modalRef => this.delegoService.delegoModal.next(modalRef));
    }
  }

  placeOrder(): void {
    this.customOrderService.placeOrder(this.checkoutSubmitForm.valid);
  }

  private subscribeToPlaceOrderBtnState() {
    this.customOrderService.isPlaceOrderBtnAvailable$
      .subscribe(isPlaceOrderBtnAvailable => {
        this.isPlaceOrderBtnAvailable = isPlaceOrderBtnAvailable;
        this.cdr.markForCheck();
      })
  }

  private getCart(): void {
    this.activeCartFacade.getActive()
      .pipe(
        take(1),
        tap((cart: any) => this.cart = cart)
      )
      .subscribe();
  }

  private getUserAccount(): void {
    this.isTestPlaceOrder$ = this.userAccount.get()
      .pipe(
        filter(user => !!user),
        map(user => user?.roles?.includes(UserGroup.testorderplacegroup))
      );
  }

  getOrderConfirmationText(isTestOrder: boolean, cart: any): string {
    if (isTestOrder) {
      return cart?.wellsFargoCart
        ? 'checkoutOrderConfirmation.placeTestWfOrder' 
        : 'checkoutOrderConfirmation.placeTestOrder';
    }
    return cart?.wellsFargoCart
      ? 'checkoutReview.placeWfOrder' 
      : 'checkoutReview.placeOrder';
  }
}
