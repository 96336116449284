import { Injectable } from '@angular/core';
import { PageMetaResolver, PageHeadingResolver, PageTitleResolver, PageDescriptionResolver, PageBreadcrumbResolver, PageRobotsResolver, RoutingService, ProductService, TranslationService, BasePageMetaResolver, PageLinkService, BreadcrumbMeta, PageRobotsMeta } from '@spartacus/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FavoritesListPageMetaResolver
  extends PageMetaResolver
  implements
    PageHeadingResolver,
    PageTitleResolver,
    PageDescriptionResolver,
    PageBreadcrumbResolver,
    PageRobotsResolver
{
  constructor(
    protected routingService: RoutingService,
    protected productService: ProductService,
    protected translation: TranslationService,
    protected basePageMetaResolver: BasePageMetaResolver,
    protected pageLinkService: PageLinkService
  ) {
    super();
    this.pageUid = 'favorites' as any;
  }

  resolveHeading(): Observable<string> {
    return this.basePageMetaResolver.resolveTitle();
  }

  resolveTitle(): Observable<string> {
    return this.basePageMetaResolver.resolveTitle();
  }

  resolveDescription(): Observable<string> {
    return this.basePageMetaResolver.resolveDescription();
  }

  resolveBreadcrumbs(): Observable<BreadcrumbMeta[]> {
    return combineLatest([
      this.translation.translate('pageMetaResolver.favorites.title'),
      this.translation.translate('common.home'),
    ]).pipe(
      map(([title, label]) => {
        const breadcrumbs = [];
        breadcrumbs.push({ label, link: '/' });
        breadcrumbs.push({
          label: title,
        } as BreadcrumbMeta);
        return breadcrumbs;
      })
    );
  }

  resolveRobots(): Observable<PageRobotsMeta[]> {
    return this.basePageMetaResolver.resolveRobots();
  }

  resolveCanonicalUrl(): Observable<string> {
    return this.basePageMetaResolver.resolveCanonicalUrl();
  }
}
