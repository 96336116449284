<ng-container *ngIf="shippingGroups?.length > 0">
  <div *ngFor="let shippingGroup of shippingGroups" class="order-review-shipping-group">
    <h3 class="order-review-shipping-group-name">{{ getShippingGroupDisplayName(shippingGroup?.shippingGroupName) }}</h3>
    <div class="order-review-shipping-group-special-handling" *ngIf="shippingGroup?.handlingOptions?.length > 0 || shippingGroup?.cartTypes[0] == 'SERVICE_PARTS'">
      <div
        *ngIf="shippingGroup?.handlingOptions?.length > 0"
        class="col-md-12 col-lg-6 col-xl-6 special-handling-card"
        [class.review-sp-special-handling-col]="shippingGroup?.cartTypes[0] == 'SERVICE_PARTS'">
        <div class="cx-review-summary-edit-step">
          <a *ngIf="!isOrderConfirmPage" [attr.aria-label]="'checkoutReview.editPaymentDetails' | cxTranslate"
            [routerLink]="{ cxRoute: getCheckoutStepUrl(checkoutStepTypeSpecialHandling, shippingGroup.shippingGroupName) } | cxUrl">
            <div class="edit-icon"></div>
          </a>
        </div>
        <cx-card [content]="getSpecialHandlingCard(shippingGroup?.handlingOptions) | async"></cx-card>
      </div>
      <ng-container *ngIf="shippingGroup?.cartTypes[0] == 'SERVICE_PARTS'">
        <div class="col-md-12 col-lg-6 col-xl-6 review-sp-shipping-col" [ngClass]="{'col-lg-12 col-xl-12': shippingGroup?.handlingOptions?.length < 1}">
          <div class="review-sp-shipping-carrier" [class.col-lg-6]="shippingGroup?.handlingOptions?.length < 1">
            <div class="cx-review-summary-edit-step">
              <a *ngIf="!isOrderConfirmPage" [attr.aria-label]="'checkoutReview.editPaymentDetails' | cxTranslate"
                [routerLink]="{ cxRoute: getCheckoutStepUrl(checkoutStepTypeServiceParts) } | cxUrl">
                <div class="edit-icon"></div>
              </a>
            </div>
            <cx-card [content]="getSpCarrierCard(shippingGroup) | async"></cx-card>
          </div>
          <div class="review-sp-shipping-conditions" [class.col-lg-6]="shippingGroup?.handlingOptions?.length < 1">
            <div class="cx-review-summary-edit-step">
              <a *ngIf="!isOrderConfirmPage" [attr.aria-label]="'checkoutReview.editPaymentDetails' | cxTranslate"
                [routerLink]="{ cxRoute: getCheckoutStepUrl(checkoutStepTypeServiceParts) } | cxUrl">
                <div class="edit-icon"></div>
              </a>
            </div>
            <cx-card [content]="getSpShippingConditionsCard(shippingGroup) | async"></cx-card>
          </div>
        </div>
      </ng-container>
    </div>
    <generac-checkout-delivery-groups [shippingGroup]="shippingGroup" [isOrderReview]="true">
    </generac-checkout-delivery-groups>
  </div>
</ng-container>