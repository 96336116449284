import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, combineLatest, forkJoin, Observable, of, Subject, throwError } from "rxjs";
import { catchError, finalize, first, map, skipWhile, switchMap, take, tap, timeout } from "rxjs/operators";
import {
  AddToBundleResponse,
  Bundle,
  BundleItem,
  BundleItemPrice,
  BundlePagination,
  BundlePrice,
  BundlePriceResponse,
  BundleResponse,
  ExtendedBundle,
  ImageFormat
} from "../interfaces/bundle.model";
import {
  GlobalMessageService,
  GlobalMessageType,
  OccEndpointsService,
  PaginationModel,
  TranslationService
} from "@spartacus/core";
import { BaseStoreService } from "./base-store.service";
import { UserAccountFacade } from "@spartacus/user/account/root";
import { ActiveCartFacade, MultiCartFacade } from '@spartacus/cart/base/root';
import { isObjectEmpty } from "../shared/helpers/is-empty";
import { LoadingEnum } from "../enums/loading.enum";

@Injectable({
  providedIn: "root"
})
export class BundleService {

  private loading = new Subject<LoadingEnum>();
  loading$ = this.loading.asObservable();

  constructor(
    private httpClient: HttpClient,
    private baseStoreService: BaseStoreService,
    private globalMessageService: GlobalMessageService,
    private userAccount: UserAccountFacade,
    private activeCartFacade: ActiveCartFacade,
    private multiCartFacade: MultiCartFacade,
    private occEndpoints: OccEndpointsService,
    private translation: TranslationService
  ) {
  }

  getBundlesList(unit: string, productCode: string, currentPage = 0) {
    return this.userAccount.get().pipe(
      skipWhile(user => !user),
      take(1),
      switchMap((user) =>
        this.getBundlesForList(productCode, user.uid, currentPage).pipe(
          switchMap((bundleResponse) => {
            const bundles = bundleResponse.bundleTemplates;
            return bundles?.length ? forkJoin(bundles.map(bundle => this.getPrices(user.uid, unit, bundle.items.map((item: BundleItem) => item.code))))
              .pipe(
                map<BundlePriceResponse, [PaginationModel, ExtendedBundle[]]>((prices: BundlePriceResponse) => [this.mapPagination(bundleResponse.pagination), this.mapBundlesWithPrices(bundles, prices, this.sortByTotalPrice)])
              ) : of([]);
          }),
          catchError(err => {
            this.loading.next(LoadingEnum.Failed);
            return throwError(err);
          }),
          finalize(() => this.loading.next(LoadingEnum.Loaded))
        )
      )
    )
  }

  getBundlesWithPrice(unit: string, productCode: string) {
    this.loading.next(LoadingEnum.Loading);
    return this.userAccount.get().pipe(
      skipWhile(user => !user),
      take(1),
      switchMap((user) => {
        const stream = productCode ? this.getBundlesByProductCode(productCode) : this.getBundlesWithoutProductCode(user.uid);
          return stream.pipe(
            switchMap((bundleResponse) => {
              const bundles = bundleResponse.bundleTemplates;
              const sortRule = productCode ? this.sortByEmptyTotalPrice : null;
              return bundles?.length ? forkJoin(bundles.map(bundle => this.getPrices(user.uid, unit, bundle.items.map((item: BundleItem) => item.code))))
                .pipe(
                  map<BundlePriceResponse, ExtendedBundle[]>((prices: BundlePriceResponse) => this.mapBundlesWithPrices(bundles, prices, sortRule))
                ) : of([]);
            }),
            catchError(err => {
              this.loading.next(LoadingEnum.Failed);
              return throwError(err);
            }),
            finalize(() => this.loading.next(LoadingEnum.Loaded))
          )
        }
      )
    )
  }

  addToCart(templateId: string, productCode: string, quantity = 1): Observable<AddToBundleResponse> {
    this.loading.next(LoadingEnum.Loading);
    return combineLatest([
      this.activeCartFacade.getActive(),
      this.userAccount.get()
    ]).pipe(
      take(1),
      switchMap(([activeCart, user]) => {
        const stream$ = isObjectEmpty(activeCart) ? this.multiCartFacade.createCart({
          userId: user.displayUid,
          extraData: {
            active: true,
          }
        }) : of(activeCart);
        return stream$
          .pipe(
            first(),
            switchMap(cart => this.httpClient.post(
              `${this.occEndpoints.getBaseUrl()}/users/${user.displayUid}/carts/${cart.code}/addBundle`,
              {
                templateId,
                productCode,
                quantity
              }).pipe(tap(() => this.multiCartFacade.reloadCart(cart.code)))
            )
          )
      }),
      tap(() => {
        this.loading.next(LoadingEnum.Loaded);
        this.globalMessageService.add(
          {
            key: 'bundle.addToCartSuccess'
          },
          GlobalMessageType.MSG_TYPE_INFO
        );
      }),
      catchError((error) =>
        this.baseStoreService.getPhoneNumber()
          .pipe(
            switchMap(({contactInfo}) => {
              const standardError = "The application has encountered an error";
              if (error?.error?.errors?.length && error?.error?.errors[0].message && error.error.errors[0].message !== standardError) {
                return of({
                  error: {
                    message: [error.error.errors[0].message],
                    type: error.error.errors[0].type,
                  }
                });
              } else {
                return this.translation.translate('bundle.addToCartFailed', {
                  phoneNumber: contactInfo
                }).pipe(switchMap((errorMessage: string) => {
                  return of({
                    error: {
                      message: [errorMessage]
                    }
                  })
                }))
              }
            }),
            take(1),
            tap(() => this.loading.next(LoadingEnum.Failed))
          ),
      )
    )
  }

  private mapPagination(response: BundlePagination): PaginationModel {
    return {
      currentPage: response?.page,
      totalPages: response?.totalPages,
      pageSize: 1,
    } as PaginationModel;
  }

  private getBundlesForList(productCode: string, userId: string, currentPage = 0) {
    const url = `${this.occEndpoints.getBaseUrl()}/users/${userId}/bundles/${productCode}?currentPage=${currentPage}&pageSize=4`;
    return this.httpClient.get<BundleResponse>(url);
  }

  private getBundlesByProductCode(productCode: string): Observable<BundleResponse> {
    const url = `${this.occEndpoints.getBaseUrl()}/products/${productCode}?fields=bundleTemplates(DEFAULT)`;
    return this.httpClient.get<BundleResponse>(url);
  }

  private getBundlesWithoutProductCode(userId: string): Observable<BundleResponse> {
    const url = `${this.occEndpoints.getBaseUrl()}/users/${userId}/bundles`;
    return this.httpClient.get<BundleResponse>(url);
  }

  private getPrices(userEmail: string, unitId: string, productCodes: string[],): Observable<BundlePrice> {
    const params = `${productCodes.map(productCode => `codes=${productCode}`).join('&')}`;
    const url = `${this.occEndpoints.getBaseUrl()}/users/${userEmail}/company/${unitId}/cpiPriceBundle?${params}`;
    return this.httpClient.get<BundlePrice>(url)
      .pipe(
        catchError(() => this.baseStoreService.getPhoneNumber()
          .pipe(
            switchMap(({contactInfo}) => {
              return this.translation.translate('httpHandlers.netPriceError', {
                phoneNumber: contactInfo
              })
            }),
            take(1),
            map(errorMessage => ({errorMessages: [errorMessage]}))
          )
        )
      );
  }

  private mapBundlesWithPrices(bundles: Bundle[], bundlePriceResponse: BundlePriceResponse, sortByFn?: (a: ExtendedBundle, b: ExtendedBundle) => number): ExtendedBundle[] {
    const mappedBundles = bundles.reduce((acc, bundle, index) => {
      const selectedBundlePriceResponse = bundlePriceResponse.length ? bundlePriceResponse[index] : null;
      const items = this.mapItemsWithPrice(bundle.items, selectedBundlePriceResponse?.products || []);
      acc.push({
        ...bundle,
        product: items?.length ? items[0] : undefined,
        items: items?.slice(1),
        totalPrice: selectedBundlePriceResponse?.totalPrice || null,
        errorMessages: selectedBundlePriceResponse?.errorMessages
      });
      return acc;
    }, []);
    return sortByFn ? mappedBundles.sort(sortByFn) : mappedBundles;
  }

  private mapItemsWithPrice(items: BundleItem[], prices: BundleItemPrice[]) {
    return items.reduce((acc: BundleItem[], item: BundleItem, index) => {
      const productImage = item.images?.find(image => image.format === ImageFormat.Product);
      const price = prices.length ?
        {
          currency: prices[index].currency,
          value: prices[index].value,
          unit: prices[index].unit
        } : null;
      acc.push({
        ...item,
        images: productImage ? [productImage] : [],
        price
      });
      return acc;
    }, [])
  }

  private sortByEmptyTotalPrice(bundleA: ExtendedBundle, bundleB: ExtendedBundle) {
    if ((bundleA.totalPrice && bundleB.totalPrice) || (!bundleA.totalPrice && !bundleB.totalPrice)) {
      return 0;
    } else if (!bundleA.totalPrice) {
      return 1;
    } else if (!bundleB.totalPrice) {
      return -1;
    }
    return 0;
  };

  private sortByTotalPrice(bundleA: ExtendedBundle, bundleB: ExtendedBundle): number {
    if (bundleA.totalPrice && bundleB.totalPrice) {
      return +bundleA.totalPrice - +bundleB.totalPrice;
    } else if (bundleA.totalPrice) {
      return -1;
    } else if (bundleB.totalPrice) {
      return 1;
    }
    return 0;
  };

}
