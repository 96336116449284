import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DeliveryDateGroup, ManufactureGroup, ShippingGroup, SingleConsignmentInfo } from 'src/app/interfaces/cart';
import { CommonModule } from '@angular/common';
import { InfoMessageModule } from 'src/app/shared/components/info-message/info-message.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { CheckoutScheduleReplenishmentOrderModule } from '../checkout-schedule-replenishment-order/checkout-schedule-replenishment-order.module';
import { CartSharedModule } from '../../../cart/cart-shared';
import { BehaviorSubject } from 'rxjs';
import { CheckoutSingleConsignmentComponent } from '../checkout-single-consignment/checkout-single-consignment.component';
import { CartType } from 'src/app/enums/cart-type.enum';
import { I18nModule } from '@spartacus/core';

@Component({
  selector: 'generac-checkout-delivery-groups',
  templateUrl: './checkout-delivery-groups.component.html',
  styleUrls: ['./checkout-delivery-groups.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    InfoMessageModule,
    MatExpansionModule,
    CartSharedModule,
    I18nModule,
    CheckoutScheduleReplenishmentOrderModule,
    CheckoutSingleConsignmentComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutDeliveryGroupsComponent implements OnInit {
  private updateDataSubject$$ = new BehaviorSubject<string | null>(null);

  @Input() shippingGroup: ShippingGroup;
  @Input() cartCode: string;
  @Input() purchaseOrderNumber: string;
  @Input() isServicePartsEnabled: boolean;
  @Input() isOrderReview: boolean;
  desiredShipmentDateAvailable: boolean = JSON.parse(localStorage.getItem('desiredShipmentDateAvailable'));
  panelOpenState: boolean[] = [];
  updateDataTrigger$ = this.updateDataSubject$$.asObservable();
  isSpStep: boolean;
  isSubsStep: boolean;

  getSingleConsignmentInfo(manufactureGroupId: string): void {
    this.updateDataSubject$$.next(manufactureGroupId);
  }

  ngOnInit() {
    this.isSpStep = this.shippingGroup?.cartTypes[0] == CartType.SERVICE_PARTS;
    this.isSubsStep = this.shippingGroup?.cartTypes[0] == CartType.SUBSCRIPTION;
  }

  isManufactureGroup(group: DeliveryDateGroup | ManufactureGroup): group is ManufactureGroup {
    return (group as ManufactureGroup).manufactureGroupID !== undefined;
  }

  getManufactureGroupID(group: DeliveryDateGroup | ManufactureGroup): string | undefined {
    return this.isManufactureGroup(group) ? group.manufactureGroupID : undefined;
  }

  getDeliveryGroupTitle(group: DeliveryDateGroup | ManufactureGroup): string | undefined {
    return this.isManufactureGroup(group) ? group.manufactureGroupIndex || group.manufactureGroupID : group?.deliveryDateGroupIndex;
  }

  getDeliveryGroupSingleConsigmentInfo(group: DeliveryDateGroup | ManufactureGroup): SingleConsignmentInfo | undefined {
    return this.isManufactureGroup(group) ? group.singleConsignmentInfo : undefined;
  }

  getGroupDeliveryDate(group: DeliveryDateGroup | ManufactureGroup): string | undefined {
    return !this.isManufactureGroup(group) ? group.groupDeliveryDate : undefined;
  }
}
