import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { GlobalMessageService, GlobalMessageType } from "@spartacus/core";
import { LaunchDialogService } from "@spartacus/storefront";
import { UserAccountFacade } from "@spartacus/user/account/root";
import { Observable, ReplaySubject, Subscription } from "rxjs";
import { filter, switchMap, take, tap } from "rxjs/operators";
import { UserGroup } from "src/app/enums/user.enum";
import { ExtAddress } from "src/app/interfaces/checkout";
import { CheckoutService } from "src/app/services/checkout.service";
import { CustomerService } from "src/app/services/customer.service";

@Component({
  selector: 'saved-addresses',
  templateUrl: './saved-addresses.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SavedAddressesComponent implements OnInit, OnDestroy {
  private savedAddressesSubject = new ReplaySubject<ExtAddress[]>(1);
  savedAddresses$: Observable<ExtAddress[]> = this.savedAddressesSubject.asObservable();
  limitCountOfAddressesMessage: string;
  isUserRestricted: boolean = false;

  private subscription = new Subscription();

  constructor(
    protected checkoutService: CheckoutService,
    protected customerService: CustomerService,
    protected cd: ChangeDetectorRef,
    protected launchDialogService: LaunchDialogService,
    private userAccount: UserAccountFacade,
    private globalMessageService: GlobalMessageService,
  ) { }

  ngOnInit(): void {
    this.getSavedAddresses();
    this.subscribeToUnitChange();
  }

  savedAddressDeleted() {
    this.getSavedAddresses();
  }

  public openAddSavedAddressModal(): void {
    if (this.isUserRestricted) return;
    if (this.limitCountOfAddressesMessage) {
      return this.globalMessageService.add(this.limitCountOfAddressesMessage, GlobalMessageType.MSG_TYPE_WARNING);
    }
    const dialog = this.launchDialogService.openDialog(
      'EDIT_SAVED_ADDRESS',
      undefined,
      undefined,
      {}
    );

    if (dialog) {
      dialog.pipe(take(1)).subscribe();
    }

    this.subscription.add(
      this.launchDialogService.dialogClose
        .pipe(
          filter((data) => !!data?.companyName),
          take(1),
          tap(() => this.getSavedAddresses())
        )
        .subscribe()
    );
  }

  trackById(index: number, item: any) {
    return item?.id;
  }

  private getSavedAddresses() {
    this.subscription.add(
      this.userAccount.get().pipe(
        filter(user => !!user),
        take(1),
        tap((user) => this.isUserRestricted = user?.roles.includes(UserGroup.savedaddresschangerestrictedgroup)),
        switchMap((user) => this.checkoutService.getDropShipAddresses(user?.uid)),
        tap((res: any) => {
          this.limitCountOfAddressesMessage = res?.message ? res?.message : null;
          this.savedAddressesSubject.next(res.addresses);
          this.cd.markForCheck();
        })
      ).subscribe()
    )
  }

  private subscribeToUnitChange(): void {
    this.subscription.add(
      this.customerService.getB2bUnitChanged().pipe(
        filter((isB2bUnitChanged) => !!isB2bUnitChanged),
        tap(() => this.getSavedAddresses())
      ).subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}