import { NgModule } from '@angular/core';
import { cartBaseTranslationChunksConfig, cartBaseTranslations } from '@spartacus/cart/base/assets';
import { ADD_TO_CART_FEATURE, CartBaseRootModule, CART_BASE_FEATURE, MINI_CART_FEATURE } from '@spartacus/cart/base/root';
import { CmsConfig, I18nConfig, provideConfig } from '@spartacus/core';
import { CUSTOM_EMPTY_CART_FEATURE } from './root/feature-name';
import { OrderConfirmationGuard } from '@spartacus/order/components';
import { CustomOrderConfirmationThankYouMessageComponent } from './quick-order/components/custom-order-confirmation-thank-you-message/custom-order-confirmation-thank-you-message.component';
import { CustomOrderConfirmationThankYouMessageModule } from './quick-order/components/custom-order-confirmation-thank-you-message/custom-order-confirmation-thank-you-message.module';

@NgModule({
  declarations: [],
  imports: [CartBaseRootModule, CustomOrderConfirmationThankYouMessageModule],
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        [CART_BASE_FEATURE]: {
          module: () =>
            import('./custom-cart-base.module').then(
              (m) => m.CustomCartBaseModule
            ),
        },
        [CUSTOM_EMPTY_CART_FEATURE]: {
          module: () =>
            import('./custom-empty-cart/custom-empty-cart.module').then(
              (m) => m.CustomEmptyCartModule
            ),
          cmsComponents: ['CustomEmptyCartComponent'],
        },
      },
    }),
    provideConfig(<CmsConfig>{
      featureModules: {
        [MINI_CART_FEATURE]: {
          module: () =>
            import('@spartacus/cart/base/components/mini-cart').then(
              (m) => m.MiniCartModule
            ),
        },
      },
    }),
    provideConfig(<CmsConfig>{
      featureModules: {
        [ADD_TO_CART_FEATURE]: {
          module: () =>
            import('@spartacus/cart/base/components/add-to-cart').then(
              (m) => m.AddToCartModule
            ),
        },
      },
      cmsComponents: {
        OrderConfirmationThankMessageComponent: {
          component: CustomOrderConfirmationThankYouMessageComponent,
          guards: [OrderConfirmationGuard],
        },
      }
    }),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: cartBaseTranslations,
        chunks: cartBaseTranslationChunksConfig,
      },
    }),
  ],
})
export class GeneracCartBaseFeatureModule {}
