import { Component, OnInit } from '@angular/core';
import { CartOutlets, PromotionLocation } from '@spartacus/cart/base/root';
import { CmsOrderDetailItemsComponent, TranslationService } from '@spartacus/core';
import { Consignment, Order } from '@spartacus/order/root';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrderDetailsService } from "@spartacus/order/components";

export const completedValues = ['DELIVERY_COMPLETED', 'PICKUP_COMPLETE'];
export const cancelledValues = ['CANCELLED'];

@Component({
    selector: 'cx-order-details-items',
    templateUrl: './order-detail-items.component.html',
})
export class OrderDetailItemsComponent implements OnInit {
    constructor(
        protected orderDetailsService: OrderDetailsService,
        protected component: CmsComponentData<CmsOrderDetailItemsComponent>,
        protected translation: TranslationService
    ) {}

    readonly CartOutlets = CartOutlets;

    promotionLocation: PromotionLocation = PromotionLocation.Order;
    order$: Observable<Order | any> = this.orderDetailsService.getOrderDetails();
    others$: Observable<Consignment[] | undefined>;
    completed$: Observable<Consignment[] | undefined>;
    cancel$: Observable<Consignment[] | undefined>;
    buyItAgainTranslation$: Observable<string>;
    enableAddToCart$: Observable<boolean | undefined> = this.component.data$.pipe(
        map((data) => data.enableAddToCart)
    );
    isOrderLoading$: Observable<boolean>;

    ngOnInit() {
        this.isOrderLoading$ =
            typeof this.orderDetailsService.isOrderDetailsLoading === 'function'
                ? this.orderDetailsService.isOrderDetailsLoading()
                : of(false);
        this.others$ = this.getOtherStatus(...completedValues, ...cancelledValues);
        this.completed$ = this.getExactStatus(completedValues);
        this.cancel$ = this.getExactStatus(cancelledValues);
        this.buyItAgainTranslation$ = this.translation.translate(
            'addToCart.buyItAgain'
        );
    }

    private getExactStatus(
        consignmentStatus: string[]
    ): Observable<Consignment[] | undefined> {
        return this.order$.pipe(
            map((order: any) => {
                if (Boolean(order.consignments)) {
                    return order.consignments?.filter(
                        (consignment: Consignment) =>
                            consignment.status &&
                            consignmentStatus.includes(consignment.status)
                    );
                }
            })
        );
    }

    private getOtherStatus(
        ...consignmentStatus: string[]
    ): Observable<Consignment[] | undefined> {
        return this.order$.pipe(
            map((order: any) => {
                if (Boolean(order.consignments)) {
                    return order.consignments?.filter(
                        (consignment: Consignment) =>
                            consignment.status &&
                            !consignmentStatus.includes(consignment.status)
                    );
                }
            })
        );
    }
}
