import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ActiveCartFacade } from "@spartacus/cart/base/root";
import { map } from "rxjs/operators";
import { Cart } from "@spartacus/cart/base/root/models/cart.model";
import { RouteTrackerService } from "../services/route-tracker.service";

@Injectable({
  providedIn: 'root'
})
export class CustomCartNotEmptyGuard implements CanActivate {
  constructor(
    private activeCartFacade: ActiveCartFacade,
    private routeTrackerService: RouteTrackerService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.activeCartFacade.takeActive()
      .pipe(
        map((cart) => {
          if(!this.isEmpty(cart)) {
            return true;
          }
          this.router.navigate([this.routeTrackerService.getCurrentUrl()]);
          return false;
        })
      )
  }

  private isEmpty(cart: Cart): boolean {
    return cart && !cart.totalItems;
  }
  
}
