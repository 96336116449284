/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { CardComponent } from './card.component';
import { AtMessageModule, IconModule, TruncateTextPopoverModule, KeyboardFocusModule } from '@spartacus/storefront';

@NgModule({
  imports: [
    CommonModule,
    AtMessageModule,
    I18nModule,
    IconModule,
    TruncateTextPopoverModule,
    KeyboardFocusModule,
  ],
  declarations: [CardComponent],
  exports: [CardComponent],
})
export class CardModule {}
