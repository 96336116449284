<cx-carousel
  *ngIf="items$ | async as items"
  role="region"
  [items]="items"
  [title]="title$ | async"
  [template]="carouselItem"
  itemWidth="273px"
>
</cx-carousel>

<ng-template
  #carouselItem
  let-item="item"
>
  <cx-product-carousel-item [item]="item" [gaListName]="gaListName"></cx-product-carousel-item>
</ng-template>