import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomMessageComponent } from "./custom-message.component";
import { IconModule } from "@spartacus/storefront";

@NgModule({
  declarations: [
    CustomMessageComponent
  ],
  exports:[
    CustomMessageComponent
  ],
  imports: [
    CommonModule,
    IconModule
  ]
})
export class CustomMessageModule { }
