import { CartEntryAdapter } from '@spartacus/cart/base/core';
import { CartModification } from '@spartacus/cart/base/root';
import { Observable } from 'rxjs';

export abstract class GeneracCartEntryAdapter extends CartEntryAdapter {
  abstract override add(
    userId: string,
    cartId: string,
    productCode: string,
    quantity?: number,
    itemListName?: string,
    navigatedToPdpFrom?: string,
  ): Observable<CartModification>;
}
