import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from "@spartacus/storefront";
import { OfflineBadgeComponent } from './offline-badge.component';
import { InfoMessageModule } from '../info-message/info-message.module';

@NgModule({
  declarations: [OfflineBadgeComponent],
  imports: [CommonModule, IconModule, InfoMessageModule],
  exports: [OfflineBadgeComponent]
})
export class OfflineBadgeModule {}
