import { DOCUMENT } from "@angular/common";
import { ComponentRef, Inject, Injectable } from "@angular/core";
import { BaseSiteService } from "@spartacus/core";
import { filter, take } from "rxjs/operators";
import { CssThemes, sitesByTheme } from "../enums/themes.enum";

@Injectable({
  providedIn: "root"
})
export class CustomThemeService {
  constructor(
    protected baseSiteService: BaseSiteService,
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  init(): void {
    this.baseSiteService.get()
      .pipe(
        filter(baseSite => !!baseSite),
        take(1),
      ).subscribe((baseSite) => {
      this.loadThemeCss(baseSite.theme as CssThemes);
      this.setFavicon(baseSite.theme as CssThemes);
    });
  }

  private loadThemeCss(styleName: CssThemes) {
    const head = this.document.getElementsByTagName('head')[0];
    let themeLink = this.document.getElementById(
      'client-theme'
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = this.document.createElement('link');
      style.id = 'site-theme';
      style.rel = 'stylesheet';
      style.href = `${styleName}-theme.css`;

      head.appendChild(style);
    }
  }

  private setFavicon(theme: CssThemes) {
    const fileName = sitesByTheme[theme];
    this.document.getElementById('favicon')
      .setAttribute('href', `assets/favicons/${fileName}.ico`);
  }
}
