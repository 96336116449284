<div [cxFocus]="focusConfig" (esc)="close('Escape clicked')" class="add-to-favorites-dialog">
  <div class="add-to-favorites-dialog-container">
    <!-- Modal Header -->
    <div class="modal-header add-to-favorites-dialog-header">
      <div class="add-to-favorites-dialog-title modal-title">
        {{ 'addToWishList.addToFavoriteModal.title' | cxTranslate }}
      </div>

      <button (click)="close('Close Add To Favorites Dialog')" [attr.aria-label]="'common.close' | cxTranslate"
        class="add-to-favorites-dialog-close close" type="button">
        <img class="" src="../../../../../../../assets/icons/close_black_24.svg">
      </button>
    </div>

    <!-- Modal Body -->
    <div class="add-to-favorites-dialog-body">
      <div class="info-message-block">
        <img class="info-message-icon" src="../../../../../assets/icons/info-grey.svg">
        <span class="info-message-text">{{ 'addToWishList.addToFavoriteModal.infoMessage' | cxTranslate }}</span>
      </div>
      <p class="select-list-text">{{ 'addToWishList.addToFavoriteModal.selectList' | cxTranslate }}</p>
      <div class="existing-lists-of-favorites" *ngIf="favoritesLists?.length > 0">
        <div *ngFor="let list of favoritesLists" class="list-item">
          <label class="form-check">
            <span class="form-check-label">{{ list.name }}</span>
            <input type="checkbox" role="checkbox" class="checkbox" [checked]="list.productAlreadyInList"
              (change)="selectList($event.target, list)">
          </label>
          <span class="label-indicator" [ngClass]="{'added': listsStateObj[list.id] == 1, 'removed': listsStateObj[list.id] == -1}">
            <ng-container [ngSwitch]="listsStateObj[list.id]">
              <ng-container *ngSwitchCase="1">
                {{ 'addToWishList.addToFavoriteModal.addedLabel' | cxTranslate }}
              </ng-container>
              <ng-container *ngSwitchCase="-1">
                {{ 'addToWishList.addToFavoriteModal.removedLabel' | cxTranslate }}
              </ng-container>
            </ng-container>
          </span>
        </div>
      </div>

      <p class="select-list-text">{{ 'addToWishList.favoriteList.addNewList' | cxTranslate }}</p>
      <div class="add-list-wrapper">
        <input class="add-list-input" type="text" maxlength="100" [(ngModel)]="customListName"
          placeholder="{{ 'addToWishList.favoriteList.enterListName' | cxTranslate}}">
        <button class="add-list-btn btn-secondary" (click)="selectList({checked: true}, {name: customListName})"
          [disabled]="customListName?.length < 1 || getLoadingStatus()">
          {{ 'addToWishList.addToFavoriteModal.add' | cxTranslate }}
        </button>
      </div>
    </div>
  </div>
</div>