import { Injectable } from '@angular/core';
import { Cart } from '@spartacus/cart/base/root';
import { Observable } from 'rxjs';
import { SavedCartAdapter } from './saved-cart.adapter';
import { CartExtended } from 'src/app/interfaces/cart';

@Injectable()
export class SavedCartConnector {
  constructor(protected adapter: SavedCartAdapter) {}

  get(userId: string, cartId: string, cartOwner: string): Observable<Cart> {
    return this.adapter.load(userId, cartId, cartOwner);
  }

  getList(userId: string): Observable<Cart[]> {
    return this.adapter.loadList(userId);
  }

  restoreSavedCart(userId: string, cartId: string, cartOwner: string): Observable<CartExtended> {
    return this.adapter.restoreSavedCart(userId, cartId, cartOwner);
  }

  cloneSavedCart(
    userId: string,
    cartId: string,
    saveCartName?: string,
    cartOwner?: string
  ): Observable<Cart> {
    return this.adapter.cloneSavedCart(userId, cartId, saveCartName, cartOwner);
  }
}
