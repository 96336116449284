<ng-container *ngIf="product$ | async as product">
  <ng-container *ngIf="mainImage$ | async as main">
    <cx-media *ngIf="(product.servicePart && product.images?.PRIMARY) || !product.servicePart; else spGearThumb"
      [container]="main" (click)="triggerZoom(true)"></cx-media>
    <ng-template #spGearThumb>
      <img src="../../../../../../assets/icons/sp_gear_thumb.svg" alt="gear" class="sp-gear-thumb">
    </ng-template>
    <cx-product-image-zoom-trigger *ngIf="(product.servicePart && product.images?.PRIMARY) || !product.servicePart" [expandImage]="expandImage.value" [galleryIndex]="selectedIndex"
      (dialogClose)="triggerZoom(false)"></cx-product-image-zoom-trigger>
  </ng-container>

  <ng-container *ngIf="thumbs$ | async as thumbs">
    <cx-carousel role="region" [attr.aria-label]="
        'carousel.carouselForProduct' | cxTranslate: { product: product.name }
      " tabindex="-1" *ngIf="thumbs.length" class="thumbs" [items]="thumbs" itemWidth="120px" [hideIndicators]="false"
      [template]="thumb"></cx-carousel>
  </ng-container>
</ng-container>


<ng-template #thumb let-item="item">
  <cx-media [container]="item.container" tabindex="0" (focus)="openImage(item.container)"
    [class.is-active]="isActive(item.container) | async" format="product">
  </cx-media>
</ng-template>