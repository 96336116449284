/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  CartOutlets,
  DeleteCartSuccessEvent as DeleteSavedCartSuccessEvent,
  PromotionLocation
} from '@spartacus/cart/base/root';
import {
  EventService,
  GlobalMessageService,
  GlobalMessageType,
  RoutingService,
  TranslationService
} from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, mapTo, switchMap, take, tap } from 'rxjs/operators';
import { SavedCartDetailsService } from '../saved-cart-details.service';
import { SavedCartFacade } from '../../../root/facade';
import { LoadingEnum } from 'src/app/enums/loading.enum';
import { BundleService } from "../../../../../../services/bundle.service";
import { CartExtended } from "../../../../../../interfaces/cart";
import { AddToBundleResponse } from "../../../../../../interfaces/bundle.model";
import { isEqual } from "../../../../../../shared/helpers/is-equal";

@Component({
  selector: 'cx-saved-cart-details-items',
  templateUrl: './saved-cart-details-items.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SavedCartDetailsItemsComponent implements OnInit, OnDestroy {
  LoadingEnum = LoadingEnum;

  readonly CartOutlets = CartOutlets;

  CartLocation = PromotionLocation;

  buyItAgainTranslation$: Observable<string>;

  cartLoaded$: Observable<boolean> = this.savedCartDetailsService
    .getSavedCartId()
    .pipe(switchMap((cartId) => this.savedCartService.isStable(cartId)));

  savedCart: CartExtended;
  loading$: Observable<LoadingEnum>;
  private subscription = new Subscription()

  constructor(
    protected savedCartDetailsService: SavedCartDetailsService,
    protected savedCartService: SavedCartFacade,
    protected eventSercvice: EventService,
    protected globalMessageService: GlobalMessageService,
    protected routingService: RoutingService,
    protected translation: TranslationService,
    private bundleService: BundleService,
    private cd: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.subscribeToEventService();
    this.subscribeToSavedCart();
    this.buyItAgainTranslation$ = this.translation.translate('addToCart.addToActiveCart');
    this.loading$ = this.bundleService.loading$;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onDeleteComplete(): void {
    this.routingService.go({cxRoute: 'savedCarts'});

    if (!this.savedCart.isEmptyAfterBackendCleanup) {
      this.globalMessageService.add(
        {
          key: 'savedCartDialog.deleteCartSuccess',
        },
        GlobalMessageType.MSG_TYPE_CONFIRMATION
      );
    }
  }

  onBundleAdded(response: AddToBundleResponse) {
    if(response?.error?.type !== "CommerceCartModificationError") {
      return;
    }
    this.savedCartDetailsService.reloadSavedCart();
  }

  private subscribeToEventService() {
    this.subscription.add(
      this.eventSercvice
        .get(DeleteSavedCartSuccessEvent)
        .pipe(take(1), mapTo(true))
        .subscribe((success) => this.onDeleteComplete())
    );
  }

  private subscribeToSavedCart() {
    this.subscription.add(
      this.savedCartDetailsService
        .getCartDetails()
        .pipe(
          distinctUntilChanged(isEqual),
          tap((cart) => {
            if (((cart as CartExtended)?.entryGroups ?? []).length <= 0 && !!cart?.code) {
              this.savedCartService.deleteSavedCart(cart.code);
            }
          })
        ).subscribe(savedCart => {
          this.savedCart = savedCart as CartExtended;
          this.cd.markForCheck();
      })
    )
  }

}
