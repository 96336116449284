import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { IconModule, MediaModule } from '@spartacus/storefront';
import { SharedModule } from 'src/app/shared/shared.module';
import { CustomProductAddToCartModule } from '../../../cart/components/custom-product-add-to-cart/custom-product-add-to-cart.module';
import { InfoMessageModule } from 'src/app/shared/components/info-message/info-message.module';
import { InfoPriceWrapperComponent } from "../info-price-wrapper/info-price-wrapper.component";
import { GeneracSpInfoComponent } from './generac-sp-info.component';
import { GeneracGreenCheckIconComponent } from './generac-green-check-icon/generac-green-check-icon.component';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    CustomProductAddToCartModule,
    SharedModule,
    IconModule,
    MediaModule,
    InfoMessageModule,
    InfoPriceWrapperComponent,
    GeneracGreenCheckIconComponent,
  ],
  declarations: [GeneracSpInfoComponent],
  exports: [GeneracSpInfoComponent],
})
export class GeneracSpInfoModule {}
