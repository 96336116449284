<div class="cx-modal-container" [cxFocus]="focusConfig">
  <div class="cx-modal-content">
    <!-- Modal Header -->
    <ng-container>
      <div class="cx-dialog-header modal-header">
        <div class="cx-dialog-title modal-title">
          Verify Your Address
        </div>
        <button type="button" class="close" (click)="dismiss('Cross click')"
          attr.aria-label="{{ 'addToCart.closeModal' | cxTranslate }}">
          <span aria-hidden="true">
            <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
          </span>
        </button>
      </div>
      <!-- Separator -->
      <div class="cx-dialog-separator col-sm-12"></div>

      <!-- Modal Body -->
      <div class="modal-body">
        <div *ngIf="errorMessage" class="error-description" 
        [class]="{'error-description-hidden': hideErrorDescription}">
          <div class="cx-dialog-item">
            <cx-icon class="regular-icon active" [type]="iconTypes.INFO"></cx-icon>
            <span>
              {{ errorMessage | cxTranslate }}
            </span>
            <button type="button" class="close" (click)="hideErrorDescription = true;">
              <img class="" src="../../../../../../../assets/icons/close_black_24.svg">
            </button>
          </div>
        </div>
        <ng-container *ngIf="suggestedAddresses.length > 0">
          <div class="cx-dialog-item">
            We suggest you accept one of the following changes to your address:
          </div>

          <div class="overflow-auto">
            <div class="suggested-address" *ngFor="let address of suggestedAddresses; let i = index"
              (click)="selectedIndex = i">
              <div class="suggested-wrapper">
                <div class="suggested-wrapper-title">
                  Suggested Address: <span>{{ address.line1 }}</span>
                </div>
                <div class="suggested-desc">{{ address.town }}, {{ address.postalCode }}, {{ address.region.isocode }}
                </div>
              </div>
              <div *ngIf="selectedIndex === i" class="selected-block">
                <img src="../../../../../../assets/icons/done_orange_24.svg" />
                <span>Selected</span>
              </div>
            </div>
          </div>
        </ng-container>


        <div class="cx-dialog-item pt-4 pb-3">
          You can keep your original address:
        </div>

        <div class="suggested-address entered" (click)="selectedIndex = null">
          <div class="suggested-wrapper">
            <div class="suggested-wrapper-title">
              User Entered Address: <span>{{ currentAddress.line1 }}</span>
            </div>
            <div class="suggested-desc">{{ currentAddress.town }}, {{ currentAddress.postalCode }}, {{
              currentAddress.region.isocode }}</div>
          </div>
          <div *ngIf="selectedIndex === null" class="selected-block">
            <img src="../../../../../../assets/icons/done_orange_24.svg" />
            <span>Selected</span>
          </div>
        </div>

        <div class="cx-dialog-actions">
          <div class="cx-dialog-buttons">
            <button (click)="dismiss('Cancel click')" class="cx-btn btn btn-block btn-secondary mr-2">Cancel</button>
            <button class="cx-btn btn btn-block btn-primary mt-0" [disabled]="selectedIndex === undefined"
              (click)="dismiss()">Apply
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>