import { NgModule } from '@angular/core';
import { SavedCartCoreModule } from './core/saved-cart-core.module';
import { SavedCartOccModule } from './occ/saved-cart-occ.module';
import { SavedCartComponentsModule } from './components/saved-cart-components.module';
import { I18nConfig, provideConfig } from '@spartacus/core';
import { savedCartTranslations, savedCartTranslationChunksConfig } from './assets/translations/translations';
import { CartAdapter } from '@spartacus/cart/base/core';
import { OccCartAdapter } from '../cart/occ/adapters/occ-cart.adapter';

@NgModule({
  imports: [SavedCartCoreModule, SavedCartOccModule, SavedCartComponentsModule],
  providers: [
    provideConfig(<I18nConfig>{
      i18n: {
        resources: savedCartTranslations,
        chunks: savedCartTranslationChunksConfig,
      },
    }),
    {
      provide: CartAdapter,
      useClass: OccCartAdapter,
    },
  ]
})
export class SavedCartModule {}
