import { Component, Input } from '@angular/core';
import { Badge } from 'src/app/interfaces/product';

@Component({
  selector: 'generac-product-badge',
  templateUrl: './generac-product-badge.component.html',
  styleUrls: ['./generac-product-badge.component.scss'],
})
export class GeneracProductBadgeComponent {
  @Input() badge: Badge;
}
