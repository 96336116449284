<div class="d-flex justify-content-between align-items-center">
    <h2 class="cx-checkout-title d-block">
        {{ 'checkoutB2B.methodOfPayment.paymentType' | cxTranslate }}
    </h2>
    <cx-offline-badge *ngIf="!isServicePartsEnabled" [internalCalculation]="internalCalculation"></cx-offline-badge>
</div>

<ng-container *ngIf="paymentTypes$ | async as paymentTypes">
    <ng-container
            *ngIf="
      !!paymentTypes.length &&
        (typeSelected$ | async)
      else loading
    "
    >
            <div role="status" [attr.aria-label]="'common.loaded' | cxTranslate"></div>

            <div class="row">
                <div class="col-md-12">
                    <div class="cx-payment-type-container">
                        <span class="label-content">{{'paymentTypes.title' | cxTranslate}}</span>
                        <div class="form-check" *ngFor="let type of paymentTypes">
                            <input
                                id="paymentType-{{ type.code }}"
                                class="form-check-input"
                                role="radio"
                                type="radio"
                                aria-checked="true"
                                name="payments"
                                (change)="changeType(type)"
                                [value]="type.code"
                                [disabled]="!type.available && typeSelected == 'WELLS_FARGO'"
                                [checked]="type.code === typeSelected"
                                formControlName="paymentType"
                            />
                            <label
                                    class="cx-payment-type-label form-check-label form-radio-label"
                                    for="paymentType-{{ type.code }}"
                            >
                                <div class="cx-payment-type">
                                    {{ 'paymentTypes.paymentType_' + type?.code | cxTranslate }}
                                </div>
                            </label>
                            <ng-container *ngIf="type.messages && typeSelected === type.code">
                                <p class="form-check-message" *ngFor="let message of type.messages">{{ message }}</p>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>

            <generac-checkout-control-btns
                [isContinueDisabled]="!isAvailable || (isUpdating$ | async)"
                [isNextActionCustom]="true"
                (nextBtnClicked)="next()"
            >
            </generac-checkout-control-btns>
    </ng-container>
</ng-container>

<ng-template #loading>
    <div class="cx-spinner">
        <cx-spinner></cx-spinner>
    </div>
</ng-template>
