import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BundleCarouselItemComponent } from "./bundle-carousel-item/bundle-carousel-item.component";
import { BundleCornerItemComponent } from "./bundle-corner-item/bundle-corner-item.component";
import { BundleSlideComponent } from "./bundle-slide.component";
import { RouterModule } from "@angular/router";
import { I18nModule, provideConfig, TranslationChunksConfig, UrlModule } from "@spartacus/core";
import { IconModule, MediaModule, SpinnerModule } from "@spartacus/storefront";
import { InfoMessageModule } from "../info-message/info-message.module";
import { CustomMessageModule } from "../custom-message/custom-message.module";
import { translationChunksConfig, translations } from "@spartacus/assets";

export const productTranslationChunksConfig: TranslationChunksConfig = {
  product: [
    ...translationChunksConfig.product,
    'bundle'
  ],
};

@NgModule({
  declarations: [
    BundleSlideComponent,
    BundleCarouselItemComponent,
    BundleCornerItemComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    I18nModule,
    IconModule,
    InfoMessageModule,
    UrlModule,
    CustomMessageModule,
    MediaModule,
    SpinnerModule
  ],
  exports: [
    BundleSlideComponent
  ],
  providers:[
    provideConfig({
      i18n: {
        resources: {
          en: translations
        },
        chunks: productTranslationChunksConfig
      }
    })
  ]
})
export class BundleSlideModule { }
