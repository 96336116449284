import { Injectable } from '@angular/core';
import { MultiCartFacade } from '@spartacus/cart/base/root';
import { UserIdService, WindowRef } from '@spartacus/core';
import { withLatestFrom } from 'rxjs/operators';
import { getCartIdByUserId } from '../utils/utils';
import { GeneracActiveCartFacade } from './active-cart.facade';
import { GeneracMultiCartService } from './multi-cart.service';
import { ActiveCartService } from '@spartacus/cart/base/core';
import { CartExtended } from 'src/app/interfaces/cart';
import { Observable } from 'rxjs';

@Injectable()
export class GeneracActiveCartService extends ActiveCartService implements GeneracActiveCartFacade {
  override activeCart$: Observable<CartExtended>;

  constructor(
    protected generacMultiCartService: GeneracMultiCartService,
    multiCartFacade: MultiCartFacade,
    userIdService: UserIdService,
    winRef: WindowRef,
  ) {
    super(multiCartFacade, userIdService, winRef);
  }
 
  override addEntry(productCode: string, quantity: number, itemListName?: string, navigatedToPdpFrom?: string): void {
    this.requireLoadedCart()
      .pipe(withLatestFrom(this.userIdService.getUserId()))
      .subscribe(([cart, userId]) => {
        this.generacMultiCartService.addEntry(
          userId,
          getCartIdByUserId(cart, userId),
          productCode,
          quantity,
          itemListName,
          navigatedToPdpFrom,
        );
      });
  }

  override getActive(): Observable<CartExtended> {
    return this.activeCart$;
  }
}
