import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { OrderConfirmationItemsComponent } from '@spartacus/order/components';
import { OrderFacade } from '@spartacus/order/root';
import { Observable } from 'rxjs';
import { OrderExtended } from 'src/app/interfaces/order';

@Component({
    selector: 'cx-order-confirmation-items',
    templateUrl: './order-confirmation-items.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneracOrderConfirmationItemsComponent extends OrderConfirmationItemsComponent implements OnDestroy {
    override order$: Observable<OrderExtended | undefined> = this.orderFacade.getOrderDetails() as Observable<OrderExtended | undefined>;
    isServicePartsEnabled: boolean;

    constructor(
        override orderFacade: OrderFacade,
        protected winRef: WindowRef,
    ) {
        super(orderFacade);
        this.isServicePartsEnabled = JSON.parse(this.winRef.localStorage.getItem('isServicePartsEnabled'));
    }
}
