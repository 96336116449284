import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ICON_TYPE } from "@spartacus/storefront";

@Component({
  selector: 'cx-no-return-warning',
  templateUrl: './no-return-warning.component.html',
  styleUrls: ['./no-return-warning.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoReturnWarningComponent {
  @Input() color: string;
  @Input() message: string;
  iconTypes = ICON_TYPE;
}
