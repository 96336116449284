import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomCheckoutPaymentTypeComponent } from "./custom-checkout-payment-type.component";
import { CmsConfig, I18nModule, provideConfig } from "@spartacus/core";
import { SpinnerModule } from "@spartacus/storefront";
import { CheckoutAuthGuard } from "@spartacus/checkout/base/components";
import { CartValidationGuard } from "@spartacus/cart/base/core";
import { OfflineBadgeModule } from 'src/app/shared/components/offline-badge/offline-badge.module';
import { CustomCartNotEmptyGuard } from "../../../../../guards/custom-cart-not-empty.guard";

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    SpinnerModule,
    OfflineBadgeModule
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutPaymentType: {
          component: CustomCheckoutPaymentTypeComponent,
          guards: [CheckoutAuthGuard, CustomCartNotEmptyGuard, CartValidationGuard],
        },
      },
    }),
  ],
  declarations: [CustomCheckoutPaymentTypeComponent],
  exports: [CustomCheckoutPaymentTypeComponent],
})
export class CustomCheckoutPaymentTypeModule { }
