import { Injectable } from "@angular/core";
import { Converter, Occ, Product } from "@spartacus/core";

@Injectable()
export class ProductNormalizer
    implements Converter<Occ.Product, Product> {
    convert(source: Occ.Product, target?: any): any {
        if (target.slug) {
            target.urlName = target.slug?.replace(/\.|-$/g,'');
        }
        return target;
    }
}
