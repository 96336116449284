import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BasePageMetaResolver, ProductSearchService, RoutingService, SearchPageMetaResolver, TranslationService } from "@spartacus/core";
import { combineLatest, Observable } from "rxjs";
import { map, mergeMap, switchMap } from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class GeneracSearchPageMetaResolver extends SearchPageMetaResolver {
  protected queryParams$: Observable<any> = this.activatedRoute.queryParams.pipe(map((state) => state));

  constructor(
    override routingService: RoutingService,
    override productSearchService: ProductSearchService,
    override translation: TranslationService,
    override basePageMetaResolver: BasePageMetaResolver,
    protected activatedRoute: ActivatedRoute,
  ) {
    super(routingService, productSearchService, translation, basePageMetaResolver);
  }

  override resolveTitle(): Observable<string> {
    const sources = [this.total$, this.query$, this.queryParams$];
    return combineLatest(sources).pipe(
      switchMap(([count, query, queryParams]) =>
        this.translation
          .translate('pageMetaResolver.search.default_title')
          .pipe(
            mergeMap((defaultQuery) => {
              return this.translation.translate(queryParams?.code?.length > 0 ? 'pageMetaResolver.category.title' : 'pageMetaResolver.search.title', {
                count,
                query: queryParams?.code?.length > 0 ? queryParams?.code : (decodeURIComponent(query) || defaultQuery),
              })
            })
          )
      )
    );
  }
}
