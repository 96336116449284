export const environment = {
  production: false,
  isLocal: false,
  backend: {
    occ: {
      baseUrl:
        'https://api.shop-d1.generac.com/',
    },
    apim: {
      baseUrl:
        'https://apim-dcoe-qa.azure-api.net/sapCommerceCommand/WebServices/',
      subscriptionKey: '6b92f079c7bf4aeb8643b50f6247155d',
    },
  },
  gcGoogleAnalyticsId: 'G-TE795MD9HD',
  hwGoogleAnalyticsId: 'G-H4V55R1984',
  delegoRemoteDomain: 'https://generac-era-public.delego-cloud.com',
  clientId: 'JsGWUDPEmYxdFEuHqTAPHH6yW75Rql9F',
  domain: 'id-dev.generac.com',
};