import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { CmsBannerCarouselComponent, ProductService, CmsService } from '@spartacus/core';
import { BannerCarouselComponent, CmsComponentData } from '@spartacus/storefront';
import { Subject, merge } from 'rxjs';
import { map, filter, takeUntil } from 'rxjs/operators';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'cx-banner-carousel',
  templateUrl: 'custom-banner-carousel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomBannerCarouselComponent
  extends BannerCarouselComponent
  implements OnInit, OnDestroy
{
  private unsubscribe$ = new Subject();

  constructor(
    protected _componentData: CmsComponentData<CmsBannerCarouselComponent>,
    protected _cmsService: CmsService,
    protected productService: ProductService,
    protected customerService: CustomerService
  ) {
    super(_componentData, _cmsService);
  }

  ngOnInit(): void {
    merge(
      this._componentData.data$,
      this.customerService.getB2bUnitChanged().pipe(
        map(() =>
          this._cmsService.refreshComponent(this._componentData.uid, {
            id: 'current',
          })
        )
      )
    )
      .pipe(filter(Boolean), takeUntil(this.unsubscribe$))
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
