import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BundleCarouselComponent } from './bundle-carousel.component';
import { CmsConfig, ConfigModule, I18nModule, provideConfig } from "@spartacus/core";
import { IconModule, SpinnerModule } from "@spartacus/storefront";
import { translations } from "@spartacus/assets";
import { BundleSlideModule, productTranslationChunksConfig } from "../../shared/components/bundle-slide/bundle-slide.module";

@NgModule({
  declarations: [
    BundleCarouselComponent
  ],
  imports: [
    CommonModule,
    BundleSlideModule,
    I18nModule,
    IconModule,
    SpinnerModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        ProductDetailsBundlesComponent: {
          component: BundleCarouselComponent
        },
      },
    }),
  ],
  exports: [
    BundleCarouselComponent,
  ],
  providers:[
    provideConfig({
      i18n: {
        resources: {
          en: translations
        },
        chunks: productTranslationChunksConfig
      }
    })
  ]
})
export class BundleCarouselModule { }
