import { ChangeDetectionStrategy, Component, ElementRef, HostListener } from '@angular/core';
import { EventService,  RoutingService } from '@spartacus/core';
import { FavoritesService } from 'src/app/services/favorites.service';
import { Subscription } from 'rxjs';
import { FocusConfig, ICON_TYPE, LaunchDialogService } from "@spartacus/storefront";
import { switchMap, take, tap } from 'rxjs/operators';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { CheckoutService } from 'src/app/services/checkout.service';

@Component({
  selector: 'delete-saved-address-dialog',
  templateUrl: './delete-saved-address-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteSavedAddressDialogComponent {
  private subscription = new Subscription();
  iconTypes = ICON_TYPE;
  loading: boolean = false;

  savedAddressId: string;

  focusConfig: FocusConfig = {
    trap: true,
    block: true,
    autofocus: 'button',
    focusOnEscape: true,
  };

  @HostListener('click', ['$event'])
  handleClick(event: UIEvent): void {
    // Close on click outside the dialog window
    if ((event.target as any).tagName === this.el.nativeElement.tagName) {
      this.close('Cross click');
    }
  }

  constructor(
    protected launchDialogService: LaunchDialogService,
    protected el: ElementRef,
    private userAccount: UserAccountFacade,
    private checkoutService: CheckoutService,
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.launchDialogService.data$.subscribe(
        (data: any) => {
          this.savedAddressId = data.id;
        }
      )
    );
  }

  public deleteSavedAddress() {
    this.loading = true;
  
    return this.userAccount.get().pipe(
      take(1),
      switchMap((user) => this.checkoutService.deleteDropShipAddress(user?.uid, this.savedAddressId)),
      tap(() => this.loading = false))
      .subscribe(() => {
        this.launchDialogService.closeDialog(this.savedAddressId);
      });
  }

  public close(reason: string): void {
    this.launchDialogService.closeDialog(reason);
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.close('close dialog');
  }
}
