import { FavoritesListSortConfig } from "src/app/interfaces/favorites.model";

export const favoritesListSortConfig: FavoritesListSortConfig[] = [
    {
      "code": "name-asc",
      "name": "Name (ascending)",
      "selected": false
    },
    {
      "code": "name-desc",
      "name": "Name (descending)",
      "selected": false
    },
    {
      "code": "price-asc",
      "name": "Price (lowest first)",
      "selected": false
    },
    {
      "code": "price-desc",
      "name": "Price (highest first)",
      "selected": false
    }
];
