<favorites-navigation (activeListChanged)="setActiveList($event)"></favorites-navigation>
<cx-spinner *ngIf="loading === LoadingEnum.Idle; else favorites;" class="spinner-overlay"></cx-spinner>
<ng-template #favorites>
  <div class="favorites-wrapper">
    <div class="favorites-top">
      <h1>{{currentList?.name}}</h1>
      <button class="remove-all-btn" *ngIf="favoritesList?.length > 0"
        (click)="openDeleteAllItemsFromListModal(currentList)">
        <img src="../../../../../assets/icons/trash.svg">
        {{ 'addToWishList.favoriteList.removeAllProductsFromList' | cxTranslate}}
      </button>
    </div>
    <ng-container *ngIf="favoritesList?.length > 0;else noFavorites">
      <div class="favorite-items">
        <div class="favorite-items-top">
          <label class="form-group cx-sort-dropdown mr-auto">
            <span>{{ 'productList.sortBy' | cxTranslate }}</span>
            <cx-sorting [sortOptions]="sorts" [selectedOption]="getSelectedSortOption()"
              (sortListEvent)="sortList($event)" [ariaLabel]="'productList.sortResults' | cxTranslate"
               placeholder="{{ 'productList.sortBy' | cxTranslate }}"></cx-sorting>
          </label>
          <button class="btn btn-primary add-all-to-cart" (click)="openAddAllItemsToCartModal(currentList)">
            {{ 'addToWishList.favoriteList.addAllToCartBtn' | cxTranslate }}
          </button>
        </div>
        <div class="row">
          <cx-spinner *ngIf="loading === LoadingEnum.Loading" class="spinner-overlay"></cx-spinner>
          <cx-product-grid-item *ngFor="let favoriteItem of favoritesList; trackBy: trackByFavorites;"
            [product]="favoriteItem" [tooltipMessage]="tooltipMessage" [phoneNumberOrEmail]="phoneOrEmail"
            [gaListName]="gaListName"
            class="col-12 col-sm-6 col-md-6 col-lg-4"></cx-product-grid-item>
        </div>
        <div class="cx-pagination pagination-bottom" *ngIf="pagination.totalPages > 1">
          <cx-pagination [pagination]="pagination" [defaultPage]="0"
            (viewPageEvent)="onPageChange($event)"></cx-pagination>
        </div>
      </div>
    </ng-container>
    <ng-template #noFavorites>
      <div class="no-favorites">
        <div class="favorites-icon">
          <cx-icon class="favorite-icon not-active" [type]="iconTypes.STAR"></cx-icon>
        </div>
        <h1>{{ 'addToWishList.noFavorites' | cxTranslate}}</h1>
        <div class="favorites-text">Click the
          <cx-icon class="favorite-icon not-active" [type]="iconTypes.STAR"></cx-icon>
          icon on any product to add to favorites
        </div>
        <a class="btn btn-primary btn-block"
          [routerLink]="{ cxRoute: 'category', params: {code: 'air-cooled-home-standby-generators'} } | cxUrl">
          Find products
        </a>
      </div>
    </ng-template>
  </div>
</ng-template>