<div
        class="cx-modal-container"
        [cxFocus]="focusConfig"
        (esc)="dismissModal('Escape pressed')"
>
    <div class="cx-modal-content">
        <!-- Modal Header -->
        <ng-container *ngIf="!(isCartLoaded$ | async) && !itemsWithTabs; else loaded">
            <div class="cx-dialog-header modal-header">
                <div class="cx-dialog-title modal-title">
                    {{ 'addToCart.updatingCart' | cxTranslate }}
                </div>
                <button
                        type="button"
                        class="close"
                        [attr.aria-label]="'common.close' | cxTranslate"
                        (click)="dismissModal('Cross click')"
                >
                    <img src="../../../../../../../assets/icons/close_black_24.svg">
                </button>
            </div>
            <!-- Modal Body -->
            <div class="cx-dialog-body modal-body">
                <div class="cx-dialog-row">
                    <div class="col-sm-12">
                        <cx-spinner></cx-spinner>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-template #loaded>
            <cx-spinner *ngIf="!(isCartLoaded$ | async)" class="spinner-overlay"></cx-spinner>
            <div class="cx-dialog-header modal-header">
                <div class="cx-dialog-title modal-title">
                    {{
                    (addedEntryWasMerged$ | async)
                        ? ('addToCart.itemsIncrementedInYourCart' | cxTranslate)
                        : ('addToCart.itemsAddedToYourCart' | cxTranslate)
                    }}
                </div>
                <button
                        type="button"
                        class="close"
                        [attr.aria-label]="'addToCart.closeModal' | cxTranslate"
                        (click)="dismissModal('Cross click')"
                >
                    <img src="../../../../../../../assets/icons/close_black_24.svg">
                </button>
            </div>

            <cx-no-return-warning
                    [message]="(entry$ | async)?.product.displayItemAttentionMessage"
            ></cx-no-return-warning>

            <!-- Modal Body -->
            <div *ngIf="entries" class="cx-dialog-body modal-body">
                <div class="cx-dialog-row">
                    <div class="cx-dialog-item col-sm-12 col-md-12">
                        <cx-custom-cart-item
                                *ngFor="let entry of entries"
                                [item]="entry"
                                [compact]="true"
                                [quantityControl]="$any(getControl(entry))"
                                [promotionLocation]="promotionLocation"
                        ></cx-custom-cart-item>
                    </div>
                    <!-- Separator -->
                    <div class="cx-dialog-separator col-sm-12 d-xs-block d-sm-block"></div>
                    <!-- Total container -->
                    <div
                            class="cx-dialog-actions col-sm-12 col-md-12"
                            *ngIf="cart$ | async as cart"
                    >
                        <!-- Promotions -->
                        <div
                                *ngIf="cart.appliedOrderPromotions?.length || cart.potentialOrderPromotions?.length"
                                class="cx-dialog-promotions"
                        >
                            <!-- remove???? -->
                            <cx-promotions
                                    [promotions]="(cart.appliedOrderPromotions || []).concat(cart.potentialOrderPromotions || [])"
                            >
                            </cx-promotions>
                        </div>

                        <ng-container *ngIf="itemsWithTabs?.length">
                            <generac-carousel
                                    [title]="'Please review the accessories to ensure you have added the required items for your application and installation.'"
                                    [tabReferences]="itemsWithTabs"
                                    [template]="carouselItem"
                                    itemWidth="240px"
                            >
                            </generac-carousel>
                        </ng-container>
    
                        <ng-template #carouselItem let-item="item">
                            <cx-product-grid-item
                                    [tooltipMessage]="tooltipMessage"
                                    [phoneNumberOrEmail]="phoneOrEmail"
                                    [product]="item"
                                    [gaListName]="gaListName"
                                    [isAddModal]="true"
                                    (onAddModalAdded)="onAccessoryAdd($event)"
                            ></cx-product-grid-item>
                        </ng-template>

                        <!-- Actions -->
                        <div class="cx-dialog-buttons">
                            <a *ngIf="!restrictedCheckoutGroup"
                               [routerLink]="{ cxRoute: 'checkout' } | cxUrl"
                               (click)="dismissModal('Proceed To Checkout click')"
                               class="btn btn-secondary"
                            >{{ 'addToCart.proceedToCheckout' | cxTranslate }}</a>
                            <a
                                    [routerLink]="{ cxRoute: 'cart' } | cxUrl"
                                    (click)="dismissModal('View Cart click')"
                                    class="btn btn-primary"
                                    autofocus
                            >{{ 'addToCart.viewCart' | cxTranslate }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>

        <!-- For screen reader purposes (not visual)-->
        <div class="cx-visually-hidden" aria-live="polite" aria-atomic="true">
            {{
            (addedEntryWasMerged$ | async)
                ? ('addToCart.itemsIncrementedInYourCart' | cxTranslate)
                : ('addToCart.itemsAddedToYourCart' | cxTranslate)
            }}
        </div>
    </div>
</div>
