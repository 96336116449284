import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserIdService } from '@spartacus/core';
import { CartActions } from '../store/actions/index';
import { StateWithMultiCart } from '../store/multi-cart-state';
import { GeneracMultiCartFacade } from './multi-cart.facade';
import { MultiCartService } from '@spartacus/cart/base/core';

@Injectable()
export class GeneracMultiCartService extends MultiCartService implements GeneracMultiCartFacade {
  constructor(
    store: Store<StateWithMultiCart>,
    userIdService: UserIdService
  ) {
    super(store, userIdService);
  }

  override addEntry(
    userId: string,
    cartId: string,
    productCode: string,
    quantity: number,
    itemListName?: string,
    navigatedToPdpFrom?: string,
  ): void {
    this.store.dispatch(
      new CartActions.CartAddEntry({
        userId,
        cartId,
        productCode,
        quantity,
        itemListName,
        navigatedToPdpFrom,
      })
    );
  }

  override addEntries(
    userId: string,
    cartId: string,
    products: Array<{ productCode: string; quantity: number, itemListName?: string, navigatedToPdpFrom?: string, }>
  ): void {
    products.forEach((product) => {
      this.store.dispatch(
        new CartActions.CartAddEntry({
          userId,
          cartId,
          productCode: product.productCode,
          quantity: product.quantity,
          itemListName: product.itemListName,
          navigatedToPdpFrom: product.navigatedToPdpFrom,
        })
      );
    });
  }
}
