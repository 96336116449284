import {Component, ElementRef, HostBinding, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {combineLatest, forkJoin, Observable, Subject, Subscription} from "rxjs";
import {finalize, map, startWith, take} from "rxjs/operators";
import {TranslationService} from "@spartacus/core";
import { ProductService } from "../../../services/product.service";

@Component({
  selector: 'cx-custom-item-counter',
  templateUrl: './custom-item-counter.component.html',
  styleUrls: ['./custom-item-counter.component.scss']
})
export class CustomItemCounterComponent implements OnInit {
  @Input() control!: UntypedFormControl;
  @Input() min = 1;
  @Input() max!: number;
  @Input() isBundle: boolean;
  @Input() step = 1;
  @Input() allowZero = false;
  @Input() messagePosition = 'position-left';
  @Input() productCode: string;
  @HostBinding('class.readonly') @Input() readonly = false;
  @ViewChild('qty') private input!: ElementRef<HTMLInputElement>;

  @HostListener('click') handleClick() {
    this.input.nativeElement.focus();
  }

  infoMessage: string | null = null;
  private subscriptions = new Subscription();

  constructor(private translation: TranslationService,
              protected productService: ProductService) {
  }

  ngOnInit() {
    this.subscribeToControl();
  }

  onQtyChange(ev: any): void {
    if (ev?.key?.includes('-')) {
      ev.preventDefault();
    }
    setTimeout(() => {
      const newQuantity = parseInt(ev.target.value, 10);
      if (newQuantity > 0 && this.productCode) {
        this.productService.setQuantity(this.productCode, newQuantity);
      }
    }, 0);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  increment() {
    const newQuantity = parseInt(this.control.value + this.step, 10);
    if (newQuantity > 0 && this.productCode) {
      this.productService.setQuantity(this.productCode, newQuantity);
    }
    this.control.setValue(this.control.value + this.step);
    this.control.markAsDirty();
  }

  decrement() {
    const newQuantity = this.control.value - this.step;
    if (newQuantity > 0 && this.productCode) {
      this.productService.setQuantity(this.productCode, newQuantity);
    }
    this.control.setValue(this.control.value - this.step);
    this.control.markAsDirty();
  }

  setInfoMessage(): void {
    if (this.infoMessage !== null) return;

    const messages: Observable<string>[] = [];

    if (this.step > 1) {
      messages.push(this.translation.translate('itemCounter.infoMessage.step', {
        step: this.step
      }));
    }

    if (this.min > 1) {
      messages.push(this.translation.translate('itemCounter.infoMessage.min', {
        min: this.min
      }));
    }

    if (this.max > 1 && this.max !== Infinity) {
      const message = this.isBundle ? 'itemCounter.infoMessage.maxBundleQty' : 'itemCounter.infoMessage.max';
      messages.push(this.translation.translate(message, {
        max: this.max
      }));
    }

    this.infoMessage = '';

    combineLatest(messages)
      .pipe(
        take(1),
        map((infoMessages: string[]) => infoMessages.join(' '))
      ).subscribe((message: string) => this.infoMessage = message);
  }

  removeMessage(): void {
    this.infoMessage = null;
  }

  private subscribeToControl() {
    this.subscriptions.add(
      this.control.valueChanges
        .pipe(startWith(this.control.value))
        .subscribe((value) => this.control.setValue(this.getValidCount(value), {emitEvent: false}))
    );
  }

  private getValidCount(value: number) {
    if (value < this.min && !(value === 0 && this.allowZero)) {
      value = this.min;
    }
    if (this.max && value > this.max) {
      value = this.max;
    }
    return value;
  }
}
