import { ChangeDetectionStrategy, Component, ElementRef, HostListener } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { FocusConfig, ICON_TYPE } from "@spartacus/storefront";
import { switchMap, take, tap } from 'rxjs/operators';
import { ExtAddress } from 'src/app/interfaces/checkout';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { CheckoutService } from 'src/app/services/checkout.service';
import { UntypedFormGroup } from '@angular/forms';
import { CustomLaunchDialogService } from 'src/app/services/custom-launch-dialog.service';

@Component({
  selector: 'edit-saved-address-dialog',
  templateUrl: './edit-saved-address-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditSavedAddressDialogComponent {
  private subscription = new Subscription();
  iconTypes = ICON_TYPE;
  loading: boolean = false;
  mode: string;

  savedAddressInit: ExtAddress;
  savedAddress: ExtAddress;
  isSaveAllowed: boolean = false;

  focusConfig: FocusConfig = {
    trap: true,
    block: true,
    autofocus: 'button',
    focusOnEscape: true,
  };

  @HostListener('click', ['$event'])
  handleClick(event: UIEvent): void {
    // Close on click outside the dialog window
    if ((event.target as any).tagName === this.el.nativeElement.tagName) {
      this.close('Cross click');
    }
  }

  constructor(
    protected launchDialogService: CustomLaunchDialogService,
    protected el: ElementRef,
    private userAccount: UserAccountFacade,
    private checkoutService: CheckoutService,
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.launchDialogService.data$.subscribe(
        (data: any) => {
          this.savedAddressInit = data.savedAddress;
          this.mode = data?.savedAddress ? 'edit' : 'add';
        }
      )
    );
  }

  savedAddressChange(addressForm: UntypedFormGroup) {
    if (addressForm?.valid) {
      this.savedAddress = addressForm.getRawValue();
    }
    this.isSaveAllowed = addressForm?.valid;
  }


  public createOrEditSavedAddress(): Subscription {
    this.loading = true;
    return this.userAccount.get().pipe(
      take(1),
      switchMap((user) => this.mode == 'edit' ? this.editSavedAddress(user?.uid) : this.createSavedAddress(user?.uid)),
      tap(() => this.loading = false))
      .subscribe(() => {
        this.launchDialogService.closeDialog(this.savedAddress, 'EDIT_SAVED_ADDRESS');
      });
  }

  private editSavedAddress(userUid: string): Observable<any> {
    return this.checkoutService.editDropShipAddress(userUid, this.savedAddressInit.id, this.savedAddress)
  };

  private createSavedAddress(userUid: string): Observable<any> {
    return this.checkoutService.addDropShipAddress(userUid, this.savedAddress);
  };

  public close(reason: string): void {
    this.launchDialogService.closeDialog(reason, 'EDIT_SAVED_ADDRESS');
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.close('close dialog');
  }
}
