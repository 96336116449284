<ng-container *ngIf="isReady">
  <div
    class="row"
    *ngIf="subscriptions$"
  >
    <cx-product-grid-item
      *ngFor="let product of subscriptions$.products"
      [isSubs]="true"
      [product]="product"
      class="col-12 col-sm-6 col-md-3"
    ></cx-product-grid-item>
  </div>
</ng-container>

<cx-spinner
  *ngIf="!isReady"
  class="spinner-overlay mt-5"
></cx-spinner>