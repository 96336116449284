import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ProductScope } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { CurrentProductService } from "@spartacus/storefront";
import { switchMap, take } from 'rxjs/operators';
import { ProductService } from 'src/app/services/product.service';
import { ApplicableProductsResponse } from 'src/app/interfaces/product';

@Component({
  selector: 'generac-applicable-products',
  templateUrl: './applicable-products.component.html',
  styleUrls: ['./applicable-products.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicableProductsComponent implements OnDestroy {
  currentPage: number = 0;
  applicableProducts: ApplicableProductsResponse;

  private subscriptions = new Subscription();

  constructor(
    protected currentProductService: CurrentProductService,
    protected productService: ProductService,
    private cdr: ChangeDetectorRef,
  ) {
    this.getApplicableProductsByPage();
   }

  private getApplicableProductsByPage(): void {
    this.subscriptions.add(
      this.currentProductService.getProduct(
        ProductScope.ATTRIBUTES
      ).pipe(
        switchMap(product => this.productService.getApplicableProducts(product?.code, this.currentPage)),
        take(1),
      ).subscribe(res => {
        this.applicableProducts = res;
        this.cdr.markForCheck();
      })
    )
  }

  onPaginate(page: number): void {
    this.currentPage = page;
    this.getApplicableProductsByPage();
  }

  ngOnDestroy(): void {
      this.subscriptions.unsubscribe();
  }
}
