<div class="favorites-navigation-wrapper">
  <span class="favorites-navigation-title">
    <cx-icon class="favorite-icon not-active" [type]="iconTypes.STAR"></cx-icon>
    {{ 'addToWishList.favoriteList.title' | cxTranslate }}
  </span>
  <!-- .favorites-navigation-items is used for displaying of the lists for non-mobile view and as popup for mobile view -->
  <div class="favorites-navigation-items" [class.popup]="openListsInPopup" #listEl>
    <img *ngIf="openListsInPopup" class="cross-icon" src="../../../../../assets/icons/close_black_24.svg" (click)="changeMobilePopupState()">
    <span *ngIf="openListsInPopup" class="popup-title"> {{ 'addToWishList.favoriteList.title' | cxTranslate }} </span>
    <div *ngFor="let favoriteList of favoritesLists; let i = index;" class="favorites-navigation-item"
      (click)="setActiveList(i)" [ngClass]="{'list-active': favoriteList.active}">
      <span class="count" [ngClass]="{'count-active': favoriteList.active}">{{ favoriteList.count }}</span>
      <span>{{ favoriteList.name }}</span>
      <button class="item-actions" *ngIf="favoriteList.name !== 'Default'" (click)="openContextMenu($event, i)">
        <img src="../../../../../assets/icons/more-vertical.svg">
      </button>
      <div class="context-menu" *ngIf="favoriteList.showContextMenu" #contextMenu [style.margin-top.px]="offset">
        <button *ngIf="favoriteList.name !== 'Default'" class="rename-list"
          (click)="openCreateOrEditListModal($event, favoriteList)">
          {{ 'addToWishList.favoriteList.editListName' | cxTranslate }}
        </button>
        <button *ngIf="favoriteList.name !== 'Default'" class="remove-list"
          (click)="openDeleteFavoriteListModal($event, favoriteList)">
          {{ 'addToWishList.favoriteList.deleteList' | cxTranslate }}
        </button>
      </div>
    </div>
  </div>
  <div class="favorites-navigation-items-mobile">
    <div class="selected-item" *ngIf="favoritesLists && favoritesLists[activeListIndex]">
      <span class="count">{{
        favoritesLists[activeListIndex].count }}</span>
      <span>{{ favoritesLists[activeListIndex].name }}</span>
      <button class="item-actions" *ngIf="favoritesLists[activeListIndex].name !== 'Default'"
        (click)="openContextMenu($event, activeListIndex)">
        <img src="../../../../../assets/icons/more-vertical.svg">
      </button>
      <div class="context-menu" *ngIf="favoritesLists[activeListIndex].showContextMenu" #contextMenu
        [style.margin-top.px]="offset">
        <button *ngIf="favoritesLists[activeListIndex].name !== 'Default'" class="rename-list"
          (click)="openCreateOrEditListModal($event, favoritesLists[activeListIndex])">
          {{ 'addToWishList.favoriteList.editListName' | cxTranslate }}
        </button>
        <button *ngIf="favoritesLists[activeListIndex].name !== 'Default'" class="remove-list"
          (click)="openDeleteFavoriteListModal($event, favoritesLists[activeListIndex])">
          {{ 'addToWishList.favoriteList.deleteList' | cxTranslate }}
        </button>
      </div>
    </div>
    <button class="show-favorites-lists-popup-mobile" (click)="changeMobilePopupState()">
      <img src="../../../../../assets/icons/align-center.svg">
    </button>
  </div>
  <div class="add-new-list-block">
    <button class="add-new-list-btn" (click)="openCreateOrEditListModal($event)">
      <img src="../../../../../assets/icons/plus-circle.svg">
      {{ 'addToWishList.favoriteList.addNewList' | cxTranslate }}
    </button>
  </div>
</div>