import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  CartItemComponentOptions,
  ConsignmentEntry,
  OrderEntry,
  PromotionLocation,
  PromotionResult,
  SelectiveCartFacade
} from '@spartacus/cart/base/root';
import { UserIdService, WindowRef } from '@spartacus/core';
import { OutletContextData } from '@spartacus/storefront';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, pairwise, startWith, take, tap } from 'rxjs/operators';
import { ImageFormat } from "../../../../../interfaces/bundle.model";
import { isEqual } from "../../../../../shared/helpers/is-equal";
import { findChangedInnerObject } from "../../../../../shared/helpers/find-changed-inner-object";
import { CartPrice, EntryGroup, ItemListContext } from "../../../../../interfaces/cart";
import {
  SavedCartDetailsService
} from '../../../../../../app/spartacus/features/saved-cart/components/details/saved-cart-details.service'
import { GeneracActiveCartService } from '../../core/facade/active-cart.service';
import { GeneracMultiCartFacade } from '../../core/facade/multi-cart.facade';

@Component({
  selector: 'cx-cart-item-list',
  templateUrl: './cart-item-list.component.html',
  styleUrls: ['./cart-item-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartItemListComponent implements OnInit, OnDestroy {
  protected subscription = new Subscription();
  protected userId: string;

  @Input() isBundleTest: boolean = false;
  @Input() readonly: boolean = false;
  @Input() hasHeader: boolean = true;
  @Input() options: CartItemComponentOptions = {
    isSaveForLater: false,
    optionalBtn: null,
    displayAddToCart: false,
  };
  @Input() cartId: string;

  protected _items: OrderEntry[] = [];
  form: UntypedFormGroup = new UntypedFormGroup({});

  @Input('items')
  set items(items: OrderEntry[]) {
    this.resolveItems(items);
    this.createForm();
  }

  @Input('groups')
  set groups(groups: EntryGroup[]) {
    const oldGroups = this._groups?.length ? [...this._groups] : [];
    this.resolveGroups(groups);
    this.createGroupsForm(oldGroups);
  }

  get items(): any[] {
    return this._items;
  }

  get groups(): any[] {
    return this._groups;
  }

  @Input() promotionLocation: PromotionLocation = PromotionLocation.ActiveCart;

  @Input('cartIsLoading') set setLoading(value: boolean) {
    if (!this.readonly) {
      // Whenever the cart is loading, we disable the complete form
      // to avoid any user interaction with the cart.

      this.savedCartDetailsService.getSavedCartId()
        .pipe(
          take(1),
        ).subscribe((cartId: string) => {
        // TODO: We have to analyze do we need this changes. Because of this changes the remove product functionality on the saved cart screen is not working
        if (cartId) {
            value
              ? this.form.disable({emitEvent: false})
              : this.form.disable({emitEvent: false});
          } else {
            value
              ? this.form.disable({emitEvent: false})
              : this.form.enable({emitEvent: false});
          }
        });
      this.cd.markForCheck();
    }
  }

  isReviewOrder: boolean;

  private _groups: any[];
  private formSubscription: Subscription;

  constructor(
    protected activeCartService: GeneracActiveCartService,
    protected selectiveCartService: SelectiveCartFacade,
    protected userIdService: UserIdService,
    protected multiCartService: GeneracMultiCartFacade,
    protected cd: ChangeDetectorRef,
    protected winRef: WindowRef,
    public savedCartDetailsService: SavedCartDetailsService,
    @Optional() protected outlet?: OutletContextData<ItemListContext>
  ) {
  }

  ngOnInit(): void {
    this.subscription.add(this.getInputsFromContext());
    this.subscribeToUser();
    this.isReviewOrder = this.winRef.location.href.includes('review-order') || this.winRef.location.href.includes('order-confirmation');
  }

  removeEntry(item: OrderEntry): void {
    if (this.options.isSaveForLater) {
      this.selectiveCartService.removeEntry(item);
    } else if (this.cartId && this.userId) {
      this.multiCartService.removeEntry(
        this.userId,
        this.cartId,
        item.entryNumber as number
      );
    } else {
      this.activeCartService.removeEntry(item);
    }
    delete this.form.controls[this.getControlName(item)];
  }

  onBundleCollapse(group: any) {
    group.isBundleCollapsed = !group.isBundleCollapsed;
  }

  getControl(entry: OrderEntry): UntypedFormGroup | undefined {
    return <UntypedFormGroup>this.form.get(this.getControlName(entry));
  }

  protected getInputsFromContext(): Subscription | undefined {
    return this.outlet?.context$.subscribe((context) => {
      if (context.readonly !== undefined) {
        this.readonly = context.readonly;
      }
      if (context.hasHeader !== undefined) {
        this.hasHeader = context.hasHeader;
      }
      if (context.options !== undefined) {
        this.options = context.options;
      }
      if (context.cartId !== undefined) {
        this.cartId = context.cartId;
      }
      if (context.items !== undefined) {
        this.items = context.items;
      }
      if (context.promotionLocation !== undefined) {
        this.promotionLocation = context.promotionLocation;
      }
      if (context.cartIsLoading !== undefined) {
        this.setLoading = context.cartIsLoading;
      }
    });
  }

  /**
   * Resolves items passed to component input and updates 'items' field
   */
  protected resolveItems(items: OrderEntry[]): void {
    if (!items) {
      this._items = [];
      return;
    }

    // The items we're getting from the input do not have a consistent model.
    // In case of a `consignmentEntry`, we need to normalize the data from the orderEntry.
    if (items.every((item) => item.hasOwnProperty('orderEntry'))) {
      this.normalizeConsignmentEntries(items);
    } else {
      this.rerenderChangedItems(items);
    }
  }

  protected normalizeConsignmentEntries(items: OrderEntry[]) {
    this._items = items.map((consignmentEntry) => {
      const entry = Object.assign(
        {},
        (consignmentEntry as ConsignmentEntry).orderEntry
      );
      entry.quantity = consignmentEntry.quantity;
      return entry;
    });
  }

  /**
   * We'd like to avoid the unnecessary re-renders of unchanged cart items after the data reload.
   * OCC cart entries don't have any unique identifier that we could use in Angular `trackBy`.
   * So we update each array element to the new object only when it's any different to the previous one.
   */
  protected rerenderChangedItems(items: OrderEntry[]) {
    let offset = 0;
    for (
      let i = 0;
      i - offset < Math.max(items.length, this._items.length);
      i++
    ) {
      const index = i - offset;
      if (
        JSON.stringify(this._items?.[index]) !== JSON.stringify(items[index])
      ) {
        if (this._items[index]) {
          this.form?.removeControl(this.getControlName(this._items[index]));
        }
        if (!items[index]) {
          this._items.splice(index, 1);
          offset++;
        } else {
          this._items[index] = items[index];
        }
      }
    }
  }

  /**
   * Creates form models for list items
   */
  protected createForm(): void {
    this._items.forEach((item) => {
      const controlName = this.getControlName(item);
      const control = this.form.get(controlName);
      if (control) {
        if (control.get('quantity')?.value !== item.quantity) {
          control.patchValue({quantity: item.quantity}, {emitEvent: false});
        }
      } else {
        const group = new UntypedFormGroup({
          entryNumber: new UntypedFormControl(item.entryNumber),
          quantity: new UntypedFormControl(item.quantity, {updateOn: 'blur'}),
        });
        this.form.addControl(controlName, group);
      }

      // If we disable form group before adding, disabled status will reset
      // Which forces us to disable control after including to form object
      if (!item.updateable || this.readonly) {
        this.form.controls[controlName].disable();
      }
    });
  }

  protected createGroupsForm(oldGroups: EntryGroup[]): void {
    if (oldGroups.length === this._groups.length) {
      this._groups.forEach((group: EntryGroup) => {
        const controlName = this.getControlName(group.entry);
        const control = this.form.get(controlName);
        if (control && (control.get('quantity')?.value !== group.entry.quantity)) {
          control.setValue({entryNumber: group.entry.entryNumber, quantity: group.entry.quantity});
        }
      })
      return;
    }

    const formGroup = new UntypedFormGroup({});
    this._groups.forEach((group: EntryGroup) => {
      const controlName = this.getControlName(group.entry);
      const groupControl = new UntypedFormGroup({
        entryNumber: new UntypedFormControl(group.entry.entryNumber),
        quantity: new UntypedFormControl(group.entry.quantity, {updateOn: 'blur'}),
      });
      formGroup.addControl(controlName, groupControl);
    });
    this.form = formGroup;
    this.subscribeToFormChanges();
  }

  protected getControlName(item: OrderEntry): string {
    return item.entryNumber?.toString() || '';
  }

  private resolveGroups(groups: EntryGroup[]): void {
    if (!groups) {
      this._groups = [];
      return;
    }
    this._groups = this.mapGroupsForUI(groups);
  }

  private subscribeToUser() {
    this.subscription.add(
      this.userIdService
        ?.getUserId()
        .subscribe((userId) => (this.userId = userId))
    );
  }

  private subscribeToFormChanges() {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }

    this.formSubscription = this.form.valueChanges
      .pipe(
        startWith(this.form.value),
        filter(value => !!value),
        distinctUntilChanged(isEqual),
        pairwise(),
        map(([previous, current]) => findChangedInnerObject(previous, current)?.changedValue),
        tap(value => {
          const entry = this._groups.find(group => value?.entryNumber === group.entry.entryNumber)?.entry;
          if (entry?.updateable && value && !this.readonly) {
            if (this.options.isSaveForLater) {
              this.selectiveCartService.updateEntry(
                value.entryNumber,
                value.quantity
              );
            } else if (this.cartId && this.userId) {
              this.multiCartService.updateEntry(
                this.userId,
                this.cartId,
                value.entryNumber,
                value.quantity
              );
            } else {
              this.activeCartService.updateEntry(
                value.entryNumber,
                value.quantity
              );
            }
          }
        }),
      ).subscribe();
  }

  private mapGroupsForUI(groups: any[]): any[] {
    return groups.map((group, index, arr) => {
      const entries = group.entryGroups?.length ? group.entryGroups[0].entries : [];
      let mainEntry = (group.entries?.length === 1 ? group.entries[0] : null) || entries[0];
      const existingGroup = this._groups?.find((entryGroup: any) => entryGroup.entryGroupNumber === group.entryGroupNumber);

      if (entries?.length) {
        const promotions = entries.reduce((acc: PromotionResult[], orderEntry: OrderEntry) => {
          orderEntry.promotions?.forEach(promotion => {
            const condition = acc.find(item => item.promotion.code === promotion.promotion.code);
            if (!condition) {
              acc.push(promotion);
            }
          });
          return acc;
        }, []);
        mainEntry = {
          ...mainEntry,
          promotions
        };
      }

      return {
        entry: this.mapEntryWithImage(
          mainEntry,
          entries.length ? group.entryGroups[0].totalPrice : null,
          entries.length ? group.entryGroups[0].adjustedWithPromoTotalPrice : group.adjustedWithPromoTotalPrice
        ),
        entries: entries.map((groupEntry: any) => this.mapEntryWithImage(groupEntry)),
        entryGroupNumber: group.entryGroupNumber,
        erroneous: group.erroneous,
        isBundleCollapsed: !!existingGroup?.isBundleCollapsed,
        bundleId: group.entryGroups?.length ? group.entryGroups[0].externalReferenceId : null,
        maxItemsAllowed: group.entryGroups?.length ? group.entryGroups[0].maxItemsAllowed : null,
        label: group.label,
        type: group.type,
      }
    })
  }

  private mapEntryWithImage(entry: any, totalPrice?: CartPrice, adjustedWithPromoTotalPrice?: CartPrice, isPromo?: boolean) {
    const productImage = entry?.product?.images?.find((image: any) => image.format === ImageFormat.Product);
    const basePrice = totalPrice || entry.basePrice;
    return {
      ...entry,
      basePrice,
      adjustedWithPromoTotalPrice,
      product: {
        ...entry.product,
        images: {
          PRIMARY: productImage
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();

    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }
}
