import { ChangeDetectionStrategy, Component, ElementRef, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { FocusConfig, ICON_TYPE, LaunchDialogService } from "@spartacus/storefront";

@Component({
  selector: 'favorite-products-added-to-cart-dialog',
  templateUrl: './favorite-products-added-to-cart-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavoriteProductsAddedToCartDialogComponent {
  private subscription = new Subscription();
  iconTypes = ICON_TYPE;
  productsAmount: number;

  focusConfig: FocusConfig = {
    trap: true,
    block: true,
    autofocus: 'button',
    focusOnEscape: true,
  };

  @HostListener('click', ['$event'])
  handleClick(event: UIEvent): void {
    // Close on click outside the dialog window
    if ((event.target as any).tagName === this.el.nativeElement.tagName) {
      this.close('Cross click');
    }
  }

  constructor(
    protected launchDialogService: LaunchDialogService,
    protected el: ElementRef,
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.launchDialogService.data$.subscribe(
        (data: any) => {
          this.productsAmount = data.productsAmount;
        }
      )
    );
  }

  public close(reason: string): void {
    this.launchDialogService.closeDialog(reason);
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.close('Close a dialog');
  }
}
