<div class="row plp-desktop-item" *ngIf="!isMobileView; else plpMbileTmpl">
  <span class="file-type-value" [title]="document.fileType">{{document.fileType}}</span>
  <a class="file-name-value" (click)="viewDocument(document)" [title]="document.fileName">{{document.fileName}}</a>
  <!-- [href]="document.fileUrl" target="_blank" -->
  <span class="item-number-value" [title]="document.itemNumber">{{document.itemNumber}}</span>
  <span class="language-value">{{document.language}}</span>
</div>

<ng-template #plpMbileTmpl>
  <div class="row plp-mobile-item">
    <div class="documents-mobile-titles">
      <th scope="col" class="file-type">{{ "productList.fileType" | cxTranslate }}</th>
      <th scope="col" class="file-name">{{ "productList.fileName" | cxTranslate }}</th>
      <th scope="col" class="item-number">{{ "productList.itemNumber" | cxTranslate }}</th>
      <th scope="col" class="language">{{ "productList.language" | cxTranslate }}</th>
    </div>
    <div class="documents-mobile-values">
      <span class="file-type-value">{{document.fileType}}</span>
      <a class="file-name-value" [href]="document.fileUrl" target="_blank">{{document.fileName}}</a>
      <span class="item-number-value">{{document.itemNumber}}</span>
      <span class="language-value">{{document.language}}</span>
    </div>
  </div>
</ng-template>