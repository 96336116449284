import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { OrderConfirmationItemsComponent } from '@spartacus/order/components';
import { Observable } from 'rxjs';
import { ExtOrder } from '../../order-details/order-detail-shipping/order-detail-shipping.component';

@Component({
    selector: 'cx-order-confirmation-items',
    templateUrl: './order-confirmation-items.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneracOrderConfirmationItemsComponent extends OrderConfirmationItemsComponent implements OnDestroy {
    override order$: Observable<ExtOrder | undefined> = this.orderFacade.getOrderDetails();
}
