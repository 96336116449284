import { Injectable } from "@angular/core";
import { Params } from "@angular/router";
import { ActivatedRouterStateSnapshot, Breadcrumb, PageType, ProductSearchPage, RoutingService } from "@spartacus/core";
import { FacetList, ProductFacetService, ProductListComponentService } from "@spartacus/storefront";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class CustomProductFacetService extends ProductFacetService {
  constructor(
    override routing: RoutingService,
    override productListComponentService: ProductListComponentService
  ) {
    super(routing, productListComponentService);
  }

  override filterForPage(
    state: ActivatedRouterStateSnapshot,
    page: ProductSearchPage
  ): boolean {
    if (!page.currentQuery?.query?.value) {
      return false;
    }
    if (state.context.type === PageType.CATEGORY_PAGE) {
      return (
        page.currentQuery.query.value.indexOf(
          `allCategories:${state.context.id}`
        ) > -1
      );
    }

    if (
      state.context.type === PageType.CONTENT_PAGE &&
      state.context.id.indexOf('search') > -1
    ) {
      return true;
    }
    return false;
  }

  override facetList$: Observable<FacetList> = this.searchResult$.pipe(
    map(
      (result: ProductSearchPage) => ({
        facets: result.facets,
        activeFacets: result.breadcrumbs.filter((breadcrumb) => breadcrumb.facetCode !== 'servicePart'),
      } as FacetList))
  );
}