import { Injectable } from '@angular/core';
import { GlobalMessageService, GlobalMessageType, OccEndpointsService } from '@spartacus/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { ShippingAccount } from '../interfaces/shipping-account';
import { LoadingEnum } from '../enums/loading.enum';

@Injectable({
  providedIn: 'root',
})
export class ShippingAccountsService {
  private loading = new Subject<LoadingEnum>();
  loading$ = this.loading.asObservable();

  constructor(
    protected globalMessageService: GlobalMessageService,
    private httpClient: HttpClient,
    private occEndpoints: OccEndpointsService,
  ) { }

  getShippingAccounts(): Observable<ShippingAccount[]> {
    this.loading.next(LoadingEnum.Loading);
    const url = `${this.occEndpoints.getBaseUrl()}/users/current/shipping-accounts?fields=DEFAULT`;
    return <Observable<ShippingAccount[]>>this.httpClient.get(url).pipe(
      map((response: any): ShippingAccount[] => response.shippingAccounts.filter((shippingAccounts:ShippingAccount) => !shippingAccounts.generacShippingAccount)),
      catchError(res => {
        this.loading.next(LoadingEnum.Failed);
        res.error.errors
          .forEach((error: any) => {
            this.globalMessageService.add(
              error.message
                ? error.message
                : { key: 'httpHandlers.unknownIdentifier' },
              GlobalMessageType.MSG_TYPE_ERROR
            );
          });
        return throwError(res.error.errors[0]);
      }),
      finalize(() => this.loading.next(LoadingEnum.Loaded))
    );
  }
}
