<!-- Item Start Outlet -->
<ng-template [cxOutlet]="CartOutlets.LIST_ITEM">
  <td role="cell">
    <ng-template [cxOutlet]="CartOutlets.ITEM_CONFIGURATOR_ISSUES"></ng-template>
    <cx-cart-item-validation-warning [code]="item.product?.code"></cx-cart-item-validation-warning>
    <div class="cx-table-item-container"
      [ngClass]="{'cx-table-bundle-item': bundleId, 'cx-table-subscription-item': isSubscription && isReviewOrder}">
      <ng-container *ngIf="!bundleId && !isSubscription;">
        <a (click)="navigateToProduct()" tabindex="0">
          <cx-media class="cx-product-image"
            *ngIf="(item.product.servicePart && item.product.images?.PRIMARY) || !item.product.servicePart; else spGearThumb"
            [container]="item.product?.images?.PRIMARY" format="cartIcon"
            [alt]="item.product.name || item.product.summary"></cx-media>
          <ng-template #spGearThumb>
            <div class="sp-gear-thumb">
              <img src="../../../../../../assets/icons/sp_gear_thumb.svg" alt="gear">
            </div>
          </ng-template>
        </a>
      </ng-container>

      <ng-container *ngIf="isSubscription;">
        <cx-media [container]="item.product?.images?.PRIMARY" format="cartIcon"></cx-media>
      </ng-container>

      <div class="cx-info">
        <!-- Item Name -->
        <cx-icon *ngIf="bundleId" [ngClass]="{'collapsed': isBundleCollapsed}" class="collapse-icon"
          [type]="iconTypes.CARET_DOWN" (click)="onExpandToggle()"></cx-icon>
        <div class="cx-info-block">
          <div *ngIf="item.product?.name" class="cx-name">

            <ng-container *ngIf="!isSubscription; else itemNameForSubscription">
              <a class="cx-link"
                [routerLink]="['/product/' + this.item.product.code + '/' + transformNameToUrl(this.item.product.name)]"
                tabindex="0">
                {{ item.product?.name }} <span *ngIf="bundleId">Bundle</span>
              </a>
            </ng-container>

            <ng-template #itemNameForSubscription>
              <strong>{{ item.product?.name }}</strong>
            </ng-template>

          </div>

          <ng-container *ngIf="!bundleId && ((!isReviewOrder) || (!isSubscription && isReviewOrder))">
            <div *ngIf="item.product?.code && !item.product?.displayModelNumber" class="cx-code">
              {{ (item.product?.servicePart ? 'cartItems.part' : 'cartItems.id') | cxTranslate }} #{{ item.product?.code
              }}
            </div>
            <div *ngIf="item.product?.displayModelNumber" class="cx-code">
              {{ (item.product?.servicePart ? 'cartItems.part' : 'cartItems.id') | cxTranslate }} #{{
              item.product?.displayModelNumber }}
            </div>
          </ng-container>
          <!-- Item Price -->
          <div *ngIf="item.basePrice && ((!isReviewOrder) || (!isSubscription && isReviewOrder))"
            class="cx-price cx-mobile-only">
            <div *ngIf="item.basePrice" class="cx-value">
              {{ item.basePrice?.formattedValue }}
            </div>
          </div>
          <!-- Mobile Item Price -->
          <!-- Item Quantity -->
          <div role="cell" class="cx-quantity cx-mobile-only">
            <div class="cx-mobile-header" placement="left" title="{{ 'cartItems.quantityTitle' | cxTranslate }}">
              {{ "cartItems.quantity" | cxTranslate }}
            </div>
            <div class="cx-value justify-center" [class.readonly-value]="readonly">
              <div *ngIf="isBundleItem;else notABundleItem">{{quantityControl?.value}}</div>

              <ng-template #notABundleItem>
                <cx-custom-item-counter *ngIf="!isBundleItem && quantityControl" [control]="quantityControl"
                  [readonly]="!item.updateable || readonly || options.isSaveForLater"
                  [min]="+item.product?.minOrderQuantity"
                  [max]="bundleId ? +maxItemsAllowed : +item.product?.maxOrderQuantity"
                  [step]="+item.product?.addToCartQtyMultiplier" [isBundle]="!!bundleId"
                  [allowZero]="true"></cx-custom-item-counter>
              </ng-template>
            </div>
          </div>
          <!-- Total -->
          <div class="cx-mobile-only">
            <ng-container *ngIf="options.isSaveForLater; else total">
              <div role="cell" class="cx-total cx-mobile-only">
                <div class="cx-mobile-header">
                  {{ "saveForLaterItems.stock" | cxTranslate }}
                </div>
                <div *ngIf="item.product?.stock?.stockLevel >= 0; else forceInstock" class="cx-value">
                  {{ item.product?.stock.stockLevel }}
                </div>
                <ng-template #forceInstock>
                  <div class="cx-value">
                    {{ "saveForLaterItems.forceInStock" | cxTranslate }}
                  </div>
                </ng-template>
              </div>
            </ng-container>
          </div>
          <div class="sp-product-icons" *ngIf="item.product?.servicePart && isCartPage">
            <img *ngIf="item.itemOnHand" src="../../../../../../assets/icons/check-green.svg">
            <generac-sp-ltl *ngIf="item.product.ltl" [ltl]="item.product.ltl"></generac-sp-ltl>
            <generac-sp-flammable *ngIf="item.product.flammable"
              [flammable]="item.product.flammable"></generac-sp-flammable>
          </div>
          <ng-container *ngIf="!isBundleItem">
            <ng-container *ngIf="item.product.displayItemAttentionMessage">
              <cx-no-return-warning #noReturnWarning (cxSizeChanged)="onResize()" [ngClass]="{'bundle': bundleId}"
                [message]="item.product.displayItemAttentionMessage"></cx-no-return-warning>
            </ng-container>

            <ng-container *ngIf="item.promotions?.length">
              <cx-promotions #promotions (cxSizeChanged)="onResize(!!item.product.displayItemAttentionMessage)"
                [ngClass]="{'bundle': bundleId}" [promotions]="item.promotions"></cx-promotions>
            </ng-container>
          </ng-container>
          <div class="cx-code cx-delivery">
            <ng-container
              *ngIf="!bundleId && item.availabilityMessage && ((!isReviewOrder) || (!isSubscription && isReviewOrder))">
              <img src="../../../../../../assets/icons/delivery_icon_black_24.svg" />
              <span class="availability-msg">{{ item.availabilityMessage }}</span>
            </ng-container>
            <div class="sp-product-icons" *ngIf="item.product?.servicePart && !isCartPage">
              <img *ngIf="item.itemOnHand" src="../../../../../../assets/icons/check-green.svg">
              <generac-sp-ltl *ngIf="item.product.ltl" [ltl]="item.product.ltl"></generac-sp-ltl>
              <generac-sp-flammable *ngIf="item.product.flammable"
                [flammable]="item.product.flammable"></generac-sp-flammable>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cx-cart-addons">
      <!-- Item Details Outlet -->
      <ng-template [cxOutlet]="CartOutlets.ITEM_DETAILS"> </ng-template>

      <!-- Item Bundle Details Outlet -->
      <ng-template [cxOutlet]="CartOutlets.ITEM_BUNDLE_DETAILS"> </ng-template>

    </div>

    <!-- Variants -->
    <ng-container *ngIf="item.product?.baseOptions?.length">
      <div *ngFor="
          let variant of item.product?.baseOptions[0]?.selected
            ?.variantOptionQualifiers
        " class="cx-property">
        <!-- cx-mobile-header -->
        <div class="cx-label" *ngIf="variant.name && variant.value">
          {{ variant.name }}: {{ variant.value }}
        </div>
      </div>
    </ng-container>
  </td>
  <!-- Mobile Item Price -->
  <!-- <td *ngIf="item.basePrice" role="cell" class="cx-price desktop-only">
    <div class="cx-mobile-header" *ngIf="!isServicePartsEnabled || !isCartPage">
      {{ "cartItems.itemPrice" | cxTranslate }}
    </div>
    <div *ngIf="item.basePrice" class="cx-value">
      {{ item.basePrice?.formattedValue }}
    </div>
  </td> -->
  <!-- Item Quantity -->
  <td role="cell" class="cx-quantity desktop-only">
    <div class="cx-mobile-header" placement="left" title="{{ 'cartItems.quantityTitle' | cxTranslate }}">
      {{ "cartItems.quantity" | cxTranslate }}
    </div>
    <div class="cx-value justify-center" [class.readonly-value]="readonly">
      <div *ngIf="isBundleItem;else notABundleItem">{{quantityControl?.value}}</div>

      <ng-template #notABundleItem>
        <cx-custom-item-counter *ngIf="!isBundleItem && quantityControl" [control]="quantityControl"
          [readonly]="!item.updateable || readonly || options.isSaveForLater" [min]="+item.product?.minOrderQuantity"
          [max]="bundleId ? +maxItemsAllowed : +item.product?.maxOrderQuantity"
          [step]="+item.product?.addToCartQtyMultiplier" [isBundle]="!!bundleId"
          [allowZero]="true"></cx-custom-item-counter>
      </ng-template>
    </div>
  </td>
  <!-- Total -->
  <div class="desktop-only total-desktop-wrap">
    <ng-container *ngIf="options.isSaveForLater; else total">
      <td role="cell" class="cx-total desktop-only">
        <div class="cx-mobile-header">
          {{ "saveForLaterItems.stock" | cxTranslate }}
        </div>
        <div *ngIf="item.product?.stock?.stockLevel >= 0; else forceInstock" class="cx-value">
          {{ item.product?.stock.stockLevel }}
        </div>
        <ng-template #forceInstock>
          <div class="cx-value">
            {{ "saveForLaterItems.forceInStock" | cxTranslate }}
          </div>
        </ng-template>
      </td>
    </ng-container>
  </div>

  <td role="cell"
    *ngIf="(!readonly || options.isSaveForLater || options.displayAddToCart) && !isBundleItem && item.updateable"
    class="cx-actions">
    <ng-container *ngIf="!isProductOutOfStock(item.product)">
      <ng-container *ngTemplateOutlet="
          options.optionalBtn;
          context: {
            $implicit: {
              loading: quantityControl?.disabled,
              item: item,
              options: options,
              bundleId: bundleId
            }
          }
        "></ng-container>
    </ng-container>

    <span role="cell" *ngIf="isProductOutOfStock(item.product)" class="cx-availability">
      {{ "addToCart.outOfStock" | cxTranslate }}
    </span>
    <button *ngIf="!readonly" (click)="removeEntry(item)" [cxAtMessage]="'cartItems.itemRemoved' | cxTranslate"
      [disabled]="quantityControl?.disabled" attr.aria-label="{{ 'addToCart.removeFromCart' | cxTranslate }}"
      class="link cx-action-link cx-remove-btn">
      <cx-icon [type]="iconTypes.TRASH"></cx-icon>
      {{ "common.remove" | cxTranslate }}
    </button>
  </td>
</ng-template>
<ng-template #total>
  <td role="cell">
    <div *ngIf="item?.basePrice && item.quantity && item.totalPrice"
      class="cx-total text-center d-flex align-items-center justify-content-center">
      <div class="cx-mobile-header">
        {{ "cartItems.total" | cxTranslate }}
      </div>
      <div *ngIf="item.basePrice?.value && item.totalPrice?.currencyIso" class="cx-value"
        [class]="{'text-crossed': !isBundleItem && item.adjustedWithPromoTotalPrice?.value && (item.basePrice?.value - item.adjustedWithPromoTotalPrice?.value  > 0)}">
        {{
        item.basePrice?.value * item.quantity
        | currency : item.totalPrice.currencyIso
        }}
      </div>
    </div>
    <div
      *ngIf="item.adjustedWithPromoTotalPrice?.value && (item.basePrice?.value - item.adjustedWithPromoTotalPrice?.value)  > 0"
      class="cx-discount-total text-center">
      <div class="cx-value"
        *ngIf="item.adjustedWithPromoTotalPrice?.value && item.quantity && item.totalPrice?.currencyIso">
        {{
        item.adjustedWithPromoTotalPrice?.value * item.quantity
        | currency : item.totalPrice.currencyIso
        }}
      </div>
    </div>
  </td>
</ng-template>