import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProductExtended } from 'src/app/interfaces/product';

@Component({
  selector: 'generac-sp-info',
  templateUrl: './generac-sp-info.component.html',
  styleUrls: ['./generac-sp-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneracSpInfoComponent {
  @Input() servicePart: ProductExtended;
}
