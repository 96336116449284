<nav [attr.aria-label]="navAriaLabel" xmlns="http://www.w3.org/1999/html">
    <ul class="custom-nav-list">
        <ng-container *ngFor="let child of node?.children;trackBy: trackByItem;first as isFirst">
            <ng-container *ngIf="isFirst && isAllCategoriesVisible; else allOthers;">
                <ng-container>
                    <li
                            class="all-categories"
                            [ngClass]="{'active': isAllCategoriesExpanded}"
                            (cxClickOutside)="deactivateAllCategoriesByClickOutside(child.children)"
                    >
                        <button
                                class="all-categories-btn"
                                (click)="onMouseClickAllCategories(child.children)"
                        >
                            <img src="../../../../../assets/icons/categories.svg">
                            <span>{{child.title}}</span>
                        </button>

                        <cx-icon class="navigation-btn" [type]="iconType.CARET_RIGHT"></cx-icon>
                        <div
                                *ngIf="isAllCategoriesExpanded"
                                class="all-categories-wrapper"
                                [class]="'slides-' + activeCategorySlide"
                        >
                            <ng-container
                                    *ngTemplateOutlet="wrapperList; context: { categories: child.children, depth: 0, parent: child}"></ng-container>
                            <ng-template
                                #wrapperList
                                let-categories="categories"
                                let-depth="depth"
                                let-parent="parent"
                            >
                                <ng-container *ngIf="categories?.length">
                                    <ul
                                        #firstLevelCategoryList
                                        class="wrapper-list"
                                        [ngClass]="{'hidden-mobile': activeCategorySlide - 1 !== depth, 'desktop-hidden': depth >= 3 && (activeCategorySlide !== depth + 1) }"
                                        [style.max-height]="!isMobile && depth !==0 ? firstLevelCategoryList.offsetHeight + 'px' : 'unset'"
                                    >

                                        <li class="parent-item">
                                            <div class="parent-item-title">{{parent?.title}}</div>
                                            <div (click)="toggleHeader()" class="close-header"></div>
                                        </li>

                                        <li (click)="depth === 0 ? deactivateAllCategories(categories) : categoryBack(parent, $event)"
                                            class="wrapper-list-item back-button"
                                            [ngClass]="{'d-none': !isMobile && depth <= 3}"
                                        >
                                            <cx-icon [type]="iconType.CARET_LEFT"></cx-icon>
                                            {{isMobile ? 'Back' : 'Previous Level'}}
                                        </li>

                                        <li
                                                *ngFor="let category of categories;index as i;"
                                                class="wrapper-list-item"
                                                [class.active]="category.isSelected"
                                                (mouseenter)="onCategoryMouseEnter(categories, i, depth + 1)"
                                        >

                                            <ng-container
                                                    *ngIf="(!child.customClickHandler && category.url); else withoutUrl;">
                                                <cx-generic-link
                                                        [url]="category.url"
                                                        [target]="category.target"
                                                >
                                                    <div (click)="deactivateAllCategories(categories)">{{category?.title}}</div>
                                                </cx-generic-link>
                                            </ng-container>

                                            <ng-template #withoutUrl>
                                                <p class="category-title">{{category?.title}}</p>
                                            </ng-template>

                                            <button
                                                    *ngIf="category?.children"
                                                    class="navigate-deeper"
                                                    (click)="onCategoryMouseClick(categories, i, depth + 1)"
                                            >
                                                <cx-icon [type]="iconType.CARET_RIGHT"></cx-icon>
                                            </button>
                                        </li>
                                    </ul>

                                    <ng-container *ngIf="!isMobile && depth === 2; else simpleCategoryView">
                                        <ng-container *ngIf="getActiveCategory(categories) as activeCategory;">
                                            <div
                                                    *ngIf="activeCategory?.children?.length"
                                                    class="child-categories"
                                                    [class]="'slides-' + activeCategorySlide"
                                                    [style.max-height]="!isMobile && depth !==0 ? firstLevelCategoryList.offsetHeight + 'px' : 'unset'"
                                            >
                                                <ng-container
                                                        *ngTemplateOutlet="wrapperList; context: { categories: activeCategory.children, depth: depth + 1, parent: activeCategory}"
                                                ></ng-container>
                                            </div>
                                        </ng-container>

                                    </ng-container>

                                    <ng-template #simpleCategoryView>
                                        <ng-container *ngIf="getActiveCategory(categories) as activeCategory;">
                                            <ng-container
                                                    *ngTemplateOutlet="wrapperList; context: { categories: activeCategory.children, depth: depth + 1, parent: activeCategory}"
                                            ></ng-container>
                                        </ng-container>
                                    </ng-template>

                                </ng-container>
                            </ng-template>
                        </div>
                    </li>
                    <li class="divider">
                        <div class="divider-line"></div>
                    </li>
                </ng-container>
            </ng-container>
            <ng-template #allOthers>
                <ng-container *ngTemplateOutlet="nav; context: { node: child, depth: 0 }"></ng-container>
            </ng-template>
        </ng-container>

    </ul>
</nav>
<ng-template #nav let-node="node" let-depth="depth">
    <li [class.category-width]="isAllCategoriesVisible">
        <cx-generic-link
                *ngIf="(!!node.customClickHandler || node.url) && (!node.children || node.children?.length === 0); else heading"
                [url]="!!node.customClickHandler ? undefined : node.url"
                [target]="node.target"
                [style]="node.styleAttributes"
                [class]="node.styleClasses"
                [ngClass]="{'isActive' : !!node.isActive}"
                (click)="closeIfClickedTheSameLink(node); !!node.customClickHandler && node.customClickHandler(); closeMenu()"
        >
            {{ node.title }}
        </cx-generic-link>

        <ng-template #heading>
            <ng-container *ngIf="flyout && node.children?.length > 0; else title;">

                <cx-generic-link
                        *ngIf="(!!node.customClickHandler || node.url)"
                        [url]="!!node.customClickHandler ? undefined : node.url"
                        [class.active]="isWrapperVisible"
                        [target]="node.target"
                        [style.width]="nodeTitle.offsetWidth ? nodeTitle.offsetWidth + 1 + 'px' : '100%'"
                        (keydown.space)="closeMenu()"
                        (keydown.esc)="back()"
                        (mouseenter)="onMouseEnter($event)"
                        (click)="closeMenu(); !!node.customClickHandler && node.customClickHandler();"
                >
                    <div *ngIf="node.header" class="node-header">{{ node.header }}</div>
                    <span #nodeTitle class="node-title">{{ node.title }}</span>
                </cx-generic-link>

                <button
                        [attr.tabindex]="depth < 1 ? 0 : -1"
                        [attr.aria-haspopup]="true"
                        [attr.aria-expanded]="false"
                        [attr.aria-label]="node.title"
                        (click)="toggleOpen($any($event));"
                        (mouseenter)="onMouseEnter($event)"
                        (keydown.space)="toggleOpen($any($event))"
                        (keydown.esc)="back()"
                >
                    <ng-container *ngIf="!node.url">
                        <div *ngIf="node.header" class="node-header">{{ node.header }}</div>
                        {{ node.title }}
                    </ng-container>
                    <cx-icon class="dropdown-btn" [type]="iconType.CARET_DOWN"></cx-icon>
                    <cx-icon class="navigation-btn" [type]="iconType.CARET_RIGHT"></cx-icon>
                </button>
            </ng-container>

            <ng-template #title>
                <span [attr.tabindex]="-1">
                  {{ node.title }}
                </span>
            </ng-template>

        </ng-template>

        <div *ngIf="isWrapperVisible && node.children && node.children.length > 0" class="wrapper wrapper-dropdown">
            <ul
                    class="childs"
                    [attr.depth]="getTotalDepth(node)"
                    [attr.wrap-after]="node.children.length > wrapAfter ? wrapAfter : null"
            >
                <li class="back-button" [class.category-width]="isAllCategoriesVisible">
                    <cx-generic-link
                            [url]="undefined"
                            [target]="node.target"
                            [style]="node.styleAttributes"
                            [class]="node.styleClasses"
                            (click)="closeMenu()"
                    >
                        <cx-icon [type]="iconType.CARET_LEFT"></cx-icon>
                        Back
                    </cx-generic-link>
                </li>
                <ng-container *ngFor="let child of node?.children">
                    <ng-container *ngTemplateOutlet="nav; context: { node: child, depth: depth + 1 }"></ng-container>
                </ng-container>
            </ul>
        </div>
    </li>
</ng-template>