
import { Component, OnDestroy, OnInit } from '@angular/core';
import { GlobalMessageService, GlobalMessageType, ProductSearchPage, WindowRef } from '@spartacus/core';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { filter, skip, tap } from 'rxjs/operators';
import { ViewModes } from '../product-view/product-view.component';
import { CustomProductListComponentService } from './product-list-component.service';
import { ICON_TYPE, ViewConfig } from '@spartacus/storefront';
import { BaseStoreService } from 'src/app/services/base-store.service';
import { CustomerService } from 'src/app/services/customer.service';
import { GaItem, GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { GaListNames } from 'src/app/enums/ga-list-names.enum';
import { ActivatedRoute, Router } from '@angular/router';

const SEARCH_PAGE_TABS = [
  { name: 'Models', value: 'models' },
  { name: 'Service Parts', value: 'serviceParts' },
  { name: 'Documents', value: 'documents' },
];

@Component({
  selector: 'cx-product-list',
  templateUrl: './product-list.component.html',
})
export class ProductListComponent implements OnInit, OnDestroy {

  isInfiniteScroll: boolean | undefined;

  model$: Observable<ProductSearchPage> =
    this.productListComponentService.model$;

  viewMode$ = new BehaviorSubject<ViewModes>(ViewModes.List);
  ViewModes = ViewModes;
  iconTypes = ICON_TYPE;
  private subscription = new Subscription();

  tooltipMessage: string;
  phoneOrEmail: string;
  gaItems: GaItem[] = [];
  gaListName: string;
  gaProductCategories: { name: string }[] = [];
  activeTabIndex: number = 0;
  isServicePartsEnabled: boolean = false;
  isSearchPage: boolean;
  searchPageTabs = SEARCH_PAGE_TABS;
  searchBy: string;
  spSearchCode: string;
  searchTerm: string;
  currentQueryParams: any;
  servicePartsTabIndex = this.searchPageTabs.findIndex((tab) => tab.value == 'serviceParts');
  documentsTabIndex = this.searchPageTabs.findIndex((tab) => tab.value == 'documents');
  isServicePartsList: boolean;

  constructor(
    private productListComponentService: CustomProductListComponentService,
    private globalMessageService: GlobalMessageService,
    public scrollConfig: ViewConfig,
    private baseStoreService: BaseStoreService,
    protected customerService: CustomerService,
    protected googleAnalyticsService: GoogleAnalyticsService,
    protected winRef: WindowRef,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
  ) { }

  ngOnInit(): void {
    this.isInfiniteScroll = this.scrollConfig.view?.infiniteScroll?.active;
    this.gaListName = this.setGaListName();
    this.isServicePartsEnabled = JSON.parse(this.winRef.localStorage.getItem('isServicePartsEnabled'));
    this.isSearchPage = this.winRef.location.pathname.startsWith('/search');
    this.subscription.add(
      combineLatest([
        this.baseStoreService.getTooltipMessage(),
        this.baseStoreService.getPhoneNumber(),
      ]).subscribe(([messsage, phoneOrEmail]) => {
        this.tooltipMessage = messsage?.message;
        this.phoneOrEmail = phoneOrEmail?.contactInfo;
      })
    );

    this.subscription.add(
      combineLatest([this.model$, this.viewMode$])
        .pipe(
          tap(([model, mode]) => {
            if (model?.products && model?.breadcrumbs) {
              this.gaProductCategories = [];
              if (model?.breadcrumbs) {
                for (const breadcrumb of model.breadcrumbs) {
                  this.gaProductCategories.push({ name: breadcrumb.facetValueName })
                };
              }
              const mappedProducts = model.products.map((product) => {
                return {
                  ...product,
                  categories: this.gaProductCategories
                }
              });
              for (let [index, product] of mappedProducts.entries()) {
                this.gaItems.push(
                  this.googleAnalyticsService.mapProductToGaItem(product, index, undefined, this.gaListName)
                );
                if (this.gaItems.length == mappedProducts.length) {
                  this.googleAnalyticsService.sendGaEvent('view_item_list', {
                    items: this.gaItems,
                    item_list_name: this.gaListName || undefined,
                    item_list_id: this.gaListName ? this.gaListName.toLowerCase().trim().replace(/ /g, '_') : undefined,
                  });
                  this.gaItems = [];
                }
              }
              this.isServicePartsList = model?.breadcrumbs?.some(breadcrumb => breadcrumb.facetValueCode == 'service-parts') || this.activeTabIndex == this.servicePartsTabIndex;
            }
          }),
          skip(1),
          filter(([model, mode]) => !!model && !!mode)
        )
        .subscribe(() =>
          this.globalMessageService.add(
            { key: 'sorting.pageViewUpdated' },
            GlobalMessageType.MSG_TYPE_ASSISTIVE,
            500
          )
        )
    );

    this.subscription.add(
      this.customerService.getB2bUnitChanged().subscribe((isChanged) => {
        if (isChanged) {
          this.productListComponentService.getPageItems(0);
        }
      })
    );

    if (this.isServicePartsEnabled && this.isSearchPage) {
      this.subscription.add(
        this.activatedRoute.queryParams.pipe(
          tap((queryParams) => {
            this.currentQueryParams = queryParams;
            this.searchBy = queryParams?.searchBy;
            this.spSearchCode = queryParams?.code;
            this.searchTerm = this.getSearchTermFromUrl();
            let index;
            let servicePartQueryParam;
            if (queryParams?.query) {
              const querySplitted: Array<string> = queryParams?.query?.split(':');
              servicePartQueryParam = querySplitted[querySplitted.indexOf('servicePart') + 1] === 'true';
            }
            if ((queryParams?.searchBy?.length > 0 && queryParams?.code?.length > 0 || servicePartQueryParam) && !queryParams?.tab) {
              index = 1;
            } else if (queryParams?.tab?.length > 0) {
              index = Number(queryParams?.tab);
            } else {
              index = 0;
            }
            this.activeTabIndex = index;
            if (!queryParams?.tab && this.winRef.location.pathname.startsWith('/search')) {
              this.navigateToSearch();
            }
          })
        ).subscribe()
      )
    }
  }

  private setGaListName(): string {
    if (this.isSearchPage) {
      return GaListNames.SEARCH;
    } else if (this.winRef.location.href.indexOf('accessoriesProductRef') > -1) {
      return GaListNames.ACCESSORIES;
    } else {
      return GaListNames.PLP;
    }
  }

  sortList(sortCode: string): void {
    this.productListComponentService.sort(sortCode);
  }

  setViewMode(mode: ViewModes): void {
    this.viewMode$.next(mode);
  }

  selectTab(index: number): void {
    this.activeTabIndex = index;
    this.navigateToSearch(true);
  }

  getSearchTermFromUrl(): string {
    const splittedPath = this.winRef.location.pathname.split('/');
    const searchTerm = decodeURI(splittedPath?.length > 2 ? splittedPath[splittedPath?.length - 1] : '');
    return decodeURIComponent(searchTerm);
  }

  navigateToSearchWithExtraParams(freeTextSearch: string) {
    const splittedQuery = this.currentQueryParams.query.split(':');
    splittedQuery[0] = freeTextSearch;
    const query = this.currentQueryParams.query?.startsWith(':') ? (freeTextSearch + this.currentQueryParams.query) : splittedQuery.join(':');;
    this.router.navigate([`/search/${encodeURIComponent(freeTextSearch)}`], { queryParams: {...this.currentQueryParams, query } });
  }

  navigateToSearch(initState?: boolean): void {
    this.productListComponentService.navigateToSearch(this.activeTabIndex, this.getSearchTermFromUrl(), initState);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
