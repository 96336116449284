import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomLoadingSpinnerService {
  private spinnerState$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  showSpinner(): void {
    this.spinnerState$.next(true);
  }

  hideSpinner(): void {
    this.spinnerState$.next(false);
  }

  getSpinnerState(): Observable<boolean> {
    return this.spinnerState$.asObservable();
  }
}
