import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CombineArraysPipe } from "./combine-arrays.pipe";

@NgModule({
  declarations: [
    CombineArraysPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CombineArraysPipe
  ]
})
export class CombineArraysModule { }
