import { Injectable } from '@angular/core';
import { combineLatest, Observable } from "rxjs";
import { BREAKPOINT } from "@spartacus/storefront";
import { map } from "rxjs/operators";
import { CmsService } from "@spartacus/core";

@Injectable({
  providedIn: 'root'
})
export class LayoutHandlerService {

  handle(slots$: Observable<string[]>, pageTemplate?: string, section?: string, breakpoint?: BREAKPOINT): any {
    if (!section) {
      return combineLatest([
        this.cmsService.getCurrentPage()
          .pipe(
            map((page: any) => {
              const slotsWithComponents: string[] = [];

              for (let slot in page?.slots) {
                if (page.slots[slot]?.components?.length) {
                  slotsWithComponents.push(slot);
                }
              }
              return slotsWithComponents;
            })
          ),
        slots$
      ]).pipe(
        map(([slotsWithComponents, homeSlots]) => {
          return homeSlots.filter(homeSlot => slotsWithComponents.includes(homeSlot));
        })
      );
    }
    return slots$;
  }


  constructor(private cmsService: CmsService) {
  }
}
