import { Component, OnDestroy, OnInit } from '@angular/core';
import {CategoryNavigationComponent, CmsComponentData, NavigationNode, NavigationService, } from "@spartacus/storefront";
import {CmsNavigationComponent, CmsService} from "@spartacus/core";
import { NavigationService as ExtNavigationService } from "./navigation.service"
import { CustomerService } from 'src/app/services/customer.service';
import { Observable, Subscription } from 'rxjs';
import { Router } from "@angular/router";
import { filter, mapTo } from "rxjs/operators";

@Component({
  selector: 'cx-custom-category-navigation',
  templateUrl: './custom-category-navigation.component.html',
  styleUrls: ['./custom-category-navigation.component.scss']
})
export class CustomCategoryNavigationComponent extends CategoryNavigationComponent implements OnInit, OnDestroy {
  override node$: Observable<NavigationNode> = this.extService.getNavigationNode(
    this.componentData.data$
  );
  private subscription = new Subscription();

  constructor(
    override componentData: CmsComponentData<CmsNavigationComponent>,
    override service: NavigationService,
    protected extService: ExtNavigationService,
    protected customerService: CustomerService,
    protected cmsService: CmsService,
    private router: Router,
  ) {
    super(componentData, service);
  }

  ngOnInit(): void {
    this.subscribeToUnitChange();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private subscribeToUnitChange() {
    this.subscription.add(
      this.customerService.getB2bUnitChanged()
        .pipe(
          filter(Boolean),
          mapTo(this.router.routerState.snapshot.url?.split('/')?.filter(segment => !!segment)[0]),
          filter(url => url !== "product"),
        ).subscribe(() => this.cmsService.refreshLatestPage())
    );
  }
}
