import { Injectable } from '@angular/core';
import { GlobalMessageService, GlobalMessageType, LoggerService, normalizeHttpError, OccEndpointsService, Product, ProductActions, StateWithProduct } from '@spartacus/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { DocumentsResponse } from '../interfaces/document';
import { catchError, map, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  constructor(
    protected globalMessageService: GlobalMessageService,
    protected store: Store<StateWithProduct>,
    protected logger: LoggerService,
    private httpClient: HttpClient,
    private occEndpoints: OccEndpointsService,
  ) { }

  getDocuments(searchBy: string, code: string, currentPage: number = 0, pageSize: number = 20): Observable<DocumentsResponse> {
    const url = `${this.occEndpoints.getBaseUrl()}/users/current/documents?searchBy=${searchBy}&code=${code}&currentPage=${currentPage}&pageSize=${pageSize}`;
    return <Observable<DocumentsResponse>>this.httpClient.get(url).pipe(
      map((response: any): DocumentsResponse => {
        const mappedPagination = {
          currentPage: response.pagination.page,
          totalPages: response.pagination.totalPages,
          totalResults: response.pagination.totalCount,
          pageSize: response.pagination.count,
        };
        return {
          documents: response.documents,
          pagination: mappedPagination,
        };
      }),
      tap((res) => {
        this.store.dispatch(
          new ProductActions.SearchProductsSuccess(
            {
              products: res.documents as Product[],
              pagination: res.pagination,
              facets: [],
              breadcrumbs: [],
            },
            false
          )
        )
      }),
      catchError(res => {
        this.store.dispatch(
          new ProductActions.SearchProductsSuccess(
            {
              products: res.documents as Product[] || [],
              pagination: res.pagination || {totalResults: 0},
              facets: [],
              breadcrumbs: [],
            },
            false
          )
        );
        res.error.errors
          .forEach((error: any) => {
            this.globalMessageService.add(
              error.message
                ? error.message
                : { key: 'httpHandlers.unknownIdentifier' },
              GlobalMessageType.MSG_TYPE_ERROR
            );
          });
        return throwError(res.error.errors[0]);
      }),
    );
  }
}
