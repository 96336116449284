<div [cxFocus]="focusConfig" (esc)="close('Escape clicked')" class="favorite-products-added-to-cart-dialog">
  <div class="favorite-products-added-to-cart-dialog-container">
    <!-- Modal Header -->
    <div class="modal-header favorite-products-added-to-cart-dialog-header">
      <button (click)="close('Close Favorite Products Added To Cart Dialog')" [attr.aria-label]="'common.close' | cxTranslate"
        class="favorite-products-added-to-cart-dialog-close close" type="button">
        <span aria-hidden="true">
          <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
        </span>
      </button>
    </div>

    <!-- Modal Body -->
    <div class="favorite-products-added-to-cart-dialog-body">
      <img class="cart-icon" src="../../../../../assets/icons/cart.svg">
      <span class="confirm-message">
        {{ 'addToWishList.favoriteList.favoritesAddedToCart' | cxTranslate : { productsAmount } }}
      </span>

      <div class="favorite-products-added-to-cart-dialog-footer">
        <a [routerLink]="{ cxRoute: 'checkout' } | cxUrl" (click)="close('Proceed To Checkout click')"
          class="btn btn-secondary">
          {{ 'addToCart.proceedToCheckout' | cxTranslate }}</a>
        <a [routerLink]="{ cxRoute: 'cart' } | cxUrl" (click)="close('View Cart click')" class="btn btn-primary"
          autofocus>
          {{ 'addToCart.viewCart' | cxTranslate }}</a>
      </div>
    </div>
  </div>
</div>