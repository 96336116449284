import { NgModule } from '@angular/core';
import { BaseStorefrontModule } from '@spartacus/storefront';
import { SpartacusConfigurationModule } from './spartacus-configuration.module';
import { SpartacusFeaturesModule } from './spartacus-features.module';
import { HttpErrorHandler } from "@spartacus/core";
import { BadRequestHandler } from "./global-message/http-interceptors/bad-request/bad-request.handler";

@NgModule({
  declarations: [],
  imports: [
    SpartacusFeaturesModule,
    SpartacusConfigurationModule,
    BaseStorefrontModule
  ],
  providers: [
    {
      provide: HttpErrorHandler,
      useExisting: BadRequestHandler,
      multi: true,
    }
  ],
  exports: [BaseStorefrontModule, SpartacusFeaturesModule]
})
export class SpartacusModule { }
