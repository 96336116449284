<cx-spinner *ngIf="loading === LoadingEnum.Idle; else documentList;"></cx-spinner>
<ng-template #documentList>
  <div class="cx-sorting top">
    <div class="row">
      <div class="col-auto ml-auto pagination-block" [ngClass]="{'ml-auto': !isMobileView}">
        <div class="cx-pagination" [attr.aria-label]="'productList.productSearchPagination' | cxTranslate">
          <cx-pagination [pagination]="pagination" (viewPageEvent)="onPageChange($event)" queryParam="currentPage"
            [defaultPage]="0"></cx-pagination>
        </div>
      </div>
    </div>
  </div>
  <div class="document-list-header" *ngIf="!isMobileView">
    <thead>
      <th scope="col" class="file-type">{{ "productList.fileType" | cxTranslate }}</th>
      <th scope="col" class="file-name">{{ "productList.fileName" | cxTranslate }}</th>
      <th scope="col" class="item-number">{{ "productList.itemNumber" | cxTranslate }}</th>
      <th scope="col" class="language">{{ "productList.language" | cxTranslate }}</th>
    </thead>
  </div>
  <div id="product-results-list" class="cx-product-container">
    <gc-document-list-item *ngFor="let document of documents" [document]="document">
    </gc-document-list-item>

    <div *ngIf="documents?.length < 1" class="no-results">
      <img class="no-results-icon" src="../../../../../../assets/icons/no_results.svg" />
      <span class="no-results-text">{{ 'productList.noResults' | cxTranslate }}</span>
    </div>
  </div>
  <div class="cx-sorting bottom">
    <div class="row">
      <div class="col-auto ml-auto pagination-bottom"
        [attr.aria-label]="'productList.productSearchPagination' | cxTranslate">
        <div class="cx-pagination">
          <cx-pagination [pagination]="pagination" (viewPageEvent)="onPageChange($event)" queryParam="currentPage"
            [defaultPage]="0"></cx-pagination>
        </div>
      </div>
    </div>
  </div>
</ng-template>