<div [cxFocus]="focusConfig" (esc)="close('Escape clicked')" class="favorites-confirm-action-dialog">
  <div class="favorites-confirm-action-dialog-container">
    <!-- Modal Header -->
    <div class="modal-header favorites-confirm-action-dialog-header">
      <button (click)="close('Close Save Cart Dialog')" [attr.aria-label]="'common.close' | cxTranslate"
        class="favorites-confirm-action-dialog-close close" type="button">
        <span aria-hidden="true">
          <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
        </span>
      </button>
    </div>

    <!-- Modal Body -->
    <div class="favorites-confirm-action-dialog-body">
      <span class="confirm-message">
        <ng-container [ngSwitch]="mode">
          <ng-container *ngSwitchCase="'removeList'">
            {{ 'addToWishList.favoriteList.deleteListConfirmationText' | cxTranslate : {listName: list.name} }}
          </ng-container>
          <ng-container *ngSwitchCase="'deleteItems'">
            {{ 'addToWishList.favoriteList.removeAllItemsFromListConfirmationText' | cxTranslate : {listName: list.name} }}
          </ng-container>
          <ng-container *ngSwitchCase="'addAll'">
            {{ 'addToWishList.favoriteList.addAllToCartConfirmationText' | cxTranslate : {listName: list.name} }}
          </ng-container>
        </ng-container>
      </span>

      <span *ngIf="mode == 'removeList'" class="info-message">
        {{ 'addToWishList.favoriteList.deleteListInfoText' | cxTranslate }}
      </span>

      <div class="favorites-confirm-action-dialog-footer">
        <button (click)="close('Close Add To Favorites Dialog')" [attr.aria-label]="'common.close' | cxTranslate"
          [disabled]="loading" class="btn btn-action" type="button">
          {{ 'common.cancel' | cxTranslate }}
        </button>
        <button (click)="confirmAction(list.id)" [attr.aria-label]="'common.save' | cxTranslate" class="btn btn-primary"
          type="button" [disabled]="loading">
          <ng-container [ngSwitch]="mode">
            <ng-container *ngSwitchCase="'removeList'">
              {{ 'addToWishList.favoriteList.delete' | cxTranslate }}
            </ng-container>
            <ng-container *ngSwitchCase="'deleteItems'">
              {{ 'addToWishList.favoriteList.remove' | cxTranslate }}
            </ng-container>
            <ng-container *ngSwitchCase="'addAll'">
              {{ 'addToWishList.addToFavoriteModal.add' | cxTranslate }}
            </ng-container>
          </ng-container>
        </button>
      </div>
    </div>
  </div>
</div>