import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { I18nModule } from '@spartacus/core';

import { ActiveFacetsComponent, IconModule, KeyboardFocusModule } from '@spartacus/storefront';

@Component({
  selector: 'gc-active-facets',
  templateUrl: './active-facets.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    I18nModule,
    IconModule,
    KeyboardFocusModule,
  ],

})
export class GeneracActiveFacetsComponent extends ActiveFacetsComponent {

}
