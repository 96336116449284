import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { GeneracProductBadgeComponent } from './generac-product-badge.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [GeneracProductBadgeComponent],
  exports: [GeneracProductBadgeComponent],
})
export class GeneracProductBadgeModule {}
