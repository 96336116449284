import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { ActiveCartFacade } from "@spartacus/cart/base/root";
import { filter, mapTo } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CartReloadGuard implements CanActivate {

  constructor(private activeCartFacade: ActiveCartFacade) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.activeCartFacade.reloadActiveCart()
    return combineLatest([
      this.activeCartFacade.isStable().pipe(filter(isLoading => isLoading === true)),
      this.activeCartFacade.getLoading().pipe(filter(isLoading => isLoading === false))
    ]).pipe(mapTo(true));
  }
  
}
