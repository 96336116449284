<div class="cx-modal-container">
  <div class="delego__frame">
    <button
      type="button"
      class="close close-btn"
      (click)="closeModal()"
    >
      <span>
        <cx-icon
          class="cx-icon fas fa-times"
          ng-reflect-type="CLOSE"
        ></cx-icon>
      </span>
    </button>
    <ng-container *ngIf="!(isLoading$ | async); else loading">
      <iframe
        height="100%"
        id="delegoFrame"
        width="100%"
      ></iframe>
    </ng-container>
  </div>
  <ng-template #loading>
    <div
      class="d-flex align-items-center justify-content-center delego__spinner"
    >
      <cx-spinner></cx-spinner>
    </div>
  </ng-template>
</div>
