import { NgModule } from '@angular/core';
import { META_REDUCERS } from '@ngrx/store';
import { clearDetailProductStateMetaReducer } from './meta-reducer.reducer';

@NgModule({
  providers: [
    {
      provide: META_REDUCERS,
      deps: [],
      useFactory: clearDetailProductStateMetaReducer,
      multi: true,
    },
  ],
})
export class ReducersModule {}
