<div *ngIf="tabReferences && tabReferences.length > 0 && (size$ | async) as size" class="generac-carousel-wrapper">
    <div class="generac-carousel-header">
        <h2 *ngIf="title">{{ title }}</h2>
        <a *ngIf="viewAllLink" [href]="viewAllLink">
            {{ 'carousel.viewAll' | cxTranslate }}
        </a>
        <div class="generac-carousel-controls">
            <button
                    *ngIf="size < tabItems.length"
                    (click)="activeSlide = activeSlide - size"
                    [disabled]="activeSlide === 0"
                    [attr.aria-label]="'carousel.previousSlide' | cxTranslate"
                    class="previous"
                    type="button"
            >
                <cx-icon [type]="previousIcon"></cx-icon>
            </button>
            <button
                    *ngIf="size < tabItems.length" class="next"
                    (click)="activeSlide = activeSlide + size"
                    tabindex="-1"
                    [disabled]="activeSlide > tabItems.length - size - 1"
                    [attr.aria-label]="'carousel.nextSlide' | cxTranslate"
                    type="button"
            >
                <cx-icon [type]="nextIcon"></cx-icon>
            </button>
        </div>
    </div>

    <div class="carousel-panel" [ngClass]="'size-' + size">
        <div class="desktop-tab-paragraph-container" *ngIf="tabReferences?.length > 1">
            <ul cxHorizontalScrollOverflowHide class="desktop-tab-paragraph-tabs">
                <ng-container *ngFor="let item of tabReferences; let i = index">
                    <li class="desktop-tab-paragraph-tab">
                        <a>
                            <input [id]="'tabReference_' + i" type="radio" name="tab-references" [value]="i" />
                            <label [class.active]="activeTabIndex === i" (click)="selectTab(i)" [for]="'tabReference_' + i"
                                role="tab">{{ item.tab }}</label>
                        </a>
                    </li>
                </ng-container>
            </ul>
        </div>
        <div class="slides">
            <ng-container *ngFor="let _ of tabItems; let i = index">
                <div class="slide" *ngIf="i % size === 0" [class.active]="i === activeSlide">
                    <ng-container *ngFor="let item of tabItems | slice: i:i + size; let j = index">
                        <div *ngIf="item" class="item" [class.active]="i === activeSlide">
                            <ng-container *ngTemplateOutlet="template; context: { item: item }"></ng-container>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>
</div>