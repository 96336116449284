<div class="d-flex pb-2 pt-2">
  <h2 class="d-none d-lg-block">
    {{ 'checkoutAddress.ffAddress' | cxTranslate }}
  </h2>
</div>

<div class="freight-forwarder-info">
  <cx-icon class="regular-icon grey not-active" [type]="iconTypes.INFO"></cx-icon>
  <span class="info-desc">
    The specified final destination requires a freight forwarder. Please enter the forwarder information below
  </span>
</div>

<ng-container>
  <div>
    <label>
      <span class="label-content freight-forwarder-title required">
        {{ 'checkoutAddress.ffAddress' | cxTranslate }}
      </span>
      <ng-select [items]="fFAddresses" [clearable]="false" placeholder="Select address" [(ngModel)]="selectedCard"
        (ngModelChange)="onFFAddressChange($event)">
        <ng-template ng-label-tmp let-item="item">
          <div class="bold-title">
            <strong>{{ item.addressType.name }}</strong>:
            <strong>{{ item.companyName }}</strong>
          </div>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index">
          <div class="address">
            <div class="address-option">
              <div class="bold-title">
                <strong>{{ item.addressType.name }}</strong>:
                <strong>{{ item.companyName }}</strong>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-select>
      <cx-form-errors [control]="addressForm.get('country.isocode')"></cx-form-errors>
    </label>
  </div>
</ng-container>
<form [formGroup]="addressForm">
  <div class="form-group" formGroupName="country">

  </div>
  <div class="freight-forword-form">
    <div class="form-group">
      <ng-container>
        <div>
          <label>
            <span class="label-content">Company Name</span>
            <input class="form-control" type="text" placeholder="Company Name"
              formControlName="companyName" />
            <cx-form-errors [control]="addressForm.get('companyName')"></cx-form-errors>
          </label>
        </div>
      </ng-container>
    </div>
    <div class="row">
      <div class="form-group col-sm-6">
        <label>
          <span class="label-content required">Contact Name</span>
          <input
                  class="form-control"
                  type="text"
                  placeholder="Contact Name"
                  formControlName="firstName"
          >
          <cx-form-errors [control]="addressForm.get('firstName')"></cx-form-errors>
        </label>
      </div>
      <div class="form-group col-sm-6">
        <label>
          <span class="label-content required">Contact Phone</span>
          <input required="true" type="text" class="form-control" placeholder="Contact Phone"
            formControlName="phone" />
          <cx-form-errors [control]="addressForm.get('phone')"></cx-form-errors>
        </label>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-6">
        <label>
          <span class="label-content">Street</span>
          <input type="text" class="form-control"
            [placeholder]="'addressForm.streetAddress' | cxTranslate" formControlName="line1" />
          <cx-form-errors [control]="addressForm.get('line1')"></cx-form-errors>
        </label>
      </div>
      <div class="form-group col-sm-6">
        <label>
          <span class="label-content">Apt,suite,etc</span>
          <input type="text" class="form-control" [placeholder]="'addressForm.aptSuite' | cxTranslate"
            formControlName="line2" />
        </label>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-6">
        <label>
          <span class="label-content">{{
            'addressForm.city.label' | cxTranslate
            }}</span>
          <input type="text" class="form-control"
            [placeholder]="'addressForm.city.placeholder' | cxTranslate" formControlName="town" />
          <cx-form-errors [control]="addressForm.get('town')"></cx-form-errors>
        </label>
      </div>
      <div class="form-group col-sm-6">
        <ng-container *ngIf="regions.length !== 0" formGroupName="region">
          <label>
            <span class="label-content">{{
              'addressForm.state' | cxTranslate
              }}</span>
            <ng-select class="region-select" formControlName="isocode"
              [searchable]="true" [clearable]="false" [items]="regions"
              bindLabel="{{ regions[0].name ? 'name' : 'isocode' }}"
              bindValue="{{ regions[0].name ? 'isocode' : 'region' }}"
              [placeholder]="'addressForm.selectOne' | cxTranslate " id="region-select" [cxNgSelectA11y]="{
                        ariaLabel: 'addressForm.state' | cxTranslate
                      }">
            </ng-select>
            <cx-form-errors [control]="addressForm.get('region.isocode')"></cx-form-errors>
          </label>
        </ng-container>
      </div>
      <div class="form-group col-sm-6">
        <label>
          <span class="label-content">Postal Code</span>
          <input type="text" class="form-control"
            [placeholder]="'addressForm.zipCode.placeholder' | cxTranslate" formControlName="postalCode" />
          <cx-form-errors [control]="addressForm.get('postalCode')"></cx-form-errors>
        </label>
      </div>
    </div>
  </div>
</form>