/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';
import {
  AuthHttpHeaderService,
  AuthRedirectService,
  AuthService,
  AuthStorageService,
  GlobalMessageService,
  GlobalMessageType,
  OAuthLibWrapperService,
  OccEndpointsService,
  RoutingService
} from '@spartacus/core';
import {
  EMPTY,
} from 'rxjs';
import {
  catchError,
  filter,
  switchMap,
} from 'rxjs/operators';
import { AuthService as SSOAuth } from '@auth0/auth0-angular';
import { AuthHelperService } from './auth-helper.service';

/**
 * Extendable service for `AuthInterceptor`.
 */
@Injectable({
  providedIn: 'root',
})
export class CustomAuthHttpHeaderService extends AuthHttpHeaderService {

  constructor(
    protected override authService: AuthService,
    protected override authStorageService: AuthStorageService,
    protected override oAuthLibWrapperService: OAuthLibWrapperService,
    protected override routingService: RoutingService,
    protected override globalMessageService: GlobalMessageService,
    protected occEndpointsService: OccEndpointsService,
    protected override authRedirectService: AuthRedirectService,
    private ssoAuth: SSOAuth,
    private authHelperService: AuthHelperService
  ) {
    super(
      authService,
      authStorageService,
      oAuthLibWrapperService,
      routingService,
      occEndpointsService,
      globalMessageService,
      authRedirectService
    );
  }

  /**
   * Logout user, redirected to login page and informs about expired session.
   */
  override handleExpiredRefreshToken(): void {
    this.ssoAuth.getAccessTokenSilently().pipe(
      filter(token => !!token),
      switchMap(token => this.authHelperService.loginToSSOAndSAP(token)),
      switchMap(() => this.ssoAuth.loginWithRedirect()),
      catchError(err => {
        this.authService.coreLogout().finally(() => {
          this.routingService.go({ cxRoute: 'login' });
          this.globalMessageService.add(
            {
              key: 'httpHandlers.sessionExpired',
            },
            GlobalMessageType.MSG_TYPE_ERROR
          );
        });
        return EMPTY;
      })
    ).subscribe();
  }

}
