import { NgModule } from '@angular/core';
import { CmsConfig, I18nConfig, I18nModule, provideConfig } from "@spartacus/core";
import { productImageZoomTranslationChunksConfig, productImageZoomTranslations } from "@spartacus/product/image-zoom/assets";
import { ProductImageZoomRootModule, PRODUCT_IMAGE_ZOOM_FEATURE } from "@spartacus/product/image-zoom/root";
import { GeneracProductImageZoomProductImagesComponent } from './product-image-zoom-product-images/product-image-zoom-product-images.component';
import { CarouselModule, IconModule, KeyboardFocusModule, MediaModule, OutletModule } from '@spartacus/storefront';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ProductImageZoomModule, ProductImageZoomTriggerComponent } from '@spartacus/product/image-zoom/components';

@NgModule({
  declarations: [
    GeneracProductImageZoomProductImagesComponent
  ],
  imports: [
    ProductImageZoomRootModule,
    CarouselModule,
    CommonModule,
    I18nModule,
    IconModule,
    KeyboardFocusModule,
    MediaModule,
    OutletModule,
    RouterModule,
    ProductImageZoomModule
  ],
  providers: [
  provideConfig(<I18nConfig>{
    i18n: {
      resources: productImageZoomTranslations,
      chunks: productImageZoomTranslationChunksConfig,
    },
  })
  ],
  exports: [
    GeneracProductImageZoomProductImagesComponent,
  ],
})
export class ProductImageZoomFeatureModule { }
