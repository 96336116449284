import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from "@spartacus/core";
import { SmartEditConfig, SmartEditRootModule, SMART_EDIT_FEATURE } from "@spartacus/smartedit/root";
import { SmartEditModule } from "@spartacus/smartedit";

@NgModule({
  declarations: [],
  imports: [
    SmartEditRootModule,
    SmartEditModule
  ],
  providers: [provideConfig(<CmsConfig>{
  }),
  provideConfig(<SmartEditConfig>{
    smartEdit: {
      storefrontPreviewRoute: 'STOREFRONT_PREVIEW_ROUTE_PLACEHOLDER',
      allowOrigin: 'ALLOWED_ORIGIN_PLACEHOLDER',
    },
  })
  ]
})
export class SmartEditFeatureModule { }
