import { Injectable, isDevMode } from '@angular/core';
import { ActionsSubject } from '@ngrx/store';
import { CartActions, ProductImportInfoService } from '@spartacus/cart/base/core';
import { ProductImportInfo, ProductImportStatus } from '@spartacus/cart/base/root';

@Injectable({
  providedIn: 'root',
})
export class CustomProductImportInfoService extends ProductImportInfoService {
  constructor(protected override actionsSubject: ActionsSubject) {
    super(actionsSubject);
  }

  protected override mapMessages(
    action: CartActions.CartAddEntrySuccess | CartActions.CartAddEntryFail
  ): ProductImportInfo {
    const { productCode } = action.payload;
    if (action.type === CartActions.CART_ADD_ENTRY_SUCCESS) {
      const { quantity, quantityAdded, entry, statusCode } = action.payload;
      if (statusCode === ProductImportStatus.LOW_STOCK) {
        return {
          productCode,
          statusCode,
          productName: entry?.product?.name,
          quantity,
          quantityAdded,
        };
      }
      if (
        statusCode === ProductImportStatus.SUCCESS ||
        statusCode === ProductImportStatus.NO_STOCK
      ) {
        return { productCode, statusCode, productName: entry?.product?.name };
      }
    } else if (action.type === CartActions.CART_ADD_ENTRY_FAIL) {
      const { error } = action.payload;
      if (error?.details[0]?.type === 'UnknownIdentifierError') {
        return {
          productCode,
          statusCode: ProductImportStatus.UNKNOWN_IDENTIFIER,
        };
      }
    }
    if (isDevMode()) {
      console.warn(
        'Unrecognized cart add entry action type while mapping messages',
        action
      );
    }
    return { productCode, statusCode: ProductImportStatus.UNKNOWN_ERROR };
  }
}
