<div [cxFocus]="focusConfig" (esc)="close('Escape clicked')" class="cx-modal-container warning-dialog">
  <div class="cx-modal-content">
    <div class="modal-header cx-dialog-header">
      <button (click)="close('close dialog')" [attr.aria-label]="'common.close' | cxTranslate"
        class="warning-dialog-close close" type="button">
        <img class="" src="../../../../../../../assets/icons/close_black_24.svg">
      </button>
    </div>

    <div class="cx-dialog-body modal-body">
      <div class="cx-dialog-row">
        <span class="cx-dialog-item">{{ message }}</span>
      </div>

      <div class="cx-dialog-footer modal-footer">
        <button class="btn btn-primary" (click)="close('close dialog')">
          Ok
        </button>
      </div>
    </div>
  </div>
</div>