<div
        class="bundle-carousel-slide"
        [ngClass]="{'active': isActive, 'no-price': !bundle?.totalPrice}"
>
    <cx-custom-message [messages]="errorMessages"></cx-custom-message>

    <cx-spinner *ngIf="loading === LoadingEnum.Loading" class="spinner-overlay"></cx-spinner>

    <cx-bundle-corner-item [bundle]="bundle"></cx-bundle-corner-item>

    <div class="bundle-carousel-items">
        <cx-bundle-carousel-item
                *ngFor="let item of bundle.items; trackBy: trackByProduct;"
                [product]="item"
                [ngClass]="{
                            'items-size-one': bundle.items.length === 1,
                            'items-size-multiply-two': bundle.items.length % 2 ===0,
                            'items-size-multiply-two-plus-one': bundle.items.length % 2 === 1
                            }"
        ></cx-bundle-carousel-item>
    </div>
    <cx-bundle-corner-item
            [isSummary]="true"
            [bundle]="bundle"
            (addBundleToTheCart)="addBundleToTheCart.emit($event)"
    ></cx-bundle-corner-item>
</div>