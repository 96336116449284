import { Injectable } from '@angular/core';
import {
  OrderEntry,
  ORDER_ENTRY_PROMOTIONS_NORMALIZER,
  PromotionResult,
} from '@spartacus/cart/base/root';
import {
  Converter,
  ConverterService,
} from '@spartacus/core';
import { EntryGroup } from "../../../../../../interfaces/cart";
import { OrderExtended } from 'src/app/interfaces/order';

@Injectable({ providedIn: 'root' })
export class OccOrderNormalizer implements Converter<OrderExtended, OrderExtended> {
  constructor(private converter: ConverterService) {}

  convert(source: OrderExtended, target?: OrderExtended): OrderExtended {
    if (target === undefined) {
      target = { ...(source as any) } as OrderExtended;
    }
    const promosIds: number[][] =  target?.appliedProductPromotions?.map(promo => promo.consumedEntries.map(consumedEntry => consumedEntry.orderEntryNumber));

    if (source.entries) {
      target.entries = source.entries.map((entry: any) =>
        this.convertOrderEntry(entry, source.code, this.getActualPromos(promosIds, entry, target))
      );
    }

    if (source.consignments) {
      target.consignments = source.consignments.map((consignment: any) => ({
        ...consignment,
        entries: consignment.entries?.map((entry: any) => ({
          ...entry,
          orderEntry: this.convertOrderEntry(
            entry.orderEntry,
            source.code,
            this.getActualPromos(promosIds, entry, target)
          ),
        })),
      }));
    }

    if (source.unconsignedEntries) {
      target.unconsignedEntries = source.unconsignedEntries.map((entry: any) =>
        this.convertOrderEntry(
          entry,
          source.code,
          this.getActualPromos(promosIds, entry, target)
        )
      );
    }

    if (source?.entryGroups) {
      target.entryGroups = source.entryGroups.map((entryGroup: EntryGroup) => {
        let entryGroupEntries: OrderEntry[] = [];

        if (entryGroup.type === 'CONFIGURABLEBUNDLE') {
          entryGroupEntries = entryGroup.entryGroups[0].entries.map((orderEntry: OrderEntry) =>
            this.convertOrderEntry(orderEntry, source.code, this.getActualPromos(promosIds, orderEntry, target))
          );

          return {
            ...entryGroup,
            entryGroups: [{
              ...entryGroup.entryGroups[0],
              entries: entryGroupEntries
            }]
          } as EntryGroup;
        } else {
          entryGroupEntries = entryGroup.entries.map((orderEntry: OrderEntry, index) =>
            this.convertOrderEntry(orderEntry, source.code, this.getActualPromos(promosIds, orderEntry, target))
          );
          return {
            ...entryGroup,
            entries: entryGroupEntries
          } as EntryGroup;
        }
      })
    }

    return target;
  }

  private convertOrderEntry(source?: OrderEntry, code?: string, promotions?: PromotionResult[]): OrderEntry {
    return {
      ...source,
      orderCode: code,
      promotions: this.converter.convert(
        { item: source, promotions: promotions },
        ORDER_ENTRY_PROMOTIONS_NORMALIZER
      ),
    };
  }

  private getActualPromos(promosIds: number[][], entry: OrderEntry, target: OrderExtended) {
    const actualPromotionsIndexes = promosIds?.map((promoIds) =>
      promoIds.some(promoId => promoId === entry.entryNumber)
    );
    return target?.appliedProductPromotions?.filter((_, index) => actualPromotionsIndexes[index]);
  }
}
