import { NgModule } from '@angular/core';
import { quickOrderTranslationChunksConfig, quickOrderTranslations } from '@spartacus/cart/quick-order/assets';
import { CART_QUICK_ORDER_FEATURE, QuickOrderRootModule } from '@spartacus/cart/quick-order/root';
import { CmsConfig, I18nConfig, provideConfig } from '@spartacus/core';

@NgModule({
  declarations: [],
  imports: [QuickOrderRootModule],
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        [CART_QUICK_ORDER_FEATURE]: {
          module: () =>
            import('./quick-order/custom-quick-order.module').then(
              (m) => m.CustomQuickOrderModule
            ),
        },
      },
    }),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: quickOrderTranslations,
        chunks: quickOrderTranslationChunksConfig,
      },
    }),
  ],
})
export class CartQuickOrderFeatureModule {}
