import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CarouselComponent } from "@spartacus/storefront";
import { ProductExtended, ProductTabReference } from 'src/app/interfaces/product';

@Component({
  selector: 'generac-carousel',
  templateUrl: './generac-carousel.component.html',
  styleUrls: ['./generac-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeneracCarouselComponent extends CarouselComponent {
  activeTabIndex: number = 0;
  tabItems: ProductExtended[];
  tabReferences: ProductTabReference[];
  @Input('tabReferences')
  set setTabReferences(inputItems: ProductTabReference[]) {
    this.tabReferences = inputItems;
    this.selectTab(this.activeTabIndex);
  }
  @Input() viewAllLink: string;

  selectTab(index: number) {
    this.activeTabIndex = index;
    this.tabItems = this.tabReferences[this.activeTabIndex]?.products as ProductExtended[];
    this.activeSlide = 0;
  }
}
