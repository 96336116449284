<div *ngIf="bundle" class="bundle-corner-item" [ngClass]="{'first-item': !isSummary}">
    <div class="bundle-corner-item-symbol"></div>

    <ng-container *ngIf="isSummary; else notSummary">
        <p class="bundle-corner-item-name summary">Total NET Price:</p>
        <p *ngIf="bundle.totalPrice" class="bundle-corner-item-price summary-price">
            {{ bundle.totalPrice | currency }}
        </p>
    </ng-container>

    <ng-template #notSummary>
        <a (click)="goToProduct()">
            <div class="bundle-corner-item-image-container" tabindex="-1" role="button">
                <cx-media
                        class="bundle-corner-item-image"
                        [container]="bundle.product.images[0]"
                        [alt]="bundle.product?.name"
                        format="product"
                ></cx-media>
            </div>
            <p class="bundle-corner-item-name" [innerHTML]="bundle.product?.name"></p>

            <p *ngIf="bundle.product?.price?.value" class="bundle-corner-item-price">
                {{ bundle.product.price.value | currency }}
            </p>
        </a>
    </ng-template>

    <button *ngIf="isSummary" (click)="addToCart()" class="btn btn-primary bundle-corner-item-submit">
        <cx-icon [type]="iconTypes.CART"></cx-icon>
        Add to Cart
    </button>
</div>