import { ActiveCartFacade } from "@spartacus/cart/base/root";
import { Injectable } from "@angular/core";
import { CartExtended } from "../interfaces/cart";

@Injectable({ providedIn: 'root' })
export class CheckoutMonitorService {
  private inCheckout = false;
  private isWellsFargoCart = false;

  constructor(private activeCartService: ActiveCartFacade) {
    this.activeCartService.getActive().subscribe((cart) => {
      this.isWellsFargoCart = !!(cart as CartExtended)?.wellsFargoCart;
    });
  }

  setInCheckout(value: boolean): void {
    this.inCheckout = value;
  }

  shouldTrackAbandonment(): boolean {
    return this.inCheckout && this.isWellsFargoCart;
  }
}