import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { SubscriptionInformationItem } from '../interfaces/checkout';
import { OccEndpointsService, User } from '@spartacus/core';
import { CustomLoadingSpinnerService } from './custom-loading-spinner.service';
import { UserAccountFacade } from "@spartacus/user/account/root";

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsService {

  constructor(
    protected customLoadingSpinnerService: CustomLoadingSpinnerService,
    private httpClient: HttpClient,
    private userAccount: UserAccountFacade,
    private occEndpoints: OccEndpointsService,
  ) {
  }

  getSubscriptions(userEmail: string): Observable<any> {
    const url = `${this.occEndpoints.getBaseUrl()}/users/${userEmail}/subscriptions`;
    return this.httpClient.get(url);
  }

  getCurrentSubscriptions(
    userEmail: string,
    sapMaterialNumber: string
  ): Observable<any> {
    const url = `${this.occEndpoints.getBaseUrl()}/users/${userEmail}/subscriptions/current`;
    const params = {
      sapMaterialNumber,
    };
    return this.httpClient.get(url, {params});
  }

  getUserRoles(userEmail: string): Observable<any> {
    const url = `${this.occEndpoints.getBaseUrl()}/users/${userEmail}/subscriptions/getUserRoles`;
    return this.httpClient.get(url);
  }

  getSubscriptionInformation(userEmail: string, cartId: string): Observable<any> {
    const url = `${this.occEndpoints.getBaseUrl()}/users/${userEmail}/carts/${cartId}/subscriptions/getInformationList`;
    return this.httpClient.get(url);
  }

  setSubscriptionInformation(
    userEmail: string,
    cartId: string,
    body: SubscriptionInformationItem
  ): Observable<any> {
    const url = `${this.occEndpoints.getBaseUrl()}/users/${userEmail}/carts/${cartId}/subscriptions/setInformation`;
    return this.httpClient.post(url, body);
  }

  validateEmail(email: string, subscriptionId: string): Observable<any>  {
    return this.userAccount.get()
      .pipe(
        filter<User>(Boolean),
        take(1),
        switchMap(user => {
          const url = `${this.occEndpoints.getBaseUrl()}/users/${user.uid}/subscriptions/validateEmail`;
          return this.httpClient.post(url,
            {
              userName: email,
              sapMaterialNbr : subscriptionId
            })
        }),
        catchError(err => {
          const errors = err?.error?.errors;
          return errors?.length ? of(errors[0].message) : null;
        })
      )
  }

}
