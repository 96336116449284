import { ChangeDetectionStrategy, Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FocusConfig, LaunchDialogService } from "@spartacus/storefront";
import { combineLatest, Observable, Subscription } from "rxjs";
import { ActiveCartFacade, Cart, OrderEntry, PromotionLocation } from "@spartacus/cart/base/root";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { ConverterService, isNotUndefined, Product, PRODUCT_NORMALIZER, RoutingService, User } from "@spartacus/core";
import {
  distinctUntilChanged,
  filter,
  map, pairwise,
  startWith,
  switchMap,
  switchMapTo,
  take,
  tap
} from "rxjs/operators";
import { UserAccountFacade } from "@spartacus/user/account/root";
import { UserGroup } from "../../../../../enums/user.enum";
import { CheckoutStepService } from "@spartacus/checkout/base/components";
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { CartUiEventAddToCart } from './custom-added-to-cart-dialog-event.listener';
import { GaListNames } from 'src/app/enums/ga-list-names.enum';
import { BaseStoreService } from "../../../../../services/base-store.service";
import { ProductExtended, ProductTabReference } from "../../../../../interfaces/product";
import { ProductService } from "../../../../../services/product.service";
import { LoadingEnum } from "../../../../../enums/loading.enum";
import { isEqual } from "../../../../../shared/helpers/is-equal";
import { findChangedInnerObject } from "../../../../../shared/helpers/find-changed-inner-object";
import { GeneracActiveCartService } from "../../core/facade/active-cart.service";

@Component({
  selector: 'cx-added-to-cart-dialog',
  templateUrl: './custom-added-to-cart-dialog.component.html',
  styleUrls: ['./custom-added-to-cart-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomAddedToCartDialogComponent implements OnInit, OnDestroy {
  entry$!: Observable<any | undefined>;
  entries!: OrderEntry[];
  isCartLoaded$: Observable<boolean> = this.activeCartService.isStable();
  cart$: Observable<Cart> = this.activeCartService.getActive();
  addedEntryWasMerged$!: Observable<boolean>;
  promotionLocation: PromotionLocation = PromotionLocation.ActiveCart;
  quantity = 0;
  form: UntypedFormGroup = new UntypedFormGroup({});
  focusConfig: FocusConfig = {
    trap: true,
    block: true,
    autofocus: false,
    focusOnEscape: true,
  };
  restrictedCheckoutGroup = true;
  gaListName: string;
  navigatedToPpdFrom: string;
  tooltipMessage: string;
  phoneOrEmail: string;
  itemsWithTabs: ProductTabReference[];

  @HostListener('click', ['$event'])
  handleClick(event: UIEvent): void {
    if ((event.target as any).tagName === this.el.nativeElement.tagName) {
      this.dismissModal('Cross click');
    }
  }

  protected readonly LoadingEnum = LoadingEnum;
  protected subscription = new Subscription();
  protected formSubscription: Subscription;
  private currentEntriesIds: string[] = [];

  constructor(
    protected activeCartFacade: ActiveCartFacade,
    protected activeCartService: GeneracActiveCartService,
    protected launchDialogService: LaunchDialogService,
    protected routingService: RoutingService,
    protected checkoutStepService: CheckoutStepService,
    private userAccount: UserAccountFacade,
    protected el: ElementRef,
    protected googleAnalyticsService: GoogleAnalyticsService,
    private baseStoreService: BaseStoreService,
    private productService: ProductService,
    private converter: ConverterService,
  ) {
  }

  ngOnInit(): void {
    this.subscribeToDialogData();
    this.subscribeToRoutingState();
    this.getUserAccount();
    this.initGA();
    this.subscribeToBaseInfo();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.formSubscription?.unsubscribe();
  }

  createQuantityControls() {
    this.subscription.add(
      this.activeCartService.getEntries().pipe(
        distinctUntilChanged((previous, current) => isEqual(previous, current)),
        filter((e) => !!e),
      ).subscribe(entries => {
        this.createForm(entries);
        this.subscribeToForm();
      })
    )
  }

  init(
    productCode: string,
    quantity: number,
    numberOfEntriesBeforeAdd: number,
    gaListName: string
  ): void {
    this.currentEntriesIds.push(productCode);
    this.entry$ = this.activeCartService.getLastEntry(productCode) as Observable<any>;
    this.createQuantityControls();
    this.quantity = quantity;
    this.addedEntryWasMerged$ = this.getAddedEntryWasMerged(
      numberOfEntriesBeforeAdd,
      productCode
    );
    this.gaListName = gaListName;
  }

  dismissModal(reason?: any): void {
    this.checkoutStepService.resetSteps();
    this.launchDialogService.closeDialog(reason);
  }

  onAccessoryAdd(accessor: { productCode: string, quantity: string }) {
    this.activeCartService.addEntry(accessor.productCode, +accessor.quantity, this.gaListName);

    if (!this.currentEntriesIds.includes(accessor.productCode)) {
      this.currentEntriesIds.push(accessor.productCode);
    }
  }

  getControl(entry: OrderEntry): UntypedFormGroup {
    return <UntypedFormGroup>this.form.get(this.getControlName(entry)).get('quantity');
  }

  protected getAddedEntryWasMerged(
    numberOfEntriesBeforeAdd: number,
    productCode: string
  ): Observable<boolean> {
    return this.isCartLoaded$.pipe(
      filter((loaded) => loaded),
      switchMapTo(this.activeCartFacade.getEntries()),
      map((entries) => {
        const entriesCount = entries.filter(entry => this.currentEntriesIds.includes(entry.product.code))?.length;

        if (entries.length === 0 && numberOfEntriesBeforeAdd === 0) {
          this.dismissModal('Cross click');
        }
        if (entriesCount === 0) {
          this.dismissModal('Cross click');
        }
        return entries.length === numberOfEntriesBeforeAdd;
      })
    );
  }

  private subscribeToDialogData() {
    this.subscription.add(
      this.launchDialogService.data$
        .pipe(
          tap((dialogData: CartUiEventAddToCart) => this.init(
            dialogData.productCode,
            dialogData.quantity,
            dialogData.numberOfEntriesBeforeAdd,
            dialogData.gaListName
          )),
          switchMap(dialogData => this.getProductTabsReferences(dialogData.productCode)),
          tap((productTabReferences: ProductTabReference[]) => this.itemsWithTabs = productTabReferences),
          switchMap(() => this.getEntries())
        ).subscribe(entries => this.entries = entries)
    )
  }

  private subscribeToRoutingState() {
    this.subscription.add(
      this.routingService
        .getRouterState()
        .pipe(
          tap(state => {
            if (this.gaListName !== GaListNames.WARRANTIES) {
              this.navigatedToPpdFrom = state.state?.queryParams?.gaListName;
            }
          }),
          filter((state) => !!state.nextState)
        ).subscribe(() => this.dismissModal('dismiss'))
    );
  }

  private getUserAccount(): void {
    this.userAccount.get()
      .pipe(
        take(1),
        tap((user: User) => {
          if (user) {
            this.restrictedCheckoutGroup = user.roles?.includes(UserGroup.restrictedCheckoutGroup);
          }
        }),
      ).subscribe()
  }

  private initGA() {
    if (this.entry$) {
      this.entry$.pipe(
        filter(isNotUndefined),
        take(1)
      ).subscribe((entry) => {
        const product = {
          ...entry.product,
          price: {
            value: entry.basePrice.value
          }
        };
        const quantity = this.quantity < entry?.product?.addToCartQtyMultiplier ? +(entry?.product?.addToCartQtyMultiplier) : this.quantity;
        this.googleAnalyticsService.sendGaEvent('add_to_cart', {
          currency: entry.totalPrice.currencyIso,
          value: entry.basePrice.value * quantity,
          items: [this.googleAnalyticsService.mapProductToGaItem(product, 0, quantity, this.gaListName, this.navigatedToPpdFrom)]
        });
      })
    }
  }

  private getProductTabsReferences(productCode: string): Observable<ProductTabReference[]> {
    this.itemsWithTabs = null;
    return this.productService.getProductTabReferences(productCode).pipe(
      take(1),
      map((productTabReferences: ProductTabReference[]) => {
        return productTabReferences.map(productTabReference => {
          // if(productTabReference.tab == 'All') {
          //   for(const [index, reference] of productTabReference.products?.entries()) {
          //     this.gaAccessories.push(
          //       this.googleAnalyticsService.mapProductToGaItem(reference, index, undefined, this.gaListName)
          //     );
          //     if (this.gaAccessories.length == productTabReference.products?.length) {
          //       this.googleAnalyticsService.sendGaEvent('view_item_list', {
          //         items: this.gaAccessories,
          //         item_list_name: this.gaListName || undefined,
          //         item_list_id: this.gaListName ? this.gaListName.toLowerCase().trim().replace(/ /g, '_') : undefined,
          //       });
          //       this.gaAccessories = [];
          //     }
          //   }
          // };
          return {
            tab: productTabReference.tab,
            products: productTabReference.products.map((product: Product) => {
              return this.converter.convert({
                ...product,
                productCode: product.code,
              }, PRODUCT_NORMALIZER);
            }) as ProductExtended[],
          }
        })
      })
    );
  }

  private subscribeToBaseInfo() {
    this.subscription.add(
      combineLatest([
        this.baseStoreService.getTooltipMessage(),
        this.baseStoreService.getPhoneNumber(),
      ]).subscribe(([message, phoneOrEmail]) => {
        this.tooltipMessage = message?.message;
        this.phoneOrEmail = phoneOrEmail?.contactInfo;
      })
    );
  }

  private getControlName(item: OrderEntry): string {
    return item.entryNumber?.toString() || '';
  }

  private createForm(entries: OrderEntry[]): void {
    entries.forEach((item) => {
      const controlName = this.getControlName(item);
      const control = this.form.get(controlName);
      if (control) {
        if (control.get('quantity')?.value !== item.quantity) {
          control.patchValue({quantity: item.quantity}, {emitEvent: false});
        }
      } else {
        const group = new UntypedFormGroup({
          entryNumber: new UntypedFormControl(item.entryNumber),
          quantity: new UntypedFormControl(item.quantity, {updateOn: 'blur'}),
        });
        this.form.addControl(controlName, group);
      }

      if (!item.updateable) {
        this.form.controls[controlName].disable();
      }
    });
  }

  private getEntries() {
    return this.activeCartService.getEntries()
      .pipe(
        map(entries => entries.filter(entry => this.currentEntriesIds.includes(entry.product.code))),
        filter(entries => !!entries?.length),
        tap(entries => {
          if (this.currentEntriesIds.length > entries.length) {
            this.currentEntriesIds = this.currentEntriesIds.filter(currentId => entries.find(entry => currentId === entry.product.code))
          }
        }),
        map(entries => this.currentEntriesIds.map(currentId => entries.find(entry => entry.product.code === currentId)))
      )
  }

  private subscribeToForm() {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
    this.formSubscription = this.form.valueChanges
      .pipe(
        startWith(this.form.value),
        filter(value => !!value),
        distinctUntilChanged(isEqual),
        pairwise(),
        map(([previous, current]) => findChangedInnerObject(previous, current)?.changedValue),
        tap(value => {
          const entry = this.entries?.find(entry => value?.entryNumber === entry.entryNumber);

          if (entry && value) {
            this.activeCartService.updateEntry(
              value.entryNumber,
              value.quantity
            );
          }
        })
      ).subscribe();
  }

}

