import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { OrderExtended } from "../../../../../../interfaces/order";
import { OrderConfirmationTotalsComponent } from '@spartacus/order/components';

@Component({
  selector: 'cx-order-confirmation-totals',
  templateUrl: './order-confirmation-totals.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneracOrderConfirmationTotalsComponent extends OrderConfirmationTotalsComponent implements OnDestroy {
  override order$: Observable<OrderExtended | undefined> = this.orderFacade.getOrderDetails() as Observable<any>;
}
