import { NgModule } from "@angular/core";
import { AtMessageModule, IconModule, MediaModule, OutletModule, SpinnerModule } from "@spartacus/storefront";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { I18nModule, UrlModule } from "@spartacus/core";

import { ProductGridItemComponent } from "./product-grid-item.component";
import {
  CustomProductAddToCartModule
} from "../../../cart/components/custom-product-add-to-cart/custom-product-add-to-cart.module";
import { SharedModule } from "../../../../../shared/shared.module";
import { InfoMessageModule } from "src/app/shared/components/info-message/info-message.module";
import { AddToFavoritesComponent } from "../../../favorites/add-to-favorites/add-to-favorites.component";
import { InfoPriceWrapperComponent } from "../../components/info-price-wrapper/info-price-wrapper.component";
import { GeneracSpLtlComponent } from "src/app/shared/components/generac-sp-ltl/generac-sp-ltl.component";
import { GeneracSpFlammableComponent } from "src/app/shared/components/generac-sp-flammable/generac-sp-flammable.component";
import { SafeModule } from "src/app/shared/pipes/safe/safe.module";

@NgModule({
  declarations: [ProductGridItemComponent],
  imports: [
    CommonModule,
    MediaModule,
    CustomProductAddToCartModule,
    RouterModule,
    InfoMessageModule,
    OutletModule,
    UrlModule,
    AtMessageModule,
    I18nModule,
    SharedModule,
    SpinnerModule,
    IconModule,
    SafeModule,
    AddToFavoritesComponent,
    InfoPriceWrapperComponent,
    GeneracSpLtlComponent,
    GeneracSpFlammableComponent,
  ],
  exports: [ProductGridItemComponent]
})
export class ProductGridItemModule {
}
