
import { Component, HostListener, Input } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { Document } from 'src/app/interfaces/document';

@Component({
  selector: 'gc-document-list-item',
  templateUrl: './document-list-item.component.html',
  styleUrls: ['./document-list-item.component.scss'],
})

export class GeneracDocumentListItemComponent {
  @Input() document: Document;

  @HostListener('window:resize')
  onResize() {
    this.isMobileView = this.winRef?.nativeWindow.innerWidth < 768;
  }

  isMobileView: boolean;

  constructor(
    protected winRef: WindowRef,
  ) {
    this.isMobileView = this.winRef?.nativeWindow.innerWidth < 768;
  }

  viewDocument(doc: Document): void {
    window.open(doc.fileUrl, '_blank');
  }
}
