<ng-container *ngIf="order$ | async as order">
  <div class="cx-page-header">
    <span class="cx-page-title">
      {{ 'checkoutOrderConfirmation.confirmationOfOrder' | cxTranslate }}
      {{ order.replenishmentOrderCode ?? order.code }}
    </span>
  </div>

  <div class="cx-order-confirmation-message">
    <h2 *ngIf="!order.replenishmentSchedule?.interval">{{ 'checkoutOrderConfirmation.thankYou' | cxTranslate }}</h2>
    <h2 *ngIf="order.replenishmentSchedule?.interval">Thank you for your replenishment order!</h2>
      <p *ngIf="!order.internalCalculation && !order.prospectOrder">
          {{ 'checkoutOrderConfirmation.invoiceHasBeenSentByEmail' | cxTranslate }}
      </p>
      <p *ngIf="!order.replenishmentSchedule?.interval && !order.internalCalculation && order.prospectOrder"    >
          {{ 'checkoutOrderConfirmation.callToRepresentativeDAOrder' | cxTranslate }}
      </p>
      <p *ngIf="!order.replenishmentSchedule?.interval && order.internalCalculation && (phoneNumber$ | async) as phoneNumber"
         class="internal-calculation">
          Your order has been created. Upon system restoration, your sales rep will contact you to confirm the order and order history will update with your correct net pricing.
      </p>
  </div>

  <div *ngIf="isGuestCustomer">
    <cx-guest-register-form
      [guid]="orderGuid"
      [email]="order.paymentInfo.billingAddress.email"
    ></cx-guest-register-form>
  </div>

  <cx-add-to-home-screen-banner></cx-add-to-home-screen-banner>
</ng-container>
