import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CurrentProductService, ICON_TYPE } from "@spartacus/storefront";
import { combineLatest, Observable, Subscription } from "rxjs";
import { filter, startWith, switchMap, take, tap } from "rxjs/operators";
import { BundleService } from "../../services/bundle.service";
import { ProductExtended } from "../../interfaces/product";
import { ProductScope } from "@spartacus/core";
import { CustomerService } from "../../services/customer.service";
import { ExtendedBundle } from "../../interfaces/bundle.model";
import { LoadingEnum } from "../../enums/loading.enum";

@Component({
  selector: 'cx-bundle-carousel',
  templateUrl: './bundle-carousel.component.html',
  styleUrls: ['./bundle-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BundleCarouselComponent implements OnInit, OnDestroy {
  bundles: ExtendedBundle[] = [];
  indicatorIcon = ICON_TYPE.CIRCLE;
  previousIcon = ICON_TYPE.CARET_LEFT;
  nextIcon = ICON_TYPE.CARET_RIGHT;
  activeSlide = 0;
  product: ProductExtended;
  loading = LoadingEnum.Idle;
  LoadingEnum = LoadingEnum;
  private subscription = new Subscription();

  constructor(
    private currentProductService: CurrentProductService,
    private bundleService: BundleService,
    private cdr: ChangeDetectorRef,
    private customerService: CustomerService
  ) {
  }

  ngOnInit() {
    this.getBundles();
    this.subscribeToLoadingState();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  trackByBundle(_: number, bundle: ExtendedBundle) {
    return bundle.id;
  }

  addBundleToTheCart(bundle: ExtendedBundle) {
    this.bundleService.addToCart(bundle.id, bundle.product.code)
      .pipe(filter(response => !!response?.error?.message?.length))
      .subscribe(response => {
        bundle.errorMessages = response?.error?.message;
        this.cdr.detectChanges();
      });
  }

  navigateBack() {
    this.hideErrors();
    this.activeSlide -= 1;
  }

  navigateForward() {
    this.hideErrors();
    this.activeSlide += 1;
  }

  private getBundles() {
    this.subscription.add(
      this.customerService.getB2bUnitChanged().pipe(
        switchMap(() => combineLatest([
          this.customerService.getCurrentB2bUnit().pipe(
            filter(unit => !!unit),
            take(1)
          ),
          this.currentProductService.getProduct([
            ProductScope.DETAILS,
            ProductScope.PRICE,
          ]) as unknown as Observable<ProductExtended>
        ])),
        tap(([_, product]) => this.product = product),
        switchMap(([unit, product]) => this.bundleService.getBundlesWithPrice(unit, product?.code || null)),
      ).subscribe(result => {
          this.bundles = result;
          this.cdr.markForCheck();
        })
    );
  }

  private subscribeToLoadingState() {
    this.subscription.add(
      this.bundleService.loading$
        .subscribe(loading => {
          this.loading = loading;
          this.cdr.markForCheck();
        })
    );
  }

  private hideErrors() {
    if (this.bundles[this.activeSlide].errorMessages?.length) {
      this.bundles[this.activeSlide].errorMessages = null;
    }
  }

}
