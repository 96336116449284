import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[cxClickOutside]'
})
export class ClickOutsideDirective {
  @Output() cxClickOutside = new EventEmitter<void>();
  constructor(private elementRef: ElementRef) { }

  @HostListener('document:click', ['$event.target'])
  onClick(target: Event) {
    const clickedInside = this.elementRef.nativeElement.contains(target);
    if (!clickedInside) {
      this.cxClickOutside.emit();
    }
  }
}
