<ng-container *ngIf="product">
  <a
    [routerLink]="product.routerLink ? product.routerLink : { cxRoute: 'product', params: product } | cxUrl"
    [queryParams]="{gaListName: gaListName}"
    (click)="selectItem(product)"
    class="cx-product-image-container"
    [class.disabled-link]="isSubs"
    tabindex="-1"
  >
    <cx-media class="cx-product-image" *ngIf="(product.servicePart && product.images?.PRIMARY) || !product.servicePart; else spGearThumb"
    [container]="product.images?.PRIMARY" [class.sub-non-image]="isSubs && !product.images?.PRIMARY.product"
    format="product" [alt]="product.name || product.summary"></cx-media>
    <ng-template #spGearThumb>
      <img src="../../../../../../assets/icons/sp_gear_thumb.svg" alt="gear" class="sp-gear-thumb">
    </ng-template>
  </a>
  <a
    *ngIf="!isSubs"
    [routerLink]="product.routerLink ? product.routerLink : ({ cxRoute: 'product', params: product } | cxUrl)"
    [queryParams]="{gaListName: gaListName}"
    (click)="selectItem(product)"
    class="cx-product-name"
    [innerHTML]="product.name"
    title="{{product.name}}"
  ></a>

  <a
    *ngIf="isSubs"
    class="cx-product-name"
    [innerHTML]="product.name"
  ></a>

  <span
    *ngIf="isSubs"
    class="summary"
    [innerHTML]="product.summary"
  ></span>

  <ng-template [cxOutlet]="ProductListOutlets.ITEM_DETAILS">
    <div *ngIf="product.purchasable || isSubs" class="cx-product-price-container">
      <div
        class="cx-product-price"
        [attr.aria-label]="'productDetails.productPrice' | cxTranslate"
        [class.mb-0]="product.bundleAvailable"
      >
        {{ product.price?.formattedValue }}
        <span *ngIf="!isSubs" class="price-label">{{ product.servicePart ? 'MSLP' : 'MSRP'}}</span>
        <cx-info-price-wrapper
                *ngIf="!isSubs"
                [product]="product"
                [position]="TooltipPosition.Right"
        ></cx-info-price-wrapper>
      </div>
      <a
        *ngIf="!isSubs && product.bundleAvailable"
        [routerLink]="{ cxRoute: 'bundles', params: { productCode: product.code, name: product.slug } } | cxUrl"
        class="buy-in-bundle call-for-pricing link cx-action-btn-link">
        Buy In Bundle >
      </a>
    </div>
    <div *ngIf="!isSubs && !product.purchasable && !product?.servicePart" class="cx-product-price-container non-price">
      <div class="contact-us">
        Please contact us for pricing details.
      </div>
    </div>
  </ng-template>

  <cx-add-to-cart 
    *ngIf="product?.purchasable"
    [product]="product"
    [purchasable]="true"
    [productCode]="product.code"
    [showQuantity]="false"
    [gaListName]="gaListName"
    [gaProductCategories]="gaProductCategories"
    [isSubs]="isSubs"
    [isAddModal]="isAddModal"
    (onAddModalAdded)="onAddModalAdded.emit($event)"
  ></cx-add-to-cart>

  <div *ngIf="!product.purchasable && !isSubs" class="no-add-to-cart">
    <add-to-favorites [product]="product" [gaListName]="gaListName" [gaProductCategories]="gaProductCategories"></add-to-favorites>
    <generac-sp-ltl *ngIf="product.ltl && product.servicePart" [ltl]="product.ltl"></generac-sp-ltl>
    <generac-sp-flammable *ngIf="product.flammable && product.servicePart" [flammable]="product.flammable"></generac-sp-flammable>
    <div
            class="position-relative non-price-status"
            (mouseenter)="callForPricingMessage=tooltipMessage"
            (mouseleave)="callForPricingMessage=''"
            [class.service-parts]="product?.servicePart"
    >
      <ng-container *ngIf="product?.servicePart; else regularProduct;">
        <a
                *ngIf="product?.unavailabilityDetail?.type === UnavailabilityDetailType.ContactFactory; else plainText;"
                class="call-for-pricing link cx-action-btn-link"
                (click)="openContactFactory()"
        >
          {{product?.unavailabilityDetail?.label}}
        </a>

        <cx-info-price-wrapper
                *ngIf="!isSubs"
                [product]="product"
                [position]="TooltipPosition.Right"
        ></cx-info-price-wrapper>

        <ng-template #plainText>
          <span class="call-for-pricing">{{product?.unavailabilityDetail?.label}}</span>
        </ng-template>
      </ng-container>

      <ng-template #regularProduct>
        <div
                class="position-relative"
                (mouseenter)="callForPricingMessage=tooltipMessage"
                (mouseleave)="callForPricingMessage=''">
          <a
                  [href]="contactForPricingHref"
                  class="call-for-pricing link cx-action-btn-link">
            Contact For Pricing
            <cx-info-message [message]="callForPricingMessage" [position]="'position-right'"></cx-info-message>
          </a>
        </div>
      </ng-template>

    </div>
  </div>

  <div *ngIf="isServicePartsEnabled && product?.materialNotes && product?.servicePart"
    [ngClass]="{'note-expanded': !showMoreNotes}" class="sp-note-container">
    <span>
      <strong>Note: </strong>
      <span class="sp-note" [innerHTML]="(product?.materialNotes + ' '  || '') | safe"></span>
    </span>
    <span class="dots" *ngIf="materialNotesLength >= 68 && showMoreNotes">
      ...
    </span>
    <button class="more-less" *ngIf="materialNotesLength >= 68" (click)="toggleMoreLessNotes()">
      {{showMoreNotes ? 'more' : 'less'}}
    </button>
  </div>

</ng-container>
