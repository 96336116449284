import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import { Router } from "@angular/router";
import { LaunchDialogService } from '@spartacus/storefront';

export interface GeneracNotification {
  level: GlobalMessageType;
  message: string;
};

@Injectable()
export class NotificationResponseInterceptor implements HttpInterceptor {
  constructor(
    private globalMessageService: GlobalMessageService,
    private router: Router,
    private launchDialogService: LaunchDialogService,
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      map((response) => {
        if (!(response instanceof HttpResponse)) {
          return response;
        }

        if (response?.body?.generacNotifications && response?.body?.generacNotifications.length) {
          response.body.generacNotifications.forEach((element: GeneracNotification) => {
            this.globalMessageService.add(element.message, element.level);
          });
        }

        return response;
      }),
      catchError(err => {
        if (err?.error?.generacNotifications && err?.error?.generacNotifications.length) {
          err.error.generacNotifications.forEach((element: GeneracNotification) => {
            if (element.level == ("[GlobalMessage] Subscription Warning") as GlobalMessageType) {
              setTimeout(() => {
                const dialog = this.launchDialogService.openDialog(
                  'WARNING_DIALOG',
                  undefined,
                  undefined,
                  element
                );
                if (dialog) {
                  dialog.pipe(take(1)).subscribe();
                }
              }, 300)
            } else {
              this.globalMessageService.add(element.message, element.level);
            }
          });
        }

        // Handling the errors array and looking for redirectTo
        if (err?.error?.errors && Array.isArray(err.error.errors)) {
          const redirectToError = err.error.errors.find((e: { redirectTo: any; }) => e.redirectTo);
          if (redirectToError) {
            const extras = redirectToError.relatedField ? { fragment: redirectToError.relatedField } : undefined;
            this.router.navigate([redirectToError.redirectTo], extras);
            return throwError(err); // To prevent further processing of this error
          }
        }

        return throwError(err);
      })
    );
  }
}