import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ProductScope, WindowRef } from '@spartacus/core';
import { CurrentProductService, ProductDetailOutlets } from '@spartacus/storefront';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { Badge, MSRPPrice, ProductExtended } from 'src/app/interfaces/product';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { ProductService } from '../../../../services/product.service';
import { CustomerService } from 'src/app/services/customer.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { BaseStoreService } from 'src/app/services/base-store.service';
import { ActivatedRoute } from '@angular/router';
import { GaListNames } from 'src/app/enums/ga-list-names.enum';

@Component({
  selector: 'cx-product-summary',
  templateUrl: './product-summary.component.html',
  styleUrls: ['./product-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductSummaryComponent implements OnInit, OnDestroy {
  outlets = ProductDetailOutlets;
  product$: Observable<ProductExtended | null> =
    this.currentProductService.getProduct([
      ProductScope.DETAILS,
      ProductScope.PRICE,
    ]) as unknown as Observable<ProductExtended>;
  currentPrice: MSRPPrice;
  noNetPrice = false;
  exclusionMessage: string;
  navigatedToPpdFrom: string;
  product: ProductExtended;
  stockBadge: Badge;
  purchasable: boolean;
  isMobileView: boolean;
  private subscriptions = new Subscription();


  @HostListener('window:resize')
  onResize() {
    this.isMobileView = this.winRef?.nativeWindow.innerWidth < 992;
  }

  constructor(
    protected currentProductService: CurrentProductService,
    protected customerService: CustomerService,
    protected googleAnalyticsService: GoogleAnalyticsService,
    protected activatedRoute: ActivatedRoute,
    private userAccount: UserAccountFacade,
    private productService: ProductService,
    private cdr: ChangeDetectorRef,
    protected baseStoreService: BaseStoreService,
    protected winRef: WindowRef,
  ) {}

  ngOnInit(): void {
    this.subscribeToCurrentPrice();
    this.updateRecentlyViewed();
    this.subscribeToContactInfoAndTooltip();
    this.isMobileView = this.winRef?.nativeWindow.innerWidth < 992;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private updateRecentlyViewed() {
    this.subscriptions.add(
      combineLatest([
        this.userAccount.get(),
        this.product$,
      ]).pipe(
        switchMap(([_, product]) => {
          this.product = product;
          this.purchasable = product.purchasable;
          this.stockBadge = product?.badges?.length && product?.badges?.find(badge => !badge.marketing);
          return this.productService.updateRecentlyViewed(product.code);
        })
      ).subscribe()
    )
  }

  private subscribeToContactInfoAndTooltip(): void {
    this.subscriptions.add(
      combineLatest([
        this.baseStoreService.getPhoneNumber(),
      ]).subscribe(([phoneOrEmail]) => {
        this.exclusionMessage = `This material is not currently available to you based on regional restrictions. Please contact your Sales Representative for more information via ${phoneOrEmail.contactInfo}`
        this.cdr.markForCheck();
      })
    );
  }

  private subscribeToCurrentPrice() {
    this.subscriptions.add(
      combineLatest([
        this.userAccount.get(),
        this.product$,
        this.customerService.getB2bUnitChanged(),
      ]).pipe(
        switchMap(([user, product]) => {
          return combineLatest([this.productService
            .getCPIPrice(user?.uid, product)
            .pipe(
              tap(currentPrice => {
                this.currentPrice = currentPrice;
                this.cdr.markForCheck();
              }),
              catchError(() => {
                this.noNetPrice = true;
                this.cdr.markForCheck();
                return of(null);
              })), of(product)
          ])
        })
      ).subscribe(([currentPrice, product]) => {
        if(this.activatedRoute.snapshot.queryParams?.gaListName) {
          this.navigatedToPpdFrom = this.activatedRoute.snapshot.queryParams?.gaListName;
        }
        this.googleAnalyticsService.sendGaEvent('view_item_list', {
          items: [ this.googleAnalyticsService.mapProductToGaItem({...product, price: currentPrice }, 0, undefined, GaListNames.PDP) ],
          item_list_name: GaListNames.PDP,
          item_list_id: GaListNames.PDP.toLowerCase().trim().replace(/ /g, '_'),
        });
        this.googleAnalyticsService.sendGaEvent('view_item', {
          items: [ this.googleAnalyticsService.mapProductToGaItem({...product, price: currentPrice }, 0, undefined, undefined, this.navigatedToPpdFrom) ],
          currency: product.price?.currencyIso,
        });
      })
    )
  }
}
