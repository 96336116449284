import { Component, Input, OnInit } from '@angular/core';
import { MSRPPrice, ProductExtended } from 'src/app/interfaces/product';
import { normalizeSlug } from '../../../helpers/slug.helper';
import { GaListNames } from 'src/app/enums/ga-list-names.enum';
import { TooltipPosition } from "../../../../../../enums/tooltip-position.enum";

@Component({
  selector: 'generac-product-warranty-item',
  templateUrl: './generac-product-warranty-item.component.html',
  styleUrls: ['./generac-product-warranty-item.component.scss'],
})
export class GeneracProductWarrantyItemComponent implements OnInit {
  @Input() warranty: ProductExtended;
  @Input() userUid: string;
  _phoneNumberOrEmail: string;
  phoneOrEmail: any;
  get phoneNumberOrEmail(): string {
    return this._phoneNumberOrEmail;
  }
  @Input() set phoneNumberOrEmail(value: string) {
    this.contactForPricingHref = value?.includes('@') ? `mailto: ${value}` : `tel: ${value}`;
    this._phoneNumberOrEmail = value;
  }
  @Input() tooltipMessage: string;
  warrantyHref: string;
  callForPricingMessage = '';
  contactForPricingHref: string;
  gaListName: GaListNames = GaListNames.WARRANTIES;
  TooltipPosition = TooltipPosition;
  constructor() {}
  ngOnInit(): void {
    this.warrantyHref = `/product/${this.warranty.code}/${normalizeSlug(this.warranty.name)}?gaListName=${this.gaListName}`;
  }
}
