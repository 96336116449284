<ng-container *ngIf="applicableProducts?.results?.length > 0">
    <div class="applicable-products">
        <div *ngFor="let product of applicableProducts.results" class="applicable-product col-12 col-sm-6">
            <a [attr.aria-label]="
        !product.images?.hasOwnProperty('PRIMARY')
          ? ('productDetails.noProductImage'
            | cxTranslate: { product: product.name })
          : undefined
      " [routerLink]="{ cxRoute: 'product', params: product } | cxUrl" class="cx-product-image-container">
                <cx-media class="cx-product-image" [container]="product.images?.PRIMARY" format="product"
                    [alt]="product.name || product.summary"></cx-media>
                <span class="product-name">{{product.name}}</span>
            </a>
        </div>
    </div>
    <div *ngIf="applicableProducts.pagination?.totalPages > 1" class="cx-pagination"
        [attr.aria-label]="'productList.productSearchPagination' | cxTranslate">
        <generac-pagination [pagination]="applicableProducts.pagination" [defaultPage]="0" (viewPageEvent)="onPaginate($event)"></generac-pagination>
    </div>
</ng-container>