import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OccSavedCartAdapter } from './adapters/occ-saved-cart.adapter';
import { SavedCartAdapter } from '../core/connectors';

@NgModule({
  imports: [CommonModule],
  providers: [
    {
      provide: SavedCartAdapter,
      useClass: OccSavedCartAdapter,
    },
  ],
})
export class SavedCartOccModule {}
