import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ConfigModule, I18nModule } from '@spartacus/core';
import { IconModule, ItemCounterModule } from '@spartacus/storefront';
import { CustomProductAddToCartComponent } from "./custom-product-add-to-cart.component";
import { CustomItemCounterModule } from "../../../../../shared/components/custom-item-counter/custom-item-counter.module";
import { InfoMessageModule } from 'src/app/shared/components/info-message/info-message.module';
import { SharedModule } from "../../../../../shared/shared.module";
import { AddToFavoritesComponent } from '../../../favorites/add-to-favorites/add-to-favorites.component';
import { GeneracProductBadgeModule } from 'src/app/shared/components/generac-product-badge/generac-product-badge.module';
import { GeneracSpFlammableComponent } from 'src/app/shared/components/generac-sp-flammable/generac-sp-flammable.component';
import { GeneracSpLtlComponent } from 'src/app/shared/components/generac-sp-ltl/generac-sp-ltl.component';
import { InfoPriceWrapperComponent } from '../../../product/components/info-price-wrapper/info-price-wrapper.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    I18nModule,
    ItemCounterModule,
    CustomItemCounterModule,
    InfoMessageModule,
    SharedModule,
    AddToFavoritesComponent,
    GeneracProductBadgeModule,
    IconModule,
    GeneracSpLtlComponent,
    GeneracSpFlammableComponent,
    InfoPriceWrapperComponent,
    ConfigModule.withConfig({
      cmsComponents: {
        ProductAddToCartComponent: {
          component: CustomProductAddToCartComponent,
        },
      }
    })
  ],
  declarations: [CustomProductAddToCartComponent],
  exports: [CustomProductAddToCartComponent],
})
export class CustomProductAddToCartModule {}
