/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';
import { Cart } from '@spartacus/cart/base/root';
import { RoutingService } from '@spartacus/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, mapTo, shareReplay, switchMap, tap } from 'rxjs/operators';
import { SavedCartFacade } from '../../root/facade';

@Injectable({
  providedIn: 'root',
})
export class SavedCartDetailsService {
  protected reload$= new BehaviorSubject<void>(undefined);
  protected savedCartData$ = this.routingService.getRouterState().pipe(
    distinctUntilChanged((_, curr) => !curr.nextState?.url?.includes('owner')),
    map((routingData) => ({ savedCartId: routingData.state.params.savedCartId, owner: routingData.state.queryParams.owner })),
    distinctUntilChanged()
  );

  protected savedCart$ = this.savedCartData$.pipe(
    switchMap(cartData => this.reload$.pipe(mapTo(cartData))),
    filter((cartData) => Boolean(cartData.savedCartId)),
    tap((cartData) =>
      this.savedCartService.loadSavedCart(cartData.savedCartId, cartData.owner)),
    switchMap((cartData) => this.savedCartService.get(cartData.savedCartId)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  constructor(
    protected routingService: RoutingService,
    protected savedCartService: SavedCartFacade
  ) {}

  getSavedCartId(): Observable<string> {
    return this.savedCartData$.pipe(map(x => x.savedCartId));
  }

  getCartDetails(): Observable<Cart | undefined> {
    return this.savedCart$;
  }

  reloadSavedCart() {
    this.reload$.next();
  }

}
