import { NgModule } from '@angular/core';
import { PaginationModule, SpinnerModule } from "@spartacus/storefront";
import { ConfigModule, I18nModule, PageMetaResolver, provideConfig } from "@spartacus/core";
import { BundleSlideModule } from "../../shared/components/bundle-slide/bundle-slide.module";
import { RouterModule } from "@angular/router";
import { CommonModule } from '@angular/common';
import { GeneracShippingAccountsResolver } from './shipping-accounts.resolver';
import { GeneracShippingAccountsComponent } from './shipping-accounts.component';

@NgModule({
  declarations: [GeneracShippingAccountsComponent],
  imports: [
    CommonModule,
    SpinnerModule,
    BundleSlideModule,
    RouterModule,
    PaginationModule,
    I18nModule,
  ],
  providers: [{
      provide: PageMetaResolver,
      useExisting: GeneracShippingAccountsResolver,
      multi: true,
    }]
})
export class GeneracShippingAccountsModule { }
