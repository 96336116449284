import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { CurrentProductService } from "@spartacus/storefront";
import { ProductExtended } from 'src/app/interfaces/product';

@Component({
  selector: 'generac-product-notes',
  templateUrl: './product-notes.component.html',
  styleUrls: ['./product-notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductNotesComponent {
  product$: Observable<ProductExtended | null>;

  constructor(
    protected currentProductService: CurrentProductService,
  ) {
    this.product$ = this.currentProductService.getProduct();
  }
}
