import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GlobalMessageService, GlobalMessageType, normalizeHttpError } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { SavedCartConnector } from '../../connectors/saved-cart.connector';
import { SavedCartActions } from '../actions/index';
import { ActiveCartFacade, Cart, MultiCartFacade } from '@spartacus/cart/base/root';
import { CartActions } from 'src/app/spartacus/features/cart/core/store/actions';
import { CartConnector } from '@spartacus/cart/base/core';
import { CustomLoadingSpinnerService } from 'src/app/services/custom-loading-spinner.service';
import { CartExtended } from 'src/app/interfaces/cart';

@Injectable()
export class SavedCartEffects {
  loadSavedCart$: Observable<
    | CartActions.LoadCartSuccess
    | SavedCartActions.LoadSavedCartFail
    | SavedCartActions.LoadSavedCartSuccess
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(SavedCartActions.LOAD_SAVED_CART),
      map((action: SavedCartActions.LoadSavedCart) => action.payload),
      switchMap(({ userId, cartId, cartOwner }) =>
        this.savedCartConnector.get(userId, cartId, cartOwner).pipe(
          switchMap((savedCart: Cart) => {
            return [
              new CartActions.LoadCartSuccess({
                userId,
                cartId,
                cart: savedCart,
              }),
              new SavedCartActions.LoadSavedCartSuccess({ userId, cartId }),
            ];
          }),
          catchError((error: HttpErrorResponse) =>
            of(
              new SavedCartActions.LoadSavedCartFail({
                userId,
                cartId,
                error: normalizeHttpError(error),
              })
            )
          )
        )
      )
    )
  );

  loadSavedCarts$: Observable<
    | CartActions.LoadCartsSuccess
    | SavedCartActions.LoadSavedCartsFail
    | SavedCartActions.LoadSavedCartsSuccess
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(SavedCartActions.LOAD_SAVED_CARTS),
      map((action: SavedCartActions.LoadSavedCarts) => action.payload),
      switchMap(({ userId }) =>
        this.savedCartConnector.getList(userId).pipe(
          switchMap((savedCarts: Cart[]) => {
            return [
              new CartActions.LoadCartsSuccess(savedCarts),
              new SavedCartActions.LoadSavedCartsSuccess({ userId }),
            ];
          }),
          catchError((error: HttpErrorResponse) =>
            of(
              new SavedCartActions.LoadSavedCartsFail({
                userId,
                error: normalizeHttpError(error),
              })
            )
          )
        )
      )
    )
  );

  restoreSavedCart$: Observable<
    | SavedCartActions.RestoreSavedCartFail
    | SavedCartActions.RestoreSavedCartSuccess
    | SavedCartActions.LoadSavedCarts
    | SavedCartActions.SaveCart
    | CartActions.LoadCartSuccess
    | CartActions.SetActiveCartId
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(SavedCartActions.RESTORE_SAVED_CART),
      tap(() => this.customLoadingSpinnerService.showSpinner()),
      map((action: SavedCartActions.RestoreSavedCart) => action.payload),
      withLatestFrom(this.activeCartService.getActive()),
      switchMap(([{ userId, cartId, cartOwner, hideMessages }, activeCart]) => {
        const actions: any[] = [];
        if ((activeCart?.entries ?? []).length > 0) {
          if (activeCart.code) {
            /**
             * Instead of calling the SaveCartAction, we are calling the edit saved cart
             * because we do not want to clear the state when we swap carts between active and saved cart
             */
            actions.push(
              new SavedCartActions.EditSavedCart({
                userId,
                cartId: activeCart.code,
                saveCartName: '',
                saveCartDescription: '',
              })
            );
          }
        }
        return this.savedCartConnector
          .restoreSavedCart(userId, cartId, cartOwner)
          .pipe(
            switchMap((savedCart: CartExtended) => {
              if (!hideMessages && savedCart.entryGroups?.length) {
                this.globalMessageService.add(
                  {
                    key:
                      (activeCart?.entries ?? []).length > 0
                        ? 'savedCartList.swapCartWithActiveCart'
                        : 'savedCartList.swapCartNoActiveCart',
                    params: {
                      cartName: cartId,
                      previousCartName: activeCart.code,
                    },
                  },
                  GlobalMessageType.MSG_TYPE_CONFIRMATION
                );
              }
              if (savedCart.unavailableItems?.length > 0) {
                const unavailableItems = savedCart.unavailableItems ;
                const addCartWarning = (key: string, codes: string) => {
                  this.globalMessageService.add(
                    { key, params: { codes } },
                    GlobalMessageType.MSG_TYPE_WARNING
                  );
                }
                unavailableItems.forEach((item) => {
                  if (item.validationResult === 'BUNDLE_CHANGED') {
                    addCartWarning('savedCartList.bundleChangedWarning', item.productCodes);
                  } else if (item.validationResult === 'BUNDLE_ITEMS_CHANGED') {
                    const codes = item.productCodes.split(',');
                    const key = codes.length > 1
                      ? 'savedCartList.bundleItemsChangedWarning'
                      : 'savedCartList.bundleItemChangedWarning';
                    addCartWarning(key, item.productCodes);
                  } else if (item.type === 'STANDALONE') {
                    const codes = item.productCodes.split(',');
                    const key = codes.length > 1
                      ? 'savedCartList.discontinuedOrUnapprovedProductsWarning'
                      : 'savedCartList.discontinuedOrUnapprovedProductWarning';
                    addCartWarning(key, item.productCodes);
                  }
                });
              }
              this.customLoadingSpinnerService.hideSpinner();
              return [
                ...actions,
                new CartActions.LoadCartSuccess({
                  userId,
                  cartId,
                  cart: savedCart,
                  extraData: { active: true },
                }),
                new SavedCartActions.RestoreSavedCartSuccess({
                  userId,
                  cartId,
                }),
              ];
            }),
            catchError((error: HttpErrorResponse) => {
              this.customLoadingSpinnerService.hideSpinner();
              this.multiCartFacade.createCart({
                userId,
                extraData: {
                  active: true,
                },
              });
              return of(
                new SavedCartActions.RestoreSavedCartFail({
                  userId,
                  cartId,
                  error: normalizeHttpError(error),
                })
              );
            })
          );
      })
    )
  );

  saveCart$: Observable<
    | SavedCartActions.SaveCartFail
    | SavedCartActions.SaveCartSuccess
    | SavedCartActions.SaveCart
    | CartActions.LoadCartSuccess
    | CartActions.ClearCartState
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(SavedCartActions.SAVE_CART),
      map((action: SavedCartActions.SaveCart) => action.payload),
      switchMap(({ userId, cartId, saveCartName, saveCartDescription }) => {
        return this.cartConnector
          .save(userId, cartId, saveCartName, saveCartDescription)
          .pipe(
            switchMap((savedCart: Cart) => {
              return [
                new CartActions.ClearCartState(),
                new CartActions.LoadCartSuccess({
                  userId,
                  cartId,
                  cart: savedCart,
                }),
                new SavedCartActions.SaveCartSuccess({
                  userId,
                  cartId,
                  saveCartName,
                  saveCartDescription,
                }),
              ];
            }),
            catchError((error: HttpErrorResponse) =>
              of(
                new SavedCartActions.SaveCartFail({
                  userId,
                  cartId,
                  saveCartName,
                  saveCartDescription,
                  error: normalizeHttpError(error),
                })
              )
            )
          );
      })
    )
  );

  editSavedCart$: Observable<
    | SavedCartActions.EditSavedCartFail
    | SavedCartActions.EditSavedCartSuccess
    | SavedCartActions.EditSavedCart
    | CartActions.LoadCartSuccess
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(SavedCartActions.EDIT_SAVED_CART),
      map((action: SavedCartActions.EditSavedCart) => action.payload),
      switchMap(({ userId, cartId, saveCartName, saveCartDescription }) => {
        return this.cartConnector
          .save(userId, cartId, saveCartName, saveCartDescription)
          .pipe(
            switchMap((savedCart: Cart) => {
              return [
                new CartActions.LoadCartSuccess({
                  userId,
                  cartId,
                  cart: savedCart,
                }),
                new SavedCartActions.EditSavedCartSuccess({
                  userId,
                  cartId,
                  saveCartName,
                  saveCartDescription,
                }),
              ];
            }),
            catchError((error: HttpErrorResponse) =>
              of(
                new SavedCartActions.EditSavedCartFail({
                  userId,
                  cartId,
                  saveCartName,
                  saveCartDescription,
                  error: normalizeHttpError(error),
                })
              )
            )
          );
      })
    )
  );

  cloneSavedCart$: Observable<
    | SavedCartActions.CloneSavedCartFail
    | SavedCartActions.CloneSavedCartSuccess
    | SavedCartActions.CloneSavedCart
    | SavedCartActions.RestoreSavedCart
    | SavedCartActions.LoadSavedCarts
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(SavedCartActions.CLONE_SAVED_CART),
      map((action: SavedCartActions.CloneSavedCart) => action.payload),
      switchMap(({ userId, cartId, saveCartName, cartOwner }) => {
        return this.savedCartConnector
          .cloneSavedCart(userId, cartId, saveCartName, cartOwner)
          .pipe(
            switchMap((_) => {
              return [
                new SavedCartActions.CloneSavedCartSuccess({
                  userId,
                  cartId,
                  saveCartName,
                }),
                new SavedCartActions.RestoreSavedCart({
                  userId,
                  cartId,
                  cartOwner,
                }),
                new SavedCartActions.LoadSavedCarts({ userId }),
              ];
            }),
            catchError((error: HttpErrorResponse) =>
              of(
                new SavedCartActions.CloneSavedCartFail({
                  userId,
                  cartId,
                  saveCartName,
                  error: normalizeHttpError(error),
                })
              )
            )
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private savedCartConnector: SavedCartConnector,
    private activeCartService: ActiveCartFacade,
    private globalMessageService: GlobalMessageService,
    private cartConnector: CartConnector,
    protected customLoadingSpinnerService: CustomLoadingSpinnerService,
    protected multiCartFacade: MultiCartFacade
  ) {}
}
