export function normalizeSlug(name: string): string {
  const reservedSlugCharacters = ` !*'();:@&=+$,/?%#[]`;
  const slugRegex = new RegExp(
    `[${reservedSlugCharacters.split('').join('\\')}]`,
    'g'
  );
  const slugChar = '-';
  return normalize(name)
    .trim()
    .toLowerCase()
    .replace(slugRegex, slugChar)
    .replace(new RegExp(`${slugChar}+`, 'g'), slugChar);
}

function normalize(name: string): string {
  return name.replace(/<[^>]*>/g, '');
}