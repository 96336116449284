/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { ChangeDetectionStrategy, Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { WindowRef } from '@spartacus/core';
import { HamburgerMenuService, NavigationUIComponent } from '@spartacus/storefront';

@Component({
  selector: 'cx-navigation-ui',
  templateUrl: './custom-navigation-ui.component.html',
  styleUrls: ['./custom-navigation-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomNavigationUiComponent extends NavigationUIComponent {
  @Input() logo: any | null;
  constructor(
    protected _router: Router,
    renderer: Renderer2,
    elemRef: ElementRef,
    hamburgerMenuService: HamburgerMenuService,
    winRef: WindowRef
  ) {
    super(_router, renderer, elemRef, hamburgerMenuService, winRef);
  }

  goHome(): void {
    this._router.navigate(['/']);
  }
}
