<ng-container *ngIf="subscriptionInfo">
    <tr *ngFor="let subscription of subscriptionInfo; let i = index">
        <td>{{product?.name + ' #' + (i + 1)}}</td>
        <td [ngClass]="{'text-align-center': isOrderConfirmation}">{{subscription?.firstName + ' ' + subscription?.lastName}}</td>
        <td [ngClass]="{'text-align-center': isOrderConfirmation}">Exp date: {{subscription?.subEndDate | date: "MM/dd/yyyy"}}</td>
        <td *ngIf="!isOrderConfirmation">
            <a [attr.aria-label]="'checkoutReview.editDeliveryAddressDetails' | cxTranslate"
                [routerLink]="{cxRoute: getCheckoutStepUrl(checkoutStepTypeSubscriptionInformation)} | cxUrl"
                [queryParams]="{index: i, materialNumber: product?.sapMaterialNumber}">
                <div class="edit-icon"></div>
            </a>
        </td>
    </tr>
</ng-container>