import { Pipe, PipeTransform } from '@angular/core';
import { OccConfig } from '@spartacus/core';

@Pipe({
  name: 'baseSite',
  pure: true,
})
export class BaseSitePipe implements PipeTransform {
  baseUrl: string = '';
  constructor(private config: OccConfig) {
    this.baseUrl = this.config.backend?.occ?.baseUrl || '';
  }

  transform(value: unknown): unknown {
    return `${this.baseUrl}${value}`;
  }
}
