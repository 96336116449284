import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Address } from '@spartacus/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ValidationAddress, ValidationAddressResponse } from 'src/app/interfaces/checkout';

@Injectable()
export class AddressValidationService {
  constructor(
    private httpClient: HttpClient,
  ) { }

  get gcApimEndpoint(): string {
    return `${environment.backend.apim.baseUrl}`
  }

  private isDeliveryAddressModified = new BehaviorSubject<Address>(null);
  public isAddressChange$ = this.isDeliveryAddressModified.asObservable();

  validate(address: Address): Observable<ValidationAddress> {
    const url = `${this.gcApimEndpoint}AddressValidationAPI/AddressValidation/Validate`;
    let headers = new HttpHeaders({ 'Ocp-APIM-Subscription-Key': environment.backend.apim.subscriptionKey });
    const body = {
      address1: address.line1,
      address2: address.line2,
      city: address.town,
      state: address.region?.isocode,
      postalCode: address.postalCode,
      countryCode: address.country?.isocode
    };
    return this.httpClient.post<ValidationAddressResponse>(url, body, { headers })
      .pipe(
        map<ValidationAddressResponse, ValidationAddress>((res: ValidationAddressResponse) => {
          const validation: ValidationAddress = { 
            suggested: [],
            error: {} 
          };


          if (res.SuggestionList) {
            res.SuggestionList.forEach((el: any) => {
              validation.suggested.push({
                line1: el.Address1,
                line2: el.Address2,
                town: el.City,
                postalCode: el.PostalCode,
                country: {
                  isocode: el.CountryCode
                },
                region: {
                  isocode: el.State
                },
                residential: el.AddressType === 'Residential'
              } as Address)
            });
          }
          if (res.ErrorDescription && res.ErrorCode) {
            validation.error.code = res.ErrorCode;

            if (res.ErrorDescription.includes('house number')) {
              validation.error.description = 'checkoutAddress.invalidAddress';
            } else if (res.ErrorDescription.includes('No Picklist Generated')) {
              validation.error.description = 'checkoutAddress.addressNotValidatedErrorMessage';
            } else {
              validation.error.description = 'checkoutAddress.validateAddressErrorMessage';
            }
          }
          return validation;
        }));
  }

  setDeliveryAddressModified(value: any) {
    this.isDeliveryAddressModified.next(value);
  }
}