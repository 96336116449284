<div *ngIf="savedAddress" class="saved-address-item-wrap">
    <div class="saved-address-item-info-wrap">
        <div class="saved-address-item-info">
            <p>
                <span class="field-name">{{ 'savedAddress.company' | cxTranslate}}: </span>
                <span class="field-value">{{ savedAddress.companyName}}</span>
            </p>
            <p>
                <span class="field-name">{{ 'savedAddress.name' | cxTranslate}}: </span>
                <span class="field-value">{{ savedAddress.firstName + ' ' + savedAddress.lastName}}</span>
            </p>
            <p>
                <span class="field-name">{{ 'savedAddress.address' | cxTranslate}}: </span>
                <span class="field-value">{{ savedAddress.formattedAddress}}</span>
            </p>
            <p>
                <span class="field-name">{{ 'savedAddress.phone' | cxTranslate}}: </span>
                <span class="field-value">{{ savedAddress.phone}}</span>
            </p>
        </div>
        <ng-container *ngIf="savedAddress.residential">
            <div class="delivery-residential">
                <cx-icon class="favorite-icon small active" [type]="iconTypes.PIN"></cx-icon>
                <div>Residential Address</div>
            </div>
        </ng-container>
    </div>
    <div class="saved-address-item-actions">
        <button (click)="openEditSavedAddressModal(savedAddress)" [disabled]="isUserRestricted">
            <cx-icon [type]="iconTypes.PENCIL"></cx-icon>
            {{ 'savedAddress.editAddress' | cxTranslate}}
        </button>
        <button (click)="openDeleteSavedAddressModal(savedAddress?.id)" [disabled]="isUserRestricted">
            <cx-icon [type]="iconTypes.TRASH"></cx-icon>
            {{ 'savedAddress.deleteAddress' | cxTranslate}}
        </button>
    </div>
</div>