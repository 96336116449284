import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { OrderDetailsService } from "@spartacus/order/components";
import { switchMap, take, tap } from 'rxjs/operators';
import { Order } from '@spartacus/order/root';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { PaymentType } from '@spartacus/cart/base/root';
import { EntryGroup } from 'src/app/interfaces/cart';
import { Price, User } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';

export interface ExtOrder extends Order {
    paymentType?: PaymentType,
    entryGroups?: EntryGroup[],
    promoDiscounts?: Price;
}
@Component({
    selector: 'cx-order-details-shipping',
    templateUrl: './order-detail-shipping.component.html',
})
export class OrderDetailShippingComponent implements OnInit {
    constructor(
        protected orderDetailsService: OrderDetailsService,
        protected googleAnalyticsService: GoogleAnalyticsService,
    ) {}

    order$: Observable<any>;
    isOrderLoading$: Observable<boolean>;

    ngOnInit() {
        this.order$ = this.orderDetailsService.getOrderDetails().pipe(
            take(1),
            tap((order: ExtOrder) => {
                const bundleEntryGroups = this.getBundleEntryGroups(order.entryGroups);
                if(bundleEntryGroups.length > 0) {
                    bundleEntryGroups.forEach((bundle) => {
                        this.googleAnalyticsService.sendGaEvent('purchase_bundle', {
                            currency: bundle.entryGroups[0].totalPrice.currencyIso,
                            value: bundle.entryGroups[0].totalPrice.value,
                            bundle_name: bundle.label,
                            bundle_template_id: bundle.entryGroups[0].externalReferenceId,
                            transaction_id: order.code,
                            quantity: bundle.entryGroups[0].entries[0].quantity
                        });
                    })
                }
                this.googleAnalyticsService.sendGaEvent('purchase', {
                    currency: order.totalPriceWithTax?.currencyIso,
                    payment_type: order.paymentType.displayName,
                    transaction_id: order.code,
                    tax: order.totalTax.value,
                    shipping: order.deliveryCost.value,
                    value: order.totalPriceWithTax?.value,
                    orderDiscount: order?.promoDiscounts?.value < 0 ? Math.abs(order?.promoDiscounts?.value) : undefined,
                    coupon: order?.appliedVouchers?.length > 0 ? order?.appliedVouchers[0].voucherCode : undefined,
                    items: this.googleAnalyticsService.buildGaItems(order?.entryGroups, order?.appliedProductPromotions)
                });
            })
        );

        this.isOrderLoading$ =
            typeof this.orderDetailsService.isOrderDetailsLoading === 'function'
                ? this.orderDetailsService.isOrderDetailsLoading()
                : of(false);
    }

    private getBundleEntryGroups(entryGroups: EntryGroup[]): EntryGroup[] | [] {
        return entryGroups.filter((group)=> group.type == "CONFIGURABLEBUNDLE");
    }
}
