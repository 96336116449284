import { ChangeDetectionStrategy, Component, ElementRef, Input, Renderer2 } from '@angular/core';
import {
  CustomNavigationUiComponent
} from "../../../footer-navigation/custom-navigation-ui/custom-navigation-ui.component";
import { NavigationNodeExtended } from "../../../../../interfaces/login";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { HamburgerMenuService, NavigationNode } from "@spartacus/storefront";
import { WindowRef } from "@spartacus/core";
import { CustomerService } from "../../../../../services/customer.service";
import { distinctUntilChanged, map } from "rxjs/operators";

@Component({
  selector: 'cx-header-navigation',
  templateUrl: './header-navigation.component.html',
  styleUrls: ['./header-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderNavigationComponent extends CustomNavigationUiComponent {
  @Input() isAllCategoriesVisible: boolean;

  override node: NavigationNodeExtended | null;
  isWrapperVisible = false;
  isAllCategoriesExpanded = false;
  activeCategorySlide = 1;
  isMobile: boolean;
  private customSubscriptions = new Subscription();

  constructor(
    router: Router,
    protected _renderer: Renderer2,
    protected _elemRef: ElementRef,
    protected override hamburgerMenuService: HamburgerMenuService,
    protected override winRef: WindowRef,
    protected customerService: CustomerService
  ) {
    super(router, _renderer, _elemRef, hamburgerMenuService, winRef);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.subscribeToHamburgerMenuExpand();
    this.setCompanyNumber();
    this.subscribeToWindowResize();
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.customSubscriptions.unsubscribe();
  }

  onMouseClickAllCategories(categories: NavigationNodeExtended[]): void {
    if (this.isAllCategoriesExpanded) {
      this.deactivateAllCategories(categories);
      return;
    }
    this.isAllCategoriesExpanded = true;
    this.activeCategorySlide = 1;
  }

  deactivateAllCategories(categories: NavigationNodeExtended[]): void {
    this.deactivateCategories(categories);
    this.isAllCategoriesExpanded = false;
    this.activeCategorySlide = 1;
  }

  deactivateAllCategoriesByClickOutside(categories: NavigationNodeExtended[]): void {
    if (this.isMobile) {
      return;
    }
    this.deactivateAllCategories(categories);
  }

  override onMouseEnter(event: MouseEvent): void {
    this.isWrapperVisible = true;
  }

  override toggleOpen(event: UIEvent) {
    const currentTarget: Element = event.currentTarget as Element;
    if (!!currentTarget?.closest('li')?.classList.contains('is-open')) {
      this.back();
    } else {
      this.back();
      super.toggleOpen(event);
    }
  }

  closeMenu(): void {
    this.isWrapperVisible = false;
    this.isAllCategoriesExpanded = false;
    this.back();
  }

  onCategoryMouseEnter(categories: NavigationNodeExtended[], index: number, activeCategorySlide = 0) {
    if (this.isMobile || (!this.isMobile && activeCategorySlide >= 4)) {
      return;
    }
    categories.forEach((category, categoryIndex) => {
      if (category.isSelected && (index !== categoryIndex))
        category.isSelected = false
    });
    categories[index].isSelected = true;

    this.deactivateCategories(categories[index].children);
    this.activeCategorySlide = categories[index].children?.length ? activeCategorySlide + 1 : activeCategorySlide;
  }

  onCategoryMouseClick(categories: NavigationNodeExtended[], index: number, activeCategorySlide = 0) {
    if (!categories[index].children?.length) {
      return;
    }
    categories.forEach((category, categoryIndex) => {
      if (category.isSelected && (index !== categoryIndex))
        category.isSelected = false
    });
    categories[index].isSelected = true;

    this.deactivateCategories(categories[index].children);
    this.activeCategorySlide = activeCategorySlide + 1;
  }

  trackByItem(_: number, item: NavigationNode): string {
    return item?.title;
  }

  getActiveCategory(categories: any[]) {
    if (!categories) {
      return;
    }
    return categories.find(category => category.isSelected);
  }

  categoryBack(parentCategory: any, event: MouseEvent) {
    this.deactivateCategories(parentCategory.children);
    this.activeCategorySlide = this.activeCategorySlide > 1 ? this.activeCategorySlide - 1 : 1;
    parentCategory.isSelected = false
    event.stopPropagation();
  }

  deactivateCategories(categories: any[], depth?: number) {
    if (!categories?.length) {
      return;
    }
    categories?.forEach(category => {
      if ('isSelected' in category) {
        category.isSelected = false;
      }

      if (category.children && Array.isArray(category.children)) {
        this.deactivateCategories(category.children);
      }
    });
  }

  toggleHeader() {
    this.hamburgerMenuService.toggle();
    this.isWrapperVisible = false;
    this.isAllCategoriesExpanded = false;
  }

  private subscribeToHamburgerMenuExpand() {
    this.customSubscriptions.add(
      this.hamburgerMenuService?.isExpanded
        .pipe(distinctUntilChanged())
        .subscribe((val) => !val && this.back())
    );
  }

  private setCompanyNumber() {
    const activeNode = this.node?.children[0]?.children?.find((x) => x.isActive);

    if (activeNode) {
      const companyNumber = activeNode.uid?.split('_')[0];
      localStorage.setItem('companyNumber', companyNumber);
      this.customerService.setCurrentB2bUnit(companyNumber);
    }
  }

  private subscribeToWindowResize() {
    this.customSubscriptions.add(
      this.winRef.resize$
        .pipe(
          distinctUntilChanged((_, current) => current.target.innerWidth > 992 === this.isMobile),
          map(win => win.target.innerWidth <= 992)
        ).subscribe(isMobile => this.isMobile = isMobile)
    )
  }

}

