<form [formGroup]="formGroup">
    <div class="d-flex justify-content-between align-items-center">
        <h2 *ngIf="!(isCartMixed && currentStepId == 'subscriptionInfo')" class="cx-checkout-title d-block">
            {{ 'checkoutAddress.details' | cxTranslate }}
        </h2>
        <h2 *ngIf="isCartMixed && !isServicePartsEnabled && currentStepId == 'subscriptionInfo'" class="cx-checkout-title d-block">
            {{ 'checkoutAddress.subscriptionDetails' | cxTranslate }}
        </h2>
        <cx-offline-badge *ngIf="!isServicePartsEnabled" [internalCalculation]="internalCalculation"></cx-offline-badge>
    </div>
    <generac-checkout-delivery-groups
        *ngIf="isCartMixed && isServicePartsEnabled && currentStepId == 'subscriptionInfo' && subsShippingGroup?.manufactureGroups?.length > 0"
        [shippingGroup]="subsShippingGroup"
        [cartCode]="cartCode"
        [purchaseOrderNumber]="checkoutInfo?.purchaseOrderNumber"
        [isServicePartsEnabled]="isServicePartsEnabled"
    ></generac-checkout-delivery-groups>

    <div class="subscription-details-info col-12" *ngIf="isCartMixed && currentStepId == 'subscriptionInfo'">
        <cx-icon class="regular-icon grey not-active" [type]="iconTypes.INFO"></cx-icon>
        <span class="info-desc">
            {{ 'checkoutAddress.subscriptionDetailsInfo' | cxTranslate }}
        </span>
    </div>

    <div *ngIf="!isCartMixed && currentStepId !== 'deliveryAddress'" class="row">
        <div class="col-md-6 col-lg-6">
            <label>
                <span class="label-content">
                    {{ formConfig[0].items[0].label | cxTranslate }}<span
                        *ngIf="formConfig[0].items[0].isRequired">*</span>
                </span>
                <input class="form-control" [formControlName]="formConfig[0].items[0].formControlName"
                    [maxlength]="formConfig[0].items[0].maxLength" type="text"
                    placeholder="{{ formConfig[0].items[0].placeholder | cxTranslate }}"
                    value="{{ formConfig[0].items[0].value }}" />
                <cx-form-errors [control]="formGroup.get(formConfig[0].items[0].formControlName)"></cx-form-errors>
            </label>
        </div>
    </div>

    <ng-container *ngIf="currentStepId == 'deliveryAddress'; else subscriptionForm">
        <cx-address-details-form [formGroup]="formGroup" [checkoutInfo]="checkoutInfo"></cx-address-details-form>
    </ng-container>

    <ng-template #subscriptionForm>
        <mat-accordion *ngIf="subscriptionInfo?.length" [multi]="true" [formArrayName]="'subscriptions'">
            <mat-expansion-panel *ngFor="let subscriptionItem of subscriptionInfo; let i = index;"
                (opened)="panelOpenState[i] = true" (closed)="panelOpenState[i] = false" [expanded]="panelOpenState[i]">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <img *ngIf="getItemFormGroupByIndex(i)?.status == 'VALID' && getItemFormGroupByIndex(i)?.status !== 'PENDING'"
                            src="../../../../../../assets/icons/check-green.svg" />
                        <img *ngIf="getItemFormGroupByIndex(i)?.status == 'INVALID' && getItemFormGroupByIndex(i)?.status !== 'PENDING' && getItemFormGroupByIndex(i)?.touched"
                            src="../../../../../../assets/icons/alert-circle-red.svg" />
                        <span>{{ subscriptionItem.name }}</span>
                    </mat-panel-title>
                    <mat-panel-description
                        *ngIf="getItemFormGroupByIndex(i)?.status == 'VALID' && 
                        getItemFormGroupByIndex(i)?.getRawValue().firstName && getItemFormGroupByIndex(i).getRawValue().lastName">
                        <span>
                            {{ 'checkoutAddress.currentlyAssignedTo' | cxTranslate }} 
                            {{ getItemFormGroupByIndex(i)?.getRawValue().firstName + ' ' + getItemFormGroupByIndex(i)?.getRawValue().lastName }}
                        </span>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <cx-address-details-form [product]="subscriptionItem" [formGroup]="formGroup" [index]="i"
                    [checkoutInfo]="checkoutInfo"></cx-address-details-form>
            </mat-expansion-panel>
        </mat-accordion>
    </ng-template>
</form>