import { CmsConfig, PageMetaResolver } from "@spartacus/core";
import { GeneracAddressFormComponent } from "../features/address-book/address-form/address-form.component";
import { BundleCarouselComponent } from "src/app/pages/bundle-carousel/bundle-carousel.component";
import { SavedAddressesComponent } from "../features/saved-addresses/saved-addresses.component";
import { GeneracProductFacetNavigationComponent } from "src/app/shared/components/generac-product-facet-navigation/product-facet-navigation.component";
import { GeneracProductImageZoomProductImagesComponent } from "../features/product/product-image-zoom-product-images/product-image-zoom-product-images.component";
import { GeneracShippingAccountsComponent } from "src/app/pages/shipping-accounts/shipping-accounts.component";
import { GeneracShippingAccountsResolver } from "src/app/pages/shipping-accounts/shipping-accounts.resolver";

// Created this config to move all components overrides later
export const cmsConfig: CmsConfig = {
  cmsComponents: {
    AddressFormComponent: {
      component: GeneracAddressFormComponent,
    },
    BundleHomepageCarouselComponent: {
      component: BundleCarouselComponent,
    },
    SavedAddressesComponent: {
      component: SavedAddressesComponent,
    },
    ProductRefinementComponent: {
      component: GeneracProductFacetNavigationComponent,
    },
    ProductImagesComponent: {
      component: GeneracProductImageZoomProductImagesComponent,
    },
    ShippingAccountsComponent: {
      component: GeneracShippingAccountsComponent,
      providers: [{
        provide: PageMetaResolver,
        useExisting: GeneracShippingAccountsResolver,
        multi: true,
      }]
    }
  }
}