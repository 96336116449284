import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { CmsService, EventService, I18nModule, Page, User } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { filter, take, tap } from 'rxjs/operators';
import { ProductExtended } from 'src/app/interfaces/product';
import { FavoritesService } from 'src/app/services/favorites.service';
import { ProductUpdatedEvent } from '../../product/product-list/model/product.events';
import { Subscription } from 'rxjs';
import { ICON_TYPE, IconModule, LaunchDialogService } from "@spartacus/storefront";
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'add-to-favorites',
  templateUrl: './add-to-favorites.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    I18nModule,
    IconModule
  ]
})
export class AddToFavoritesComponent implements OnDestroy {
  @Input() product: ProductExtended;
  @Input() gaListName: string;
  @Input() gaProductCategories: { name: string; }[];
  userId: string;
  page: string;
  iconTypes = ICON_TYPE;

  private subscriptions = new Subscription();

  constructor(
    private favoritesService: FavoritesService,
    private userAccount: UserAccountFacade,
    protected cd: ChangeDetectorRef,
    protected eventService: EventService,
    protected cmsService: CmsService,
    protected launchDialogService: LaunchDialogService,
    protected activatedRoute: ActivatedRoute,
  ) {
    this.subscribeToUser();
    this.subscribeToPageUid();
  }

  protected openModal(productCode: string): void {
    const addToFavoritesDialogData = {
        productCode: productCode,
        userId: this.userId,
        gaListName: this.gaListName,
        gaProductCategories: this.gaProductCategories,
    };

    const dialog = this.launchDialogService.openDialog(
        'ADD_TO_FAVORITES',
        undefined,
        undefined,
        addToFavoritesDialogData
    );

    if (dialog) {
        dialog.pipe(take(1)).subscribe();
    }
    this.subscriptions.add(
      this.launchDialogService.dialogClose
        .pipe(filter((result) => result?.code == this.product.code))
        .subscribe((product) => {
          this.setProduct(product);
        })
    );
}

  private setProduct(product: ProductExtended): void {
    this.product = product;
    this.favoritesService.setFavoritesListChanged(true);
    this.cd.markForCheck();
    this.eventService.dispatch(
      { productCode: product.code, type: 'ProductUpdatedEvent' },
      ProductUpdatedEvent
    );
  }

  private subscribeToPageUid(): void {
    this.subscriptions.add(
      this.cmsService
        .getCurrentPage()
        .pipe(
          filter<Page>(Boolean),
          take(1),
          tap((cmsPage: Page) => {
            this.page = cmsPage.pageId;
          })
        ).subscribe()
    )
  }

  private subscribeToUser(): void {
    this.subscriptions.add(
      this.userAccount.get().pipe(
        filter(Boolean),
        tap((user: User) => {
          this.userId = user?.uid;
        })
      ).subscribe()
    )
  }

  public starClick(productCode: string): void {
    if(this.page == 'favorites') {
      const listId = this.activatedRoute.snapshot?.queryParams?.listId;
      this.favoritesService.deleteFromFavorites(this.userId, productCode, listId)
        .subscribe(()=> {
          this.favoritesService.setFavoritesListChanged(true);
        });
    } else {
      this.openModal(productCode);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }
}
