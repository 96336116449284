<div
  class="cx-custom-item-counter"
  (cxClickOutside)="removeMessage()"
  (mouseleave)="removeMessage()"
  (focus)="setInfoMessage()"
  (mouseenter)="setInfoMessage()"
  [ngClass]="{ 'cx-custom-item-counter--readonly': readonly }"
>
  <button
    type="button"
    class="cx-custom-item-counter__btn decrement"
    (click)="decrement()"
    [disabled]="control.disabled || control.value <= min"
    [tabindex]="control.disabled || control.value <= min ? -1 : 0"
    [attr.aria-label]="'itemCounter.removeOne' | cxTranslate"
  >
    -
  </button>
  <input
    #qty
    type="number"
    class="cx-custom-item-counter__input input"
    [min]="min"
    [max]="max"
    [step]="step"
    [readonly]="readonly"
    [tabindex]="readonly ? -1 : 0"
    [formControl]="control"
    [attr.aria-label]="'itemCounter.quantity' | cxTranslate"
    (paste)="$event.preventDefault()"
    (keydown)="onQtyChange($event)"
  />
  <cx-info-message
    [message]="infoMessage"
    [position]="messagePosition"
  ></cx-info-message>
  <button
    type="button"
    class="cx-custom-item-counter__btn increment"
    (click)="increment()"
    [disabled]="control.disabled || control.value >= max"
    tabindex="0"
    attr.aria-label="{{ 'itemCounter.addOneMore' | cxTranslate }}"
  >
    +
  </button>
</div>
