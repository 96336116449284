<div [cxFocus]="focusConfig" (esc)="close('Escape clicked')" class="delete-saved-address-dialog">
  <div class="delete-saved-address-dialog-container">
    <!-- Modal Header -->
    <div class="modal-header delete-saved-address-dialog-header">
      <div class="delete-saved-address-dialog-title modal-title">
      </div>

      <button (click)="close('Close Dialog')" [attr.aria-label]="'common.close' | cxTranslate"
        class="delete-saved-address-dialog-close close" type="button">
        <span aria-hidden="true">
          <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
        </span>
      </button>
    </div>

    <!-- Modal Body -->
    <div class="delete-saved-address-dialog-body">
      <p>{{'savedAddress.deleteAddressConfirmationMessage' | cxTranslate}}</p>
    </div>

    <!-- Modal Footer -->
    <div class="delete-saved-address-dialog-footer">
      <button (click)="close('Close Dialog')" [attr.aria-label]="'common.close' | cxTranslate"
        class="btn btn-action" type="button" [disabled]="loading">
        {{ 'common.cancel' | cxTranslate }}
      </button>
      <button (click)="deleteSavedAddress()" [disabled]="loading" [attr.aria-label]="'common.yes' | cxTranslate"
        class="btn btn-primary" type="button">
        {{ 'common.yes' | cxTranslate }}
      </button>
    </div>
  </div>
</div>