/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AuthService,
  GlobalMessageService,
  GlobalMessageType,
  HttpErrorHandler,
  HttpResponseStatus, OccEndpointsService,
  Priority
} from '@spartacus/core';

@Injectable({
  providedIn: 'root',
})
export class ForbiddenHandler extends HttpErrorHandler {
  override responseStatus = HttpResponseStatus.FORBIDDEN;

  handleError(request: HttpRequest<any>) {
    if (
      request.url.endsWith(
        this.occEndpoints.buildUrl('user', {
          urlParams: { userId: 'current' },
        })
      )
    ) {
      this.authService.logout();
    } else if(request.url.includes('cpiPriceBundle')) {
      return;
    }
    this.globalMessageService.add(
      { key: 'httpHandlers.forbidden' },
      GlobalMessageType.MSG_TYPE_ERROR
    );
  }

  getPriority(): Priority {
    return Priority.NORMAL;
  }

  constructor(
    protected override globalMessageService: GlobalMessageService,
    protected authService: AuthService,
    protected occEndpoints: OccEndpointsService
  ) {
    super(globalMessageService);
  }
}