/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';
import { RouterStateSnapshot, UrlTree } from '@angular/router';
import {
  CmsActivatedRouteSnapshot,
  CmsService,
  isNotUndefined, ProtectedRoutesGuard,
  RouteLoadStrategy, RoutingConfigService,
  RoutingService,
} from '@spartacus/core';
import { Observable, of, Subscription } from 'rxjs';
import { filter, first, skip, switchMap, take, tap } from 'rxjs/operators';
import { CmsPageGuardService } from "@spartacus/storefront";
import { CustomerService } from '../services/customer.service';

@Injectable({
  providedIn: 'root',
})
export class CustomCmsPageGuard {
  static guardName = 'CustomCmsPageGuard';
  private subscription: Subscription;

  constructor(
    protected routingService: RoutingService,
    protected cmsService: CmsService,
    protected protectedRoutesGuard: ProtectedRoutesGuard,
    protected service: CmsPageGuardService,
    protected routingConfig: RoutingConfigService,
    private customerService: CustomerService
  ) {
  }

  /**
   * Tries to load the CMS page data for the anticipated route and returns:
   * - `true` - if it can be activated
   * - `false` - if it cannot be activated
   * - `UrlTree` - if user should be redirected to a given `UrlTree`
   *
   * If the route can be activated, it fires additional calculations on the CMS components present on this CMS page,
   * based on their configuration (`cmsComponents` config).
   *
   * For more, see docs of the `CmsPageGuardService.canActivatePage`.
   */
  canActivate(
    route: CmsActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    this.subscribeToUnitChange(route, state);
    return this.protectedRoutesGuard.canActivate(route)
      .pipe(
        switchMap((canActivate) =>
          canActivate === true
            ? this.routingService.getNextPageContext().pipe(
              filter(isNotUndefined),
              take(1),
              switchMap((pageContext) =>
                this.cmsService.getPage(pageContext, this.shouldReload()).pipe(
                  first(),
                  switchMap((pageData) =>
                    pageData
                      ? this.service.canActivatePage(
                        pageContext,
                        pageData,
                        route,
                        state
                      )
                      : this.service.canActivateNotFoundPage(
                        pageContext,
                        route,
                        state
                      )
                  )
                )
              )
            )
            : of(canActivate)
        )
      );
  }

  private subscribeToUnitChange(
    route: CmsActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    const url = `${route.data.cxRoute}/${route.params.productCode}/${route.params.urlName}`;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.customerService.getB2bUnitChanged()
      .pipe(
        skip(1),
        switchMap(() => this.routingService.getRouterState().pipe(
          take(1),
          tap(routerState => {
            if(routerState.state.semanticRoute && routerState.state.semanticRoute !== "product") {
              this.subscription.unsubscribe();
            }
          })
        )),
        switchMap(() => this.routingService.goByUrl(url + "#refresh")),
        switchMap(() => this.routingService.goByUrl(url)),
        switchMap(() => this.routingService.getPageContext().pipe(take(1))),
        switchMap(pageContext => this.cmsService.getPage(pageContext, true)
          .pipe(
            first(),
            switchMap((pageData) =>
              pageData
                ? this.service.canActivatePage(
                  pageContext,
                  pageData,
                  route,
                  state
                )
                : this.service.canActivateNotFoundPage(
                  pageContext,
                  route,
                  state
                )
            ),
          )
        )
      ).subscribe();
  }

  /**
   * Returns whether we should reload the CMS page data, even when it was loaded before.
   */
  private shouldReload(): boolean {
    return this.routingConfig.getLoadStrategy() !== RouteLoadStrategy.ONCE;
  }

}
