import { NgModule } from '@angular/core';
import { CustomOrderConfirmationThankYouMessageComponent } from './custom-order-confirmation-thank-you-message.component';
import { I18nModule } from '@spartacus/core';
import { OrderConfirmationModule } from '@spartacus/order/components';
import { PwaModule } from '@spartacus/storefront';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [CustomOrderConfirmationThankYouMessageComponent],
  imports: [CommonModule, I18nModule, OrderConfirmationModule, PwaModule],
})
export class CustomOrderConfirmationThankYouMessageModule {}
