<div [cxFocus]="focusConfig" (esc)="close('Escape clicked')" class="edit-saved-address-dialog">
  <div class="edit-saved-address-dialog-container">
    <!-- Modal Header -->
    <div class="modal-header edit-saved-address-dialog-header">
      <div class="edit-saved-address-dialog-title modal-title">
        {{ mode == 'edit' ? ('savedAddress.editAddress' | cxTranslate) : ('savedAddress.addAddress' | cxTranslate) }}
      </div>

      <button (click)="close('Close Dialog')" [attr.aria-label]="'common.close' | cxTranslate"
        class="edit-saved-address-dialog-close close" type="button">
        <span aria-hidden="true">
          <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
        </span>
      </button>
    </div>

    <!-- Modal Body -->
    <div class="edit-saved-address-dialog-body">
      <generac-address-form
        [generacAddressData]="savedAddressInit"
        [showSubmitBtn]="true"
        [showCancelBtn]="false"
        [editSavedAddressMode]="true"
        [isEditable]="true"
        (submitAddress)="savedAddressChange($event)"
      >
      </generac-address-form>
    </div>

    <!-- Modal Footer -->
    <div class="edit-saved-address-dialog-footer">
      <button (click)="close('Close Dialog')" [attr.aria-label]="'common.close' | cxTranslate"
        class="btn btn-action" type="button" [disabled]="loading">
        {{ 'common.cancel' | cxTranslate }}
      </button>
      <button (click)="createOrEditSavedAddress()" [disabled]="!savedAddress || !isSaveAllowed || loading" [attr.aria-label]="'common.save' | cxTranslate"
        class="btn btn-primary" type="button">
        {{ 'addToWishList.addToFavoriteModal.save' | cxTranslate }}
      </button>
    </div>
  </div>
</div>