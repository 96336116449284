import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { ICON_TYPE } from "@spartacus/storefront";
import { MSRPPrice, ProductExtended } from "../../../../interfaces/product";
import { combineLatest, Subscription } from "rxjs";
import { Product, User } from "@spartacus/core";
import { ProductService } from "../../../../services/product.service";
import { UserAccountFacade } from "@spartacus/user/account/root";
import { filter, finalize, take, tap } from "rxjs/operators";
import { BaseStoreService } from "../../../../services/base-store.service";
import { TooltipPosition } from "../../../../enums/tooltip-position.enum";

@Component({
  selector: 'cx-dealer-acquisition-product',
  templateUrl: './dealer-acquisition-product.component.html',
  styleUrls: ['./dealer-acquisition-product.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DealerAcquisitionProductComponent implements OnInit, OnDestroy {

  @Input() product: ProductExtended;

  tooltipMessage: string;
  callForPricingMessage = '';
  contactForPricingHref: string;
  TooltipPosition = TooltipPosition;
  private subscriptions = new Subscription();

  constructor(
    private cdr: ChangeDetectorRef,
    private baseStoreService: BaseStoreService,
  ) {
  }

  ngOnInit(): void {
    this.getTooltip();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private getTooltip() {
    this.subscriptions.add(
      combineLatest([
        this.baseStoreService.getTooltipMessage(),
        this.baseStoreService.getPhoneNumber(),
      ]).subscribe(([message, phoneOrEmail]) => {
        this.tooltipMessage = message?.message;

        if(phoneOrEmail?.contactInfo) {
          this.contactForPricingHref = phoneOrEmail?.contactInfo?.includes('@') ? `mailto: ${phoneOrEmail?.contactInfo}` : `tel: ${phoneOrEmail?.contactInfo}`;
        }
        this.cdr.markForCheck();
      })
    );
  }
}
