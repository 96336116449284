import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, I18nModule, provideDefaultConfig } from '@spartacus/core';
import { ProductNotesComponent } from './product-notes.component';
import { SafeModule } from 'src/app/shared/pipes/safe/safe.module';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    SafeModule,
  ],
  providers: [
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        ProductNotesTabComponent: {
          component: ProductNotesComponent,
        },
      },
    }),
  ],
  declarations: [ProductNotesComponent],
  exports: [ProductNotesComponent],
})
export class ProductNotesModule {}
