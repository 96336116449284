import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { SavedCartFormType } from '@spartacus/cart/saved-cart/root';
import { User } from '@spartacus/core';
import { LaunchDialogService, LAUNCH_CALLER } from '@spartacus/storefront';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { SavedCartFacade } from '../../root/facade';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Cart } from '@spartacus/cart/base/root';
import { CustomerService } from 'src/app/services/customer.service';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { CartExtended } from 'src/app/interfaces/cart';

@Component({
  selector: 'cx-saved-cart-list',
  templateUrl: './saved-cart-list.component.html',
  styleUrls: ['./saved-cart-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SavedCartListComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  isAsm: boolean;
  savedCarts$: Observable<CartExtended[]>;
  isLoading$: Observable<boolean>;
  showMyCart$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  hasSharedCarts$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  @ViewChild('element') restoreButton: ElementRef;

  constructor(
    protected savedCartService: SavedCartFacade,
    protected vcr: ViewContainerRef,
    protected launchDialogService: LaunchDialogService,
    private userAccount: UserAccountFacade,
    private router: Router,
    protected customerService: CustomerService,
    protected store: Store
  ) {}

  ngOnInit(): void {
    this.isAsm = !!localStorage.getItem('asm_enabled');
    this.subscription.add(
      this.customerService.getB2bUnitChanged().subscribe(() => {
        this.toggleShowMyCart(true);
        this.savedCartService.loadSavedCarts();
      })
    );
    this.isLoading$ = this.savedCartService.getSavedCartListProcessLoading();
    this.savedCarts$ = combineLatest([
      this.userAccount.get().pipe(filter(Boolean)),
      this.showMyCart$,
    ]).pipe(
      switchMap(([user, showMyCart]: [User, Boolean]) =>
        this.savedCartService.getList().pipe(
          map((lists: any) =>
            lists
              .filter((l: CartExtended) =>  !!l && l.totalUnitCount > 0 && !!l.cartOwner && l.saveTime)
              .sort((a: CartExtended, b: CartExtended) => {
                const date1: number = a.saveTime
                  ? new Date(a.saveTime).getTime()
                  : new Date().getTime();
                const date2: number = b.saveTime
                  ? new Date(b.saveTime).getTime()
                  : new Date().getTime();
                return date2 - date1;
              })
              .map((l: CartExtended) => ({
                ...l,
                name: !l.saveTime ? `${l.cartOwnerName}_active` : l.name,
              }))
              .filter((item: CartExtended) =>
                showMyCart
                  ? item.cartOwner === user?.uid
                  : item.cartOwner !== user?.uid
              )
          ),
          tap(() =>
            this.hasSharedCarts$.next(user?.roles.includes('sharecartsgroup'))
          )
        )
      )
    );
  }

  toggleShowMyCart(showMyCart: boolean): void {
    this.showMyCart$.next(showMyCart);
  }

  goToSavedCartDetails(cart: CartExtended): void {
    this.router.navigate([`my-account/saved-cart/${cart?.code}`], {
      queryParams: { owner: cart?.cartOwner },
    });
  }

  openDialog(event: Event, cart: Cart): void {
    const dialog = this.launchDialogService.openDialog(
      LAUNCH_CALLER.SAVED_CART,
      this.restoreButton,
      this.vcr,
      { cart, layoutOption: SavedCartFormType.RESTORE }
    );
    if (dialog) {
      this.subscription.add(dialog.pipe(take(1)).subscribe());
    }
    event.stopPropagation();
  }

  ngOnDestroy(): void {
    this.savedCartService.clearSavedCarts();
    this.savedCartService.clearSaveCart();
    this.savedCartService.clearRestoreSavedCart();
    this.subscription?.unsubscribe();
  }
}
