import { Pipe, PipeTransform } from '@angular/core';
import { splitArray } from "../../helpers/split-array-to-chunks";

@Pipe({
  name: 'arraySplit'
})
export class ArraySplitPipe implements PipeTransform {

  transform(array: any[], chunkSize: number = 2) {
    return splitArray(array, 2);
  }

}
