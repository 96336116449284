import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[cxAppendAnchor]'
})
export class AppendAnchorDirective {

  @Input() cxAppendAnchor: string;
  @Input() anchorOffset = true;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {
  }

  ngOnInit() {
    if (!this.cxAppendAnchor) {
      return;
    }
    const child = this.renderer.createElement('a');
    this.renderer.addClass(child, 'anchor');
    if (this.anchorOffset) {
      this.renderer.addClass(child, 'offset');
    }

    this.renderer.setAttribute(child, 'id', this.cxAppendAnchor);
    this.renderer.insertBefore(this.elementRef.nativeElement, child, this.elementRef.nativeElement.firstChild);
  }
}
