import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, } from '@angular/core';
import { WindowRef } from "@spartacus/core";
import { Observable, ReplaySubject, Subscription } from "rxjs";
import { CustomerService } from "../../services/customer.service";
import { filter, tap } from "rxjs/operators";
import { LoadingEnum } from "../../enums/loading.enum";
import { ShippingAccountsService } from 'src/app/services/shipping-accounts.service';
import { ShippingAccount, ShippingAccountCondition } from 'src/app/interfaces/shipping-account';

@Component({
  selector: 'generac-shipping-accounts',
  templateUrl: './shipping-accounts.component.html',
  styleUrls: ['./shipping-accounts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeneracShippingAccountsComponent {
  loading: LoadingEnum = LoadingEnum.Idle;
  LoadingEnum = LoadingEnum;
  shippingAccountsColumns: {cssClass: string, fieldName: keyof ShippingAccount | keyof ShippingAccountCondition, displayName: string}[] = [{
    cssClass: 'active',
    fieldName: 'active',
    displayName: 'active'
  }, {
    cssClass: 'carrier',
    fieldName: 'vendorName',
    displayName: 'carrier',
  }, {
    cssClass: 'shipping-conditions',
    fieldName: 'shippingConditionName',
    displayName: 'shipping conditions',
  }, {
    cssClass: 'account',
    fieldName: 'accountName',
    displayName: 'account',
  }, {
    cssClass: 'contact-name',
    fieldName: 'contactName',
    displayName: 'contact name',
  }, {
    cssClass: 'contact-phone',
    fieldName: 'contactPhone',
    displayName: 'contact phone',
  }, {
    cssClass: 'contact-address',
    fieldName: 'contactAddress',
    displayName: 'contact address',
  }, {
    cssClass: 'ltl-carrier',
    fieldName: 'ltl',
    displayName: 'ltl carrier',
  }, {
    cssClass: 'default',
    fieldName: 'default',
    displayName: 'default',
  }, {
    cssClass: 'account-no',
    fieldName: 'accountNumber',
    displayName: 'account no.',
  }, {
    cssClass: 'note',
    fieldName: 'contactNotes',
    displayName: 'note',
  }];

  @HostListener('window:resize')
  onResize() {
    this.isMobileView = this.winRef?.nativeWindow.innerWidth < 768;
  }

  isMobileView: boolean;

  private shippingAccountsSubject = new ReplaySubject<ShippingAccount[]>(1);
  shippingAccounts$: Observable<ShippingAccount[]> = this.shippingAccountsSubject.asObservable();

  private subscription = new Subscription();

  constructor(
    protected customerService: CustomerService,
    protected winRef: WindowRef,
    private cdr: ChangeDetectorRef,
    private shippingAccountService: ShippingAccountsService,
  ) {
    this.isMobileView = this.winRef?.nativeWindow.innerWidth < 768;
  }

  ngOnInit() {
    this.getShippingAccounts();
    this.subscribeToLoadingState();
    this.subscribeToUnitChange();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  trackByShippingAccounts(_: number, shippingAccount: ShippingAccount): string {
    return shippingAccount.vendorId;
  }

  getShippingAccountValue(
    shippingAccount: ShippingAccount,
    shippingCondition: ShippingAccountCondition,
    fieldName: keyof ShippingAccount | keyof ShippingAccountCondition
  ): any {
    if (fieldName in shippingAccount) {
      return shippingAccount[fieldName as keyof ShippingAccount];
    } else if (fieldName in shippingCondition) {
      return shippingCondition[fieldName as keyof ShippingAccountCondition];
    }
    return null;
  }

  private getShippingAccounts(): void {
    this.subscription.add(
      this.shippingAccountService.getShippingAccounts().pipe(
        tap((shippingAccounts: ShippingAccount[]) => {
          this.shippingAccountsSubject.next(shippingAccounts);
        })
      ).subscribe()
    )
  }

  private subscribeToUnitChange(): void {
    this.subscription.add(
      this.customerService.getB2bUnitChanged().pipe(
        filter((isB2bUnitChanged) => !!isB2bUnitChanged),
        tap(() => this.getShippingAccounts())
      ).subscribe()
    );
  }

  private subscribeToLoadingState() {
    this.subscription.add(
      this.shippingAccountService.loading$
        .subscribe(loading => {
          this.loading = loading;
          this.cdr.markForCheck();
        })
    );
  }
}
