import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AtMessageModule } from "@spartacus/storefront";
import { RouterModule } from "@angular/router";
import { CmsConfig, I18nModule, provideConfig, UrlModule } from "@spartacus/core";
import { ReactiveFormsModule } from "@angular/forms";
import { CheckoutPlaceOrderComponent } from "./checkout-place-order.component";
import { CheckoutAuthGuard } from "@spartacus/checkout/base/components";
import { delegoDialogLayoutConfig } from "../../../../configs";
import { CustomCartNotEmptyGuard } from "../../../../../guards/custom-cart-not-empty.guard";

@NgModule({
  imports: [
    AtMessageModule,
    CommonModule,
    RouterModule,
    UrlModule,
    I18nModule,
    ReactiveFormsModule,
  ],
  providers: [
      provideConfig(delegoDialogLayoutConfig),
      provideConfig(<CmsConfig>{
        cmsComponents: {
          CheckoutPlaceOrder: {
            component: CheckoutPlaceOrderComponent,
            guards: [CheckoutAuthGuard, CustomCartNotEmptyGuard],
          },
        },
      }),
  ],
  declarations: [CheckoutPlaceOrderComponent],
  exports: [CheckoutPlaceOrderComponent],
})
export class CheckoutPlaceOrderModule {}
