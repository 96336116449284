<div class="cx-order-items" *ngIf="order$ | async as order">
    <ng-container *ngIf="!isServicePartsEnabled; else deliveryGroups">
        <h4 class="cx-order-items-header">
            {{ 'checkoutOrderConfirmation.orderItems' | cxTranslate }}
        </h4>

        <cx-cart-item-list
            [groups]="order.entryGroups"
            [readonly]="true"
            [promotionLocation]="promotionLocation"
        ></cx-cart-item-list>
    </ng-container>

    <ng-template #deliveryGroups>
        <generac-shipping-groups
            [shippingGroups]="order.shippingGroups"
            [isOrderConfirmPage]="true">
        </generac-shipping-groups>
    </ng-template>
</div>
