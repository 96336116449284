<cx-spinner
  class="overlay"
  *ngIf="isUpdating$ | async"
></cx-spinner>
<ng-container
  *ngIf="isLocal"
  [ngClass]="{ 'mb-3': isLocal }"
>
  <form
    (ngSubmit)="onSubmit()"
    [formGroup]="form"
  >
    <label>
      <span class="label-content">
        {{ "loginForm.emailAddress.label" | cxTranslate }}
      </span>
      <input
        required="true"
        type="email"
        class="form-control"
        formControlName="userId"
        placeholder="{{ 'loginForm.emailAddress.placeholder' | cxTranslate }}"
      />
      <cx-form-errors [control]="form.get('userId')"></cx-form-errors>
    </label>
    <label>
      <span class="label-content">{{
        "loginForm.password.label" | cxTranslate
      }}</span>
      <input
        required="true"
        type="password"
        class="form-control"
        placeholder="{{ 'loginForm.password.placeholder' | cxTranslate }}"
        formControlName="password"
        [attr.aria-label]="'loginForm.password.placeholder' | cxTranslate"
        cxPasswordVisibilitySwitch
      />
      <cx-form-errors [control]="form.get('password')"></cx-form-errors>
    </label>
    <a
      [routerLink]="{ cxRoute: 'forgotPassword' } | cxUrl"
      class="btn-link"
    >
      {{ "loginForm.forgotPassword" | cxTranslate }}
    </a>
    <button
      type="submit"
      class="btn btn-block btn-primary"
      [disabled]="form.disabled"
    >
      {{ "loginForm.signIn" | cxTranslate }}
    </button>
  </form>
</ng-container>
<div class="login-page">
  <img src="../../../../assets/icons/user-login.svg" />
  <div class="login-desc">
    Login to your account - enjoy exclusive products and many more.
  </div>
  <div
    *ngIf="!isLoading"
    class="login-desc"
  >
    During authorization, you will be redirected to another page
  </div>
  <div
    *ngIf="isLoading"
    class="login-desc"
  >
    Please wait while the login process completes.
  </div>
  <button
    *ngIf="!isLoading"
    type="submit"
    class="btn btn-block btn-primary"
    (click)="loginClick()"
  >
    Click here to continue to Shop
  </button>
  <cx-spinner *ngIf="isLoading"></cx-spinner>
</div>
