<div [cxFocus]="focusConfig" (esc)="close('Escape clicked')" class="create-or-edit-favorites-list-dialog">
  <div class="create-or-edit-favorites-list-dialog-container">
    <!-- Modal Header -->
    <div class="modal-header create-or-edit-favorites-list-dialog-header">
      <div class="create-or-edit-favorites-list-dialog-title modal-title">
        {{ mode == 'create' ? ('addToWishList.favoriteList.newListTitle' | cxTranslate) :
            ('addToWishList.favoriteList.editListName' | cxTranslate) }}
      </div>

      <button (click)="close('Close Create Ot Edit Favorite List Dialog')" [attr.aria-label]="'common.close' | cxTranslate"
        class="create-or-edit-favorites-list-dialog-close close" type="button">
        <span aria-hidden="true">
          <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
        </span>
      </button>
    </div>

    <!-- Modal Body -->
    <div class="create-or-edit-favorites-list-dialog-body">
      <label for="list-name" class="list-name-label"> {{ 'addToWishList.favoriteList.listName' | cxTranslate }} </label>
      <input type="text" id="list-name" maxlength="100" class="list-name-input" [(ngModel)]="list.name" placeholder="{{ 'addToWishList.favoriteList.enterListName' | cxTranslate}}">
    </div>

    <!-- Modal Footer -->
    <div class="create-or-edit-favorites-list-dialog-footer">
      <button (click)="close('Close Add To Favorites Dialog')" [attr.aria-label]="'common.close' | cxTranslate"
        class="btn btn-action" type="button" [disabled]="loading">
        {{ 'common.cancel' | cxTranslate }}
      </button>
      <button (click)="createOrEditFavoritesList(list.name)" [disabled]="list?.name?.length < 1 || loading" [attr.aria-label]="'common.save' | cxTranslate"
        class="btn btn-primary" type="button">
        {{ mode == 'create' ? ('addToWishList.addToFavoriteModal.add' | cxTranslate) :
            ('addToWishList.addToFavoriteModal.save' | cxTranslate) }}
      </button>
    </div>
  </div>
</div>