import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'accountShortcut',
  pure: true
})
export class AccountShortcutPipe implements PipeTransform {

  transform(accountName: string): unknown {
    return accountName?.split('_')[0] || '';
  }

}
