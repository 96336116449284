<div
        [cxFocus]="focusConfig"
        (esc)="closeWithCheck('Escape clicked')"
        class="cfd"
        [class.ty]="isThankYouView"
>
    <div
            class="cfd-container"
    >
        <cx-spinner
                *ngIf="loading === LoadingEnum.Loading"
                class="spinner-overlay"
        ></cx-spinner>

        <div *ngIf="isAreYouSureMessageVisible" class="text-overlay">
            <div class="text-overlay-container">
                <div class="text-overlay-container-header">
                    You have filled data. Are you sure that you want to leave?
                </div>
                <div class="text-overlay-container-btns">
                    <button
                            class="btn btn-action"
                            type="button"
                            (click)="toggleAreYouSureMessage()"
                            [attr.aria-label]="'contactFactory.stay' | cxTranslate"
                    >
                        {{ 'contactFactory.stay' | cxTranslate }}
                    </button>
                    <button
                            class="btn btn-primary"
                            type="button"
                            (click)="close('Close Dialog')"
                            [attr.aria-label]="'contactFactory.leave' | cxTranslate"
                    >
                        {{ 'contactFactory.leave' | cxTranslate }}
                    </button>
                </div>
            </div>
        </div>

        <ng-container *ngIf="isThankYouView; else formView;">
            <div class="cfd-ty-header">Thank You!</div>
            <button
                    class="cfd-close close cfd-ty-close"
                    (click)="close('Close Dialog')"
                    [attr.aria-label]="'common.close' | cxTranslate"
                    type="button"
            >
                <span aria-hidden="true">
                    <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
                </span>
            </button>
            <div class="cfd-ty-text">Your message has been sent and a representative will contact your shortly</div>
        </ng-container>


        <ng-template #formView>
            <div class="modal-header cfd-header">
                <div class="cfd-header-title modal-title">
                    {{isThankYouView ? 'Thank You!' : 'contactFactory.title' | cxTranslate}}
                </div>

                <button
                        class="cfd-close close"
                        (click)="closeWithCheck('Close Dialog')"
                        [attr.aria-label]="'common.close' | cxTranslate"
                        type="button"
                >
        <span aria-hidden="true">
          <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
        </span>
                </button>
            </div>

            <div class="cfd-body">
                <div class="cfd-body-top">
                    <div class="cfd-body-top-header">{{'contactFactory.subTitle' | cxTranslate}}
                    </div>
                    <div class="cfd-body-top-description">{{'contactFactory.serviceText' | cxTranslate}}
                        <a [href]="'tel:'+contactFactoryInfo?.supportPhoneNumber">{{contactFactoryInfo?.supportPhoneNumber}}</a></div>
                </div>
                <form [formGroup]="formGroup">
                    <div class="cfd-body-block">
                        <div class="cfd-body-block-row cfd-space">
                            <div class="cfd-body-block-label">
                                <span>{{'contactFactory.accountNumber' | cxTranslate}}: </span>
                                <span>{{contactFactoryInfo?.accountNumber | accountShortcut}}</span>
                            </div>
                            <div class="cfd-body-block-label">
                                <span>{{'contactFactory.companyName' | cxTranslate}}: </span>
                                <span>{{contactFactoryInfo?.companyName}}</span>
                            </div>
                        </div>
                        <div class="cfd-body-block-content cfd-space">
<!--                            <div class="cfd-body-block-label">-->
<!--                                {{'contactFactory.address' | cxTranslate}}:-->
<!--                            </div>-->

                            <div class="cfd-body-block-row">
                                <label class="cfd-body-block-label">
                                    <span class="input-label required">{{ "contactFactory.firstName.label" | cxTranslate }}</span>
                                    <input
                                            class="form-control"
                                            type="text"
                                            [placeholder]="'contactFactory.firstName.placeholder' | cxTranslate"
                                            formControlName="firstName"
                                    />
                                    <cx-form-errors [control]="formGroup.get('firstName')"></cx-form-errors>
                                </label>

                                <label class="cfd-body-block-label">
                                    <span class="input-label required">{{ "contactFactory.lastName.label" | cxTranslate }}</span>
                                    <input
                                            class="form-control"
                                            type="text"
                                            [placeholder]="'contactFactory.lastName.placeholder' | cxTranslate"
                                            formControlName="lastName"
                                    />
                                    <cx-form-errors [control]="formGroup.get('lastName')"></cx-form-errors>
                                </label>
                            </div>

                            <div class="cfd-body-block-row cfd-space">
                                <label class="cfd-body-block-label">
                                    <span class="input-label required">{{ "contactFactory.email.label" | cxTranslate }}</span>
                                    <input
                                            class="form-control"
                                            type="text"
                                            [placeholder]="'contactFactory.email.placeholder' | cxTranslate"
                                            formControlName="email"
                                    />
                                    <cx-form-errors [control]="formGroup.get('email')"></cx-form-errors>
                                </label>

                                <label class="cfd-body-block-label">
                                    <span class="input-label required">{{ "contactFactory.daytimePhone.label" | cxTranslate }}</span>
                                    <input
                                            class="form-control"
                                            type="text"
                                            [placeholder]="'contactFactory.daytimePhone.placeholder' | cxTranslate"
                                            formControlName="daytimePhone"
                                    />
                                    <cx-form-errors [control]="formGroup.get('daytimePhone')"></cx-form-errors>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="cfd-body-block">
                        <div class="cfd-body-block-row cfd-space">
                            <div class="cfd-body-block-label">
                                <span>{{'contactFactory.subject' | cxTranslate}}: </span>
                                <span>{{contactFactoryInfo?.subject}}</span>
                            </div>
                        </div>
                        <div class="cfd-body-block-row cfd-space">
                            <div class="cfd-body-block-label">
                                <span>{{'contactFactory.itemNumber' | cxTranslate}}: </span>
                                <span>{{contactFactoryInfo?.itemNumber}}</span>
                            </div>
                            <div class="cfd-body-block-label">
                                <span>{{'contactFactory.itemDescription' | cxTranslate}}: </span>
                                <span>{{contactFactoryInfo?.itemDescription}}</span>
                            </div>
                        </div>

                        <div class="cfd-body-block-content cfd-space">
                            <div class="cfd-body-block-row">
                                <label class="cfd-body-block-label">
                                    <span class="input-label required">{{ "contactFactory.modelNumber.label" | cxTranslate }}</span>
                                    <input
                                            class="form-control"
                                            type="text"
                                            [placeholder]="'contactFactory.modelNumber.placeholder' | cxTranslate"
                                            formControlName="modelNumber"
                                    />
                                    <cx-form-errors [control]="formGroup.get('modelNumber')"></cx-form-errors>
                                </label>

                                <label class="cfd-body-block-label">
                                    <span class="input-label required">{{ "contactFactory.serialNumber.label" | cxTranslate }}</span>
                                    <input
                                            class="form-control"
                                            type="text"
                                            [placeholder]="'contactFactory.serialNumber.placeholder' | cxTranslate"
                                            formControlName="serialNumber"
                                    />
                                    <cx-form-errors [control]="formGroup.get('serialNumber')"></cx-form-errors>
                                </label>
                            </div>
                            <div class="cfd-space">
                                <label class="cfd-body-block-textarea">
                                    <span class="input-label required">{{ "contactFactory.comments.label" | cxTranslate }}</span>
                                    <textarea
                                            class="form-control"
                                            formControlName="comments"
                                    ></textarea>
                                    <cx-form-errors [control]="formGroup.get('comments')"></cx-form-errors>
                                </label>
                            </div>
                        </div>
                    </div>

                </form>
            </div>

            <div class="cfd-footer">
                <button
                        class="btn btn-action"
                        type="button"
                        (click)="closeWithCheck('Close Dialog')"
                        [attr.aria-label]="'contactFactory.cancel' | cxTranslate"
                >
                    {{ 'common.cancel' | cxTranslate }}
                </button>
                <button
                        class="btn btn-primary"
                        type="button"
                        (click)="submitContactFactoryRequest()"
                        [attr.aria-label]="'contactFactory.send' | cxTranslate"
                        [disabled]="formGroup.invalid"
                >
                    {{ 'contactFactory.send' | cxTranslate }}
                </button>
            </div>
        </ng-template>
    </div>
</div>